import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExcelConfirmation, ExcelDetails, ExcelResponse } from 'src/app/shared/models/occasional-reward.model';
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
@Component({
  selector: 'app-excel-modal',
  templateUrl: './excel-modal.component.html',
  styleUrls: ['./excel-modal.component.css']
})
export class ExcelModalComponent implements OnInit {
  displayedColumns: string[]
  repeated: boolean;
  limitExceed: boolean;
  alert: string;
  dataSource: MatTableDataSource<ExcelDetails>;
  invalidMailDataSource: MatTableDataSource<string>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<ExcelModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExcelConfirmation) {
    this.displayedColumns = ['name', 'emailId', 'amount', 'title'];
    this.dataSource = new MatTableDataSource<ExcelDetails>();
    this.invalidMailDataSource = new MatTableDataSource<string>();
    this.repeated = false;
    this.alert = '';
    this.limitExceed = false;
  }

  ngOnInit(): void {
    if (this.data) {
      if (this.data?.confirmationType === 'invalid mail') {
        this.handleInvalidMailConfirmation();
      } else if (this.data?.confirmationType === 'repeated') {
        this.handleRepeatedConfirmation();
      } else {
        this.handleLimitExceedConfirmation();
      }
    }
  }

  /**
   * Handles the confirmation for invalid mail
   */
  handleInvalidMailConfirmation(): void {
    if (this.data?.data?.faultyEmailList?.length) {
      this.displayedColumns = ['emailId'];
      this.alert = 'Alert: The following email addresses are incorrect. Kindly correct them and reupload the CSV.';
      this.invalidMailDataSource = new MatTableDataSource<string>(this.data.data.faultyEmailList);
    }
  }

  /**
   * Handles the confirmation for repeated transactions.
   */
  handleRepeatedConfirmation(): void {
    if (this.data?.data?.repeatedTransactions) {
      this.repeated = true;
      this.alert = 'Alert: Same users have been allotted the same amount today.';
    }
    for (const employee of this.data?.data?.employees) {
      employee.title = employee?.previousTitlesForRepeatedTransactions?.join(',') || '';
    }
    this.dataSource.data = this.data?.data?.employees.filter((employee) => employee?.repeatedOccurrence > 0);
  }

  /**
   * Handles the confirmation for limit exceed.
   */
  handleLimitExceedConfirmation(): void {
    this.limitExceed = true;
    this.alert = 'Alert: Some users have received over Rs.2000 in their wallets this week.';
    this.dataSource.data = this.data?.data?.employees.filter((employee) => employee?.weekly2kLimit);
  }

  ngAfterViewInit(): void {
    if (this.repeated || this.limitExceed) {
      this.dataSource.paginator = this.paginator;
    }
    else {
      this.invalidMailDataSource.paginator = this.paginator;
    }
  }

  /**
   * Closes the dialog with the specified result.
   * @param val - The result value to close the dialog with.
   */
  onClose(val: boolean): void {
    this.dialogRef.close(val);
  }

}

