import { NgModule } from '@angular/core';

import { CertificateTableComponent } from './certificate-table/certificate-table.component';
import { CertificateRoutingModule } from '../certificate/certificate-routing.module';
import { CertificateAddModalComponent } from './certificate-add-modal/certificate-add-modal.component';
import { CertificateViewComponent } from './certificate-view/certificate-view.component';
import { ToastrModule } from 'ngx-toastr';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CertificateTableComponent,
    CertificateAddModalComponent,
    CertificateViewComponent
  ],
  imports: [
    ToastrModule,
     PdfViewerModule,
     NgxMatSelectSearchModule,
     CertificateRoutingModule,
     SharedModule
  ]
})
export class CertificateModule { }
