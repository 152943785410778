import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import * as moment from 'moment';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { SocketService } from 'src/app/services/socket.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import {
  IData,
  INotification,
  NotificationDetails,
} from '../../shared/models/notification.model';

import { MatMenuTrigger } from '@angular/material/menu';
import { ViewProfileComponent } from 'src/app/modules/dashboard/view-profile/view-profile.component';
import { URLs } from 'src/app/shared/constants/constants';
import { GenericModalComponent } from 'src/app/shared/components/generic-modal/generic-modal.component';
import { genericDailogData } from 'src/app/shared/models/confirmation-modal.model';
import { NOTIFICATION_IMAGE_MAP } from './../../shared/constants/constants';

@Component({
  selector: 'app-header-section',
  templateUrl: './header-section.component.html',
  styleUrls: ['./header-section.component.css'],
})
export class HeaderSectionComponent implements OnInit {
  urls = URLs;
  notificationImageMap = NOTIFICATION_IMAGE_MAP;
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  durationInSeconds: number;
  empId: String;
  navBool: boolean;
  mobile: boolean;
  empEmail: String;
  isLoggedIn: boolean;
  notifications: any;
  notifyData: NotificationDetails[];
  pageNo: number;
  isAdmin: boolean;
  isAdminManager: boolean;
  admin: boolean;
  currentDate: Date;
  status: boolean;
  oneTime: boolean;
  nextpageData: number;
  checkManager: boolean;
  stompClient: any;
  @ViewChild('menuBtn1', { read: MatMenuTrigger, static: false })
  'msg': MatMenuTrigger;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  constructor(
    private _responsiveService: ResponsiveService,
    private authUser: AuthUserService,
    private _socketService: SocketService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router,
    public commonService: CommonService
  ) {
    this.empEmail = '';
    this.mobile = false;
    this.empId = '';
    this.checkManager = false;
    this.nextpageData = 0;
    this.notifyData = [];
    this.currentDate = new Date(moment().format('YYYY-MM-DD'));
    this.pageNo = 0;
    this.isLoggedIn = false;
    this.isAdmin = false;
    this.isAdminManager = false;
    this.admin = false;
    this.navBool = false;
    this.durationInSeconds = 5;
    this.status = true;
    this.oneTime = true;
  }

  ngOnInit(): void {
    this.authUser
      .getEmployeeDetailBehaviorSubject()
      .subscribe((item: IData) => {
        if (item) {
          this.empId = item.data.id;
          this.empEmail = item.data.email;
          this.checkManager = item.isManager;
          this.isAdmin = item.data.admin;
          this.isAdminManager = item.isAdminManager;
          this.admin = item.admin;
          this.isLoggedIn = true;
          if (this.oneTime) {
            this.stompClient = this._socketService.connect();
            this.stompClient.connect(
              { username: localStorage.getItem('email')?.toLowerCase() },
              (_frame: any) => {
                this.stompClient.subscribe(
                  '/users/topic/notification',
                  (notifications: any) => {
                    this.getNotificationCount();

                    this.notifications = JSON.parse(notifications.body);
                    for (const element of this.notifications.notification) {
                      this.openSnackBar(element);
                    }
                  }
                );
              }
            );
            this.oneTime = false;
          }
        } else {
          this.isLoggedIn = false;
        }
      });
    this.checkWidth();
    this.onResize();
  }

  /**
   * this will open the logout modal
   */
  openLogoutModal() {
    let modalData: genericDailogData = {
      title:
        'Are you sure you want to log out? This will end your current session.',
      img: 'assets/images/warning-round corner.svg',
      confirmButtonText: 'LOGOUT',
      cancelButtonText: 'CANCEL',
    };
    const dialog = this.dialog.open(GenericModalComponent, {
      width: '400px',
      data: modalData,
    });
    dialog.afterClosed().subscribe((data: boolean) => {
      if (data) {
        this.Logout();
      }
    });
  }

  /**
   * get the notifications count
   */
  getNotificationCount(): void {
    let email = localStorage.getItem('email');
    if (email !== null) this.empEmail = email;
    this._socketService
      .getNotificationCount(this.empEmail.toLocaleLowerCase())
      .subscribe(
        (data: any) => {
          this.commonService.setNotificationCount(data);
          this.commonService.setNewNotificationCount(data);
        },
        (error) => {}
      );
  }

  /**
   * Logut User
   */
  Logout(): void {
    this.stompClient.disconnect();
    this.authUser.signOut();
  }

  /**
   * show live notification section
   * @param {string}message - note to display
   */
  openSnackBar(message: string): void {
    if (this.authUser.CheckuserState() === true) {
      let snack = this._snackBar.open(message, 'View', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: this.durationInSeconds * 1000,
      });
      snack.onAction().subscribe(() => {
        this.getNewNotification('NEW');
        this.msg.openMenu();
      });
    }
  }

  /**
   * check width, set mobile value
   */
  checkWidth = (): void => {
    this._responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
  };

  /**
   * get the new notification
   */
  getNewNotification(flag: String, event?: Event): void {
    event?.stopPropagation();
    if (flag === 'MORE') {
      this.pageNo = this.pageNo + 1;
      this._socketService
        .getNewNotification(this.empEmail, this.pageNo)
        .subscribe((data: INotification) => {
          if (data.data) {
            this.navBool = true;

            for (let element of data.data) {
              this.notifyData.push(element);
            }
          } else {
            this.navBool = false;
          }
        });
    } else if (flag === 'NEW') {
      this.notifyData = [];
      this.pageNo = 1;
      this._socketService
        .getNewNotification(this.empEmail, this.pageNo)
        .subscribe((data: INotification) => {
          if (data?.data) {
            this.navBool = true;
            this.notifyData = data.data;
            if (this.notifyData.length < 1) {
              this.navBool = false;
            }
            this.getNotificationRead();
            this.commonService.setNotificationCount(0);
          }
        });
    }
  }

  /**
   * change hamburger icon
   */
  change = (): void => {
    if (this.status) {
      this.status = false;
    } else {
      this.status = true;
    }
  };

  /**
   * open live notification section
   */
  openMenu = (): void => {
    this.msg.openMenu();
  };

  /**
   * read the notification count
   */
  getNotificationRead = () => {
    this._socketService.getNotificationRead(this.empEmail).subscribe(
      () => {
        localStorage.setItem('notificationCount', '0');
      },
      () => {
        this.toastr.error('Error while loading data');
      }
    );
  };

  /**
   * refresh the get notification
   */
  refresh = (event: Event): void => {
    this.notifyData = [];
    event.stopPropagation();
    this.getNewNotification('NEW');
  };

  /**
   * set notification count to 0
   */
  menuClosed(): void {
    this.navBool = false;
    this.commonService.setNewNotificationCount(0);
  }

  /**
   * open view profile section
   */
  openDialog = (): void => {
    this.dialog.open(ViewProfileComponent, {
      width: '736px',
      height: '675px',
      panelClass: 'mClass',
    });
  };
  getNextPageData(page: number) {
    this._socketService
      .getNewNotification(this.empEmail, page + 1)
      .subscribe((data) => {
        if (data.data) {
          this.nextpageData = data.data.length;
          console.log(this.nextpageData);
        } else {
          this.nextpageData = 0;
        }
      });
  }

  /**
   * check width
   */
  onResize = (): void => {
    this._responsiveService.checkWidth();
  };

  /**
   * notification redirection
   * @param {string}notificationType - notification type
   * @param {number}eventId - event ID
   * @param  {string}eventType - type of event
   */
  notificationRedirection = (
    notificationType: String,
    eventId: Number,
    eventType: String
  ): void => {
    if (notificationType === 'ClientFeedback') {
      this._router.navigate(['client-feedbacks']);
    } else if (notificationType === 'TeamBuilding') {
      this._router.navigate(['team-building-activity']);
    } else if (notificationType === 'SelfDevelopment') {
      this._router.navigate(['self-development-module']);
    } else if (notificationType === 'Projects') {
      this._router.navigate(['projects']);
    } else if (notificationType === 'Interview') {
      this._router.navigate(['interview-taken']);
    } else if (notificationType === 'TrainingSession') {
      this._router.navigate(['trainingandsessions']);
    } else if (notificationType === 'Certificate') {
      this._router.navigate(['certification']);
    } else if (notificationType === 'Mentorship') {
      this._router.navigate(['mentorship-taken']);
    } else if (notificationType === 'Delegated Contribution Received!') {
      localStorage.setItem('contribution', 'delegate');
      this._router.navigate(['delegate-contributions']);
    } else if (notificationType === 'Pending Activity for Approval') {
      if (eventType === 'Contest') {
        this._router.navigate(['event/rmView', eventId]);
      } else {
        this._router.navigate(['event/nonContest', 'managerView', eventId]);
      }
    } else if (
      notificationType === 'New Event Created' ||
      notificationType === 'Event Approved' ||
      notificationType === 'Event Rejected' ||
      notificationType === 'Enrolled Successfully' ||
      notificationType === 'You’ve been added as a voter' ||
      notificationType === 'Reminder for Enrollment!' ||
      notificationType === 'Winners Are Out !' ||
      notificationType === 'Reminder to give Rating!' ||
      notificationType === 'Event Ended' ||
      notificationType === 'Hurry Up!!' ||
      notificationType === 'Hurry Up!'
    ) {
      if (eventType === 'Contest') {
        this._router.navigate(['event/event', eventId]);
      } else {
        this._router.navigate(['event/nonContest', 'userView', eventId]);
      }
    } else if (notificationType === 'You Won a Prize') {
      this._router.navigate(['rewards']);
    } else if (notificationType === 'Wallet Topped Up') {
      this._router.navigate(['checkout']);
    }
  };
}
