// encryption.service.ts
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ProfileData } from '../shared/models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  private secretKey = '5q/9zP9Xr7M=';

  constructor() {}

  /**
   *  Encrypts data using AES encryption.
   */
  encryptData(data:any) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(data),
      this.secretKey
    ).toString();
  }

  /**
   * Decrypts AES encrypted data.
   */
  decryptData(data: string) {
    const bytes = CryptoJS.AES.decrypt(data, this.secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}
