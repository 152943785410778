import { ResponsiveService } from './../../../services/responsive.service';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  UntypedFormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import { certificate_data } from '../../../shared/models/certificate.model';
import { CertificationService } from '../service/certification.service';
import { ErrorStateMatcher } from '@angular/material/core';
import * as moment from 'moment';
import { MentorshipService } from '../../mentorship/service/mentorship.service';
import { EmployeeTechnology } from 'src/app/shared/models/general.model';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-certification-form',
  templateUrl: './certification-form.component.html',
  styleUrls: ['./certification-form.component.css'],
})
export class CertificationFormComponent implements OnInit {
  modalForm: UntypedFormGroup;
  mobile: boolean;
  tech_obj: EmployeeTechnology;
  other_validator: boolean;
  tech_Send: EmployeeTechnology[];
  submitted: boolean;
  posList: String[];
  changeTriggered: boolean;
  changeTriggeredInvoice: boolean;
  test: String;
  file: any;
  paid: string = '';
  amount: String = '';
  invoiceFile: any;
  fileSizeInKb: number;
  invoiceFileSizeInKb: number;
  paid_validator: boolean;
  minDate: Date;
  @ViewChild('picker') datePicker!: MatDatepicker<Date>;
  maxDate: Date;
  first = false;
  second = false;
  f = false;
  s = false;

  constructor(
    public dialogRef: MatDialogRef<CertificationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: certificate_data,
    private formBuilder: UntypedFormBuilder,
    private certificateService: CertificationService,
    private _responsiveService: ResponsiveService,
    private mentorshipService: MentorshipService,
    private toastr: ToastrService
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.test = '';
    this.posList = [] as String[];
    this.tech_Send = [] as EmployeeTechnology[];
    this.tech_obj = {} as EmployeeTechnology;
    this.other_validator = false;
    this.paid_validator = true;
    this.submitted = false;
    this.changeTriggered = false;
    this.changeTriggeredInvoice = false;
    this.fileSizeInKb = 0;
    this.mobile = false;
    this.invoiceFileSizeInKb = 0;
    this.modalForm = this.formBuilder.group({});
    this.getTechnologies();
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    if (currentMonth >= 3) {
      this.minDate = new Date(currentYear, 3, 1);
    } else if (currentMonth < 3) {
      this.minDate = new Date(currentYear - 1, 3, 1);
    }
    this.modalForm = this.formBuilder.group({
      summary: [this.data.summary, Validators.required],
      technology: [this.data.selectedTechnology, Validators.required],
      attachment: [this.data.attachment],
      attachmentFile: [''],
      invoice: [this.data.invoice],
      attachmentInvoice: [''],
      other: [this.data.other],
      date: [this.data.date, Validators.required],
      ecdc: [this.data.ecdc, Validators.required],
      paid: [this.data.paid, Validators.required],
      amount: [this.data.amount]
    });
    this.setValidator();
    this.onResize();
    this._responsiveService.checkWidth();
  }

  /**
   * open calender for date pick
   */
  openCalender = (): void => {
    this.datePicker.open();
  }

  /**
   * download action
   * @param {Number}sno - certification number
   */
  downloadAttach = (sno: Number): void => {
    this.certificateService.downloadFile(sno).subscribe((binary) => {
      if (binary.data) {
        this.downloadAction(binary.data.url, this.data.attachment);
      }
    });
  }

  /**
   * download Invoice
   * @param {number}sno - certification ID
   */
  downloadInvoice = (sno: Number): void => {
    this.certificateService.downloadFile(sno).subscribe((binary) => {
      if (binary?.data) {
        this.downloadAction(binary?.data?.invoiceUrl, this.data.invoice);
      }
    });
  }



  /**
   * perform download action
   */
  downloadAction(url: string, name: string) {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => this.toastr.error('Error in downloading the data'));
  }


  /**
   * check uploadfile size
   */
  onChange = (event: any): void => {
    this.changeTriggered = true;
    this.file = event.target.files[0];
    this.fileSizeInKb = Math.round(this.file.size / 1024);
  }



  /**
   * check invoice file size
   */
  onChangeInvoice = (event: any): void => {
    this.changeTriggeredInvoice = true;
    this.invoiceFile = event.target.files[0];
    this.invoiceFileSizeInKb = Math.round(this.invoiceFile.size / 1024);
  }


  /**
   * close modal
   */
  onClose = (): void => {
    this.dialogRef.close();
  }


  /**
   * get the technologies list
   */
  getTechnologies = (): void => {
    this.mentorshipService.getTechnologies().subscribe((data) => {
      this.posList = data;
    });
  }


  /**
   * set Validator for files 
   */
  setValidator = (): void => {
    if (this.data.attachment == "") {
      this.modalForm.controls["attachmentFile"].setValidators([
        Validators.required,
      ]);
    }
    if (this.data.invoice == "") {
      this.modalForm.controls["attachmentInvoice"].setValidators([
        Validators.required,
      ]);
    }
  }


  /**
   * submit certification form
   */
  onSubmit = (value?: string): void => {
    this.submitted = true;
    if (this.fileSizeInKb > 151) return;
    if (this.invoiceFileSizeInKb > 151) return;
    if (this.modalForm.value.technology.includes('Other')) {
      this.other_validator = true;
      this.modalForm.get('other')?.setValidators([Validators.required]);
      this.modalForm.get('other')?.updateValueAndValidity();
    }
    if (!this.modalForm.value.technology.includes('Other')) {
      this.other_validator = false;
      this.modalForm.get('other')?.clearValidators();
      this.modalForm.get('other')?.updateValueAndValidity();
      this.modalForm.value.other = 0;
    }
    if (this.modalForm.value.paid === 'Paid') {
      this.paid_validator = true;
      this.modalForm.get('amount')?.setValidators([Validators.required]);
      this.modalForm.get('amount')?.updateValueAndValidity();
      if (this.data.invoice === '' && this.modalForm.value.attachmentInvoice === '') {
        this.modalForm.get('attachmentInvoice')?.setValidators([Validators.required]);
      }
      this.modalForm.get('attachmentInvoice')?.updateValueAndValidity();
    }
    if (this.modalForm.value.paid != 'Paid') {
      this.paid_validator = false;
      this.modalForm.get('amount')?.clearValidators();
      this.modalForm.get('amount')?.updateValueAndValidity();
      this.modalForm.get('attachmentInvoice')?.clearValidators();
      this.modalForm.get('attachmentInvoice')?.updateValueAndValidity();
    }
    if (this.modalForm.invalid) return;
    for (const element of this.modalForm.value.technology) {
      this.tech_obj = { employeeTechnology: element };
      this.tech_Send.push(this.tech_obj);
    }
    this.modalForm.value.technology = this.tech_Send;

    if (value === 'add') {
      (this.data.modalCheck === 'ADD') ? this.setApiCall('newAdd') : this.setApiCall('editedAdd');
    }
    else if (value === 'draft') {
      (this.data.modalCheck === 'ADD') ? this.setApiCall('newAddDraft') : this.setApiCall('editedDraft');
    }
    else {
      this.toastr.error('Unable To Perform Action');
    }
  }
  setApiCall = (flagValue: string) => {
    this.modalForm.value.date = moment(this.modalForm.value.date).format(
      'YYYY-MM-DD'
    );
    this.dialogRef.close({
      resultForm: this.modalForm.value,
      flag: flagValue,
      objFile: this.file,
      invoice: this.invoiceFile,
      checkFile: this.changeTriggered,
      invoiceCheckFile: this.changeTriggeredInvoice
    });
  }

  /**
  * check condition for EC
  * requries for design
  */
  clickEc() {
    if (this.second == true) {
      this.second = false;
    }
    this.first = true;
  }

  /**
* check condition for DC
* requries for design
*/
  clickDc() {
    if (this.first == true) {
      this.first = false;
    }
    this.second = true;
  }

  /**
* check condition for paid
* requries for design
*/
  clickPaid() {
    if (this.s == true) {
      this.s = false;
    }
    this.f = true;
  }

  /**
* check condition for non paid
* requries for design
*/
  clickNonPaid() {
    if (this.f == true) {
      this.f = false;
    }
    this.s = true;
  }

  /**
   * check the screen size
   */
  onResize = (): void => {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.mobile = isMobile;
    });
  }

  matcher = new MyErrorStateMatcher();
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid);
  }
}