<div class="container">
  <div *ngIf="isAdminOpened()" class="mb-4 admin-nav">
    <div *ngIf="mobile" class="sidenav-toggle-btn">
      <button class="common-button">
        <img (click)="toggle()" src="../../../../assets/images/sidebar.png" />
      </button>
    </div>
    <div class="first">
      <button class="common-button back_button" (click)="backRoute()">
        <mat-icon> keyboard_arrow_left</mat-icon>
        Back to Contripoint
      </button>
    </div>
    <div *ngIf="!mobile" class="no-sidenav-toggle-btn"></div>
    <div class="profile">
      <div class="admin-nav-profile">
        <div
          [ngClass]="{
            'admin-nav-img-container-mob': mobile
          }"
          class="admin-nav-img-container"
        >
          <img
            *ngIf="admin.img != null"
            class="img-fluid mx-auto admin-nav-profile-img"
            [src]="admin.img"
          />
        </div>
        <div *ngIf="!mobile" class="ml-3 d-flex align-items-center w-75">
          <div>
            <div class="admin-name">{{ admin.name }}</div>
            <div class="admin-designation">Admin</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
