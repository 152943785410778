import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { AuthUserService } from 'src/app/services/auth-user.service';
@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements OnInit {
  content:any;
  points:any;
  rewardPoints:any;
  constructor(public dialogRef: MatDialogRef<SuccessModalComponent>,
    private _authService:AuthUserService) { }
  onClose() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this._authService.getEmployeeDetailBehaviorSubject().subscribe(item =>{
      if(item){
        this.content=item.notificationContent;
        this.points=this.content.split(" ");
        this.rewardPoints=this.points[4];
      }
    });
  }

}
