import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { GenericTable } from '../../models/certificate.model';

@Component({
  selector: 'app-generic-contribution-table',
  templateUrl: './generic-contribution-table.component.html',
  styleUrls: ['./generic-contribution-table.component.css'],
})
export class GenericContributionTableComponent implements OnInit {
  @Input() contributionData: GenericTable;
  @Output() deleteEventEmitter: EventEmitter<number>;
  @Output() viewEventEmitter: EventEmitter<number>;
  @Output() editEventEmitter: EventEmitter<number>;

  screenSize: string;

  constructor(private responsiveService: ResponsiveService) {
    this.screenSize = '';
    this.deleteEventEmitter = new EventEmitter<number>();
    this.viewEventEmitter = new EventEmitter<number>();
    this.editEventEmitter = new EventEmitter<number>();
    this.contributionData = {} as GenericTable;
  }

  ngOnInit(): void {
    console.log(this.contributionData);
    this.checkScreenStatus();
    this.onResize();
  }

  checkScreenStatus(): void {
    this.responsiveService.getScreenStatus().subscribe((screenSize: string) => {
      if (screenSize) {
        this.screenSize = screenSize;
      }
    });
  }

  isScreenSizeXs(): boolean {
    return this.screenSize === 'xs' || this.screenSize === 'sm';
  }

  deleteCertificate(id: number): void {
    this.deleteEventEmitter.emit(id);
  }

  viewCertificate(id: number): void {
    this.viewEventEmitter.emit(id);
  }

  editCertificateDetails(id: number) {
    this.editEventEmitter.emit(id);
  }

  onResize(): void {
    this.responsiveService.checkWidth();
  }
}
