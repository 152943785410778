<div class="row p-4">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button type="button" class="close" (click)="onClose()" aria-label="Close">
            <span aria-hidden="true"><i class="material-icons">close</i></span>
        </button>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-center ml-1 reward-img">
        <img src="../../../../assets/images/star.svg">
    </div>

    <div>
        <p class="congrats-text">
            Congratulations! You are awarded {{this.rewardPoints}} points.
        </p>
        <p>
            Thank You for your contributions. Kindly click on the bell icon to view unread notifications.
        </p>
        <p class="congrats-text mt-4">
            You are a Gem for Gemini !
        </p>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12  col-center ml-1 mt-2">
        <button type="submit" mat-raised-button id="ok_btn" (click)="onClose()">OK</button>
    </div>
</div>