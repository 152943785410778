import { Component, OnInit } from '@angular/core';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  mobile: boolean;
  isLoggedIn: boolean;
  constructor(private _responsiveService: ResponsiveService,
    private authUser: AuthUserService,) { 
    this.mobile=false;
    this.isLoggedIn = false;
  }

  ngOnInit(): void {
    this._responsiveService.getMobileStatus().subscribe(isMobile => {
      if (isMobile) {
        this.mobile = true;
      }
      else {
        this.mobile = false;
      }
    });
    this.authUser.getEmployeeDetailBehaviorSubject().subscribe((res) => {
      if (res) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
    this.onResize();
  }

  onResize() : void{
    this._responsiveService.checkWidth();
  }

}
