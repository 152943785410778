export const GlobalConstants = Object.freeze({
  allImages: {
    searchIcon: '../../../../assets/images/activity-search.svg',
    searchBackground: '../../../assets/images/activity-search-background.svg',
    crossIcon: '../../../../../assets/images/cross-icon.svg',
    unKnownPersonIcon: '../../../../../assets/images/Unknown_person.jpg',
  },
  ecDcList: [
    { id: 1, value: 'Engineering Council', isSelected: false },
    { id: 2, value: 'Delivery Council', isSelected: false },
  ],
});

export const URLs = {
  TEAMS_HELP_CHANNEL:
    'https://teams.microsoft.com/l/team/19%3aNJSJmv6F_m756VkjMcOTSh08XAiYBZ7DJKShyoyMDbI1%40thread.tacv2/conversations?groupId=0cb4e31c-acb7-471c-87c1-bacc90a16f28&tenantId=b9806c7d-9280-4e44-afea-6dc0ff495c2f',
};

export const NOTIFICATION_IMAGE_MAP: { [key: string]: string } = {
  TrainingSession: 'assets/images/trainings-sessions.svg',
  Mentorship: 'assets/images/mentorship.svg',
  TeamBuilding: 'assets/images/team-building.svg',
  Projects: 'assets/images/projects.svg',
  ClientFeedback: 'assets/images/client-feedback.svg',
  Certificate: 'assets/images/certifications.svg',
  SelfDevelopment: 'assets/images/self-development.svg',
  Interview: 'assets/images/interviews-taken.svg',
  'Enrolled Successfully': 'assets/images/enrolledSuccessfully.png',
  'Event Approved': 'assets/images/eventApproved.png',
  'New Event Created': 'assets/images/eventCreated.png',
  'Event Ended': 'assets/images/eventEnded.png',
  'Event Rejected': 'assets/images/eventRejected.png',
  'Hurry Up!!': 'assets/images/hurryUp.png',
  'Hurry Up!': 'assets/images/hurryUp.png',
  'Pending Activity for Approval': 'assets/images/pendingApproval.png',
  'Reminder to give Rating!': 'assets/images/reminderAndVoter.png',
  'You’ve been added as a voter': 'assets/images/reminderAndVoter.png',
  'Reminder for Enrollment!': 'assets/images/reminderEnrollment.png',
  'Wallet Topped Up': '../../assets/images/wallet.svg',
  'Winners Are Out !': 'assets/images/winnersOut.png',
  'You Won a Prize!': 'assets/images/wonPrize.png',
  'Delegated Contribution Received!': 'assets/images/delegate.svg',
};
