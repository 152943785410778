<div>
  <div class="row mt-4">
    <div class="col-lg-4 col-xl-4">
      <div class="mrg-btm">
        <mat-card class="h-100">
          <div class="d-flex space-between">
            <div class="heading">
              <p class="total-contri">Total Contributions</p>
              <span class="number">{{
                contrinutionData.totalContributions |  number:'1.0-0'
              }}</span>
            </div>
            <img
              src="assets/images/total contribution.svg"
              alt="contribution icon"
            />
          </div>
          <div><mat-divider></mat-divider></div>

          <div class="d-flex space space-between py-2">
            <div class="state">
              <img
                class="mr-1"
                src="/assets/images/Pending.svg"
                alt="decline icon"
              />
              <span>Declined</span>
            </div>
            <div class="state-no">
              {{ contrinutionData.declinedContributions |  number:'1.0-0'  }}
            </div>
          </div>
          <div class="d-flex space space-between py-2">
            <div class="state">
              <img
                class="mr-1"
                src="/assets/images/Decline.svg"
                alt="pendingicon"
              />
              <span>Pending</span>
            </div>
            <div class="state-no">
              {{ contrinutionData.pendingContributions |  number:'1.0-0'  }}
            </div>
          </div>
          <div class="d-flex space space-between py-2">
            <div class="state">
              <img
                class="mr-1"
                src="/assets/images/approved-ellipse.svg"
                alt=""
              />
              <span> Approved </span>
            </div>
            <div class="state-no">
              {{ contrinutionData.approvedContributions |  number:'1.0-0'  }}
            </div>
          </div>
        </mat-card>
      </div>
      <div class="mt-3">
        <mat-card class="h-100">
          <div class="d-flex space-between">
            <div>
              <p class="total-contri">Number of Contributors</p>
              <span class="number">{{
                contrinutionData.totalContributors |  number:'1.0-0' 
              }}</span>
              <p>Total Members: {{ contrinutionData.totalMembers |  number:'1.0-0' }}</p>
            </div>
            <div>
              <img
                src="./assets/images/Number of Contributors.svg"
                alt="contrbution icon"
              />
            </div></div
        ></mat-card>
      </div>
    </div>

    <div class="col-lg-8 col-xl-8">
      <mat-card>
        <p class="total-contri">Contribution Count</p>
        <div class="row mrg-top">
          <div class="col-lg-6 col-xl-6 contribution">
            <div class="mb-2">
              <div class="d-flex space-between">
                <span>Certifications</span>
                <span>{{
                  contrinutionData?.categoryCount?.certification |  number:'1.0-0' 
                }}</span>
              </div>
              <div class="progress-container">
                <div
                  class="progress-bar"
                  [ngStyle]="{
                    'width.%': contrinutionPercentage.certification,
                    'background-color': 'rgba(251, 95, 95, 1)'
                  }"
                ></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-flex space-between">
                <span>Interview Taken</span>
                <span>{{ contrinutionData?.categoryCount?.interview  |  number:'1.0-0' }}</span>
              </div>
              <div class="progress-container">
                <div
                  class="progress-bar"
                  [ngStyle]="{
                    'width.%': contrinutionPercentage.interview,
                    'background-color': 'rgba(96, 168, 255, 1)'
                  }"
                ></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-flex space-between">
                <span>Training & Sessions</span>
                <span>{{
                  contrinutionData?.categoryCount?.trainingSessions |  number:'1.0-0' 
                }}</span>
              </div>
              <div class="progress-container">
                <div
                  class="progress-bar"
                  [ngStyle]="{
                    'width.%': contrinutionPercentage.trainingSessions,
                    'background-color': 'rgba(180, 180, 180, 1)'
                  }"
                ></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-flex space-between">
                <span>Mentorship</span>
                <span>{{ contrinutionData?.categoryCount?.mentorship |  number:'1.0-0'  }}</span>
              </div>
              <div class="progress-container">
                <div
                  class="progress-bar"
                  [ngStyle]="{
                    'width.%': contrinutionPercentage.mentorship,
                    'background-color': 'rgba(253, 157, 233, 1)'
                  }"
                ></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-flex space-between">
                <span>Projects</span>
                <span>{{ contrinutionData?.categoryCount?.projects |  number:'1.0-0'  }}</span>
              </div>
              <div class="progress-container">
                <div
                  class="progress-bar"
                  [ngStyle]="{
                    'width.%': contrinutionPercentage.projects,
                    'background-color': 'rgba(194, 135, 253, 1)'
                  }"
                ></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-flex space-between">
                <span>Team Building Activity</span>
                <span>{{
                  contrinutionData?.categoryCount?.teamBuildingActivity |  number:'1.0-0' 
                }}</span>
              </div>
              <div class="progress-container">
                <div
                  class="progress-bar"
                  [ngStyle]="{
                    'width.%': contrinutionPercentage.teamBuildingActivity,
                    'background-color': 'rgba(255, 200, 104, 1)'
                  }"
                ></div>
              </div>
            </div>
            <div class="mb-2">
              <div class="d-flex space-between">
                <span>Client Feedback</span>
                <span>{{
                  contrinutionData?.categoryCount?.clientFeedback |  number:'1.0-0' 
                }}</span>
              </div>
              <div class="progress-container">
                <div
                  class="progress-bar"
                  [ngStyle]="{
                    'width.%': contrinutionPercentage.clientFeedback,
                    'background-color': 'rgba(253, 169, 62, 1)'
                  }"
                ></div>
              </div>
            </div>
            <div>
              <div class="d-flex space-between">
                <span>Self Development Activity</span>
                <span>{{
                  contrinutionData?.categoryCount?.selfDevelopmentActivity |  number:'1.0-0' 
                }}</span>
              </div>
              <div class="progress-container">
                <div
                  class="progress-bar"
                  [ngStyle]="{
                    'width.%': contrinutionPercentage.selfDevelopmentActivity,
                    'background-color': 'rgba(76, 190, 160, 1)'
                  }"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-xl-6">
            <canvas *ngIf="totalCategoryCount !== 0" id="MyChart" #myChart>{{
              chart
            }}</canvas>
            <div *ngIf="totalCategoryCount == 0">
              <img
                src="./assets/images/empty donought.svg"
                alt="empty donought"
              />
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="row mt-3">
      <div class="col-lg-4 col-xl-4"></div>
      <div class="col-lg-8 col-xl-8"></div>
    </div>
  </div>

  <div class="row mt-3 mb-3">
    <div class="col-lg-4 col-xl-4">
      <mat-card class="h-100">
        <p class="total-contri">Top Performers</p>
        <div class="req-container" *ngIf="topPerformerTotal">
          <div
            class="d-flex align-items-center top-peroformer-div"
            *ngFor="
              let employee of contrinutionData?.analyticTopPerfomerContributionsDto
            "
          >
            <div class="ml-3">
              <img
                *ngIf="employee.pos === 1"
                src="./assets/images/gold-performer.svg"
                alt="Gold"
              />
              <img
                *ngIf="employee.pos === 2"
                src="./assets/images/silver badge.svg"
                alt="silver"
              />
              <img
                *ngIf="employee.pos === 3"
                src="./assets/images/bronze badge.svg"
                alt="bronze"
              />
              <img
                *ngIf="employee.pos === 4 || employee.pos === 5"
                src="./assets/images/bronze badge.svg"
                class="visibilty-hidden"
                alt="bronze"
              />
              <img
                class="profile-img ml-3"
                [src]="employee.image"
                alt="emp img"
              />
            </div>
            <div class="ml-3">
              <span class="name">{{ employee?.name }}</span
              ><br />
              <span class="designation">{{ employee?.designation }}</span
              ><br />
              <span class="designation"
                >Points:{{
                  employee?.totalPoints ? employee?.totalPoints : 0
                }}</span
              >
            </div>
          </div>
        </div>
        <div
          *ngIf="!topPerformerTotal"
          class="d-grid align-items-center justify-content-center mt-5"
        >
          <img
            class="mx-auto"
            src="./assets/images/no top performers.svg"
            alt=""
          />
          <p class="no_data mt-1">No Top Performers Yet!</p>
        </div>
      </mat-card>
    </div>
    <div class="col-lg-4 col-xl-4" [ngClass]="{ 'mt-4': isScreenSizeMedium() }">
      <mat-card class="h-100">
        <div *ngIf="categoriesData.mostTrending?.length; else no_data">
          <p class="total-contri mb-5">Most Trending Category</p>
          <img
            class="carousel-left-arrow pointer"
            src="./assets/images/left nav.svg"
            alt="left arrow"
            (click)="previousTrendingCarousel()"
            *ngIf="hasTrendingCategories()"
          />
          <ngb-carousel class="px-2" #trending_carousel>
            <ng-template
              ngbSlide
              *ngFor="let contribution of categoriesData.mostTrending"
            >
              <div class="d-flex justify-content-center">
                <img
                  [src]="contribution?.categoryIcon"
                  alt="contribution category"
                />
              </div>
              <div class="d-flex justify-content-center">
                <span class="category">{{ contribution?.category }} </span>
                <mat-divider></mat-divider>
              </div>
              <div class="row p-1">
                <div class="col-lg-6 col-xl-6 border-right">
                  <p class="category">{{ contribution.contributions |  number:'1.0-0' }}</p>
                  <p class="contri-head">Contributions</p>
                </div>
                <div class="col-lg-6 col-xl-6">
                  <p class="category">{{ contribution.contributors | number:'1.0-0'  }}</p>
                  <p class="contri-head">Contributors</p>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
          <img
            class="carousel-right-arrow pointer"
            src="./assets/images/right nav.svg"
            alt="right arrow"
            *ngIf="hasTrendingCategories()"
            (click)="nextTrendingCarousel()"
          />
        </div>
        <ng-template #no_data>
          <p class="total-contri mb-5">Most Trending Category</p>

          <div class="d-flex align-items-center text-center">
            <img
              class="mx-auto no_data_icon"
              src="assets/images/cross-icon.svg"
            />
          </div>
          <p class="no_data mt-1">No Records Found</p>
        </ng-template>
      </mat-card>
    </div>
    <div class="col-lg-4 col-xl-4" [ngClass]="{ 'mt-4': isScreenSizeMedium() }">
      <mat-card class="h-100">
        <div
          *ngIf="categoriesData.mostDissipating?.length; else no_dissipating"
        >
          <p class="total-contri mb-5">Most Dissipating Category</p>
          <img
            class="carousel-left-arrow pointer"
            src="./assets/images/left nav.svg"
            alt="left arrow"
            (click)="previousDissipatingCarousel()"
            *ngIf="hasDissipatingCategories()"
          />
          <ngb-carousel class="px-2" #disappating_carousel>
            <ng-template
              ngbSlide
              *ngFor="let contribution of categoriesData.mostDissipating"
            >
              <div class="d-flex justify-content-center">
                <img
                  [src]="contribution?.categoryIcon"
                  alt="contribution category"
                />
              </div>
              <div class="d-flex justify-content-center">
                <span class="category">{{ contribution.category  }} </span>
                <mat-divider></mat-divider>
              </div>
              <div class="row p-1">
                <div class="col-lg-6 col-xl-6 border-right">
                  <p class="category">{{ contribution.contributions |  number:'1.0-0' }}</p>
                  <p class="contri-head">Contributions</p>
                </div>
                <div class="col-lg-6 col-xl-6">
                  <p class="category">{{ contribution.contributors |  number:'1.0-0' }}</p>
                  <p class="contri-head">Contributors</p>
                </div>
              </div>
            </ng-template>
          </ngb-carousel>
          <img
            class="carousel-right-arrow pointer"
            src="./assets/images/right nav.svg"
            alt="right arrow"
            *ngIf="hasDissipatingCategories()"
            (click)="nextDissipatingCarousel()"
          />
        </div>
        <ng-template #no_dissipating>
          <p class="total-contri mb-5">Most Dissipating Category</p>

          <div class="d-flex align-items-center text-center">
            <img
              class="mx-auto no_data_icon"
              src="assets/images/cross-icon.svg"
            />
          </div>
          <p class="no_data mt-1">No Records Found</p>
        </ng-template>
      </mat-card>
    </div>
  </div>

  <div class="last-card mb-4" id="carousel-props">
    <div class="row p-3" *ngIf="topCategoriesData?.length">
      <p class="total-contri mrg-lft">Top Performers In Each Category</p>
      <div
        class="col-lg-3 col-xl-3 p-3"
        *ngFor="let contribution of topCategoriesData; let i = index"
      >
        <mat-card class="ngb-card h-100">
          <div
            *ngIf="
              contribution?.analyticTopPerfomerContributionsDto?.length;
              else no_contributions
            "
          >
            <div class="padding-btm">
              <img
                [src]="contribution?.categoryIcon"
                class="contri-icon-props"
                alt="contribution icon"
              />
              <span class="cat-name">{{ contribution?.categoryName }}</span>
            </div>
            <div class="lorem">
              <img
                class="left-arrow-icon pointer"
                src="./assets/images/left nav.svg"
                alt="left arrow"
                (click)="prevSlide(i)"
                *ngIf="hasMultipleTopPerformerContributions(contribution)"
              />
              <ngb-carousel
                #carousel
                [id]="'carousel-' + i"
                id="carousel-properties"
              >
                <ng-template
                  ngbSlide
                  *ngFor="
                    let item of contribution.analyticTopPerfomerContributionsDto
                  "
                >
                  <div class="align-items-center text-center">
                    <img class="carusel-image" [src]="item?.image" alt="" />
                    <div>
                      <span class="carusel-name"> {{ item?.name }}</span>
                    </div>
                    <div>
                      <span class="carusel-name"
                        >Points: {{ item?.totalPoints }}</span
                      >
                    </div>
                  </div>
                </ng-template>
              </ngb-carousel>
              <img
                class="right-arrow-icon pointer"
                src="./assets/images/right nav.svg"
                alt="right arrow"
                *ngIf="hasMultipleTopPerformerContributions(contribution)"
                (click)="nextSlide(i)"
              />
            </div>
          </div>
          <ng-template #no_contributions>
            <div class="padding-btm">
              <img
                [src]="contribution?.categoryIcon"
                class="contri-icon-props"
                alt="contribution icon"
              />
              <span class="cat-name">{{ contribution?.categoryName }}</span>
            </div>
            <div class="lorem">
              <div class="align-items-center text-center">
                <img
                  class="carusel-image"
                  src="../assets/images/empty profile.svg"
                  alt="empty profile"
                />
                <div>
                  <span class="carusel-name"> -</span>
                </div>
                <div>
                  <span class="carusel-name">Points: 0</span>
                </div>
              </div>
            </div></ng-template
          >
        </mat-card>
      </div>
    </div>

    <div *ngIf="topCategoriesData.length === 0" class="p-3">
      <p class="total-contri mrg-lft">Top Performers In Each Category</p>
      <div class="d-grid align-items-center justify-content-center">
        <img
          class="mx-auto"
          src="./assets/images/categorywise zero.svg"
          alt="category wise data"
        />
        <p class="no_data">No Top Performers Right Now</p>
        <span class="no_data_title"
          >Encourage your team to strive for excellence!</span
        >
      </div>
    </div>
  </div>
</div>
X