<div class="req-padding">
  <img class="rounded mx-auto d-block" [src]="data.img" />

  <div>
    <p class="content mt-3 text-center">
      {{ data.title }}
    </p>
  </div>

  <div class="d-flex justify-content-around mt-1">
    <div>
      <button (click)="confirmAction(true)" class="confirmation-btn">
        {{ data.confirmButtonText }}
      </button>
    </div>
    <div>
      <button (click)="confirmAction(false)" class="cancel-btn">
        {{ data.cancelButtonText }}
      </button>
    </div>
  </div>
</div>
