import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ToastrService } from 'ngx-toastr';
import { NominationService } from 'src/app/modules/hr-recommendation/services/nomination.service';
import { attachmentDetails } from '../../models/general.model';
import { CommonService } from 'src/app/services/common.service';
export interface viewDetail {
  url: string;
}
@Component({
  selector: 'app-generic-view-modal',
  templateUrl: './generic-view-modal.component.html',
  styleUrls: ['./generic-view-modal.component.css'],
})
export class GenericViewModalComponent implements OnInit {
  mobile: boolean;
  viewDetail: viewDetail;
  constructor(
    public commonService: CommonService,
    public dialogRef: MatDialogRef<GenericViewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    private _responsiveService: ResponsiveService,
    private apiService: NominationService
  ) {
    this.mobile = false;
    this.viewDetail = {} as viewDetail;
  }

  ngOnInit(): void {
    this.getData();

    this._responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
  }
  /**
   * Close the modal
   */
  onClose = () => {
    this.dialogRef.close();
  };

  /**
   * This method will proivde the current width of screen
   */
  onResize = (): void => {
    this._responsiveService.checkWidth();
  };

  /**
   *  Provide the attachment
   */
  getData() {
    this.apiService
      .downloadAttachment(this.data)
      .subscribe((data: attachmentDetails) => {
        if (data?.attachment) {
          this.viewDetail.url = this.commonService.decodeBase64ToImage(
            data.attachment
          );
        } else {
          this.toastr.error('Unable to load attachment');
        }
      });
  }

  /**
   * This will download the attachment
   */
  download() {
    this.apiService.downloadAttachment(this.data).subscribe((data) => {
      if (data?.attachment) {
        this.downloadActionPrevious(data.attachment, 'attachment');
      } else {
        this.toastr.error('Unable to load attachment');
      }
    });
  }

  /**
   *
   * @param url This method get the url and convert into downloadble file
   */
  // downloadActionPrevious(base64String: String, fileName: string): void {
  //   console.log('hii guys');
  //   const source = `data:application/;base64,${base64String}`;
  //   const link = document.createElement('a');
  //   link.href = source;
  //   link.download = fileName;
  //   link.click();
  // }
  downloadActionPrevious(base64String: String, fileName: string): void {
    console.log(base64String);
    const source = `data:image/png;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = fileName;
    link.click();
  }
  downloadBase64Image(base64String: string, fileName: string) {
    const linkSource = `data:image/png;base64,${base64String}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
}
