import { Injectable } from "@angular/core";
import {
  HttpClient, HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { LeaderBoardData } from "src/app/shared/models/leaderboard.model";

@Injectable({
  providedIn: "root",
})
export class LeaderboardService {
  valueArray: LeaderBoardData[];
  constructor(private http: HttpClient) {
    this.valueArray = [] as LeaderBoardData[];
  }

  getDetails(position: Number): Observable<LeaderBoardData[]> {
    let reqParam=new HttpParams().set('position',position.toString());
    return this.http.get<LeaderBoardData[]>("contripoint/leaderboard", {'params':reqParam});
  }

  setLeaderboardValue(data: LeaderBoardData[]) : void{
    this.valueArray = data;
  }

  getLeaderBoardValue() : LeaderBoardData[] {
    return this.valueArray;
  }
}
