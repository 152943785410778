import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { CommonService } from "src/app/services/common.service";
import { MentorshipService } from "../../../mentorship/service/mentorship.service";
import { BudgetData, EmployeeDropdownData, ParticipantBudgetModal } from "src/app/shared/models/admin-portal.model";
import { genericDailogData } from "src/app/shared/models/confirmation-modal.model";
import { MatDialog } from "@angular/material/dialog";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { EventService } from "../../../events/services/event.service";
import { AdminPortalService } from "../../service/admin-portal.service";
import { ConfirmationModalComponent } from "src/app/shared/components/confirmation-modal/confirmation-modal.component";
import { ErrorStateMatcher } from "@angular/material/core";
import { PageEvent } from "@angular/material/paginator";
import { ResponsiveService } from "src/app/services/responsive.service";
import { CsvModalComponent } from "../csv-modal/csv-modal.component";
import { ToastrService } from "ngx-toastr";
import { csvData } from "src/app/shared/models/admin-portal.model";
import { AuthUserService } from "src/app/services/auth-user.service";

@Component({
  selector: "app-budgeting-section",
  templateUrl: "./budgeting-section.component.html",
  styleUrls: ["./budgeting-section.component.css"],
})
export class BudgetingSectionComponent implements OnInit {
  rewardDistributionForm!: UntypedFormGroup;
  allotmentTypeForm!: UntypedFormGroup;
  searchInput : String;
  searchText:string;
  budgetDataSource: MatTableDataSource<BudgetData>;
  budgetDisplayedColumns: string[];
  totalAmount: Number;
  totalConversionRatio:Number;
  totalContributionPoints: Number;
  genericDailogData:genericDailogData
  employees_list: EmployeeDropdownData[];
  _employees_list : EmployeeDropdownData[];
  participantSet : Map<String, boolean>;
  participantBudget: Map<String, ParticipantBudgetModal>;
  selectedValues: String[];
  removedEmp: String[];
  allSelectedEmployees: Array<BudgetData>;
  tablePageIndex: number;
  tableLength: number;
  pageEvent?: PageEvent;
  updateTable: boolean;
  submitted1: boolean;
  submitted2: boolean;
  splitBtnClicked:boolean;
  edit_NoEdit_IconCount: number;
  updateTableBudget:boolean;
  surplus: Number;
  mobile: boolean;
  empEmail : String;
  oldExcelSheet : Map<string,number>;
  disableSplitInputandBtn:boolean;
  @ViewChild("multiUserSearch") multiuserSearchInput!: ElementRef;
  csvFile:csvData;

  constructor(
    public dialog: MatDialog,
    private commonService: CommonService,
    private mentorshipService: MentorshipService,
    private formBuilder: UntypedFormBuilder,
    private eventService: EventService,
    private adminPortalService: AdminPortalService,
    private _responsiveService: ResponsiveService,
    private toastr: ToastrService,
    private authService : AuthUserService
  ) {
    this.mobile = false;
    this.rewardDistributionForm = this.formBuilder.group({});
    this.allotmentTypeForm = this.formBuilder.group({});
    this.totalAmount = 0;
    this.totalConversionRatio = 0;
    this.totalContributionPoints = 0;
    this.genericDailogData = { title: "", img: "", heading: "" };
    this.tablePageIndex = 0;
    this.searchText='';
    this.tableLength = 0;
    this.updateTable = false;
    this.submitted1 = false;
    this.submitted2 = false;
    this.splitBtnClicked = false;
    this.edit_NoEdit_IconCount = 0;
    this.allSelectedEmployees = new Array<BudgetData>();
    this.updateTableBudget=false;
    this.surplus= 0;
    this.disableSplitInputandBtn=false;
    this.csvFile={attachment:''};
    this.searchInput = "";
    this.budgetDataSource = new MatTableDataSource<BudgetData>([]);
    this.budgetDisplayedColumns = [
      "Email",
      "Contribution Points",
      "Budget (in Rs)",
      "save/edit",
      "delete",
    ];
    this.totalContributionPoints = 0;
    this.employees_list = [] as EmployeeDropdownData[];
    this._employees_list = [] as EmployeeDropdownData[];
    this.participantSet = new Map<String, boolean>();
    this.selectedValues = [] as String[];
    this.removedEmp = [] as String[];
    this.participantBudget = new Map<String,ParticipantBudgetModal>();
    this.empEmail = '';
    this.oldExcelSheet = new Map<string,number>();
  }

  ngOnInit(): void {
    this.onResize();
    this.commonService.setAdminPortalOpened(true);
    this.rewardDistributionForm = this.formBuilder.group({
      member_type: ["", Validators.required],
      employee_select: [[], Validators.required],
      budgetAmount: [0, Validators.required],
      conversionRatio : [0,Validators.required]
    });
    this.allotmentTypeForm = this.formBuilder.group({
      allotmentData: [""],
    });
    this.authService.getEmployeeDetailBehaviorSubject().subscribe((item)=>{
      this.empEmail = item.data.email;
    })
    this.rewardDistributionForm.get("budgetAmount")?.disable();
    this.rewardDistributionForm.get("conversionRatio")?.disable();
  }

  clearAllFields(){
    this.submitted1 = false;
    this.submitted2 = false;
    this.totalAmount = 0;
    this.totalConversionRatio=0;
    this.splitBtnClicked=false;
    this.updateTableBudget=false;
    this.disableSplitInputandBtn=false;
    this.totalContributionPoints = 0;
    this.participantBudget.clear();
    this.participantSet.clear();
    this.selectedValues = [];
    this.surplus=0;
  }

  onSubmit() {
    this.submitted1 = true;
    if (
      this.allSelectedEmployees.length === 0 ||
      this.updateTable === true ||
      this.edit_NoEdit_IconCount !== 0 ||
      this.totalAmount === 0 ||
      (this.updateTableBudget === true && !this.splitBtnClicked)
    ) {

    } else {
      this.rewardDistributionForm.value.employee_select = this.selectedValues;
      let memberBudgetArray = [];
      for (let i = 0; i < this.allSelectedEmployees.length; i++) {
        let memberBudgetObject = {
          empEmail: this.allSelectedEmployees[i].employeeId ,
          empName: this.allSelectedEmployees[i].name,
          amount: this.participantBudget.get(
            this.allSelectedEmployees[i].employeeId
          )?.budgetAmount,

          contributionPoints: this.allSelectedEmployees[i].contributionPoints,
        };

        memberBudgetArray.push(memberBudgetObject);
      }
      let dataObj = {
        distributorId: this.empEmail,
        allotBudget: Number(this.totalAmount)-Number(this.surplus),
        totalContributionPoints: this.totalContributionPoints,
        conversionRatio:Number(this.totalConversionRatio.toFixed(2)),
        budgetRewardDistribution: memberBudgetArray,
      };
      this.adminPortalService.allotBudgetingData(dataObj).subscribe(
        (res) => {
          this.genericDailogData = {
            title: "Budget Alloted successfully!",
            img: "../../../../assets/images/n_image/Success.gif",
            heading: '',
          };
          const dialogRef = this.dialog.open(ConfirmationModalComponent, {
            width: "513px",
            data: this.genericDailogData,
          });
          this.clearAllFields();
          this.createBudgetTable();
          this.budgetDataSource.data = [];
          this.employees_list = [];
          this._employees_list = [];
          this.oldExcelSheet.clear();
          this.rewardDistributionForm.reset();
          dialogRef.afterClosed().subscribe((result) => {
            this.commonService.setBudgetAddBtnClicked(false);
          });
        },
        (error) => {
          this.toastr.error("Error while storing the data");
        }
      );
    }
  }

  isAllotmentTypeBudget() {
    return this.allotmentTypeForm.value.allotmentData === "budget";
  }

  isAllotmentTypeReward() {
    return this.allotmentTypeForm.value.allotmentData === "reward";
  }
  createBudgetTableBtnClicked() {
    this.submitted2 = true;
    this.createBudgetTable();
  }

  createBudgetTable() {
    this.updateTable = false;
    this.splitBtnClicked=false;
    this.updateTableBudget=false;
    let obj = {
      employeeEmailIds: this.selectedValues,
    };
    
    if(this.selectedValues.length===0){
      this.surplus=0;
      this.disableSplitInputandBtn=false;
    }
    
    for (let employeeId of this.removedEmp) {
      this.participantBudget.set(employeeId, {
        isEditingAllowed: false,
        budgetAmount: 0,
      });
    }
    this.removedEmp = [];
    this.allSelectedEmployees = [];
    this.adminPortalService.getAllSelectedEmployees(obj).subscribe((res) => {
      
      if(res.totalPoints>this.totalContributionPoints && this.totalAmount!==0 && !this.disableSplitInputandBtn){
        this.updateTableBudget=true;
      }
      this.totalContributionPoints = res.totalPoints;
      this.totalAmount = 0;
      let responseListLength = 0;
      if (res.data !== undefined) {
        responseListLength = res.data.length;
      }
      for (let i = 0; i < responseListLength; i++) {
        let participantBudgetObj = this.participantBudget.get(
          res.data[i].emailId
        );
        let participantBudgetAmount = 0;
        let participantEditingAllowed = false;
        if (participantBudgetObj !== undefined) {
          participantBudgetAmount = Number(participantBudgetObj.budgetAmount);
          participantEditingAllowed = participantBudgetObj.isEditingAllowed;
        }
        if(participantBudgetAmount === 0 && res.data[i].employeeAvailablePoints !==0){
          participantBudgetAmount=100;
        }
        this.participantBudget.set(res.data[i].emailId, {
          isEditingAllowed: participantEditingAllowed,
          budgetAmount: participantBudgetAmount,
        });
        this.totalAmount = Number(this.totalAmount) + participantBudgetAmount;

        let budgetingTableRow = {
          employeeId: res.data[i].emailId,
          name: res.data[i].empName,
          contributionPoints: res.data[i].employeeAvailablePoints,
          budgetAmount: participantBudgetAmount,
        };
        this.allSelectedEmployees.push(budgetingTableRow);
      }
      if(this.totalAmount === 0){
        this.disableSplitInputandBtn=false;
        this.surplus=0;
      }
      if (this.totalContributionPoints === 0 || this.disableSplitInputandBtn) {
        this.rewardDistributionForm.get("budgetAmount")?.disable();
        this.rewardDistributionForm.get("conversionRatio")?.disable();
      } else {
        this.rewardDistributionForm.get("budgetAmount")?.enable();
        this.rewardDistributionForm.get("conversionRatio")?.enable();
      }
      this.totalAmount = Number(this.totalAmount) + Number(this.surplus);
      this.rewardDistributionForm.value.budgetAmount = Number(this.totalAmount);
      this.onChangeBudgetAndRatio(true,false);
      this.tableLength = this.allSelectedEmployees.length;
      this.budgetDataSource = new MatTableDataSource<BudgetData>(
        this.getEmployeesForPagination()
      );
    });
  }

  splitAmount() {
    this.splitBtnClicked=true;
    this.updateTableBudget=false;
    let extraAmount = 0;
    let changedBudgetAmount : Number = 0;
    for (let i = 0; i < this.allSelectedEmployees.length; i++) {
      let participantBudgetObj: ParticipantBudgetModal = this.participantBudget.get(
        this.allSelectedEmployees[i].employeeId
      )!;
      if (Number(this.totalContributionPoints) === 0) {
        this.totalAmount = Number(this.totalAmount) - Number(participantBudgetObj.budgetAmount);
        participantBudgetObj.budgetAmount = 0;
      } else {
        let amt : Number = Math.floor(
          (Number(this.totalAmount) *
            Number(this.allSelectedEmployees[i].contributionPoints)) /
            Number(this.totalContributionPoints)
        );
        let remainder : Number = Number(amt)%100;
        amt = Number(amt) - Number(remainder);
        if(remainder>=50){
          amt = Number(amt) + 100;
        }
        participantBudgetObj.budgetAmount = amt;
        changedBudgetAmount = Number(changedBudgetAmount) + Number(amt);
        extraAmount += Number(participantBudgetObj.budgetAmount);
      }
      this.participantBudget.set(
        this.allSelectedEmployees[i].employeeId,
        participantBudgetObj
      );
    }
    this.totalAmount = changedBudgetAmount;
    this.rewardDistributionForm.value.budgetAmount = Number(this.totalAmount);
    this.surplus = Number(this.totalAmount) - Number(extraAmount);
    this.onChangeBudgetAndRatio(true,false);
  }
  participantBudgetEditingAllowed(empId: string) {
    let participantBudgetObj = this.participantBudget.get(empId);
    return participantBudgetObj?.isEditingAllowed;
  }

  getParticipantBudgetAmount(empId: string) {
    let participantBudgetObj = this.participantBudget.get(empId);
    return participantBudgetObj?.budgetAmount;
  }

  onSearchChange(){
    const searchInput = this.searchText ? this.searchText.toLowerCase() : '';
    this.employees_list = this._employees_list.filter(u=>{
       const name:string = u.name.toLowerCase();
       return name.indexOf(searchInput) > -1 ;
  
     })
  
  }
  selectionChangeNewParticipants($event: any, selectionType: String) {
    this.updateTable = true;
    this.submitted1 = false;
    this.submitted2 = false;
    let empId = $event.options[0]._value.id;
    let empEmailId = $event.options[0]._value.email
    this.participantSet.set(empEmailId, !this.participantSet.get(empEmailId));
    let index = this.removedEmp.indexOf(empEmailId); 
    if (!this.participantSet.get(empEmailId)) {
      if (index === -1) {
        this.removedEmp.push(empEmailId);
      }
      let participantBudgetObj = this.participantBudget.get(empEmailId);
      if (participantBudgetObj !== undefined && participantBudgetObj !== null) {
        if (participantBudgetObj.isEditingAllowed) {
          this.edit_NoEdit_IconCount++;
        }
      }
    }
    else{
      if (index>=0) {
        this.removedEmp.splice(index);
      }
    }

    this.setParticipants(selectionType, empId, empEmailId);
  }

  employeeListLoad() {
    this.clearAllFields();
    this.createBudgetTable();
    if (this.rewardDistributionForm.value.member_type === "employee") {
      this.mentorshipService.employeedropdown().subscribe((data) => {
        this.employees_list = data.data;
        this._employees_list = data.data;
      });
    } else if (this.rewardDistributionForm.value.member_type === "manager") {
      this.eventService.getEmployeeListByManager().subscribe((data) => {
        this.employees_list = data;
        this._employees_list = data;
      });
    }
  }

  setParticipants(selectionType: String, empId: string, empEmailId : String) {
    if (selectionType == "employee") {
      let index = this.selectedValues.indexOf(empEmailId);
      if (index == -1) {
        this.selectedValues.push(empEmailId);
      } else {
        this.selectedValues.splice(index, 1);
      }
    } else if (selectionType == "manager") {
      this.eventService.getEmployeeUnderManager(empEmailId).subscribe((data) => {
        if (data) {
          let index = this.selectedValues.indexOf(empEmailId);
          if (index == -1) {
            for (let emp of data) {
              this.selectedValues.push(emp.email);
            }
          }
          else {
            for (let emp of data) {
              index = this.selectedValues.indexOf(emp.email);
              this.selectedValues.splice(index, 1);
            }
          }
        }
      });
    }
  }

  removeParticipant(empId: string) {
    let selectionType = this.rewardDistributionForm.value.member_type;
    this.participantSet.set(empId, false);
    let index = this.selectedValues.indexOf(empId);
    let participantBudgetObj = this.participantBudget.get(empId);
    if (participantBudgetObj !== undefined && participantBudgetObj !== null) {
      if (participantBudgetObj.isEditingAllowed) {
        this.edit_NoEdit_IconCount++;
      }
    }
    if (
      (selectionType === "employee" || selectionType === "manager" || selectionType === "excel") &&
      index >= 0
    ) {
      if (participantBudgetObj !== undefined && participantBudgetObj !== null) {
      participantBudgetObj.budgetAmount = 0;
      participantBudgetObj.isEditingAllowed = false;
      this.participantBudget.set(empId, participantBudgetObj);
      this.selectedValues.splice(index, 1);
      this.createBudgetTable();
      }
    }
  }

  editParticipantBudegtAmount(empId: string) {
    let participantBudgetObj: ParticipantBudgetModal = this.participantBudget.get(empId)!;
    participantBudgetObj.isEditingAllowed =
      !participantBudgetObj.isEditingAllowed;
    
    if (participantBudgetObj.isEditingAllowed === false) {
      let amt : Number = participantBudgetObj.budgetAmount;
      let remainder : Number = Number(amt)%100;
      amt = Number(amt) - Number(remainder);
      this.totalAmount = Number(this.totalAmount) - Number(remainder);
      if(remainder>=50){
        amt = Number(amt) + 100;
        this.totalAmount = Number(this.totalAmount) + 100;
      }
      participantBudgetObj.budgetAmount = amt;
      this.rewardDistributionForm.value.budgetAmount = Number(this.totalAmount);
      this.edit_NoEdit_IconCount++;
    } else {
      this.submitted1 = false;
      this.edit_NoEdit_IconCount--;
    }
    this.participantBudget.set(empId, participantBudgetObj);
  }

  onChangeParticipantBudget(event: any, empId: string) {
    this.disableSplitInputandBtn=true;
    this.rewardDistributionForm.get("budgetAmount")?.disable();
    this.rewardDistributionForm.get("conversionRatio")?.disable();
    let participantBudgetObj: ParticipantBudgetModal = this.participantBudget.get(empId)!;
    let oldAmount = participantBudgetObj.budgetAmount;
    let newAmount;
    if (event.target.value === "") {
      newAmount = 0;
    } else {
      newAmount = Number(event.target.value);
    }
    this.totalAmount =
      Number(this.totalAmount) + (Number(newAmount) - Number(oldAmount));
    this.rewardDistributionForm.value.budgetAmount = Number(this.totalAmount);
    this.onChangeBudgetAndRatio(true,false);
    participantBudgetObj.budgetAmount = Number(newAmount);
    this.participantBudget.set(empId, participantBudgetObj);
  }
  getEmployeesForPagination() {
    let arr = [];
    for (
      let i = this.tablePageIndex * 5;
      i < this.tableLength && i < (this.tablePageIndex + 1) * 5;
      i++
    ) {
      arr.push(this.allSelectedEmployees[i]);
    }
    return arr;
  }
  getData(event?: PageEvent) {
    this.tablePageIndex = event!.pageIndex;
    this.budgetDataSource = new MatTableDataSource<BudgetData>(
      this.getEmployeesForPagination()
    );
    return event;
  }
  onChangeBudgetAndRatio(isBudgetChanged: boolean,isFnCalledFromUI:boolean){
    this.submitted1=false;
    if(isBudgetChanged){
      if(this.rewardDistributionForm.value.budgetAmount!=null && this.totalContributionPoints!==0){
        this.rewardDistributionForm.value.conversionRatio = (Number(this.rewardDistributionForm.value.budgetAmount) - Number(this.surplus)) / Number(this.totalContributionPoints);
      }
      else{
        this.rewardDistributionForm.value.conversionRatio=0;
      }
    }
    else{
      if(this.rewardDistributionForm.value.conversionRatio!=null){
        this.rewardDistributionForm.value.budgetAmount = this.rewardDistributionForm.value.conversionRatio * Number(this.totalContributionPoints) 
      }
      else{
        this.rewardDistributionForm.value.budgetAmount=0;
      }
    }
    this.totalConversionRatio=Number(this.rewardDistributionForm.value.conversionRatio);
    this.totalAmount = Number(this.rewardDistributionForm.value.budgetAmount);
    if(isFnCalledFromUI)
    {
      if(this.totalAmount!==0)
      {
        this.splitBtnClicked=false;
        this.updateTableBudget=true;
      }
      else{
        this.splitBtnClicked=true;
        this.updateTableBudget=false;
      }
    }
  }
  openDialog(){
    const dialogRef=this.dialog.open(CsvModalComponent,{
      width:'672px',
      data:this.csvFile,
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const formData = new FormData();
        formData.append("file",result.obj)
         this.sendXlsx(formData).then(()=>{
      })
    }   
  });
 }
  sendXlsx(formData:FormData)
  {
    return new Promise<void>((resolve,reject)=>{
      this.adminPortalService.getBudgetDataFromXlsx(formData).subscribe(data=>{
        if(data){
          let isDataSame = true;
          let newExcelSheet = new Map<string,number>();
          data.forEach((element : any) => {
            for (let key in element) {
              if(this.oldExcelSheet.get(key) === undefined || (this.oldExcelSheet.get(key) !== element[key]))
              {
                isDataSame = false;
              }
              newExcelSheet.set(key,element[key]);
            }
          })

          if(isDataSame && (this.oldExcelSheet.size === newExcelSheet.size)){
            this.toastr.warning('Excel data is same as previous one. Please check');
          }
          else{
            this.oldExcelSheet.clear();
            this.oldExcelSheet = newExcelSheet;
            this.clearAllFields();
            this.createTableFromExcel(data);
            this.genericDailogData = {
              title: "Data imported successfully!",
              img: "../../../../assets/images/n_image/Success.gif",
              heading: '',
            };
            this.dialog.open(ConfirmationModalComponent, {
              width: "513px",
              data: this.genericDailogData,
            });
          }
          resolve(); 
        }
        else{
          reject();
        }
      },(err)=>{
        reject(err);
        if(err.status==500)
        {
          this.toastr.error("Please enter valid excel data")
        }else{ 
          this.toastr.error(err.error);
        }
      });
    })
  }
  createTableFromExcel(excelData : Map<String,Number>){
    this.updateTable = false;
    this.splitBtnClicked=false;
    this.updateTableBudget=false;
    
    if(this.selectedValues.length===0){
      this.surplus=0;
      this.disableSplitInputandBtn=false;
    }

    this.allSelectedEmployees = [];
    this.selectedValues = [];
    this.totalContributionPoints=0;
    let excelBudgetAmount : Number = 0;
    excelData.forEach((element : any) => {
      for (let key in element) {
          this.selectedValues.push(key);
          this.participantSet.set(key, true);
          this.totalContributionPoints = Number(this.totalContributionPoints) + Number(element[key]);
          this.participantBudget.set(key, {
            isEditingAllowed: false,
            budgetAmount: element[key] !==0 ? 100 : 0,
          });
          excelBudgetAmount =  Number(excelBudgetAmount) + (element[key] !==0 ? 100 : 0)
          let budgetingTableRow = {
            employeeId: key,
            name: "",
            contributionPoints: element[key],
            budgetAmount: element[key] !==0 ? 100 : 0,
          };
          this.allSelectedEmployees.push(budgetingTableRow);
      }
    });
    if (this.totalContributionPoints === 0) {
      this.rewardDistributionForm.get("budgetAmount")?.disable();
      this.rewardDistributionForm.get("conversionRatio")?.disable();
    } else {
      this.rewardDistributionForm.get("budgetAmount")?.enable();
      this.rewardDistributionForm.get("conversionRatio")?.enable();
    }
      this.totalAmount = excelBudgetAmount;
      this.rewardDistributionForm.value.budgetAmount = Number(this.totalAmount);
      this.onChangeBudgetAndRatio(true,false);
      this.tableLength = this.allSelectedEmployees.length;
      this.budgetDataSource = new MatTableDataSource<BudgetData>(this.getEmployeesForPagination());
  }
  onResize() {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.mobile = isMobile;
    });
    this._responsiveService.checkWidth();
  }
  matcher = new MyErrorStateMatcher();
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid);
  }
}
