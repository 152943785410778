import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrls: ['./under-maintenance.component.css']
})
export class UnderMaintenanceComponent implements OnInit {

  @Input()
  serverImage: String;

  constructor() {
    this.serverImage = "";
   }

  ngOnInit(): void {
    let count = localStorage.getItem('notificationCount');
    let neoToken = localStorage.getItem('neo-token');
    localStorage.clear();
    if(count)
      localStorage.setItem('notificationCount',count);
    else{
      localStorage.setItem('notificationCount','0');
    }
    if(neoToken!=null){
      localStorage.setItem('neo-token',neoToken);
    }
  }
}
