import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelfDevelopmentService {

  constructor(private http: HttpClient) { }
  createSelfDev(createSelfDevReqBody: any) {
    return this.http.post<any>('contripoint/selfdevelopment/add', createSelfDevReqBody);
  }

  saveAsDraft(createSelfDevReqBody: any) {
    return this.http.post<any>('contripoint/selfdevelopment/drafts', createSelfDevReqBody);
  }
  getAllSelfDev    (empEmail: String,pageNo:number, fromDate:string,toDate:string,status:String) : Observable<any>{
      let obj = {
       'email': empEmail,
       'pageNo': pageNo.toString(),
       'fromDate' :fromDate,
       'toDate':toDate,
       'status':status
       }
      return this.http.post('contripoint/selfdevelopment/byYear', obj);
     
  }
  
  getSelectedSelfDevView(selfDevelopmentId: number){
    let reqParam=new HttpParams().set('selfDevelopmentId',selfDevelopmentId);
    return this.http.get<any>('contripoint/selfdevelopment/view', {'params':reqParam});
  }
  
  deleteselfdevelopment(selfDevelopmentId:number){
    return this.http.delete<any>('contripoint/selfdevelopment/delete/'+selfDevelopmentId);
  }
}
