<div class="um">
    <div class="navbar">
        <div class="row w-100">
            <div class="col-sm-10">
                <div class="container">
                    <div class="brand-logo">
                        <a class="navbar-brand" href="#">
                            <img src='../../assets/images/new-gemini-logo.svg' alt="new-gemini-logo" class="um-nav-logo" >
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <div class="um-container">
    <div class="um-container-bg">
      <img
      [src]="serverImage"
        class="img-fluid underimage"
      />
    </div>
  </div>
  <div class="um-text">
    You can contact us here :
    <a
      class="um-btn"
      href="https://teams.microsoft.com/l/team/19%3aNJSJmv6F_m756VkjMcOTSh08XAiYBZ7DJKShyoyMDbI1%40thread.tacv2/conversations?groupId=0cb4e31c-acb7-471c-87c1-bacc90a16f28&tenantId=b9806c7d-9280-4e44-afea-6dc0ff495c2f"
      target="_blank"
    >
      Contripoint Help Channel
    </a>
  </div>
</div>
