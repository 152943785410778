<div class="container">
  <div class="row pt-2 pb-2">
    <div
      class="d-flex justify-content-start align-items-center col-lg-2 col-md-2 col-sm-2 col-2"
    >
      <button class="d-flex" (click)="backRoute()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>
    <div
      class="d-flex justify-content-end align-items-center col-lg-10 col-md-10 col-sm-10 col-10"
    >
      <nav aria-label="breadcrumb">
        <ol class="m-0 breadcrumb">
          <li class="breadcrumb-item non-active pointer">
            <a (click)="backRoute()">Contribution History</a>
          </li>

          <li class="breadcrumb-item active" aria-current="page">
            {{ ContributionType }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <div class="div-props py-4">
    <p class="title pl-3">{{ ContributionType }}</p>
    <table
      mat-table
      [dataSource]="dataSource"
      [ngClass]="{ 'table-props': isScreenSizeMd() }"
    >
      <ng-container
        *ngFor="let column of displayedColumns"
        [matColumnDef]="column"
      >
        <th mat-header-cell *matHeaderCellDef [attr.data-column]="column">
          {{ columnHeaders[column] || column }}
        </th>
        <td mat-cell *matCellDef="let element" [attr.data-column]="column">
      
          <ng-container *ngIf="column === 'status'">
            <span
              [ngClass]="{
                'pending-text': element.status === 'Pending',
                approved: element.status === 'Approved',
                declined: element.status === 'Declined'
              }"
            >
              {{ element.status }}
            </span>
          </ng-container>

        
          <ng-container *ngIf="column === 'action'">
            <img
              (click)="viewCertificate(element.id)"
              [src]="element[column]"
              alt="Action"
              style="width: 24px; height: 24px"
            />
          </ng-container>


          <ng-container *ngIf="column !== 'status' && column !== 'action'">
            {{ element[column] || '' }}
          </ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      class="mb-1 pagination-container"
      [pageIndex]="tablePageIndex"
      [pageSize]="5"
      showFirstLastButtons
      (page)="getAllCertificates($event)"
      [length]="tableLength"
    >
    </mat-paginator>
  </div>
</div>
