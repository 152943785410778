import { Component, Inject, OnInit } from '@angular/core';
import { genericDailogData } from './../../models/confirmation-modal.model';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.css'],
})
export class GenericModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GenericModalComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public genericData: genericDailogData,
    @Inject(MAT_DIALOG_DATA) public data: genericDailogData
  ) {}

  ngOnInit(): void {}

  confirmAction(response: boolean) {
    this.dialogRef.close(response);
  }
}
