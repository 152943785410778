import { Injectable } from '@angular/core';
import { LeaderBoardData } from 'src/app/shared/models/leaderboard.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class LeaderboardService {
  valueArray: LeaderBoardData[];
  constructor(private http: HttpClient) {
    this.valueArray = [] as LeaderBoardData[];
  }

  /**
   * get the employee details with respective position
   */
  getDetails(position: Number): Observable<LeaderBoardData[]> {
    let reqParam = new HttpParams().set('position', position.toString());
    return this.http.get<LeaderBoardData[]>('contripoint/leaderboard', {
      params: reqParam,
    });
  }

  /**
   * set the leadership value
   */
  setLeaderboardValue(data: LeaderBoardData[]): void {
    this.valueArray = data;
  }

  /**
   * get the leader board value
   */
  getLeaderBoardValue(): LeaderBoardData[] {
    return this.valueArray;
  }
}
