import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { csvData } from "src/app/shared/models/admin-portal.model";
import { AdminPortalService } from "../../service/admin-portal.service";
@Component({
  selector: "app-csv-modal",
  templateUrl: "./csv-modal.component.html",
  styleUrls: ["./csv-modal.component.css"],
})
export class CsvModalComponent implements OnInit {
  modalForm!: UntypedFormGroup;
  changeTriggered: boolean;
  submitted: boolean;
  test: any = "";
  file: File = this.test;

  constructor(
    public dialogRef: MatDialogRef<CsvModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: csvData,
    @Inject(MAT_DIALOG_DATA) public sampleFileLink : string,
    private formBuilder: UntypedFormBuilder,
    public adminPortalService : AdminPortalService
  ) {
    this.submitted=false;
    this.changeTriggered=false;
  }

  ngOnInit(): void {
    this.modalForm = this.formBuilder.group({
      attachmentFile: [this.data.attachment],
    });
    this.setValidator();
  }
  setValidator() :void{
    if (this.data.attachment == "") {
      this.modalForm.controls["attachmentFile"].setValidators([
        Validators.required,
      ]);
    }
  }
  onChange(event: any) : void{
    this.changeTriggered = true;
    this.file = event.target.files[0];
  }
  onClose():void {
    this.dialogRef.close();
  }
  onNewSubmit():void {
    this.submitted = true;
    if (this.modalForm.invalid) return;

    this.dialogRef.close({
      obj: this.file,
      resultForm: this.modalForm.value,
    });
  }
}
