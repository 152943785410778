<div class="modal-feedback p-2">
    <div class="modal-header">
        <div>
            <p class="modal-header-text modal-title">Add Comments*</p>
        </div>
        <button type="button" class="close" (click)="onClose()" aria-label="Close">
            <span aria-hidden="true"><i class="material-icons">close</i></span>
        </button>
    </div>
    <div class="row">
        <div class=" ml-2 col-lg-11 col-md-11 col-sm-11 col-xs-11">
            <form [formGroup]="modalForm" (ngSubmit)="onSubmit()">
                
                                <mat-form-field class="w-100" hintLabel="Max. 200 characters allowed." appearance="fill">
                                    <mat-label>Comments</mat-label>
                                    <textarea type="text" formControlName="comment" [maxLength]="200" matInput [errorStateMatcher]="matcher" 
                                        placeholder="Enter Comments"></textarea>
                                    <mat-error *ngIf="modalForm.controls.comment?.errors && this.submitted==true">
                                        Comments are required to submit feedback.
                                    </mat-error>
                                </mat-form-field>
                         
                 
            </form>
           
        </div>
    </div>
<div class="row">   
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mat-dialog-content ml-1 mt-4">
    <button type="submit" mat-raised-button id="ok_btn" (click)="onSubmit()">SUBMIT</button>
</div>
</div> 