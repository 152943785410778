import { dataDailog } from './../../../review-activities/review-table/review-activity.module';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Component, OnInit, AfterViewChecked } from "@angular/core";
import { ThemePalette } from '@angular/material/core';
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { CommonService } from "src/app/services/common.service";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
import { ResponsiveService } from "src/app/services/responsive.service";
import { AdminPortalService } from "../../service/admin-portal.service";
@Component({
  selector: "app-main-section",
  templateUrl: "./main-section.component.html",
  styleUrls: ["./main-section.component.css"],
})
export class MainSectionComponent implements OnInit, AfterViewChecked {
  mobile: boolean;
  allotmentTypeForm!: UntypedFormGroup;
  amountDistribution: number;
  amountRedeem: number;
  availablePercent : number;
  amountLeft: number;
  usedPercent: number;
  geminiWalletAmount : number;
  storeData:boolean;
  value :number;
  color: ThemePalette ;
  mode: ProgressSpinnerMode ;
  constructor(
    public commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private _responsiveService: ResponsiveService,
    private adminPortalService : AdminPortalService
  ) {
    this.mobile = false;
    this.allotmentTypeForm = this.formBuilder.group({});
    this.amountDistribution = 0;
    this.amountLeft = 0;
    this.amountRedeem = 0;
    this.usedPercent = 0;
    this.availablePercent = 0;
    this.geminiWalletAmount = 0;
    this.value=50;
    this.storeData=false;
    this.color="primary";
    this.mode="indeterminate";
    this.commonService.getUpdate().subscribe((message) => {
      this.getRewardDistribution();
    });
  }
  ngOnInit(): void {
    this.onResize();
    this.commonService.setBudgetAddBtnClicked(false);
    this.commonService.setAdminPortalOpened(true);
    this.allotmentTypeForm = this.formBuilder.group({
      allotmentData: [""],
    });
    this.commonService.setBudgetAddBtnClicked(false);
    this.getGeminiWalletAmount();
  }

  ngAfterViewChecked(): void {
    if (!this.commonService.getBudgetAddBtnClicked())
      this.allotmentTypeForm.reset();
    this.getPercentage();
  }

  AddBtnClicked(): void {
    this.commonService.setBudgetAddBtnClicked(true);
  }

  isAllotmentTypeBudget(): boolean {
    return this.allotmentTypeForm.value.allotmentData === "budget";
  }

  navigateToRewardDistributionHistory(): void {
    this.commonService.setBudgetAddBtnClicked(false);
  }

  isAllotmentTypeReward(): boolean {
    return this.allotmentTypeForm.value.allotmentData === "reward";
  }

  setAllotmentTypeinService(): void {
    this.adminPortalService.setAllotmentType(this.allotmentTypeForm.value.allotmentData);
  }

  getGeminiWalletAmount(): void {
    this.adminPortalService.getGeminiWalletAmount().pipe(take(1)).subscribe((data) => {
      this.geminiWalletAmount = data;
      this.storeData=true;
      this.getRewardDistribution();
    })

  }
  getRewardDistribution(): void {
    this.adminPortalService.getRewardAmount().pipe(take(1)).subscribe((data) => {
      this.amountDistribution = data.totalAmountDistributedByAdmin;
      this.amountRedeem = data.totalAmountRedeemed;
      this.amountLeft = data.totalAmountLeftToRedeem;
    })
  }

  getPercentage() {
    if (this.amountDistribution != 0) {
      this.usedPercent = Number(((this.amountRedeem / this.amountDistribution) * 100).toFixed(2));
      this.availablePercent = Number((100 - this.usedPercent).toFixed(2));
    } else {
      this.usedPercent = 0;
      this.availablePercent = 100;
    }
  }

  onResize(): void {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.mobile = isMobile;
    });
    this._responsiveService.checkWidth();
  }
}
