import { LeaderboardDashComponent } from './../leaderboard-dash/leaderboard-dash.component';
import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ChangestatusService } from 'src/app/services/changestatus.service';
import { CommonService } from 'src/app/services/common.service';
import { LeaderBoardData } from 'src/app/shared/models/leaderboard.model';
import { LeaderboardService } from '../service/leaderboard.service';
@Component({
  selector: 'app-leaderboard-sidebar',
  templateUrl: './leaderboard-sidebar.component.html',
  styleUrls: ['./leaderboard-sidebar.component.css'],
})
export class LeaderboardSidebarComponent implements OnInit {
  rank: number;
  len: number;
  data_limit: LeaderBoardData[];
  mobile: boolean;
  data_leader: LeaderBoardData[];
  constructor(
    private _leaderboard: LeaderboardService,
    private responsiveService: ResponsiveService,
    private commonService: CommonService,
    private changestatusService: ChangestatusService
  ) {
    this.mobile = false;
    this.len = 0;
    this.rank = 30;
    this.data_limit = [] as LeaderBoardData[];
    this.data_leader = [] as LeaderBoardData[];
    this.commonService.getUpdate().subscribe(() => {
      this.getLeaderboardData();
    });
  }

  ngOnInit(): void {
    this.onResize();
    this.responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
  }

  /**
   * set leaderboard status open
   */
  setStatus() {
    localStorage.setItem('leaderboardStatus', 'open');
  }

  /**
 * set leaderboard status close
 */
  sidenavFunc() {
    this.commonService.setLeaderboardStatus(false);
    this.changestatusService.setldr('close');
  }

  /**
   * check screen width
   */
  onResize(): void {
    this.responsiveService.checkWidth();
  }

  /**
   * get leaderboard data
   */
  getLeaderboardData(): void {
    this._leaderboard.getDetails(this.rank).subscribe((data: LeaderBoardData[]) => {
      if (data) {
        this.data_limit = [];
        this.data_leader = data;
        this.len = data.length;
        for (let i = 0; i < this.data_leader.length; i++) {
          if (this.data_leader[i].position! > 10) break;
          this.data_limit.push(this.data_leader[i]);
        }
        for (const element of this.data_leader) {
          if (element.image) {
            element.imgAsset = atob(element.image);
          } else {
            element.imgAsset = 'assets/images/def.png';
          }
        }
      }
    });
  }
}
