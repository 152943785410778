import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CommonService } from 'src/app/services/common.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { EmployeeDetails, ProfileData } from 'src/app/shared/models/profile.model';
import { SelfDevelopmentDetails } from 'src/app/shared/models/self-development.model';
import { SelfDevelopmentTableService } from '../../self-development/service/self-development-table.service';

@Component({
  selector: 'app-self-development-view',
  templateUrl: './self-development-view.component.html',
  styleUrls: ['./self-development-view.component.css']
})
export class SelfDevelopmentViewComponent implements OnInit {
  goalType?: string;
  id: number;
  mobile: boolean;
  selfDevID: number;
  empId: string;
  empEmail: String;
  year: string;
  date: number;
  userData: EmployeeDetails;
  viewDetail: SelfDevelopmentDetails;
  constructor(
    private _responsiveService: ResponsiveService,
    @Inject(MAT_DIALOG_DATA) public data: number,
    public dialogRef: MatDialogRef<SelfDevelopmentViewComponent>,
    private _authService: AuthUserService,
    private selfDevViewService: SelfDevelopmentTableService,
    public dialog: MatDialog,
    private commonService: CommonService,
    private toastr: ToastrService
  ) {
    this.selfDevID = 0;
    this.empId = '';
    this.year = '';
    this.date = 0;
    this.id = 0;
    this.mobile = false;
    this.viewDetail = { description: '', title: '', status: '', points: 0, createdOn: '', category: '', contributedBy: '', duration: 0, date: '', comment: '', goalType: '', type: '' }
    this.userData = {} as EmployeeDetails;
    this.empEmail = '';
  }

  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(false);
    this._authService.getEmployeeDetailBehaviorSubject().subscribe((item: ProfileData) => {
      if (item?.data?.email) {
        this.empEmail = item?.data?.email;
      }
    });
    this.selfDevID = this.data;
    this._responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
    this.onResize();
    this.getSelectedSelfDevView();
  }

  /**
   * get the data of selected Self Development
   */
  getSelectedSelfDevView = (): void => {
    //todo: create data interface
    this.selfDevViewService.getSelectedSelfDevView(Number(this.selfDevID)).pipe(take(1)).subscribe(data => {
      this.date = (Number)(data.data.createdOn.substr(0, 4));
      this.year = `${this.date}-${this.date + 1}`;
      this.viewDetail.description = data.data.description,
        this.viewDetail.title = data.data.summary,
        this.viewDetail.type = data.data.projectType,
        this.viewDetail.status = data.data.status,
        this.viewDetail.points = data.data.totalPoints,
        this.viewDetail.createdOn = data.data.createdOn.substr(0, 10),
        this.viewDetail.category = data.data.contributionCategory.name
      this.viewDetail.contributedBy = data.data.createdBy;
      this.viewDetail.duration = data.data.selfDevelopment.duration;
      this.viewDetail.date = data.data.contributionDate.substr(0, 10);
      this.viewDetail.comment = data.data.comments;

      if (data.data.ecdc == 'EC') {
        this.goalType = 'Engineering Council (EC)';
      }
      if (data.data.ecdc == 'DC') {
        this.goalType = 'Delivery Council (DC)';
      }
    })
  }

  /**
   * check the screen width
   */
  onResize = (): void => {
    this._responsiveService.checkWidth();
  }

  /**
   * close the view modal
   */
  onClose = (): void => {
    this.dialogRef.close();
  }
}
