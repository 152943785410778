<div *ngIf="!this.storeData" class="overlay">
    <mat-progress-spinner
      class="spinner"
      [color]="color"
      [mode]="mode"
      [value]="value"
      diameter="50"
    ></mat-progress-spinner>
  </div>
  
  <div class="analytical-transaction mt-4 mb-4">
    <div class="row m-0 w-100 h-100">
      <div class="col-xl-4 col-lg-5 p-0 pr-lg-3 mb-2">
        <div class="transaction-card px-2 py-4 bg-white row m-0 w-100">
          <div class="col-8">
            <div class="amount-distributed-text mb-2">Amount Distributed</div>
            <div class="amount-distributed-data mb-1">
              ₹{{ transactionData.amountDistributed | numberFormatter  }}
            </div>
            <div class="row m-0 w-100 mt-3">
              <div class="col-6 p-0 pr-1">
                <div class="redeemed-balance-text mb-1">Redeemed</div>
                <div class="redeemed-balance-data mb-1">
             
                  ₹{{ transactionData.redeemed | numberFormatter }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="assets/images/n_image/transaction-amount-distributed.svg"
              class="transaction-card-image"
            />
          </div>
        </div>
  
        <div class="transaction-card px-2 py-4 bg-white row m-0 w-100 mt-3">
          <div class="col-8">
            <div class="amount-distributed-text mb-2">Absolute Profit</div>
            <div class="amount-distributed-data mb-1">
              ₹{{ transactionData.absoluteProfit | numberFormatter }}
            </div>
            <div class="row m-0 w-100 mt-3">
              <div class="col-12 p-0 pr-1">
                <div class="redeemed-balance-text mb-1">Overall Profit %</div>
                <div class="redeemed-balance-data mb-1">
                  {{ transactionData.overallProfit }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="assets/images/n_image/transaction-absolute-profit.svg"
              class="transaction-card-image"
            />
          </div>
        </div>
  
        <div class="transaction-card px-2 py-4 bg-white row m-0 w-100 mt-3">
          <div class="col-8">
            <div class="amount-distributed-text mb-2">Coupons Purchased</div>
            <div class="amount-distributed-data mb-1">
              {{ transactionData.couponsPurchased | number:'1.0-0'  }}
            </div>
            <div class="row m-0 w-100 mt-3">
              <div class="col-6 p-0 pr-1">
                <div class="redeemed-balance-text mb-1">List Price</div>
                <div class="redeemed-balance-data mb-1">
                  ₹{{ transactionData.listPrice | numberFormatter }}
                </div>
              </div>
              <div class="col-6 p-0 pr-1">
                <div class="redeemed-balance-text mb-1">Discounted Price</div>
                <div class="redeemed-balance-data mb-1">
                  ₹{{ transactionData.discountedPrice | numberFormatter }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 d-flex align-items-center justify-content-center">
            <img
              src="assets/images/n_image/transaction-coupon-purchased.svg"
              class="transaction-card-image"
            />
          </div>
        </div>
      </div>
  
      <div class="col-xl-8 col-lg-7 p-0 mb-2">
        <div class="bg-white transaction-card h-100 p-4">
          <div class="d-flex justify-content-end">
            <!-- <span>Select Year :&emsp;</span> -->
            <div class="d-flex align-items-center">Select Year :&emsp;</div>
            <mat-form-field
              appearance="outline"
              class="transaction-table-year-filter"
            >
              <mat-select
                [(ngModel)]="selectedValue"
                (selectionChange)="getActivityGraphData($event.value)"
              >
                <ng-container *ngFor="let item of yearsList">
                  <mat-option [value]="item">{{ item }}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
          <div>
            <canvas height="420" id="BarChart">{{ barChart }}</canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4 pb-3 px-3 pt-0 bg-white rounded">
      <div class="row m-0 w-100 pt-3 pb-2">
        <div
          class="col-md-5 p-0 mb-2 d-flex align-items-center justify-content-start transaction-table-heading"
        >
          Most Used Brands
        </div>
  
        <div
          class="col-md-7 p-0 mb-2 d-flex align-items-center justify-content-md-end justify-content-start"
        >
          <div>Select Year :&emsp;</div>
          <mat-form-field
            appearance="outline"
            class="transaction-table-year-filter"
          >
            <mat-select
              [(ngModel)]="selectedValue"
              (selectionChange)="getTableData($event.value)"
            >
              <ng-container *ngFor="let item of yearsList">
                <mat-option [value]="item">{{ item }}</mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
  
      <div [ngClass]="{ 'table-responsive': mobile }">
        <table
          mat-table
          [dataSource]="dataSource"
          class="transaction-table w-100"
        >
          <ng-container matColumnDef="no">
            <th class="py-3" mat-header-cell *matHeaderCellDef>S.No</th>
            <td class="py-3" mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}.
            </td>
          </ng-container>
  
          <ng-container matColumnDef="brandName">
            <th class="px-2 py-3" mat-header-cell *matHeaderCellDef>
              Brand Name
            </th>
  
            <td class="px-2 py-3" mat-cell *matCellDef="let element">
              <div class="d-flex">
                <img [src]="element.icon" class="person-img" />
                <div class="ml-4 d-flex align-items-center">
                  {{ element?.brandName }}
                </div>
              </div>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="couponPurchased">
            <th class="px-2 py-3" mat-header-cell *matHeaderCellDef>
              Coupon Purchased
            </th>
            <td class="px-2 py-3" mat-cell *matCellDef="let element">
              {{ element?.count }}
            </td>
          </ng-container>
  
          <ng-container matColumnDef="valueAccumulated">
            <th class="px-2 py-3" mat-header-cell *matHeaderCellDef>
              Value Accumulated
            </th>
            <td class="px-2 py-3" mat-cell *matCellDef="let element">
             ₹{{ element?.listPrice | numberFormatter }}
            </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="!dataSource?.data?.length" class="no_record center-align">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img src="assets/images/cross-icon.svg" />
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              No records found!
            </div>
          </div>
        </div>
  
        <mat-paginator
          #paginator
          [pageSizeOptions]="[5, 10, 20]"
          showFirstLastButtons
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  
  