import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/Guards/AuthGuard/auth.guard';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminContributionCategoriesComponent } from './admin-contribution-categories/admin-contribution-categories.component';
import { MainSectionComponent } from './admin-reward-distribution/main-section/main-section.component';
import { DiscountCategoryComponent } from './admin-brand-selection/discount-category/discount-category.component';
import { AllBrandsComponent } from './admin-brand-selection/all-brands/all-brands.component';
import { UnsavedGuard } from './unsaved.guard';
import { AnalyticDashboardComponent } from './analytics/analytic-dashboard/analytic-dashboard.component';
import { AdminGuard } from 'src/app/Guards/admin.guard';

const trainingSessionRoutes: Routes = [
  {
    path: '',
    redirectTo: 'analytics-dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: AdminDashboardComponent,
  },
  {
    path: 'contribution-categories',
    canActivate: [AuthGuard],
    component: AdminContributionCategoriesComponent,
  },
  {
    path: 'reward-distribution',
    canActivate: [AuthGuard, AdminGuard],
    component: MainSectionComponent,
  },
  {
    path: 'discount-selection',
    canActivate: [AuthGuard, AdminGuard],
    component: DiscountCategoryComponent,
  },
  {
    path: 'brands-selection/:id',
    canActivate: [AuthGuard, AdminGuard],
    component: AllBrandsComponent,
    canDeactivate: [UnsavedGuard],
  },
  {
    path: 'analytics-dashboard',
    canActivate: [AuthGuard],
    component: AnalyticDashboardComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(trainingSessionRoutes)],
  exports: [RouterModule],
})
export class AdminPortalRoutingModule {}
