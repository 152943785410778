import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Chart, registerables } from 'chart.js';
import { ResponsiveService } from 'src/app/services/responsive.service';
import {
  BrandsData,
  FilterCriteria,
  MostUsedBrandTable,
  TimeData,
  TransactionData,
} from 'src/app/shared/models/transaction.model';
import { AdminPortalService } from './../../service/admin-portal.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { MatPaginator } from '@angular/material/paginator';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { NumberFormatterPipe } from 'src/app/modules/pipes/number-formatter.pipe';
@Component({
  selector: 'app-transactions-section',
  templateUrl: './transactions-section.component.html',
  styleUrls: ['./transactions-section.component.css'],
})
export class TransactionsSectionComponent implements OnInit {
  @Input() filterCriteria!: FilterCriteria;
  @Output() balanceAmount: EventEmitter<number>;
  public dataSource!: MatTableDataSource<MostUsedBrandTable>;
  public displayedColumns: string[];
  public mobile: boolean;
  public selectedValue: string = '';
  public yearsList: string[] = [];
  public barChart: any;
  transactionData: TransactionData = {} as TransactionData;
  graphData: Array<BrandsData> = [];
  bgList: Array<string> = [];
  amountRewardedData: Array<number> = [];
  titleArray: Array<string> = [];
  brandsData: Array<MostUsedBrandTable> = [];
  color: ThemePalette;
  mode: ProgressSpinnerMode;
  storeData: boolean = false;
  value: number = 50;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(private numberFormatterPipe: NumberFormatterPipe,
    private responsiveService: ResponsiveService,
    public adminService: AdminPortalService,
    public toastr: ToastrService,
    public commonService: CommonService
  ) {
    this.mode = 'indeterminate';
    this.color = 'primary';
    this.balanceAmount = new EventEmitter<number>();
    this.displayedColumns = [
      'no',
      'brandName',
      'couponPurchased',
      'valueAccumulated',
    ] as string[];

    this.mobile = false;
  }

  ngOnInit(): void {
    this.getYearsData();
    this.onResize();
    this.getData();
    this.responsiveService.checkWidth();
  }

  /**
   * Fetches the transaction report based on the provided time data and updates the transaction data.
   *
   */
  getData() {
    this.adminService.getGeminiWalletAmount().subscribe((data) => {
      if (data) {
        this.storeData = true;
        this.getTransactionReport();
      }
    });
  }
  getTransactionReport() {
    this.adminService.getTransactionReport().subscribe(
      (response) => {
        if (response) {
          this.transactionData = response;
          this.balanceAmount.emit(this.transactionData?.balance);
        }
      },
      (error) => {
        this.toastr.error(error.error);
      }
    );
  }

  /**
   * Fetches the activity graph data based on the provided time data, processes the data,
   * and creates the chart.
   *
   */
  getActivityGraphData(val: string) {
    const dateRange = this.convertYearRange(val);
    let reqPayload: TimeData = {
      fromDate: dateRange.start,
      toDate: dateRange.end,
    };
    this.adminService.activityGraph(reqPayload).subscribe((response) => {
      if (response?.length) {
        this.graphData = response;
        this.amountRewardedData= this.graphData.map((item: BrandsData) =>
          parseFloat(item.amountRewarded)
        );
        this.titleArray = this.graphData.map((data: BrandsData) => data.title);
        this.bgList = this.graphData.map(
          (data: BrandsData) => data.walletEmailToName
        );

        this.createChart();
      }
    });
  }

  /**
   * Provides the data of most used brands for selected date range
   * @param reqPayload Start date and End date
   */
  mostUsedBrandsData(reqPayload: TimeData) {
    this.dataSource = new MatTableDataSource<MostUsedBrandTable>([]);
    this.adminService.mostUsedBrands(reqPayload).subscribe((response) => {
      if (response?.length) {
        this.brandsData = response;
        this.brandsData.forEach(
          (brand) =>
            (brand.icon = this.commonService.decodeBase64ToImage(brand.icon))
        );
        this.dataSource = new MatTableDataSource<MostUsedBrandTable>(
          this.brandsData
        );
        this.dataSource.paginator = this.paginator;
      }
    });
  }
  formatNumbers(numbers: number[]): string[] {
    return numbers.map(number => this.numberFormatterPipe.transform(number));
  }

  /**
   * Creates a bar chart to display the activity graph data.
   *
   *
   */
  public createChart() {
    if (this.barChart) {
      this.barChart.destroy(); // Destroy the existing chart
    }
    const formattedDataForTooltips =this.formatNumbers(this.amountRewardedData)

    Chart.register(...registerables);
    this.barChart = new Chart('BarChart', {
      type: 'bar',
      data: {
        labels: this.titleArray,
        datasets: [
          {
            label: 'Contribution category',
            data: this.amountRewardedData,
            backgroundColor: this.bgList,
            barThickness: 30,
          },
        ],
      },
      options: {
        scales: {
          x: {
            grid: {
              display: false, // Remove x-axis grid
            },
          },
          y: {
            grid: {
              display: true, // Remove y-axis grid
            },
          },
        },
        maintainAspectRatio: false, // Prevent Chart.js from resizing the canvas
        aspectRatio: 2.5,
        plugins: {
          legend: {
            display: false, // Hides the legend
          },
          title: {
            display: true, // Enable title display
            align: 'start',
            text: 'Activity Graph', // Title text
            font: {
              size: 14, // Title font size
            },
            padding: {
              top: 10,
              bottom: 30,
            },
            color: '#000000',
          },
          tooltip: {
            callbacks: {
                label: function(context) {
                    const index = context.dataIndex;
                    return `${context.dataset.label}: ${formattedDataForTooltips[index]}`;
                },
            },
        }
        },
      },
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterCriteria) {
      this.updateData(changes.filterCriteria.currentValue);
    }
  }

  updateData(data: FilterCriteria): void {
    if (Object.keys(data).length !== 0) {
      let reqData: TimeData = {
        fromDate: data.fromDate,
        toDate: data.toDate,
      };
    }
  }

  /**
   * check responsiveness
   */
  private onResize(): void {
    this.responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.mobile = isMobile;
    });
  }

  /**
   * Fetches years data from the admin service, updates the years list,
   * selects the most recent year, and fetches the table data for the selected year.
   */
  getYearsData() {
    this.adminService.yearsData().subscribe((data) => {
      this.yearsList = data;
      this.selectedValue = this.yearsList[this.yearsList.length - 1];
      this.getTableData(this.selectedValue);
      this.getActivityGraphData(this.selectedValue);
    });
  }

  /**
   * Fetches and processes table data for the given year.
   *
   * @param {string} val - The selected year value.
   */
  getTableData(val: string) {
    const dateRange = this.convertYearRange(val);
    let obj: TimeData = {
      fromDate: dateRange.start,
      toDate: dateRange.end,
    };
    this.mostUsedBrandsData(obj);
  }
  /**
   * Convert year range string to start and end dates
   * @param range - Year range string (e.g., '2023-2024')
   * @returns Object containing start and end dates
   */
  convertYearRange(range: string) {
    const [startYear, endYear] = range.split('-').map(Number);
    const fromDate = `${startYear}-04-01 00:00:00.001`;
    const toDate = `${endYear}-03-31 00:00:00.001`;

    return {
      start: fromDate,
      end: toDate,
    };
  }

  /**
   * Formats a given date string into 'YYYY-MM-DD HH:mm:ss' format.
   *
   * @param {string} date - The date string to format.
   * @returns {string} The formatted date string.
   */
  formatDate(date: string): string {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }
}
