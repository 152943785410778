import { startWith } from 'rxjs/operators';
import { TrainingandsessionsService } from './../service/trainingandsessions.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { Observable } from 'rxjs';
import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { EmployeeTechnology } from 'src/app/shared/models/general.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { session_data } from 'src/app/shared/models/training-session.model';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { ErrorStateMatcher } from '@angular/material/core';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-training-session-form',
  templateUrl: './training-session-form.component.html',
  styleUrls: ['./training-session-form.component.css']
})
export class TrainingSessionFormComponent implements OnInit {
  modalForm: UntypedFormGroup;
  submitted: boolean;
  mobile:boolean;
  changeTriggered: boolean;
  other_validator: boolean;
  skillCtrl: UntypedFormControl;
  filteredSkills!: Observable<string[]>;
  skills: String[];
  tech_obj: EmployeeTechnology;
  tech_Send: EmployeeTechnology[];
  allSkills: string[] = ['HTML', 'CSS', 'JAVA', 'Angular', 'Python'];
  @ViewChild('picker') datePicker!: MatDatepicker<Date>;
  @ViewChild('picker2') datePicker2!: MatDatepicker<Date>;
  minDate: Date;
  maxDate: Date;
  posList: String[];
  @ViewChild('skillInput') skillInput!: ElementRef<TrainingSessionFormComponent>;
  matcher: MyErrorStateMatcher;
  first = false;
  second = false;

  constructor(
    public dialogRef: MatDialogRef<TrainingSessionFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: session_data,
    private formBuilder: UntypedFormBuilder,
    private trainingService: TrainingandsessionsService,
    private _responsiveService: ResponsiveService,
  ) {
    this.changeTriggered = false;
    this.submitted = false;
    this.mobile = false;
    this.other_validator = false;
    this.skills = [] as String[];
    this.skillCtrl = new UntypedFormControl();
    this.tech_obj = {} as EmployeeTechnology;
    this.tech_Send = [] as EmployeeTechnology[];
    this.minDate = new Date();
    this.maxDate = new Date();
    this.posList = [] as String[];
    this.matcher = new MyErrorStateMatcher();
    this.modalForm = this.formBuilder.group({});
    this.filteredSkills = this.skillCtrl.valueChanges.pipe(
      startWith(null),
      map((skill: string | null) =>
        skill ? this._filter(skill) : this.allSkills.slice()
      )
    );
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    this.minDate =
      currentMonth >= 3
        ? new Date(currentYear, 3, 1)
        : new Date(currentYear - 1, 3, 1);
    this.maxDate =
      currentMonth >= 3
        ? new Date(currentYear + 1, 2, 31)
        : new Date(currentYear, 2, 31);
    this.modalForm = this.formBuilder.group(
      {
        summary: [this.data.summary, Validators.required],
        description: [this.data.description, Validators.required],
        count: [this.data.count, [Validators.min(1), Validators.required]],
        technology: [this.data.technologyapplied, Validators.required],
        activityCount: [
          this.data.activityCount,
          [Validators.min(1), Validators.required],
        ],
        startDate: [this.data.startDate, Validators.required],
        ecdc: [this.data.ecdc, Validators.required],
        endDate: [this.data.endDate, Validators.required],
        other: [this.data.other],
      },
      { validator: this.dateRangeValidator }
    );
    this._responsiveService.getMobileStatus().subscribe((isMobile:boolean) => {
      this.mobile=!!isMobile;
    });
    this.getTechnologies();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allSkills.filter((skill) =>
      skill.toLowerCase().includes(filterValue)
    );
  }
  /**
   * This will close the modal
   */
  onClose(): void {
    this.dialogRef.close();
  }
  /**
   * 
   * Date range validator
   */
  dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const start_date =
      this.modalForm &&
      moment(this.modalForm.value.startDate).format('YYYY-MM-DD');
    const end_date =
      this.modalForm &&
      moment(this.modalForm.value.endDate).format('YYYY-MM-DD');
    if (start_date && end_date) {
      invalid = new Date(start_date).valueOf() > new Date(end_date).valueOf();
    }
    return invalid ? { invalidRange: { start_date, end_date } } : null;
  };

  /**
   * Get technology list
   */
  getTechnologies(): void {
    this.trainingService.getTechnologies().subscribe((data:String[]) => {
      this.posList = data;
    });
  }

  /**
   * Check condition for EC 
   * requries for design
   */
  clickEc() {
    if (this.second ===true) {
      this.second = false;
    }
    this.first = true;
  }

  /**
   * check condition for DC
   * requries for design
   */
  clickDc() {
    if (this.first === true) {
      this.first = false;
    }
    this.second = true;
  }
  
  /**
   * open calendar for start date
   */
  openCalender(): void {
    this.datePicker.open();
  }

  /**
   * open calendar for end date
   */
  openCalender2(): void {
    this.datePicker2.open();
  }

  /**
   * Submit the new form
   */  
  onNewSubmit(): void{this.handleSubmit(this.data.modalCheck === 'ADD' ? 'newAdd' : 'editedAdd');}
  
 /**
  * Submit the draft form
  */ 
  onDraftSubmit():void {
    this.handleSubmit(this.data.modalCheck === 'ADD' ? 'newAddDraft' : 'editedDraft');
  }

  /**
   * 
   * Handling API calls ont the basis of submit types
   * 
   */
  handleSubmit(flag:string): void {
    this.submitted = true;
    if (this.modalForm.value.technology.includes('Other')) {
      this.other_validator = true;
      this.modalForm.get('other')?.setValidators([Validators.required]);
      this.modalForm.get('other')?.updateValueAndValidity();
    }
    if (!this.modalForm.value.technology.includes('Other')) {
      this.other_validator = false;
      this.modalForm.get('other')?.clearValidators();
      this.modalForm.get('other')?.updateValueAndValidity();
      this.modalForm.value.other = '';
    }

    if (this.modalForm.invalid) return;

    for (const element of this.modalForm.value.technology) {
      this.tech_obj = { employeeTechnology: element };
      this.tech_Send.push(this.tech_obj);
    }
    this.modalForm.value.technology = this.tech_Send;

    if (this.data.modalCheck === 'ADD') {
      this.modalForm.value.startDate = moment(
        this.modalForm.value.startDate
      ).format('YYYY-MM-DD');
      this.modalForm.value.endDate = moment(
        this.modalForm.value.endDate
      ).format('YYYY-MM-DD');
      this.dialogRef.close({
        resultForm: this.modalForm.value,
        flag: flag,
        objFile: '',
        checkFile: this.changeTriggered,
      });
    } else {
      this.modalForm.value.startDate = moment(
        this.modalForm.value.startDate
      ).format('YYYY-MM-DD');
      this.modalForm.value.endDate = moment(
        this.modalForm.value.endDate
      ).format('YYYY-MM-DD');
      this.dialogRef.close({
        resultForm: this.modalForm.value,
        flag: flag,
        objFile: '',
        checkFile: this.changeTriggered,
      });
    }
  }
 
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid);
  }
}
