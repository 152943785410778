import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './Guards/AuthGuard/auth.guard';
import { HrGuard } from './Guards/hrGuard/hr.guard';
import { NominationGuard } from './Guards/nominationGuard/nomination.guard';
import { EmpFeedbackGuard } from './Guards/empFeedbackGuard/emp-feedback.guard';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'event',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/events/events.module').then((m) => m.EventsModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },

  {
    path: 'main-dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/main-dashboard/main-dashboard.module').then(
        (m) => m.MainDashboardModule
      ),
  },
  {
    path: 'employee-feedback',
    canActivate: [AuthGuard, EmpFeedbackGuard],
    loadChildren: () =>
      import('./modules/employee-feedback/employee-feedback.module').then(
        (m) => m.EmployeeFeedbackModule
      ),
  },
  {
    path: 'certificate',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/certificate/certificate.module').then(
        (m) => m.CertificateModule
      ),
  },
  {
    path: 'certification',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/certification/certification.module').then(
        (m) => m.CertificationModule
      ),
  },
  {
    path: 'client-feedback',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/client-feedback/client-feedback.module').then(
        (m) => m.ClientFeedbackModule
      ),
  },
  {
    path: 'client-feedbacks',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/client-feedbacks/client-feedbacks.module').then(
        (m) => m.ClientFeedbacksModule
      ),
  },
  {
    path: 'interview',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/interview/interview.module').then(
        (m) => m.InterviewModule
      ),
  },
  {
    path: 'training-sessions',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/training-sessions/training-sessions.module').then(
        (m) => m.TrainingSessionsModule
      ),
  },
  {
    path: 'trainingandsessions',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/trainingandsessions/trainingandsessions.module').then(
        (m) => m.TrainingandsessionsModule
      ),
  },
  {
    path: 'mentorship',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/mentorship/mentorship.module').then(
        (m) => m.MentorshipModule
      ),
  },
  {
    path: 'mentorship-taken',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/mentorship-taken/mentorship-taken.module').then(
        (m) => m.MentorshipTakenModule
      ),
  },
  {
    path: 'project',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/project/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'projects',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/projects/projects.module').then(
        (m) => m.ProjectsModule
      ),
  },
  {
    path: 'self-development',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/self-development/self-development.module').then(
        (m) => m.SelfDevelopmentModule
      ),
  },
  {
    path: 'self-development-module',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/self-development-module/self-development-module.module'
      ).then((m) => m.SelfDevelopmentModuleModule),
  },
  {
    path: 'team-building',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/team-building/team-building.module').then(
        (m) => m.TeamBuildingModule
      ),
  },
  {
    path: 'team-building-activity',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/team-building-activity/team-building-activity.module'
      ).then((m) => m.TeamBuildingActivityModule),
  },
  {
    path: 'interview-taken',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/interview-taken/interview-taken.module').then(
        (m) => m.InterviewTakenModule
      ),
  },
  {
    path: 'faq-section',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/faq/faq.module').then((m) => m.FaqModule),
  },
  {
    path: 'rewards',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/rewards/rewards.module').then((m) => m.RewardsModule),
  },
  {
    path: 'bug-report',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/bug-report/bug-report.module').then(
        (m) => m.BugReportModule
      ),
  },
  {
    path: 'demo-videos',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/demo-videos/demo-videos.module').then(
        (m) => m.DemoVideosModule
      ),
  },
  {
    path: 'admin-portal',
  
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/admin-portal/admin-portal.module').then(
        (m) => m.AdminPortalModule
      ),
  },
  {
    path: 'checkout',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/checkout/checkout.module').then(
        (m) => m.CheckoutModule
      ),
  },
  {
    path: 'delegate-contributions',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/delegate-contributions/delegate-contributions.module'
      ).then((m) => m.DelegateContributionsModule),
  },
  {
    path: 'review-activities',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/review-activities/review-activities.module').then(
        (m) => m.ReviewActivitiesModule
      ),
  },
  {
    path: 'award-nomination',
    canActivate: [AuthGuard, NominationGuard],
    loadChildren: () =>
      import('./modules/award-nomination/award-nomination.module').then(
        (m) => m.AwardNominationModule
      ),
  },
  {
    path: 'HR-portal',
    canActivate: [AuthGuard, HrGuard],
    loadChildren: () =>
      import('./modules/hr-recommendation/hr-recommendation.module').then(
        (m) => m.HrRecommendationModule
      ),
  },
  {
    path: 'contribution-history',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/contribution-history-section/contribution-history-section.module'
      ).then((m) => m.ContributionHistorySectionModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
