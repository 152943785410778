import { Injectable,Inject } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthUserService } from './auth-user.service';
@Injectable()
export class ApiInterceptorService implements HttpInterceptor{
  
  constructor(@Inject('BASE_API_URL') private baseUrl: string,private authUser:AuthUserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    let authToken:any;
    let apiReq:any;
    
    if(localStorage.getItem('token')){
      authToken = localStorage.getItem('token');
    }
    
    if(request.url=='https://graph.microsoft.com/v1.0/me' || (request.url.includes('https://tokenmintingsvc.geminisolutions.com'))){
      apiReq = request.clone({
        url: `${request.url}`,
      });
    }
    
    else{
      let accessToken = localStorage.getItem("applicationAccessToken");
      apiReq = request.clone({
        setHeaders: { Authorization: "Bearer " + accessToken },
        url: `${this.baseUrl}/${request.url}`
      });
    }

    if (authToken && request.url!='https://graph.microsoft.com/v1.0/me') {
      apiReq = apiReq.clone({
        setHeaders: {'Authorization': 'Bearer ' + authToken,}
      });
    }

    return next.handle(apiReq);
  }
}
