import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: `root`,
})
export class CertificationService {
  constructor(private http: HttpClient) {}

  /**
   *Downloading file
   */
  downloadFile = (certificateSNO: Number) => {
    let reqParam = new HttpParams().set(`sno`, certificateSNO.toString());
    return this.http.get<any>(`contripoint/certificate/download/`, {
      params: reqParam,
    });
  }

  /**
   * get the certificate from with respective email ID.
   */
//todo : make interface for observable
  getAllCertificate = (
    empEmail: String,
    pageNo: number,
    status: String,
    fromDate: String,
    toDate: String
  ): Observable<any> => {
    let obj = {
      email: empEmail,
      pageNo: pageNo.toString(),
      status: status,
      fromDate: fromDate,
      toDate: toDate,
    };
    return this.http.post(`contripoint/certificate/byYear`, obj);
  }

  /**
   * get certificate detail for view
   * @returns - information of the certificate for view
   */
  //todo : make data interface
  getSelectedCertificateView = (sno: number) => {
    let header = new HttpHeaders()
      .set(`Access-Control-Allow-Origin`, `*`)
      .set(`content-type`, `application/json`);
    let reqParam = new HttpParams().set(`id`, sno);

    return this.http.get<any>(`contripoint/certificate/view`, {
      params: reqParam,
      headers: header,
    });
  }

  /**
   * delete api call for ctification deletion
   * @param id - certificate ID
   */
  //todo : make data interface
  deleteCertification = (id: Number)=> {
    return this.http.delete<any>(`contripoint/certificate/delete/` + id);
  }
}
