<div class="approve-modal">
  <div role="button" mat-icon-button class="close-button" (click)="onClose()">
    <img class="close-icon" src="assets/images/Cut.svg" alt="close-button" />
  </div>
  <div class="banner py-4 px-4 text-white">
    {{ modalTitle }}
  </div>
  <div class="p-4">
    <!-- <div class="p-2 warning-container mb-4 d-flex">
            <div class="d-flex align-items-center justify-content-center warn-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path
                        d="M11.7917 17.7031H13.3542V11.4531H11.7917V17.7031ZM12.4948 9.52604C12.7378 9.52604 12.9418 9.44792 13.1068 9.29167C13.2717 9.13542 13.3542 8.93576 13.3542 8.69271C13.3542 8.44965 13.2717 8.24132 13.1068 8.06771C12.9418 7.8941 12.7378 7.80729 12.4948 7.80729C12.2517 7.80729 12.0477 7.8941 11.8828 8.06771C11.7179 8.24132 11.6354 8.44965 11.6354 8.69271C11.6354 8.93576 11.7179 9.13542 11.8828 9.29167C12.0477 9.44792 12.2517 9.52604 12.4948 9.52604ZM12.4948 22.9115C11.0712 22.9115 9.7257 22.638 8.45833 22.0911C7.19097 21.5443 6.0842 20.7977 5.13802 19.8516C4.19184 18.9054 3.44531 17.7986 2.89844 16.5313C2.35156 15.2639 2.07812 13.9097 2.07812 12.4688C2.07812 11.0451 2.35156 9.69965 2.89844 8.43229C3.44531 7.16493 4.19184 6.0625 5.13802 5.125C6.0842 4.1875 7.19097 3.44531 8.45833 2.89844C9.7257 2.35156 11.0799 2.07812 12.5208 2.07812C13.9444 2.07812 15.2899 2.35156 16.5573 2.89844C17.8247 3.44531 18.9271 4.1875 19.8646 5.125C20.8021 6.0625 21.5443 7.16493 22.0911 8.43229C22.638 9.69965 22.9115 11.0538 22.9115 12.4948C22.9115 13.9184 22.638 15.2639 22.0911 16.5313C21.5443 17.7986 20.8021 18.9054 19.8646 19.8516C18.9271 20.7977 17.8247 21.5443 16.5573 22.0911C15.2899 22.638 13.9358 22.9115 12.4948 22.9115ZM12.5208 21.349C14.9688 21.349 17.0521 20.4852 18.7708 18.7578C20.4896 17.0304 21.349 14.934 21.349 12.4688C21.349 10.0208 20.4896 7.9375 18.7708 6.21875C17.0521 4.5 14.9601 3.64063 12.4948 3.64063C10.0469 3.64063 7.9592 4.5 6.23177 6.21875C4.50434 7.9375 3.64063 10.0295 3.64063 12.4948C3.64063 14.9427 4.50434 17.0304 6.23177 18.7578C7.9592 20.4852 10.0556 21.349 12.5208 21.349Z"
                        fill="#C57804" />
                </svg>
            </div>
            <div>Candidate has been nominated for multiple awards. Select an award category to dismiss nomination.</div>
        </div> -->

    <form [formGroup]="nominateApproveForm">
      <mat-form-field
        *ngIf="modalData?.sameAwardCategory"
        class="w-100"
        appearance="outline"
      >
        <mat-label class="label-text">Select Award</mat-label>
        <mat-select
          formControlName="awardType"
          placeholder="Select Award"
          disableOptionCentering
        >
          <mat-option
            *ngFor="let awardType of awardDropdownList"
            class="input-text"
            [value]="awardType"
          >
            {{ awardType }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="nominateApproveForm.get('awardType')?.hasError('required')"
          >Award Category field should not be empty</mat-error
        >
      </mat-form-field>

      <mat-form-field
        *ngIf="!modalData.isApproveClicked"
        class="w-100 approve-nom-error"
        appearance="outline"
      >
        <mat-label class="label-text">Reason</mat-label>
        <textarea
          rows="2"
          type="text-area"
          formControlName="reason"
          maxlength="400"
          minlength="20"
          placeholder="Reason must be at least of 20 characters long"
          matInput
        ></textarea>
        <mat-error
          *ngIf="nominateApproveForm.get('reason')?.hasError('required')"
          >Reason field should not be empty</mat-error
        >
        <mat-error
          class="error-style"
          *ngIf="
            !nominateApproveForm.get('reason')?.hasError('required') &&
            nominateApproveForm.get('reason')?.hasError('minlength')
          "
        >
          Reason field must be at least of 20 characters long</mat-error
        >
      </mat-form-field>
    </form>

    <div class="d-flex w-100 m-0">
      <button
        type="submit"
        mat-raised-button
        class="approve-btn py-1 px-4 mb-2"
        (click)="onSubmit()"
      >
        {{ modalData.isApproveClicked ? 'APPROVE' : 'REJECT' }}
      </button>
      <button
        (click)="onClose()"
        mat-raised-button
        class="py-1 px-4 cancel-btn mb-2"
      >
        CANCEL
      </button>
    </div>
  </div>
</div>
