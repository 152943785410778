import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SelfDevelopmentTableService {

  constructor(private http: HttpClient) { }
  
  createSelfDev(createSelfDevReqBody: any) {
    return this.http.post<any>('contripoint/selfdevelopment/add', createSelfDevReqBody);
  }

  saveAsDraft(createSelfDevReqBody: any) {
    return this.http.post<any>('contripoint/selfdevelopment/drafts', createSelfDevReqBody);
  }
  getAllSelfDev(empEmail: String, pageNo: number,year:string,status:string): Observable<any> {
    let obj = {
      'empEmail': empEmail,
      'pageNo': pageNo.toString(),
      'years':year,
      'status':status
    }
    return this.http.post('contripoint/selfdevelopment/byYear', obj);
  }
  
  getSelectedSelfDevView(selfDevelopmentId: number){
    let reqParam=new HttpParams().set('selfDevelopmentId',selfDevelopmentId);
    return this.http.get<any>('contripoint/selfdevelopment/view', {'params':reqParam});
  }
  
  deleteselfdevelopment(selfDevelopmentId:number){
    return this.http.delete<any>('contripoint/selfdevelopment/delete/'+selfDevelopmentId);
  }
}
