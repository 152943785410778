import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TeamBuildingService {

  constructor(private http: HttpClient) { }
  
  createTeamBuilding(createTeamBuildingReqBody: any) {
    return this.http.post<any>('contripoint/teambuilding/add', createTeamBuildingReqBody);
  }

  saveAsDraft(createTeamBuildingReqBody: any) {
    return this.http.post<any>('contripoint/teambuilding/drafts', createTeamBuildingReqBody);
  }

  getAllTeamBuilding(empEmail: String, pageNo: number,year:string,status:string): Observable<any> {
    let obj = {
      'empEmail': empEmail,
      'pageNo': pageNo.toString(),
      'years':year,
      'status':status
    }
    return this.http.post<any>('contripoint/teambuilding/byYear', obj);
  }

  getSelectedTeamBuildingView(teamBuildingId: number) {
    let reqParam=new HttpParams().set('teamBuildingId',teamBuildingId);
    return this.http.get<any>('contripoint/teambuilding/view', {'params':reqParam}).pipe(
      map((response: any) => {
        const modifiedData = { ...response };
        modifiedData.data.contributionDate = modifiedData.data.contributionDate.substr(0, 10);
        modifiedData.data.createdOn=modifiedData.data.createdOn.substr(0, 10)
        
        return modifiedData;
      })
    );
  }
  
  deleteteambuilding(teamBuildingId:number){
    return this.http.delete<any>('contripoint/teambuilding/delete/'+teamBuildingId);
  }

}
