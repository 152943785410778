import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { CsvModalComponent } from '../csv-modal/csv-modal.component';
import { ErrorStateMatcher, ThemePalette } from '@angular/material/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { AdminPortalService } from '../../service/admin-portal.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { FormGroupDirective, Validators, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { backend } from 'src/app/shared/models/admin-portal.model';
import { CommonService } from 'src/app/services/common.service';
import {
  ExcelDetails,
  XlsxEmployeeDetails,
  ExcelResponse,
} from 'src/app/shared/models/occasional-reward.model';
import { csvData } from 'src/app/shared/models/admin-portal.model';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { genericDailogData } from 'src/app/shared/models/confirmation-modal.model';
import { ExcelModalComponent } from '../../excel-modal/excel-modal.component';

@Component({
  selector: 'app-reward-section',
  templateUrl: './reward-section.component.html',
  styleUrls: ['./reward-section.component.css'],
})
export class RewardSectionComponent implements OnInit {
  testarray: Array<ExcelDetails>;
  faultyEmail: Array<string> = [];
  displayedColumns: String[];
  csvFile: csvData;
  dataSource: MatTableDataSource<backend>;
  empId: string;
  empEmail: String;
  rewardForm: UntypedFormGroup;
  submitted: boolean;
  mobile: boolean;
  file: any;
  empList: XlsxEmployeeDetails[];
  totalAmount;
  participantSet: Map<String, Number>;
  matcher: MyErrorStateMatcher;
  value: number;
  color: ThemePalette;
  mode: ProgressSpinnerMode;
  StoreData: boolean;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  genericDailogData: genericDailogData;
  titleList: Array<string>;

  constructor(
    public dialog: MatDialog,
    private _authService: AuthUserService,
    private formBuilder: UntypedFormBuilder,
    private _responsiveService: ResponsiveService,
    private commonService: CommonService,
    private toastr: ToastrService,
    private adminPortalService: AdminPortalService
  ) {
    this.csvFile = { attachment: '' };
    this.submitted = false;
    (this.empId = ''), (this.empEmail = '');
    this.rewardForm = this.formBuilder.group({});
    this.mobile = false;
    this.participantSet = new Map<String, Number>();
    this.totalAmount = 0;
    this.titleList = [];
    this.empList = [] as XlsxEmployeeDetails[];
    this.displayedColumns = ['emailId', 'amount'];
    this.dataSource = new MatTableDataSource<backend>();
    this.submitted = false;
    this.totalAmount = 0;
    this.matcher = new MyErrorStateMatcher();
    this.value = 50;
    this.StoreData = true;
    this.color = 'primary';
    this.mode = 'indeterminate';
    this.genericDailogData = { title: '', img: '', heading: '' };
    this.testarray = [];
  }

  ngOnInit(): void {
    this.onResize();
    this._authService.getEmployeeDetailBehaviorSubject().subscribe((item) => {
      if (item?.data?.email) {
        this.empEmail = item.data.email;
      }
    });
    this._responsiveService.checkWidth();

    this.rewardForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.titleDropdown();
  }
  /**
   * This method will open modal to upload excel file
   */
  openDialog(): void {
    const dialogRef = this.dialog.open(CsvModalComponent, {
      width: '672px',
      data: this.csvFile,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.StoreData = false;
        this.file = result.obj;
        const formData = new FormData();
        formData.append('file', result.obj);
        this.sendCsv(formData);
      } else {
        this.StoreData = true;
      }
    });
  }

  /**
   * Sends CSV data to the server and handles the response.
   * @param formData - The form data containing CSV information.
   */
  sendCsv(formData: FormData) {
    this.adminPortalService.getCsvData(formData).subscribe(
      (response: ExcelResponse) => {
        if (response) {
          if (response?.faultyEmailList?.length) {
            this.handleInvalidEmailList(response);
          } else {
            this.successModal('Data imported successfully!', false, response);
          }
        }
      },
      (err) => {
        this.StoreData = true;
        this.toastr.error(err.error);
      }
    );
  }

  /**
   * Handles the response based on different scenarios.
   * @param response - The response received from the API.
   */
  private handleResponse(response: ExcelResponse): void {
    if (response?.repeatedTransactions) {
      this.handleRepeatedTransactions(response);
    } else if (response?.weekly2kLimit) {
      this.handleWeekly2kLimit(response);
    } else {
      this.handleDefaultResponse(response);
    }
  }

  /**
   * Handles the default response scenario, add pagination to data
   * @param response - The response from the API.
   */
  private handleDefaultResponse(response: ExcelResponse): void {
    this.updateDataSource(response);
  }
  /**
   * Handles the scenario of invalid email list.
   * @param response - The response from the API.
   */
  private handleInvalidEmailList(response: ExcelResponse): void {
    this.openExcelModal('invalid mail', response);
  }

  /**
   * Handles the scenario of repeated transactions.
   * @param response - The response from the API.
   */
  private handleRepeatedTransactions(response: ExcelResponse): void {
    this.openExcelModal('repeated', response)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          if (response?.weekly2kLimit) {
            this.openExcelModal('limit exced', response)
              .afterClosed()
              .subscribe((data) => {
                if (data) {
                  this.updateDataSource(response);
                }
              });
          } else {
            this.updateDataSource(response);
          }
        }
      });
  }

  /**
   * Handles the scenario of weekly 2k limit.
   * @param response - The response from the API.
   */
  private handleWeekly2kLimit(response: ExcelResponse): void {
    this.openExcelModal('limit exced', response)
      .afterClosed()
      .subscribe((val) => {
        if (val) {
          this.updateDataSource(response);
        }
      });
  }

  /**
   * Opens the Excel modal.
   * @param type - The type of confirmation.
   * @param response - The response from the API.
   * @returns The dialog reference of the opened modal.
   */
  private openExcelModal(
    type: string,
    response: ExcelResponse
  ): MatDialogRef<ExcelModalComponent> {
    const dialogRef = this.dialog.open(ExcelModalComponent, {
      width: '960px',
      data: { confirmationType: type, data: response },
    });

    this.StoreData = true; // Moved StoreData assignment here to avoid repetition
    return dialogRef;
  }

  /**
   * Updates the data source with the response from the API.
   * @param response - The response from the API.
   */
  private updateDataSource(response: ExcelResponse): void {
    this.StoreData = true;
    this.totalAmount = 0;

    response?.employees.forEach((element) => {
      this.totalAmount += parseInt(element.amount);
    });
    this.empList = response?.employees;
    this.dataSource.data = response?.employees;
    this.dataSource.paginator = this.paginator;
  }

  handleFormSubmission(): void {
    let formData = new FormData();
    let employeeList = this.empList.map((emp) => ({
      emailId: { emailId: emp.emailId },
      amount: Number(emp.amount),
    }));

    let reqBody = {
      distributorId: this.empEmail,
      title: this.rewardForm.value.title,
      occasionalRewardDistribution: employeeList,
      description: this.rewardForm.value.description,
    };

    let con = JSON.stringify(reqBody);
    formData.append('formDetails', con);
    formData.append('file', this.file);

    this.adminPortalService.sendData(formData).subscribe(
      (data) => {
        if (data) {
          this.StoreData = true;
          this.commonService.sendUpdate('Reward allotted successfully');
          this.successModal('Reward allotted successfully!', true);
          this.dataSource.data = [];
          this.rewardForm.reset();
          this.submitted = false;
        }
      },
      (err) => {
        this.StoreData = true;
        this.toastr.error(err.error);
      }
    );
  }

  /**
   * @param title - success modal title
   * @param rewardAlloted - for confirmation reward has been aloted or not
   * @param excelData - provide excek data to perform further operations
   */
  successModal(
    title: string,
    rewardAlloted?: boolean,
    excelData?: ExcelResponse
  ): void {
    this.genericDailogData = {
      title: title,
      img: '/assets/images/n_image/Success.gif',
      heading: 'Success!',
    };

    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '513px',
      data: this.genericDailogData,
    });

    if (rewardAlloted) {
      dialogRef.afterClosed().subscribe(() => {
        this.commonService.setBudgetAddBtnClicked(false);
      });
    }
    if (excelData) {
      dialogRef.afterClosed().subscribe(() => {
        this.handleResponse(excelData);
      });
    }
  }

  /**
   * Submit the form details
   */
  onNewSubmit(): void {
    this.submitted = true;
    if (this.rewardForm.invalid || !this.dataSource.data.length) return;
    this.StoreData = false;
    this.handleFormSubmission();
  }

  /**
   * check responsiveness
   */
  onResize(): void {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.mobile = isMobile;
    });
  }

  /**
   * Provided title dropdown
   */
  titleDropdown() {
    this.adminPortalService.getTitleList().subscribe(
      (res) => {
        if (res?.length) {
          this.titleList = res;
        }
      },
      (er) => {
        this.toastr.error(er.error);
      }
    );
  }
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid);
  }
}
