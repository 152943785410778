import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BugFeedbackTableData } from '../bug-report-table/bug-report.model';

@Injectable({
  providedIn: 'root'
})
export class BugReportService {

  constructor(private http: HttpClient) { }

  reportBug(createBugreport: any) {
    return this.http.post<any>('contripoint/feedback/add', createBugreport);
  }

  getAllBugReport(pageNo:number): Observable<any>{
    let reqParam=new HttpParams().set('pageNo',pageNo);
    return this.http.get<BugFeedbackTableData>('contripoint/get/all/feedback',{'params':reqParam});
  }

  deleteBug(feedbackId:Number){
    return this.http.delete<any>('contripoint/feedback/delete/'+feedbackId);
  }

  getSelectedBugReportView(id: number) {
    let reqParam=new HttpParams().set('id',id);
    return this.http.get<any>('contripoint/get/single/feedback', {'params':reqParam});
  }

  takeAction(id:number,assignedEmployee:string,priority:string,description:string,status:string): Observable<any> {
    let obj = {
      "id": id,
      "assignedEmployee": assignedEmployee,
      "priority": priority,
      "adminDescription": description,
      "status": status
    }
    return this.http.post('contripoint/take/action', obj);
  }
  assigneeList(): Observable<any>{
    return this.http.get<any>('contripoint/get/dev/list');
  }
}
