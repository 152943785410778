import { ToastrService } from 'ngx-toastr';
import { ProfileData } from 'src/app/shared/models/profile.model';
import { self_dev_data } from 'src/app/shared/models/self-development.model';
import { take } from 'rxjs/operators';
import { SelfDevelopmentService } from 'src/app/modules/self-development-module/service/self-development.service'
import { GenericTable } from './../../../shared/models/certificate.model';
import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { MatDialog } from '@angular/material/dialog';
import { SelfDevelopmentFormComponent } from '../self-development-form/self-development-form.component';
import { DeleteModalComponent } from 'src/app/shared/components/delete-modal/delete-modal.component';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { SelfDevelopmentViewComponent } from '../self-development-view/self-development-view.component';

@Component({
  selector: 'app-self-development-dashboard',
  templateUrl: './self-development-dashboard.component.html',
  styleUrls: ['./self-development-dashboard.component.css']
})
export class SelfDevelopmentDashboardComponent implements OnInit {
  pageEvent?: PageEvent;
  tablePageIndex: number;
  screenSize: string;
  tableLength: number;
  fromDate:string;
  toDate:string;
  mobile: boolean;
  empEmail: String;
  status: string;
  allSelfDevelpmentData: GenericTable[];
  allSelfDevelopmentDetails: self_dev_data[]
  ecdcMap = {
    EC: 'Engineering Council',
    DC: 'Delivery Council',
  };
  selfDevForm: self_dev_data;
  constructor(
    private commonService: CommonService,
    private responsiveService: ResponsiveService,
    private router: Router,
    private authService: AuthUserService,
    public dialog: MatDialog,
    private selfDevelopmentService: SelfDevelopmentService,
    private toastr: ToastrService,
  ) {
    this.screenSize = ''
    this.tableLength = 0;
    this.tablePageIndex = 0;
     this.fromDate='';
     this.toDate='';
    this.empEmail = '';
    this.mobile = false;
    this.status = 'allStatus';
    this.allSelfDevelpmentData = [] as GenericTable[];
    this.allSelfDevelopmentDetails = [] as self_dev_data[];
    this.selfDevForm = { id: 0, summary: '', description: '', action: '', modalCheck: '', status: '', createdOn: '', duration: 0, date: '', ecdc: '' };
  }

  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(false);
    this.fromDate = this.commonService.getYearsData().fromDate;
    this.toDate = this.commonService.getYearsData().toDate;
   this.getEmployeeDetails();
    this.getAllSelfDevelpment();
    this.checkScreenStatus();
    this.onResize();
    this.responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
  }

  getYearsData(){
    let currentMonth = new Date().getMonth() + 1;
    if (currentMonth <= 3) {
      this.fromDate = `${moment().year() - 1}-04-01 00:00:00.001`;
      this.toDate = `${moment().year()}-03-31 23:59:59.999`;
    } else {
      this.fromDate = `${moment().year()}-04-01 00:00:00.001`;
      this.toDate = `${moment().year() + 1}-03-31 23:59:59.999`;
    }
  }

  /**
   * get the employee details
   */
  getEmployeeDetails = (): void => {
    this.authService.getEmployeeDetailBehaviorSubject().subscribe((item: ProfileData) => {
      if (item?.data?.email) {
        this.empEmail = item?.data?.email;
      }
    });
  }

  /**
   * get all self Develpment data with respect to email.
   */
  getAllSelfDevelpment(event?: PageEvent): PageEvent {
    this.allSelfDevelpmentData = [] as GenericTable[];
    let pageNo = 0;
    if (event) {
      pageNo = event.pageIndex + 1;
      this.tablePageIndex = event.pageIndex;
    } else {
      pageNo = 1;
      this.tablePageIndex = 0;
    }
    this.selfDevelopmentService.getAllSelfDev(this.empEmail, pageNo, this.fromDate,this.toDate, this.status).pipe(take(1))
      .subscribe({
        //todo: create data interface
        next: (data) => {
          if (data && data.data) {
            this.allSelfDevelopmentDetails = [];
            for (let SelfDevelopmentData of data.data) {

              SelfDevelopmentData.date = SelfDevelopmentData.contributionDate.substr(0, 10);
              SelfDevelopmentData.duration = SelfDevelopmentData.selfDevelopment.duration

              let SelfDevelopmentTableData = {} as GenericTable;
              SelfDevelopmentTableData.id = SelfDevelopmentData.id;
              SelfDevelopmentTableData.title = SelfDevelopmentData.summary;
              SelfDevelopmentTableData.createdOn = SelfDevelopmentData.createdOn.substr(
                0,
                10
              );
              SelfDevelopmentTableData.ecdc =
                SelfDevelopmentData.ecdc === 'DC'
                  ? this.ecdcMap.DC
                  : this.ecdcMap.EC;
              SelfDevelopmentTableData.status = SelfDevelopmentData.status;
              this.allSelfDevelpmentData.push(SelfDevelopmentTableData);
              this.allSelfDevelopmentDetails.push(SelfDevelopmentData);
            }
            this.tableLength = data.total_rows;
          }
        },
        error: () => {
          this.toastr.error('Unable to load Self Development')
        },
      });
    if (event) {
      return event;
    }
    return new PageEvent();
  }

  /**
   * add new self development
   */
  addSelfDevelopment = (): void => {
    this.selfDevForm.modalCheck = 'ADD'
    const dialogRef = this.dialog.open(SelfDevelopmentFormComponent, {
      width: '650px',
      panelClass: 'my-dialog',
      data: this.selfDevForm,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.commonService
          .createSelfDevCommon(result, this.empEmail)
          .then(() => {
            this.getAllSelfDevelpment();
          });
      }
    });
  }

  /**
   * delete Self Development
   *  @param {number}id - Self Development selected ID
   */
  deleteSelfDevelpment = (id: number): void => {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '481px',
      data: {
        warningTitle: 'Are you sure you want to delete this contribution?',
      },
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe({
      next: (data) => {
        if (data.yesClicked) {
          this.selfDevelopmentService.deleteselfdevelopment(id).pipe(take(1)).subscribe({
            next: () => {
              this.deleteConfirmationModal();
            },
            error: () => {
              this.toastr.error('Unable to delete Self Development')
            },
          });
        }
      },
    });
  }

  /**
   * Conformance for Delete Modal
   */
  deleteConfirmationModal = (): void => {
    let genericDailogData = {
      title: 'Contribution deleted successfully!',
      img: 'assets/images/n_image/Success.gif',
      heading: 'Success!',
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '513px',
      data: genericDailogData,
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe({
      next: () => {
        this.getAllSelfDevelpment();
      },
    });
  }

  /**
   * View the selected Self Development
   * @param {number}id - Self Development selected ID
   */
  viewSelfDevelpment = (id: number): void => {
    this.dialog.open(SelfDevelopmentViewComponent, {
      width: '810px',
      panelClass: 'my-dialog',
      data: id
    });
  }

  /**
   * edit the self development
   *@param {number}id - Self Development selected ID
   */
  editSelfDevelpmentDetails = (id: number): void => {
    let SelfDevelopmentDetails = this.allSelfDevelopmentDetails.filter(
      (allSelfDevelopment) => {
        return allSelfDevelopment.id === id;
      }
    );
    const dialogRef = this.dialog.open(SelfDevelopmentFormComponent, {
      width: '650px',
      panelClass: 'my-dialog',
      data: SelfDevelopmentDetails[0],
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe({
      next: (result) => {
        if (result) {
          this.commonService
            .createSelfDevEdit(result, id, this.empEmail)
            .then(() => {
              this.getAllSelfDevelpment();
            });
        }
      },
    });
  }

  /**
   * check screen status
   */
  checkScreenStatus = (): void => {
    this.responsiveService.getScreenStatus().subscribe((screenSize: string) => {
      if (screenSize) {
        this.screenSize = screenSize;
      }
    });
  }

  /**
   * check the screen width
   */
  onResize = (): void => {
    this.responsiveService.checkWidth();
  }

  /** 
   * set the screen status for small screen
   */
  isScreenSizeSmall = (): boolean => {
    return this.screenSize === 'sm';
  }

  /**
   * set the screen status for extra small
   */
  isScreenSizeXS = (): boolean => {
    return this.screenSize === 'xs' || this.screenSize === 'sm';
  }

  /**
   * back routing to main dashboard
   */
  routeBackToMainDashboard = (): void => {
    this.router.navigate(['main-dashboard']);
  }
}
