<div class="container">
  <div class="row clearfix">
      <div class="mt-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row pt-2 pb-2">
              <div class="d-flex justify-content-start align-items-center col-lg-2 col-md-2 col-sm-2 col-2">
                  <button class="d-flex" (click)="backButtonRoute()">
                      <mat-icon>keyboard_arrow_left</mat-icon>
                  </button>
              </div>
          </div>
          <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <mat-card class="example-card">
                      <div class="row mt-3 ml-2">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                              <mat-card-title class="a">View Certificate</mat-card-title>
                          </div>
                      </div>
                      <mat-card-content>
                          <div class="body-color row">
                            <div [ngClass]="{'offset-2': !mobile , 'body-color-mobile': mobile}"
                            class="col-lg-8 col-md-8 col-sm-12 col-xs-12 display-container">
                            <pdf-viewer [ngClass]="{'pdf-viewer-web': !mobile , 'pdf-viewer-mobile': mobile}" *ngIf="fileName?.includes('.pdf'); else elseBlock" [src]="pdfSource"
                                [original-size]="true" [fit-to-page]="true">
                            </pdf-viewer>
                            <ng-template #elseBlock>
                                <img class="certificate-img" [src]="imageSource">
                            </ng-template>

                        </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <mat-card-title class="sub-header  title-alignment">
                                        {{viewDetail.summary ? viewDetail.summary : "-" }}</mat-card-title>
                                </div>
                            </div>
                            <div class="row ml-2">
                                <div class="mt-3 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <mat-card-title class="heading">Date of Completion : </mat-card-title>
                                    <mat-card-subtitle class="body-text">{{viewDetail.date | date: 'dd'}}/{{
                                        viewDetail.date | date:'MM'}}/{{viewDetail.date |
                                        date:'YYYY'}}
                                    </mat-card-subtitle>
                                </div>
                                <div class="mt-3 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <mat-card-title class="heading">Attachment </mat-card-title>
                                    <div class="body-text">
                                        <img src="../../../../assets/images/attachment-black.svg">
                                        <a class="body-text ptr text-break"
                                            (click)="downloadActionPrevious(viewDetail.url,viewDetail.fileName)">{{
                                            fileName ? fileName : "-"}}</a>
                                    </div>
                                </div>
                            </div>
                            <div class=" mt-3 ml-2 row">
                                <div class=" col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <mat-card-title class="heading">Goal Type</mat-card-title>
                                    <mat-card-subtitle class="body-text">
                                        {{goalType}}
                                    </mat-card-subtitle>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6"  *ngIf="viewDetail.paid === 'Paid'">
                                    <mat-card-title class="heading">Invoice </mat-card-title>
                                    <div class="body-text">
                                        <img src="../../../../assets/images/attachment-black.svg">
                                        <a class="body-text ptr text-break"
                                            (click)="downloadAttach(viewDetail.id, invoiceName!,'invoice')"
                                            >{{ invoiceName ? invoiceName : "-" }}</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class=" col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <mat-divider class="w-100"></mat-divider>
                                </div>
                            </div>
                            <div *ngIf="(this.viewType=='myView' || this.viewType=='myteam' || this.viewType=='myactivity'  || this.viewType=='my-contribution-history' ||this.viewType=='team-contribution-history')"
                                class="row mt-2 ml-2 mb-2">
                                <div class="mt-3 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <mat-card-header>
                                        <div mat-card-avatar class="example-header-image"></div>
                                        <mat-card-title
                                            *ngIf="this.viewType=='myteam' || this.viewType=='team-contribution-history'; else userName"
                                            class="heading"> {{viewDetail.createdBy+"'s" }} Contribution Points
                                        </mat-card-title>
                                        <ng-template #userName>
                                            <mat-card-title class="heading">Your Contribution Points </mat-card-title>
                                        </ng-template>


                                        <mat-card-subtitle class="body-text">
                                            {{viewDetail.points ? viewDetail.points : "-" }}
                                        </mat-card-subtitle>
                                    </mat-card-header>
                                </div>
                                <div class="mt-3 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <mat-card-title class="heading">Status </mat-card-title>
                                    <ng-container>
                                        <mat-chip *ngIf="viewDetail.status=='DRAFTED';else pending" id="new" selected>
                                            {{viewDetail.status}}</mat-chip>
                                        <ng-template #pending>
                                            <mat-chip *ngIf="viewDetail.status=='PENDING APPROVAL';else approved"
                                                id="pending" selected>{{viewDetail.status}}</mat-chip>
                                        </ng-template>
                                        <ng-template #approved>
                                            <mat-chip *ngIf="viewDetail.status=='APPROVED';else declined" id="approved"
                                                selected>{{viewDetail.status}}</mat-chip>
                                        </ng-template>
                                        <ng-template #declined>
                                            <mat-chip *ngIf="viewDetail.status=='DECLINED'" id="declined" selected>
                                                {{viewDetail.status}}</mat-chip>
                                        </ng-template>
                                    </ng-container>
                                    <mat-chip-list></mat-chip-list>
                                </div>
                            </div>
                            <div *ngIf="viewDetail.status=='APPROVED'  || viewDetail.status=='DECLINED'">
                                <div class="mt-3 ml-2 row">
                                    <div class="mrg-comment col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <mat-card-title class="heading">Comment:</mat-card-title>
                                        <mat-card-subtitle class="body-text">
                                            {{viewDetail.comment ? viewDetail.comment : "-" }}
                                        </mat-card-subtitle>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="this.viewType=='review'">
                                <div class="row mt-4 ml-2">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 heading-1">
                                        Contribution Details
                                    </div>
                                </div>
                                <div class="row mt-3 ml-2">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <mat-card-title class="heading">Contribution by: </mat-card-title>
                                        <mat-card-subtitle class="body-text">
                                            {{viewDetail.createdBy}}
                                        </mat-card-subtitle>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <mat-card-title class="heading">Points to be credited:</mat-card-title>
                                        <mat-card-subtitle class="body-text">
                                            {{viewDetail.points}}
                                        </mat-card-subtitle>
                                    </div>
                                </div>
                                <div class="row ml-2">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <mat-card-title class="heading">Contribution category:</mat-card-title>
                                        <mat-card-subtitle class="body-text">
                                            {{viewDetail.category}}
                                        </mat-card-subtitle>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <mat-card-title class="heading">Date of posting:</mat-card-title>
                                        <mat-card-subtitle class="body-text">
                                            {{viewDetail.createdOn | date: 'dd'}}/{{
                                            viewDetail.createdOn | date:'MM'}}/{{viewDetail.createdOn |
                                            date:'YYYY'}}
                                        </mat-card-subtitle>
                                    </div>
                                </div>
                                <div class="row mt-3 ml-2">
                                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                        <mat-card-title class="heading">Certificate Cost :</mat-card-title>
                                        <mat-card-subtitle class="body-text">
                                            {{ viewDetail.paid? viewDetail.paid: '-' }}
                                        </mat-card-subtitle>
                                    </div>
                                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-6" *ngIf="viewDetail.paid === 'Paid'">
                                            <mat-card-title class="heading">Amount : </mat-card-title>
                                            <mat-card-subtitle class="body-text">
                                                {{ viewDetail.amount ? viewDetail.amount :'-' }}
                                            </mat-card-subtitle>
                                </div>
                                    </div>
                                   
                                <div class="row">
                                    <div class="mt-3 col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <mat-divider class="w-100"></mat-divider>
                                    </div>
                                </div>
                                <div class="mt-4 ml-2 row">
                                    <div *ngIf="this.checkApprove=='APPROVED'"
                                        class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <img src="../../../../assets/images/APPROVED.svg">
                                    </div>
                                    <div *ngIf="this.checkApprove==''" class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <button (click)="approveActivity()" class="approve-btn mb-2"
                                            mat-flat-button>APPROVE</button>

                                    </div>
                                    <div *ngIf="this.checkApprove==''" class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <button (click)="rejectActivity()" class="decline-btn mb-2"
                                            mat-flat-button>DECLINE</button>
                                    </div>
                                    <div *ngIf="this.checkApprove=='REJECTED'"
                                        class="col-lg-2 col-md-2 col-sm-2 col-xs-2">
                                        <img src="../../../../assets/images/DECLINED.svg">
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>