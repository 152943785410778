<div *ngIf="this.storeData == false" class="overlay">
  <mat-progress-spinner
    class="spinner"
    [color]="color"
    [mode]="mode"
    [value]="value"
    diameter="50"
    *ngIf="this.storeData == false"
  ></mat-progress-spinner>
</div>
<div class="container">
  <div>
    <span class="walletHeading mb-2"> Gemini Wallet </span>
    <div class="row mt-2">
      <div class="col-sm-12 col-md-12 col-lg-4">
        <mat-card class="walletSection d-flex justify-content-between p-3 mb-4">
          <div class="p-0 mt-1">
            <mat-card-title class="amountText p-0 m-0">
              Total Amount
            </mat-card-title>
            <mat-card-subtitle class="totalAmount p-0 m-0">
              ₹ {{ geminiWalletAmount | numberFormatter}}
            </mat-card-subtitle>
          </div>
          <div class="walletImg p-0 m-0">
            <img
              src="assets/images/totalAmount.svg"
              class="img-fluid"
              alt=""
            />
          </div>
        </mat-card>
      </div>

      <div class="col-sm-12 col-md-12 col-lg-8">
        <mat-card class="progressBar p-3 mb-4">
          <div class="d-flex justify-content-between">
            <mat-card-title class="barHead p-0">
              Amount Distribution</mat-card-title
            >
            <mat-card-content class="d-flex mt-2">
              <img
                class="barColor"
                src="assets/images/greenCircle.svg"
                alt="green"
              />
              <span class="barElement ml-1">Available</span>
              <img
                class="barColor ml-2"
                src="assets/images/redCircle.svg"
                alt="red"
              />
              <span class="barElement ml-1">Used</span>
            </mat-card-content>
          </div>
          <div class="d-flex justify-content-between">
            <mat-card-subtitle class="barPercent m-0 p-0">
              {{ usedPercent }}%
            </mat-card-subtitle>
            <mat-card-subtitle class="barPercent m-0 p-0">
              {{ availablePercent }}%
            </mat-card-subtitle>
          </div>
          <mat-progress-bar
            mode="determinate"
            value="{{ usedPercent }}"
          ></mat-progress-bar>
        </mat-card>
      </div>
    </div>
  </div>

  <div>
    <span class="walletHeading mb-2"> Reward Distribution </span>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-4">
        <mat-card class="p-0 card mr-2 mt-2 mb-2 p-3">
          <mat-card-title class="rewardHeading">
            Amount Distributed
          </mat-card-title>
          <mat-card-subtitle class="rewardSubtitle">
            ₹ {{ amountDistribution | numberFormatter }}
          </mat-card-subtitle>
        </mat-card>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4 p-1">
        <mat-card class="p-0 card m-2 p-3">
          <mat-card-title class="rewardHeading">
            Amount Redeemed
          </mat-card-title>
          <mat-card-subtitle class="rewardSubtitle">
            ₹ {{ amountRedeem  | numberFormatter}}
          </mat-card-subtitle>
        </mat-card>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-4 p-1">
        <mat-card class="p-0 card m-2 p-3">
          <mat-card-title class="rewardHeading"> Amount Left </mat-card-title>
          <mat-card-subtitle class="rewardSubtitle">
            ₹ {{ amountLeft | numberFormatter}}
          </mat-card-subtitle>
        </mat-card>
      </div>
    </div>
  </div>

  <mat-card class="p-0 main-section">
    <div
      *ngIf="this.commonService.getBudgetAddBtnClicked()"
      class="pl-4 pt-4 pb-1 brand-back-btn-text text-style"
      (click)="navigateToRewardDistributionHistory()"
    >
      <mat-icon class="brand-back-btn">keyboard_arrow_left</mat-icon>
      Go back to Reward Distribution History
    </div>
    <div class="d-flex mt-2 ml-2 mb-2 p-4">
      <div class="w-50 d-flex align-items-center distribution-history-title">
        Reward Distribution
      </div>
      <div
        class="w-50 d-flex justify-content-end"
        *ngIf="!this.commonService.getBudgetAddBtnClicked()"
      >
        <button
          *ngIf="!mobile"
          class="add-new-btn"
          mat-raised-button
          (click)="AddBtnClicked()"
        >
          ADD NEW
        </button>
        <div
          *ngIf="mobile"
          mat-raised-button
          id="add_btn_mobile"
          (click)="AddBtnClicked()"
        >
          <img src="../../../../../assets/images/add_.png" />
        </div>
      </div>
    </div>
    <app-budget-reward-tabs
      *ngIf="!this.commonService.getBudgetAddBtnClicked()"
    >
    </app-budget-reward-tabs>
    <div *ngIf="this.commonService.getBudgetAddBtnClicked()">
      <form [formGroup]="allotmentTypeForm">
        <div class="col-lg-4 col-md-6 col-sm-6 col-xs-4 click">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label class="label-text">Allotment Type</mat-label>
            <mat-select
              formControlName="allotmentData"
              placeholder="Select"
              disableOptionCentering
              (selectionChange)="setAllotmentTypeinService()"
            >
              <mat-option class="input-text" value="budget">
                Allot Budget
              </mat-option>
              <mat-option class="input-text" value="reward">
                Allot Occasional Reward
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
      <div class="p-3" *ngIf="isAllotmentTypeBudget()">
        <app-budgeting-section > </app-budgeting-section>
      </div>
      <div *ngIf="isAllotmentTypeReward()">
        <app-reward-section> </app-reward-section>
      </div>
    </div>
  </mat-card>
</div>
