import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MyProfileService } from 'src/app/modules/dashboard/dash-info-cards/service/my-profile.service';
import { NominationService } from 'src/app/modules/hr-recommendation/services/nomination.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CommonService } from 'src/app/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class NominationGuard implements CanActivate {
  constructor(
    private authUserService: AuthUserService,
    private router: Router,
    private profileService: MyProfileService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.profileService.getDetails().pipe(
      map((data) => {
        if (data && data?.isEcManager) {
          return true;
        } else {
          this.router.navigate(['/main-dashboard']);
          return false;
        }
      })
    );
  }
}
