<div
  class="mt-3 mb-3 d-flex justify-content-center"
  *ngIf="contributionData.img || contributionData.pdf; else cool"
>
  <div
    [ngClass]="{ 'w-100': !isScreenSizeXs() }"
    class="row bg-white contribution-card-container p-4 m-0 font-style w-75"
  >
    <div
      class="col-md-2 col-sm-12 col-12 d-flex justify-content-center align-items-center p-0"
    >
      <div *ngIf="isScreenSizeXs()" class="card-option-xs position-absolute">
        <ng-container [ngTemplateOutlet]="options"> </ng-container>
      </div>

      <div
        *ngIf="isScreenSizeXs()"
        class="ribbon text-white position-absolute"
        [ngClass]="{
          rejected: contributionData.status === 'DECLINED',
          success: contributionData.status === 'APPROVED',
          pending: contributionData.status === 'PENDING APPROVAL',
          drafted: contributionData.status === 'DRAFTED'
        }"
      >
        <ng-container [ngTemplateOutlet]="statusIcon"> </ng-container>
        {{ contributionData.status }}
      </div>

      <pdf-viewer
        class="card-img-container pdf-container"
        [ngClass]="{
          'card-img-xs': isScreenSizeXs(),
          'card-img': !isScreenSizeXs()
        }"
        *ngIf="contributionData.pdf; else elseBlock"
        [src]="contributionData.pdf"
        [fit-to-page]="false"
        [original-size]="false"
        [zoom-scale]="'page-fit'"
        [show-all]="false"
      >
      </pdf-viewer>

      <ng-template #elseBlock>
        <img
          [src]="contributionData.img"
          [ngClass]="{
            'card-img-xs': isScreenSizeXs(),
            'card-img': !isScreenSizeXs()
          }"
          class="card-img-container"
        />
      </ng-template>
    </div>
    <div
      [ngClass]="{ 'mt-4': isScreenSizeXs() }"
      class="col-md-7 col-sm-12 col-10 p-0 d-flex align-items-center"
    >
      <div class="p-1">
        <div class="card-title">{{ contributionData.title }}</div>
        <div class="card-subtitle">
          {{ contributionData.createdOn }} | {{ contributionData.ecdc }}
        </div>
      </div>
    </div>
    <div
      *ngIf="!isScreenSizeXs()"
      [ngClass]="{ 'mt-4': isScreenSizeXs() }"
      class="col-md-2 p-0 d-flex align-items-center"
    >
      <div
        class="text-center text-white w-100 card-status p-1"
        [ngClass]="{
          rejected: contributionData.status === 'DECLINED',
          success: contributionData.status === 'APPROVED',
          pending: contributionData.status === 'PENDING APPROVAL',
          drafted: contributionData.status === 'DRAFTED'
        }"
      >
        <ng-container [ngTemplateOutlet]="statusIcon"> </ng-container>
        {{ contributionData.status }}
      </div>
    </div>
    <div
      *ngIf="!isScreenSizeXs()"
      [ngClass]="{ 'mt-4': isScreenSizeXs() }"
      class="col-md-1 p-0 d-flex align-items-center justify-content-center"
    >
      <ng-container [ngTemplateOutlet]="options"> </ng-container>
    </div>
  </div>
</div>

<ng-template #cool>
  <div class="mt-3 mb-3 d-flex justify-content-center w-100">
    <div
      [ngClass]="{ 'w-100': !isScreenSizeXs() }"
      class="row bg-white contribution-card-container p-4 m-0 font-style w-75"
    >
      <div
        class="col-sm-12 col-12 d-flex justify-content-center align-items-center p-0"
      >
        <div *ngIf="isScreenSizeXs()" class="card-option-xs position-absolute">
          <ng-container [ngTemplateOutlet]="options"> </ng-container>
        </div>

        <div
          *ngIf="isScreenSizeXs()"
          class="ribbon text-white position-absolute"
          [ngClass]="{
            rejected: contributionData.status === 'DECLINED',
            success: contributionData.status === 'APPROVED',
            pending: contributionData.status === 'PENDING APPROVAL',
            drafted: contributionData.status === 'DRAFTED'
          }"
        >
          <ng-container [ngTemplateOutlet]="statusIcon"> </ng-container>
          {{ contributionData.status }}
        </div>
      </div>
      <div
        [ngClass]="{ 'mt-4': isScreenSizeXs() }"
        class="col-md-9 col-sm-12 col-10 p-0 d-flex align-items-center"
      >
        <div class="p-1">
          <div class="card-title">{{ contributionData.title }}</div>
          <div class="card-subtitle">
            {{ contributionData.createdOn }} | {{ contributionData.ecdc }}
          </div>
        </div>
      </div>
      <div
        *ngIf="!isScreenSizeXs()"
        [ngClass]="{ 'mt-4': isScreenSizeXs() }"
        class="col-md-2 p-0 d-flex align-items-center"
      >
        <div
          class="text-center text-white w-100 card-status p-1"
          [ngClass]="{
            rejected: contributionData.status === 'DECLINED',
            success: contributionData.status === 'APPROVED',
            pending: contributionData.status === 'PENDING APPROVAL',
            drafted: contributionData.status === 'DRAFTED'
          }"
        >
          <ng-container [ngTemplateOutlet]="statusIcon"> </ng-container>
          {{ contributionData.status }}
        </div>
      </div>
      <div
        *ngIf="!isScreenSizeXs()"
        [ngClass]="{ 'mt-4': isScreenSizeXs() }"
        class="col-md-1 p-0 d-flex align-items-center justify-content-center"
      >
        <ng-container [ngTemplateOutlet]="options"> </ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #options>
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Example icon-button with a menu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      *ngIf="
        contributionData.status === 'DRAFTED' ||
        contributionData.status === 'DECLINED'
      "
      mat-menu-item
      (click)="editCertificateDetails(contributionData.id)"
    >
      <mat-icon>remove_red_eye</mat-icon>
      <span>Edit</span>
    </button>
    <button mat-menu-item (click)="viewCertificate(contributionData.id)">
      <mat-icon>remove_red_eye</mat-icon>
      <span>Preview</span>
    </button>
    <button mat-menu-item (click)="deleteCertificate(contributionData.id)">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button>
  </mat-menu>
</ng-template>
<ng-template #statusIcon>
  <img
    class="icon"
    *ngIf="contributionData.status === 'DRAFTED'"
    src="../../../../assets/images/n_image/draft-icon.svg"
  />
  <img
    class="icon"
    *ngIf="contributionData.status === 'PENDING APPROVAL'"
    src="../../../../assets/images/n_image/pending-icon.svg"
  />
  <img
    class="icon"
    *ngIf="contributionData.status === 'APPROVED'"
    src="../../../../assets/images/n_image/approved-icon.svg"
  />
  <img
    class="icon"
    *ngIf="contributionData.status === 'DECLINED'"
    src="../../../../assets/images/n_image/declined-icon.svg"
  />
</ng-template>
