import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  constructor(private http: HttpClient) { }
  getAllInterview(empEmail: String, pageNo: Number, years: String,status:String): Observable<any> {
    let obj = {
      'empEmail': empEmail,
      'pageNo': pageNo.toString(),
      'years': years,
      'status':status
    }
    return this.http.post<any>('contripoint/interview/byYear', obj);

  }
  createInterviewTaken(createInterviewRequestBody: any) {
    return this.http.post<any>('contripoint/interview/add', createInterviewRequestBody);
  }
  saveAsDraft(createInterviewRequestBody: any) {
    return this.http.post<any>('contripoint/interview/drafts', createInterviewRequestBody);
  }
  getSelectedInterviewView(interviewId: number){
    let reqParam=new HttpParams().set('interviewId',interviewId);
     return this.http.get<any>('contripoint/interview/view',{'params':reqParam});
  }

  getMonth(): Observable<Array<String>>{
    return this.http.get<Array<String>>('contripoint/month/view');
  }
  
  getProfiles(): Observable<Array<String>>{
    return this.http.get<Array<String>>('contripoint/profiles/view');
  }

  getExperience(): Observable<Array<String>>{
    return this.http.get<Array<String>>('contripoint/experience/view');
  }

  deleteInterview(interviewId:Number){
    return this.http.delete<any>('contripoint/interview/delete/'+interviewId);
  }
  
}