import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { CommonService } from "src/app/services/common.service";

@Component({
  selector: "app-welcome-modal",
  templateUrl: "./welcome-modal.component.html",
  styleUrls: ["./welcome-modal.component.css"],
})
export class WelcomeModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<WelcomeModalComponent>,
    private commonService: CommonService,
  ) {}

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }
  startTutorialGuide(){
    this.dialogRef.close();
    this.commonService.setWelcomeGifClosed(true);
  }
}
