import { Component, OnInit, AfterViewChecked } from "@angular/core";
import { Router } from "@angular/router";
import { AuthUserService } from "src/app/services/auth-user.service";
import { CommonService } from "src/app/services/common.service";
import { AdminNavbarDetails } from "src/app/shared/models/admin-portal.model";
import { ResponsiveService } from "../../../services/responsive.service";
@Component({
  selector: "app-admin-navbar",
  templateUrl: "./admin-navbar.component.html",
  styleUrls: ["./admin-navbar.component.css"],
})
export class AdminNavbarComponent implements OnInit, AfterViewChecked {
  mobile: boolean;
  admin: AdminNavbarDetails;
  
  constructor(
    private _responsiveService: ResponsiveService,
    private router: Router,
    private commonService: CommonService,
    private authUser: AuthUserService
  ) {
    this.mobile = false;
    this.admin = {} as AdminNavbarDetails;
    this.commonService.setAdminSideNavOpened(true);
  }

  ngOnInit(): void {
    this.checkMobile();
    this._responsiveService.checkWidth();
    this.setAdminName();
  }

  ngAfterViewChecked(): void {
    this.checkMobile();
  }

  setAdminName() : void {
    this.authUser.getEmployeeDetailBehaviorSubject().subscribe((res: any) => {
      if (res) {
        this.admin.name = res.data.name;
        this.admin.img = atob(res.data.image);
      } else {
        this.admin.name = "Admin";
        this.admin.img = "../../../../assets/images/Unknown_person.jpg";
      }
    });
  }
  
  checkMobile() : void {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      if (isMobile) {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    });
  }

  isAdminOpened() : boolean {
    return this.commonService.getAdminPortalOpened();
  }

  backRoute() : void {
    this.router.navigate(["main-dashboard"]);
  }

  toggle() : void {
    this.commonService.setAdminSideNavOpened(
      !this.commonService.getAdminSideNavOpened()
    );
  }
}
