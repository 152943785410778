import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { MyProfileService } from '../dash-info-cards/service/my-profile.service';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Award } from 'src/app/shared/models/profile.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-view-profile',
  templateUrl: './view-profile.component.html',
  styleUrls: ['./view-profile.component.css'],
})
export class ViewProfileComponent implements OnInit {
  base_img: string;
  badge_name: string;
  badge_level_image: string;
  empPoints: number;
  empPos: string;
  totalBudget: number;
  redeemedReward: number;
  utilizedPoints: number;
  availablePoints: number;
  empImage: string;
  empName: string;
  empDesignation: string;
  empEmail: string;
  groupedAwards: Array<Array<Award>> = [];
  awards: Array<Award> = [];
  lastAssigned: string = '';

  @ViewChild('carousel', { static: false }) carousel!: NgbCarousel;
  constructor(
    config: NgbCarouselConfig,
    public dialogRef: MatDialogRef<ViewProfileComponent>,
    private _authService: AuthUserService,
    private _myprofile: MyProfileService,
    private toastr: ToastrService,
    public commonService: CommonService
  ) {
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
    this.base_img = '/assets/images/';
    this.badge_name = '';
    this.badge_level_image = '';
    this.empPoints = 0;
    this.empPos = '';
    this.totalBudget = 0;
    this.redeemedReward = 0;
    this.utilizedPoints = 0;
    this.availablePoints = 0;
    this.empImage = '';
    this.empName = '';
    this.empDesignation = '';
    this.empEmail = '';
  }

  /**
   * Toggles the expand property of an award based on the recommendation ID.
   * @param {number} id - The ID of the recommendation.
   */
  isExpand(id: number) {
    let award = this.awards.find(
      (selectedAward) => selectedAward.recommendation.id === id
    );
    if (award) {
      award.expand = !award.expand;
    }
  }

  /**
   * This method group the matcards for carosuel
   */
  groupAwards() {
    const totalAwards = this.awards.length;
    if (totalAwards % 3 === 0) {
      for (let i = 0; i < totalAwards; i += 3) {
        this.groupedAwards.push([this.awards[i], this.awards[i + 1]]);
        this.groupedAwards.push([this.awards[i + 2], this.awards[i]]);
      }
    } else {
      for (let i = 0; i < totalAwards; i += 2) {
        this.groupedAwards.push(this.awards.slice(i, i + 2));
      }
    }
  }
  ngOnInit(): void {
    this._authService.getEmployeeDetailBehaviorSubject().subscribe((item) => {
      if (item) {
        this.empName = item?.data?.name;
        this.empDesignation = item?.data?.designation;
        this.empEmail = item?.data?.email;
      }
    });
    this.getData();
  }

  /**
   * get employee profile data
   */
  getData() {
    this._myprofile.getProfileData().pipe(take(1)).subscribe((data) => {
      this.badgeAsset(data.position);
      if (data.position === 1) this.empPos = '1st';
      else if (data.position === 2) this.empPos = '2nd';
      else if (data.position === 3) this.empPos = '3rd';
      else this.empPos = data.position + 'th';
      this.empPoints = data.totalPoints;
      if (data.data.image) {
        this.empImage = atob(data.data.image);
      } else {
        this.empImage = 'assets/images/def.png';
      }
      this.totalBudget = data?.totalBudget;
      this.redeemedReward = data?.redeemedReward;
      this.utilizedPoints = data?.utilizedPoints;
      this.availablePoints = data?.availablePoints;

      this.lastAssigned = this.quarterToDateRange(data?.lastAssignedQuarter);
      let lastAssignedQuarterDate = new Date(this.lastAssigned);

      if (data?.awards?.length) {
        data.awards.forEach((award: any) => {
          let recommendationDate = new Date(award.recommendation.recommendedOn);
          if (recommendationDate <= lastAssignedQuarterDate) {
            award.s3Image = this.commonService.decodeBase64ToImage(
              award?.s3AwardImage
            );
            this.awards.push(award);
          }
        });
      }
      this.awards.forEach((award) => {
        award.expand = false;
        award.recommendation.quarter = this.quarterConvert(
          award?.recommendation?.recommendedOn,
          award?.recommendation?.praise
        );
      });

      this.groupAwards();
    });
  }

  /**
   * This will close the dialog box
   */
  onClose(): void {
    this.dialogRef.close();
  }

  /**
   *
   * @param val position
   * this will provide the badge name
   */
  badgeAsset(val: number) {
    if (val >= 1 && val < 11) {
      this.badge_name = 'Superstar';
    } else if (val < 21) {
      this.badge_name = 'Explorer';
    } else if (val < 31) {
      this.badge_name = 'Newbie';
    } else {
      this.badge_name = 'Good Work';
    }
  }

  /**
   *This method convert date to quarter format
   * @param recommendedOn - recommended date
   * @returns quarter with year
   */
  quarterConvert(recommendedOn: string, praise: string) {
    let date = moment(new Date(recommendedOn.substr(0, 10))).format(
      'YYYY-MM-DD'
    );
    return praise?.toLowerCase().includes('historic')
      ? this.getQuarterForHistoric(date)
      : this.getQuarterForCurrent(date);
  }

  getQuarterForHistoric(date: string) {
    let quarterdate = new Date(date);
    let quarterValue = '';
    if (quarterdate.getMonth() < 3) {
      quarterValue = `Q4-${quarterdate.getFullYear() - 1}`;
    } else if (quarterdate.getMonth() >= 3 && quarterdate.getMonth() < 6) {
      quarterValue = `Q1-${quarterdate.getFullYear()}`;
    } else if (quarterdate.getMonth() >= 6 && quarterdate.getMonth() < 9) {
      quarterValue = `Q2-${quarterdate.getFullYear()}`;
    } else {
      quarterValue = `Q3-${quarterdate.getFullYear()}`;
    }
    return quarterValue;
  }

  /**
   *
   * @param date convert date range to current quarter
   * @returns
   */
  getQuarterForCurrent(date: string) {
    let quarterdate = new Date(date);
    let quarterValue = '';
    if (quarterdate.getMonth() < 3) {
      quarterValue = `Q3-${quarterdate.getFullYear() - 1}`;
    } else if (quarterdate.getMonth() >= 3 && quarterdate.getMonth() < 6) {
      quarterValue = `Q4-${quarterdate.getFullYear() - 1}`;
    } else if (quarterdate.getMonth() >= 6 && quarterdate.getMonth() < 9) {
      quarterValue = `Q1-${quarterdate.getFullYear()}`;
    } else {
      quarterValue = `Q2-${quarterdate.getFullYear()}`;
    }

    return quarterValue;
  }

  /**convert quarter date to actual date format  */
  quarterToDateRange(quarterStr: string) {
    const [quarter, yearString] = quarterStr.split('-');
    const year = parseInt(yearString);

    let startDate: Date, endDate: Date;

    switch (quarter) {
      case 'Q1':
        startDate = new Date(year, 6, 1); // July 1st
        endDate = new Date(year, 8, 30); // September 30th
        break;
      case 'Q2':
        startDate = new Date(year, 9, 1); // October 1st
        endDate = new Date(year, 11, 31); // December 31st
        break;
      case 'Q3':
        startDate = new Date(year + 1, 0, 1); // January 1st
        endDate = new Date(year + 1, 2, 31); // March 31st
        break;
      case 'Q4':
        startDate = new Date(year + 1, 3, 1); // April 1st
        endDate = new Date(year + 1, 5, 30); // June 30th
        break;
      default:
        throw new Error('Invalid quarter string');
    }

    let formatedEndDate = moment(new Date(endDate)).format('YYYY-MM-DD');
    return formatedEndDate;
  }

  /**
   * Moves the carousel to the previous slide.
   */
  prevSlide() {
    this.carousel.prev();
  }

  /**
   * Moves the carousel to the next slide.
   */
  nextSlide() {
    this.carousel.next();
  }

  /**
   *
   * @param url This method get the url and convert into downloadble file
   */
  downloadCertificate = (base64Data: string) => {
    const blob = this.base64ToBlob(base64Data, 'application/pdf');
    console.log(blob);
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'certificate.pdf';
    a.click();

    window.URL.revokeObjectURL(url);
  };

  private base64ToBlob(base64: string, contentType: string): Blob {
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
}
