<div *ngIf="this.storeData == false" class="overlay">
  <mat-progress-spinner class="spinner" [color]="color" [mode]="mode" [value]="value" diameter="50"
    *ngIf="this.storeData == false"></mat-progress-spinner>
</div>


<div class="container pt-4 mb-4">
  <div class="font-style">
    <div class="d-flex justify-content-center">
      <div [ngClass]="{
          'w-75': isScreenSizeXS(),
          'w-100': !isScreenSizeXS()
        }" class="row w-100">
        <div class="col-md-7 col-sm-7 col-7 d-flex align-items-center p-0">
          <div class="row m-0 p-0 w-100">
            <button (click)="routeBackToMainDashboard()"
              class="col-lg-1 col-md-1 col-sm-2 col-3 p-0 d-flex align-items-center justify-content-center border-0 back-btn">
              <mat-icon class="w-100 h-100 d-flex align-items-center justify-content-center">keyboard_arrow_left
              </mat-icon>
            </button>
            <div class="col-lg-11 col-md-11 col-sm-10 col-9 title p-0 pl-3">
              Certificate
            </div>
          </div>
        </div>
        <div class="col-md-5 col-sm-5 col-5 d-flex justify-content-end p-0">
          <button *ngIf="!mobile" (click)="addNewCertificate()" class="add-new-btn border-0 text-white p-2">ADD
            NEW</button>
          <mat-icon *ngIf="mobile" (click)="addNewCertificate()" class="mat-add">add_circle</mat-icon>
        </div>
      </div>
    </div>
    <div *ngFor="let certificate of allCertificatesBasicData">
      <app-generic-contribution-table [contributionData]="certificate" (deleteEventEmitter)="deleteCertificate($event)"
        (viewEventEmitter)="viewCertificate($event)" (editEventEmitter)="editCertificateDetails($event)">
      </app-generic-contribution-table>
    </div>
    <div class="d-flex justify-content-center mt-3">
      <div *ngIf="!allCertificatesBasicData.length" [ngClass]="{
          'w-75': isScreenSizeSmall(),
          'w-100': !isScreenSizeSmall()
        }" class="d-flex align-items-center justify-content-center mb-3 mt-5">
        <div class="row mt-5">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
            <img src="assets/images/no data.svg" />
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 text-center mt-2">
            <p class="no-record"> Whoops, No Contributions Yet!</p>
            <p class="no-record-sub-heading mt-1">You don’t have any contributions yet.</p>
            <p class="no-record-sub-heading">You can add your contributions using the ADD NEW button</p>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center" *ngIf="allCertificatesBasicData.length">
      <mat-paginator [ngClass]="{
          'w-75': isScreenSizeSmall(),
          'w-100': !isScreenSizeSmall()
        }" class="mb-1" [pageIndex]="tablePageIndex" [pageSize]="5" showFirstLastButtons
        (page)="pageEvent = getAllCertificates($event)" [length]="tableLength">
      </mat-paginator>
    </div>
  </div>
</div>