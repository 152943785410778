import { Component, OnInit } from "@angular/core";
import { DeleteModalComponent } from "../delete-modal/delete-modal.component";
@Component({
  selector: "app-routing-confirmation-modal",
  templateUrl: "./routing-confirmation-modal.component.html",
  styleUrls: ["./routing-confirmation-modal.component.css"],
})
export class RoutingConfirmationModalComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
