<img
  class="rounded mx-auto d-block"
  src="../../../../assets/images/warning.png"
/>
<div class="d-flex justify-content-center heading mt-1">Unsaved Changes</div>
<mat-dialog-content>
  <p class="content mt-3">
    You have added the brands that haven't been saved yet.Are you sure to
    discard changes?
  </p>
</mat-dialog-content>

<div class="d-flex justify-content-around mt-1">
  <mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" mat-raised-button id="yes_btn">
      YES
    </button>

    <button mat-button [mat-dialog-close]="false" mat-raised-button id="no_btn">
      NO
    </button>
  </mat-dialog-actions>
</div>
