<div class="approve-modal">
    <div role="button" mat-icon-button class="close-button" (click)="onClose()">
        <img class="close-icon" src="assets/images/Cut.svg" alt="close-button" />
    </div>
    <div class="banner py-4 px-4 text-white fw-bold d-flex align-items-center">
        <div><img class="header-icon" [src]="headerIcon" /></div>
        <div>{{headerTitle}}</div>

    </div>
    <div class="p-4">
        <div class="row m-0 w-100">
            <div class="col-md-6 mb-2">
                <div class="title">Nominated By</div>
                <div class="data d-flex align-items-center fw-bold mt-1">
                    <div>
                        <img class="icon" [src]="nomineeIcon" />
                    </div>
                    <div>{{nomineeName}}</div>
                </div>
            </div>

            <div class="col-md-6 mb-2">
                <div class="title">Award Category</div>
                <div class="data d-flex align-items-center fw-bold mt-1">
                    <div>
                        <img class="icon" [src]="awardIcon" />
                    </div>
                    <div>{{awardType}}</div>
                </div>
            </div>

            <div class="col-12 mt-3" *ngIf="onBehalf">
                <div class="title">Nomination Received On Behalf of</div>
                <div class="data d-flex align-items-center fw-bold mt-1">
                    {{onBehalf}}
                </div>
            </div>

            <div class="col-12 mt-3" *ngIf="date">
                <div class="title">Nomination Date</div>
                <div class="data d-flex align-items-center fw-bold mt-1">
                    {{date}}
                </div>
            </div>

            <div class="col-12 mt-4">
                <div class="title">Reason For Nomination</div>
                <div class="data d-flex align-items-center mt-1">
                    {{reason}}
                </div>
            </div>
        </div>
    </div>
</div>