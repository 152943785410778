import { CommonService } from "src/app/services/common.service";
import { Component, OnInit } from "@angular/core";
import { ResponsiveService } from "../../../services/responsive.service";
import { dashboard_data } from "../admin-brand-selection/models/adminPortal.model";
import { LeaderboardService } from "../../dashboard/dash-info-cards/service/leaderboard.service";
import { MentorshipService } from "../../mentorship/service/mentorship.service";
import { Subscription } from "rxjs";
import {
  FormGroup,
  Validators,
} from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { ChangestatusService } from "src/app/services/changestatus.service";
import { EventService } from "../../events/services/event.service";
import { ViewChild } from "@angular/core";
import { ElementRef } from "@angular/core";
import { LeaderBoardData } from "src/app/shared/models/leaderboard.model";
import { EmployeeDropdownData } from "src/app/shared/models/admin-portal.model";

const ELEMENT_DATA: dashboard_data[] = [
  {
    position: 1,
    name: "aliquam repellendus placeat, excepturi adipisci maiores voluptatum laudantium",
    weight: 1.0079,
    symbol: "H",
    code: 1,
  },
  {
    position: 2,
    name: "aliquam repellendus placeat, excepturi adipisci maiores voluptatum laudantium",
    weight: 4.0026,
    symbol: "He",
    code: 2,
  },
  {
    position: 3,
    name: "aliquam repellendus placeat, excepturi adipisci maiores voluptatum laudantium",
    weight: 6.941,
    symbol: "Li",
    code: 3,
  },
  {
    position: 4,
    name: "aliquam repellendus placeat, excepturi adipisci maiores voluptatum laudantium",
    weight: 9.0122,
    symbol: "Be",
    code: 4,
  },
  {
    position: 4,
    name: "aliquam repellendus placeat, excepturi adipisci maiores voluptatum laudantium",
    weight: 9.0122,
    symbol: "Be",
    code: 5,
  },
  {
    position: 4,
    name: "aliquam repellendus placeat, excepturi adipisci maiores voluptatum laudantium",
    weight: 9.0122,
    symbol: "Be",
    code: 6,
  },
  {
    position: 4,
    name: "aliquam repellendus placeat, excepturi adipisci maiores voluptatum laudantium",
    weight: 9.0122,
    symbol: "Be",
    code: 7,
  },
  {
    position: 4,
    name: "aliquam repellendus placeat, excepturi adipisci maiores voluptatum laudantium",
    weight: 9.0122,
    symbol: "Be",
    code: 8,
  },
];

@Component({
  selector: "app-admin-dashboard",
  templateUrl: "./admin-dashboard.component.html",
  styleUrls: ["./admin-dashboard.component.css"],
})
export class AdminDashboardComponent implements OnInit {
  mobile: boolean;
  submitted : boolean;
  opened : boolean;
  rank: number;
  len: Number;
  filterForm!: FormGroup;
  employeForm!: FormGroup;
  participantSet : Map<string, boolean>;
  selectedValues: String[];
  allEmp: EmployeeDropdownData[];
  employees_list : EmployeeDropdownData[]; 
  _employees_list : EmployeeDropdownData[];
  @ViewChild("multiUserSearch") multiuserSearchInput!: ElementRef;
  dataSource = ELEMENT_DATA;
  displayedColumns: String[];
  subscriptionName: Subscription;
  data_leader : LeaderBoardData[];
  data_limit: LeaderBoardData[];
  select_emp : String[];
  constructor(
    private mentorshipService: MentorshipService,
    private _eventService: EventService,
    private formbuilder: FormBuilder,
    private _responsiveService: ResponsiveService,
    private _changeStatus: ChangestatusService,
    private _leaderboard: LeaderboardService,
    private commonService: CommonService
  ) {
    this.subscriptionName = this._changeStatus.getUpdate().subscribe(() => {
      this.getLeaderboardData();
    });
    this.data_leader = [] as LeaderBoardData[];
    this.mobile = false;
    this.len = 0;
    this.participantSet = new Map<string,boolean>();
    this.selectedValues = [] as String[];
    this.employees_list = [] as EmployeeDropdownData[];
    this._employees_list = [] as EmployeeDropdownData[];
    this.data_limit = [] as LeaderBoardData[];
    this.submitted = false;
    this.opened = false;
    this.rank = 30;
    this.select_emp = ["Select individual participants", "Select by manager"];
    this.displayedColumns = [
      "S.no",
      "Contribution category",
      "Description",
      "Contribution By",
      "Points",
    ];
    this.allEmp = [] as EmployeeDropdownData[];
  }

  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(true);
    this.filterForm = this.formbuilder.group({
      Startdate: ["", Validators.required],
      Enddate: ["", Validators.required],
    });
    this.employeForm = this.formbuilder.group({
      employ_select: ["", Validators.required],
      employees: [""],
    });
    this.onResize();
    this.listLoad("Onload");
    this._responsiveService.checkWidth();
    this.getLeaderboardData();
    this.commonService.setAdminPortalOpened(true);
  }

  onResize() : void{
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.mobile = isMobile;
    });
  }

  getLeaderboardData() : void{
      this._leaderboard.getDetails(this.rank).subscribe((data) => {
        if (data) {
          this.data_limit = [] as LeaderBoardData[];
          this.data_leader = data;
          this.len = data.length;
          for (let i = 0; i < this.data_leader.length; i++) {
            if (this.data_leader[i].position! > 10) break;
            this.data_limit.push(this.data_leader[i]);
          }
          for (const element of this.data_leader) {
            if (element.image) {
              element.imgAsset = atob(element.image);
            } else {
              element.imgAsset = "../../../../assets/images/def.png";
            }
          }
          this._leaderboard.setLeaderboardValue(this.data_leader);
        }
      });

  }

  listLoad(flag?: String) : void {
    this.selectedValues = [];
    this.participantSet.clear();
    if (
      this.employeForm.value.employ_select == "Select individual participants"
    ) {
      this.mentorshipService.employeedropdown().subscribe((data) => {
        this.allEmp = data.data;
        this.employees_list = data.data;
        this._employees_list = data.data;
      });
    } else if (this.employeForm.value.employ_select == "Select by manager") {
      this._eventService.getEmployeeListByManager().subscribe((data) => {
        this.employees_list = data;
        this._employees_list = data;
      });
      this.mentorshipService.employeedropdown().subscribe((data) => {
        this.allEmp = data.data;
      });
    }
  }

  onInputChange() : void {
    const searchInput = this.multiuserSearchInput.nativeElement.value
      ? this.multiuserSearchInput.nativeElement.value.toLowerCase()
      : "";

    this.employees_list = this._employees_list.filter((u) => {
      const name: string = u.name.toLowerCase();
      return name.indexOf(searchInput) > -1;
    });
  }

  selectionChangeNewParticipant($event: any, selectionType: String) : void {
    this.participantSet.set(
      $event.option.value.id,
      !this.participantSet.get($event.option.value.email)
    );
    this.funcParticipant(selectionType);
  }
  
  funcParticipant(selectionType : String) : void {
    if (selectionType == "Select individual participants") {
      for (let [key, value] of this.participantSet) {
        let index = this.selectedValues.indexOf(key);
        if (value == true && index == -1) {
          this.selectedValues.push(key);
        }
        if (value == false && index >= 0) {
          this.selectedValues.splice(index, 1);
        }
      }
    } else if (selectionType == "Select by manager") {
      this.selectedValues = [];
      for (let [key, value] of this.participantSet) {
        let index = this.selectedValues.indexOf(key);
        if (value == true && index == -1) {
          this._eventService.getEmployeeUnderManager(key).subscribe((data) => {
            if (data) {
              for (let emp of data) {
                this.selectedValues.push(emp.id);
              }
            }
          });
        }
        if (value == false && index >= 0) {
          this.selectedValues.splice(index, 1);
        }
      }
    }
  }

  download() : void {}
  
  onNewSubmit() : void {
    this.submitted = true;
  }

  onClose() : void {}
  
  onApply() : void {
    this.employeForm.value.employees = this.selectedValues;
  }
}
