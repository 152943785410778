import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { CommonModalDetails } from '../../models/common-modal.model';

@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.css']
})
export class CommonModalComponent implements OnInit {
  mobile: boolean;
  constructor(private _responsiveService: ResponsiveService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private _authService: AuthUserService,
    public dialogRef: MatDialogRef<CommonModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CommonModalDetails) {
    this.mobile = false;
  }
  onClose(check:boolean) {
    this.dialogRef.close({
      res:check,
      id:this.data
    });
  }

  ngOnInit(): void {
  }

}
