import { Component, OnInit } from "@angular/core";
import { AdminPortalService } from "../../service/admin-portal.service";
import { ToastrService } from "ngx-toastr";
import { CoordinatesModule } from "ngx-color";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { BrandDetails } from "../models/adminPortal.model";
import { ConfirmationModalComponent } from "src/app/shared/components/confirmation-modal/confirmation-modal.component";
export interface employeeDetails {
  email: string;
  brands: number[];
}

@Component({
  selector: "app-all-brands",
  templateUrl: "./all-brands.component.html",
  styleUrls: ["./all-brands.component.css"],
})
export class AllBrandsComponent implements OnInit {
  allBrand: Array<BrandDetails>;
  uniqueBrand: Array<BrandDetails>;
  discountId: number;
  requestData: Array<employeeDetails>;
  discountText: string;
  filteredIdArray: Array<number>;
  isDirty = false;
  testData: Array<string>;
  saveBtnShow: boolean;
  addedbrand: Array<BrandDetails>;
  constructor(
    public dialog: MatDialog,
    private adminPortalService: AdminPortalService,
    private toastr: ToastrService,
    private _route: ActivatedRoute,
    private commonService: CommonService,
    private route: Router
  ) {
    this.testData = new Array<string>();
    this.uniqueBrand = new Array<BrandDetails>();
    this.requestData = new Array<employeeDetails>();
    this.allBrand = new Array<BrandDetails>();
    this.discountId = 0;
    this.filteredIdArray = new Array<number>();
    this.discountText = "Add Brands ";
    this.saveBtnShow = false;
    this.addedbrand = new Array<BrandDetails>();
  }
  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(true);
    this._route.paramMap.subscribe((params) => {
      this.discountId = Number(params?.get("id"));
      switch (Number(this.discountId)) {
        case 1:
          this.discountText += "with 28% off";
          break;

        case 2:
          this.discountText += "with 12% off";
          break;

        case 3:
          this.discountText += "with 7% off";
          break;

        case 4:
          this.discountText += "with 3.5% off";
          break;
      }
    });
    this.checkData();
  }

  navigateBackToDiscount() {
    this.route.navigate(["admin-portal/discount-selection"]);
  }
  checkData(){
    if(this.commonService.getEmails().length===0)
   {
    this.route.navigate(["admin-portal/discount-selection"]);
   }
  else{
    this.testData = this.commonService.getEmails();
    this.getAllBrands();
    this.getaddedbrand();
   }
  }
  updateBrands() {
    this.isDirty = false;
    this.filteredIdArray = this.uniqueBrand.map((data: BrandDetails) => {
      return data.id;
    });
    for (let i = 0; i < this.testData.length; i++) {
      this.requestData.push({
        email: this.testData[i],
        brands: this.filteredIdArray,
      });
    }
    let req = {
      employeeDetails: this.requestData,
    };
    this.adminPortalService.setUniqueBrand(req).subscribe((data) => {
      let dataDialog = {
        title: "Brands saved successfully!",
        img: "../../../../assets/images/n_image/Success.gif",
        heading: "Success!",
      };
      const dialogRef = this.dialog.open(ConfirmationModalComponent, {
        width: "513px",
        data: dataDialog,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.route.navigate(["admin-portal/discount-selection"]);
      });
    });
  }

  getAllBrands() {
    this.adminPortalService.getAllBrands().subscribe(
      (data) => {
        const bucketName = 'bucket' + this.discountId;
        this.allBrand = data[bucketName];
        this.allBrand.forEach((brand) => {
          brand.brandImage = this.commonService.decodeBase64ToImage(
            brand.brandImage
          );
        });
        this.getUniqueBrands();
      },
      (error) => {
        this.toastr.error('Error in loading Brands');
      }
    );
  }
  getUniqueBrands() {
    this.adminPortalService.getUniqueBrand(this.testData).subscribe(
      (response) => {
        const bucketName = 'bucket' + this.discountId;
        this.uniqueBrand = response[bucketName];

        if (this.uniqueBrand == null) {
          this.uniqueBrand = new Array<BrandDetails>();
        }
        this.uniqueBrand.forEach((brand) => {
          brand.brandImage = this.commonService.decodeBase64ToImage(
            brand?.brandImage
          );
        });
        this.allBrand = this.allBrand.filter((el: { id: number }) => {
          return !this.uniqueBrand.find((element: { id: number }) => {
            return element.id === el.id;
          });
        });
      },
      (error) => {
        this.toastr.error('Error in loading Brands');
      }
    );
  }

  setBrandsAsActive(index: number) {
    this.isDirty = true;
    let brand = this.allBrand[index];
    this.allBrand.splice(index, 1);
    this.uniqueBrand.push(brand);
    this.getcheck();
    this.toastr.success("Brand Added Successfully");
  }

  removeBrand(index: number) {
    this.isDirty = true;
    let item = this.uniqueBrand[index];
    this.uniqueBrand.splice(index, 1);
    this.allBrand.push(item);
    this.getcheck();
    this.toastr.success("Brand Removed Successfully");
  }

  getaddedbrand() {
    this.adminPortalService
      .getUniqueBrand(this.testData)
      .subscribe((response) => {
        const bucketName = 'bucket' + this.discountId;
        this.uniqueBrand =
          response[bucketName] === null ? [] : response[bucketName];
        this.uniqueBrand.forEach((element) => {
          element.brandImage = this.commonService.decodeBase64ToImage(
            element.brandImage
          );
        });
        this.addedbrand = this.uniqueBrand;
      });
  }

  getcheck() {
    let a;
    if (this.addedbrand.length >= this.uniqueBrand.length) {
      a = this.addedbrand.filter(o1 => {
        return !this.uniqueBrand.some((o2) => {
          return o1.id === o2.id
        })
      })
    } else {
      a = this.uniqueBrand.filter(o1 => {
        return !this.addedbrand.some((o2) => {
          return o1.id === o2.id
        })
      })
    }

    if (a.length > 0) {
      this.saveBtnShow = true;
    }
    else {
      this.saveBtnShow = false
    }
  }

}