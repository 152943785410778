<form [formGroup]="modalForm" class="p-3">
  <div class="container">
    <div>
      <span class="text-left headtop">Import Data</span>
      <button
        type="button"
        class="close float-right"
        (click)="onClose()"
        aria-label="Close"
      >
        <span aria-hidden="true"><i class="material-icons">close</i></span>
      </button>
    </div>
    <div class="row mt-5">
      <div class="col-lg-7 col-md-7 col-sm-7 col-xs-7">
        <span class="sampleText">Only excel file is accepted.</span>
        <p class="text-style">
          Please download the sample file before uploading any document.The
          uploaded document should've same columns as in the sample file.
        </p>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-5 col-xs-5 download-style">
        <button>
          <img src="/assets/images/download-csv.png" />
          <a
            *ngIf="this.adminPortalService.getAllotmentType() === 'reward'"
            href="/assets/RewardSample.xlsx"
            download="RewardSample.xlsx"
          >
            Download sample file(.xlsx)
          </a>
          <a
            *ngIf="this.adminPortalService.getAllotmentType() === 'budget'"
            href="/assets/BudgetSample.xlsx"
            download="BudgetSample.xlsx"
          >
            Download sample file(.xlsx)
          </a>
        </button>
      </div>
    </div>
    <div class="d-flex justify-content-start note mt-2">
      Note:- Excel file should have amount in multiples of 100
    </div>
  </div>
  <div class="divider"></div>
  <mat-divider class="mt-2"></mat-divider>
  <div class="container mt-3">
    <div
      [ngClass]="{
        'attachment-div': changeTriggered && this.data.attachment === ''
      }"
    >
      <img
        class="attachment-icon"
        *ngIf="changeTriggered && this.data.attachment === ''"
        src="/assets/images/attachment-black.svg"
      />
      <a class="attachment-display mt-2">{{ file.name }}</a>
    </div>

    <div>
      <label for="firsting" class="attachment_text" id="attachment_btn"
        ><img src="/assets/images/attachment-icon.png" /> Upload Excel
        File</label
      >
      <input
        type="file"
        id="firsting"
        class="form-control"
        formControlName="attachmentFile"
        (change)="onChange($event)"
      />
      <p class="ml-2 helper-text"></p>
      <p class="required-styling" *ngIf="!file"></p>
      <mat-error
        *ngIf="modalForm.controls.attachmentFile?.errors && this.submitted"
      >
        Attachment is required
      </mat-error>
    </div>

    <div class="form-buttons row">
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
        <button mat-raised-button id="add_btn" (click)="onNewSubmit()">
          SUBMIT
        </button>
      </div>
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
        <button (click)="onClose()" mat-raised-button id="cancel_btn">
          CANCEL
        </button>
      </div>
    </div>
  </div>
</form>
