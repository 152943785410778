import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
let SockJs = require("sockjs-client");
let Stomp = require("stompjs");
@Injectable({
  providedIn: 'root'
})
export class SocketService {

  constructor(private http: HttpClient,@Inject('BASE_API_URL') private baseUrl: string) { }
  public connect() {
    let socket = new SockJs(this.baseUrl+`/contripoint/socket`);

    let stompClient = Stomp.over(socket);

    return stompClient;
}
getNewNotification(empEmail: String,pageNo: number): Observable<any> {
  let obj = {
    'empEmail': empEmail,
    'pageNo': pageNo.toString()
  }
  return this.http.post('contripoint/get/notifications', obj);
}
getNotificationCount(empEmail: String){
  return this.http.post('contripoint/notificationcount', empEmail);
}
getNotificationRead(empEmail: String){
  return this.http.post('contripoint/notifications/read', empEmail);
}
}
