import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FeedbackTableService {
  constructor(private http: HttpClient) {}
  getAllFeedback(
    empEmail: String,
    pageNo: number,
    year: string,
    status: string
  ): Observable<any> {
    let obj = {
      empEmail: empEmail,
      pageNo: pageNo.toString(),
      years: year,
      status: status,
    };
    return this.http.post("contripoint/clientfeedback/byYear", obj);
  }

  createFeedback(createFeedbackRequestBody: any) {
    return this.http.post<any>(
      "contripoint/clientfeedback/add",
      createFeedbackRequestBody
    );
  }

  saveAsDraft(createFeedbackRequestBody: any) {
    return this.http.post<any>(
      "contripoint/clientfeedback/drafts",
      createFeedbackRequestBody
    );
  }

  getSelectedFeedbackView(feedbackId: number) {
    let reqParam = new HttpParams().set("feedbackId", feedbackId);
    return this.http.get<any>("contripoint/clientfeedback/view", {
      params: reqParam,
    });
  }

  downloadFile(feedbackId: Number) {
    let test=String(feedbackId)
    let reqParam = new HttpParams().set("feedbackId", test);
    return this.http.get<any>("contripoint/clientfeedback/download", {
      params: reqParam,
    });
  }

  deleteclientfeedback(id: number) {
    return this.http.delete<any>("contripoint/clientfeedback/delete/" + id);
  }
}
