<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button type="button" class="close" (click)="onClose()" aria-label="Close">
            <span aria-hidden="true"><i class="material-icons">close</i></span>
        </button>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-center ml-1">
        <img src="../../../../assets/images/welcome.png">
    </div>
    <div>
        <p class="congrats-text mt-4">
            WELCOME TO CONTRIPOINT
        </p>
        <p>
            You’re awarded 50 points as a joining bonus on your first login. Keep contributing to earn more points.
        </p>
        <p class="congrats-text mt-4">
            You are a Gem for Gemini !
        </p>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12  col-center ml-1 mt-2">
        <button type="submit" mat-raised-button id="ok_btn" (click)="onClose()">AWESOME</button>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12  col-center ml-1 mt-2">
        <button type="submit" mat-raised-button id="guide-btn" (click)="startTutorialGuide()">Start Tutorial Guide</button>
    </div>
</div>
