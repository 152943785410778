<!-- <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button type="button" class="close" (click)="onClose()"aria-label="Close">
            <span aria-hidden="true"><i class="material-icons">close</i></span>
        </button>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mat-dialog-content col-center ml-1 mt-4">
<img src="../../../../assets/images/done-tick.svg" >
</div>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-center mat-dialog-content ml-1 mt-4">
    <p>{{data.title}}</p>
</div>
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mat-dialog-content col-center ml-1 mt-4">
    <button type="submit" mat-raised-button id="ok_btn" (click)="onClose()">OK</button>
</div>
</div> -->

<div class="container">
    <div class="row"> 
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-3">
                <img class="gif" src={{genericData.img}}>
        </div>
        <div     [ngClass]="{
            headingSuccess: genericData.heading == 'Success!',
            headingDraft: genericData.heading == 'Draft Saved!'}"
             >
            {{genericData.heading}}
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mb-3">
           <p class="message">{{genericData.title}}</p> 
        </div>
    </div>
</div>
