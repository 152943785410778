import { NgModule } from "@angular/core";

import { CertificationRoutingModule } from "./certification-routing.module";
import { CertificationDashboardComponent } from "./certification-dashboard/certification-dashboard.component";
import { CertificationFormComponent } from "./certification-form/certification-form.component";
import { CertificationViewComponent } from "./certification-view/certification-view.component";
import { SharedModule } from "src/app/shared/shared.module";
import {PdfViewerModule} from 'ng2-pdf-viewer';
@NgModule({
  declarations: [CertificationDashboardComponent, CertificationFormComponent, CertificationViewComponent],
  imports: [SharedModule, CertificationRoutingModule,PdfViewerModule],
})
export class CertificationModule {}
