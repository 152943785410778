<div id="certificate-form">
  <button mat-icon-button class="close-button">
    <img class="on-close" src="assets/images/Cut.svg" (click)="onClose()" alt="close-icon" />
  </button>
  <mat-card class="banner d-flex justify-content-start mb-4">
    <img class="certificate-heading" src="assets/images/certificate badge.svg " alt="certificate badge" />
    <div class="ml-1 pl-3">
      <span class="banner-heading">Add New Certificate</span>
      <span class="banner-subheading d-flex">For Current Financial Year</span>
    </div>
  </mat-card>
  <div class="pad">
    <div [ngClass]="{ scroll: mobile }">
      <div class="note col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-start">
        <img class="mr-3" src="assets/images/info_icon.svg" alt="info-icon" />
        <span class="note-text">Uploading certificates acquired from free online platforms, such as Udemy, YouTube, Coursera, etc., is not permitted. Please ensure that the certificates are from accredited sources or recognized educational institutions.
        </span>
      </div>

      <mat-dialog-content class="modal-body">
        <form [formGroup]="modalForm" (ngSubmit)="onSubmit()">
          <div class="form-body">
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <span class="heading-style required">Name of Certification</span>
                <mat-form-field class="w-100" appearance="fill">
                  <input class="input-text" type="text" formControlName="summary" matInput
                    [errorStateMatcher]="matcher" />
                </mat-form-field>
                <mat-error class="error-style fix-top-margin" *ngIf="
                  modalForm.controls.summary?.errors && submitted
                ">
                  Name Of Certification is required
                </mat-error>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <span class="heading-style required">Date of Completion </span>
                <mat-form-field class="w-100" hintLabel="(Current Financial Year only)" appearance="fill">
                  <input class="input-text" (click)="openCalender()" readonly matInput [matDatepicker]="picker"
                    [min]="minDate" [max]="maxDate" formControlName="date" [errorStateMatcher]="matcher" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-error class="error-style" *ngIf="modalForm.controls.date?.errors && submitted">
                  Completion Date is required
                </mat-error>
              </div>

              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" [ngClass]="{ 'mt-3': mobile }">
                <span class="heading-style required">Technology Covered</span>
                <mat-form-field class="w-100" appearance="fill">
                  <mat-select formControlName="technology" multiple disableOptionCentering panelClass="panel-class">
                    <mat-option *ngFor="let posValue of posList" [value]="posValue">{{ posValue }}</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error class="error-style fix-top-margin" *ngIf="
                modalForm.controls.technology?.errors && submitted
              ">
                  Technology is required
                </mat-error>
              </div>
            </div>

            <div class="row mt-2" *ngIf="modalForm.value.technology.includes('Other')">
              <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <span class="heading-style required">Other</span>
                <mat-form-field class="w-100" appearance="fill">
                  <input class="input-text" type="text" formControlName="other" matInput [errorStateMatcher]="matcher"
                    placeholder="Enter Other technology" />
                </mat-form-field>
                <mat-error class="error-style fix-top-margin" *ngIf="other_validator && submitted">
                  Other technology is required
                </mat-error>
              </div>
            </div>

            <div>
              <div [ngClass]="{ 'mt-1': mobile }" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-3">
                <div>
                  <span class="heading-style required goal-type-mrg">Goal Type</span>
                  <mat-radio-group formControlName="ecdc">
                    <div class="row mrgn-left">
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="goal-type-border text-truncate d-block" [ngClass]="{ 'selected-goal': first }">
                          <mat-radio-button (click)="clickEc()" [checked]="data.ecdc === 'EC'" value="0"><span
                              class="goal-type-font mrg-right" [ngClass]="{ selectTrue: first }">Engineering Council
                              (EC)</span>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="goal-type-border text-truncate d-block" [ngClass]="{ 'selected-goal': second }">
                          <mat-radio-button (click)="clickDc()" [checked]="data.ecdc === 'DC'" value="1"><span
                              class="goal-type-font" [ngClass]="{ selectTrue: second }">Delivery Council (DC)</span>
                          </mat-radio-button>
                        </div>
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
              </div>
              <mat-error class="error-style fix-top-margin" *ngIf="modalForm.controls.ecdc?.errors && submitted">
                Goal Type is required
              </mat-error>
            </div>

            <div>
              <div [ngClass]="{ 'mt-1': mobile }" class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-3">
                <div>
                  <span class="heading-style required goal-type-mrg">Certification Cost</span>
                  <mat-radio-group formControlName="paid">
                    <div class="row mrgn-left">
                      <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="goal-type-border  text-truncate" [ngClass]="{ 'selected-goal': f }">
                          <mat-radio-button (click)="clickPaid()" [checked]="data.paid === 'Paid'" value="Paid"><span
                              class="goal-type-font mrg-right" [ngClass]="{ selectTrue: f }">Paid</span>
                          </mat-radio-button>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-sm-12" [ngClass]="{ 'mobile-margin': mobile }">
                        <div class="goal-type-border  text-truncate" [ngClass]="{ 'selected-goal': s }">
                          <mat-radio-button (click)="clickNonPaid()" [checked]="data.paid === 'Non-Paid'"
                            value="Non-Paid"><span class="goal-type-font" [ngClass]="{ selectTrue: s }">Non-Paid</span>
                          </mat-radio-button>
                        </div>
                      </div>
                    </div>
                  </mat-radio-group>
                </div>
              </div>
              <mat-error class="error-style fix-top-margin" *ngIf="modalForm.controls.paid?.errors && submitted">
                Certificate Cost is required
              </mat-error>
            </div>
            <div class="row" *ngIf="modalForm.value.paid==='Paid'">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <span class="heading-style required">Amount (in Rs) </span>
                <mat-form-field class="w-100" appearance="fill">
                  <input class="input-text" type="number" formControlName="amount" min="0"
                    oninput="validity.valid||(value='');" matInput [errorStateMatcher]="matcher" />
                </mat-form-field>
                <mat-error class="error-style fix-top-margin" *ngIf="
            modalForm.controls.amount?.errors && submitted && paid_validator
          ">
                  Amount is requried
                </mat-error>
              </div>
            </div>

            <div class="row" *ngIf="modalForm.value.paid==='Paid'">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-2">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  [ngClass]="{ 'attachment-div': data.invoice != '' }">
                  <img class="mr-2" *ngIf="data.invoice != ''" src="assets/images/attachment-black.svg" />
                  <a class="attachment-display-new" *ngIf="changeTriggeredInvoice === false; else elseBlockInvoice"
                    (click)="downloadInvoice(data.id)">{{data.invoice }}</a>
                </div>
                <ng-template #elseBlockInvoice>
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" [ngClass]="{
                        'attachment-div':
                          changeTriggeredInvoice === true && data.invoice === ''
                      }">
                      <img class="mr-2" *ngIf="
                          changeTriggeredInvoice === true && data.invoice === ''
                        " src="assets/images/attachment-black.svg" />
                      <a class="attachment-display mt-2">{{ invoiceFile.name}}</a>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label for="invoice" class="attachment_text ml-1 required" id="attachment_btn"><img
                    src="assets/images/attachment_bg.svg" /> Upload Invoice</label>
                <img src="assets/images/info_button.svg" alt="information"
                  title="(Only files with max. size of 150 KB in pdf, jpg, jpeg, eml are supported)">
                <input type="file" id="invoice" class="form-control" formControlName="attachmentInvoice"
                  (change)="onChangeInvoice($event)" />
                <mat-error class="mt-1 error-style fix-top-margin" *ngIf="
                    modalForm.controls.attachmentInvoice?.errors &&
                    submitted && paid_validator
                  ">
                  Invoice is required
                </mat-error>
                <mat-error class="mt-1 error-style fix-top-margin"
                  *ngIf="invoiceFileSizeInKb > 151 && submitted && paid_validator">
                  Max. file size must be 150 KB
                </mat-error>
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-2">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                  [ngClass]="{ 'attachment-div': data.attachment != '' }">
                  <img class="mr-2" *ngIf="data.attachment != ''" src="assets/images/attachment-black.svg" />
                  <a class="attachment-display-new" *ngIf="changeTriggered === false; else elseBlock"
                    (click)="downloadAttach(data.id)">{{data.attachment }}</a>
                </div>
                <ng-template #elseBlock>
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" [ngClass]="{
                      'attachment-div':
                        changeTriggered === true && data.attachment === ''
                    }">
                      <img class="mr-2" *ngIf="
                        changeTriggered === true && data.attachment === ''
                      " src="assets/images/attachment-black.svg" />
                      <a class="attachment-display mt-2">{{ file.name }}</a>
                    </div>
                  </div>
                </ng-template>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <label for="firsting" class="attachment_text ml-1 required" id="attachment_btn"><img
                    src="assets/images/attachment_bg.svg" /> Upload
                  Certificate</label>
                <img src="assets/images/info_button.svg" alt="information"
                  title="(Only files with max. size of 150 KB in pdf, jpg, jpeg, eml are supported)">
                <input type="file" id="firsting" class="form-control" formControlName="attachmentFile"
                  (change)="onChange($event)" />
                <mat-error class="mt-1 error-style fix-top-margin" *ngIf="
                  modalForm.controls.attachmentFile?.errors &&
                  submitted
                ">
                  Attachment is required
                </mat-error>
                <mat-error class="mt-1 error-style fix-top-margin" *ngIf="fileSizeInKb > 151 && submitted">
                  Max. file size must be 150 KB
                </mat-error>
                <p class="required-styling" *ngIf="!file"></p>
              </div>
            </div>
          </div>
        </form>
      </mat-dialog-content>
    </div>
    <div class="form-buttons row">
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
        <button mat-raised-button id="add_btn" (click)="onSubmit('add')">
          SUBMIT
        </button>
      </div>
      <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
        <button (click)="onClose()" mat-raised-button id="cancel_btn">
          CANCEL
        </button>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
        <button id="draft_btn" (click)="onSubmit('draft')">
          <img class="mr-2" src="assets/images/saveasdraft.svg" />Save
          As Draft
        </button>
      </div>
    </div>
  </div>