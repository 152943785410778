import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-successfull-modal',
  templateUrl: './successfull-modal.component.html',
  styleUrls: ['./successfull-modal.component.css']
})
export class SuccessfullModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
