import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericNominateDetails } from '../../models/generic-nomination-detail.model';

@Component({
  selector: 'app-generic-nomination-details',
  templateUrl: './generic-nomination-details.component.html',
  styleUrls: ['./generic-nomination-details.component.css']
})
export class GenericNominationDetailsComponent implements OnInit {

  public headerTitle: string;
  public headerIcon: string;
  public nomineeName: string;
  public awardType: string;
  public onBehalf: string | null;
  public date: string | null;
  public reason: string;
  public awardIcon: string;
  public nomineeIcon: string;
  constructor(private dialogRef: MatDialogRef<GenericNominationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public genericData: GenericNominateDetails) {
    this.headerTitle = '';
    this.headerIcon = '';
    this.nomineeName = '';
    this.awardType = '';
    this.onBehalf = null;
    this.date = null;
    this.reason = '';
    this.awardIcon = '';
    this.nomineeIcon = '';
  }

  ngOnInit(): void {
    this.headerTitle = this.genericData?.headerTitle;
    this.headerIcon = this.genericData?.headerIcon;
    this.nomineeName = this.genericData?.nomineeName;
    this.awardType = this.genericData?.awardType;
    this.onBehalf = this.genericData?.behalf;
    this.date = this.genericData?.date;
    this.reason = this.genericData?.reason;
    this.awardIcon = this.genericData?.awardIcon;
    this.nomineeIcon = this.genericData?.nomineeIcon
  }

  /**
   * closing the modal
   */
  public onClose(): void {
    this.dialogRef.close();
  }

}
