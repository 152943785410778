<div (click)="setStatus()">
  <button (click)="sidenavFunc()">
    <img class="close" src="assets/images/Close L.svg" alt="close-arrow" />
  </button>
  <div>
    <img
      class="leaderboardBanner"
      src="assets/images/Nomination Journey.svg"
      alt="leaderboardBanner"
    />
  </div>
  <div class="mt-5">
    <div class="d-flex">
      <div class="w-25 mrg-auto">
        <span class="heading">Nominee:</span>
      </div>
      <div class="w-75 ml-2 d-flex">
        <div>
          <img
            class="img-property"
            [src]="viewJourneyData.employee.image"
            alt=""
          />
        </div>
        <div class="req-mrg">
          <span class="title">{{ viewJourneyData.employee.name }}</span> <br />
          <p class="position">{{ viewJourneyData.employee.designation }}</p>
        </div>
      </div>
    </div>
    <div class="ml-2 mt-3 d-flex">
      <span class="heading"> Status : </span>
      <div class="pending ml-3">
        <span class="text-center text-white"> Pending For Approval </span>
      </div>
    </div>
    <mat-divider class="mt-13rem"></mat-divider>
    <div class="ml-2 mt-13rem">
      <img src="assets/images/Number 1.svg" alt="" />
      <span class="heading ml-2">Nominations:</span>
    </div>
    <div *ngFor="let item of nominatedData">
      <div class="ml-2 mt-3">
        <img src="assets/images/journey-calendar.svg" alt="" />
        <span class="date ml-3">{{ item.recommendedOn }}</span>
      </div>

      <div class="description">
        <div>
          <!-- <div >
              <img src="assets/images/journey-calendar.svg" alt="">
              <span class="date ml-3">{{item.recommendedOn}}</span>
              </div> -->
          <div class="d-flex mt-3">
            <div class="d-flex w-50">
              <img
                class="mr-3 img-height"
                style="height: 45px"
                src="assets/images/journy-Person.svg"
                alt="goal"
              />
              <div>
                <span class="sub-heading">Nominated By</span>
                <p class="subtitle">{{ item.recommendedByName }}</p>
              </div>
            </div>
            <div class="d-flex w-50">
              <img
                class="mr-3 img-height"
                style="height: 45px"
                src="assets/images/Trophy.svg"
                alt="attachment"
              />
              <div>
                <span class="sub-heading">Award Category</span>
                <p class="subtitle">{{ item.typeOfAward }}</p>
              </div>
            </div>
          </div>

          <div>
            <p class="rfh-heading">Reason For Nomination</p>
            <div>
              <span class="rfh-text">{{ item.praise }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider class="mt-13rem"></mat-divider>
  </div>
  <div class="ml-2 mt-13rem">
    <img src="assets/images/Number 2.svg" alt="" />
    <span class="heading ml-2">Recommendations:</span>
  </div>

  <ng-container *ngIf="recommededData?.length; else second">
    <div class="d-flex space-between mt-3" *ngFor="let item of recommededData">
      <div class="d-flex w-50">
        <img
          class="mr-3 img-height"
          style="height: 45px"
          src="assets/images/req-date.svg"
          alt="goal"
        />
        <div>
          <span class="sub-heading">Date</span>
          <p class="subtitle">{{ item.recommendedOn }}</p>
        </div>
      </div>
      <div class="d-flex w-50">
        <img
          class="mr-3 img-height"
          style="height: 45px"
          src="assets/images/journy-Person.svg"
          alt="attachment"
        />
        <div>
          <span class="sub-heading">Recommended By</span>
          <p class="subtitle">{{ item.recommendedByName }}</p>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #second>
    <span class="ml-5"> Direct Nomination </span>
  </ng-template>
</div>
