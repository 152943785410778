<div class="scroll-modal" id="certificate-view">
  <button mat-icon-button class="close-button">
    <img
      class="close"
      src="assets/images/Cut.svg"
      (click)="onClose()"
      alt="close-icon"
    />
  </button>

  <mat-card class="leaderboard d-flex justify-content-start mb-4">
    <img
      class="certificate-heading"
      src="assets/images/cerificateIconView.svg  "
      alt="certificate-banner"
    />
    <div class="ml-1 mt-2 pl-3">
      <span class="heading">View Certificate</span>
    </div>
  </mat-card>

  <div class="container">
    <div class="row mt-3" [ngClass]="{ 'mrg-left': !mobile }">
      <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
        <pdf-viewer
          [ngClass]="{
            'pdf-viewer-web': !mobile,
            'pdf-viewer-mobile': mobile
          }"
          *ngIf="viewDetail.fileName?.includes('.pdf'); else elseBlock"
          [src]="viewDetail.imageSource"
          [original-size]="true"
          [fit-to-page]="true"
        >
        </pdf-viewer>
        <ng-template #elseBlock>
          <div class="image-container">
            <img class="certificate-img" [src]="viewDetail.imageSource" />
          </div>
        </ng-template>
      </div>
    </div>

    <div class="head">
      <span> {{ viewDetail.summary }}</span>
    </div>

    <mat-divider class="mt-3"></mat-divider>
    <div class="ml-2">
      <div class="row mt-4">
        <div class="row mb-3" [ngClass]="{ mrgbtm: mobile }">
          <div
            class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
          >
            <img
              class="mr-3 img-height"
              src="assets/images/Date.svg   "
              alt="date"
            />

            <div>
              <p class="title">Created On</p>
              <p class="subtitle">
                {{ viewDetail.createdOn | date : 'dd' }}/{{
                  viewDetail.createdOn | date : 'MM'
                }}/{{ viewDetail.createdOn | date : 'YYYY' }}
              </p>
              <p></p>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
          >
            <img
              class="mr-3 img-height"
              src="assets/images/Date.svg  "
              alt="date"
            />

            <div>
              <p class="title">Date of Completion</p>
              <p class="subtitle">
                {{ viewDetail.date | date : 'dd' }}/{{
                  viewDetail.date | date : 'MM'
                }}/{{ viewDetail.date | date : 'YYYY' }}
              </p>
              <p></p>
            </div>
          </div>
        </div>
        <div class="row mb-3" [ngClass]="{ mrgbtm: mobile }">
          <div
            class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
          >
            <img
              class="mr-3 img-height"
              src="assets/images/Goal.svg   "
              alt="goal"
            />

            <div>
              <p class="title">Goal Type</p>
              <p class="subtitle">
                {{
                  viewDetail.goalType === 'EC'
                    ? 'Engineering Council (EC)'
                    : 'Delivery Council (DC)'
                }}
              </p>
              <p></p>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
          >
            <img
              class="mr-3 img-height"
              src="assets/images/Certification_Cost.svg  "
              alt="date"
            />

            <div>
              <p class="title">Certificate Cost</p>
              <p class="subtitle">
                {{ viewDetail.paid ? viewDetail.paid : '-' }}
              </p>
            </div>
          </div>
        </div>

        <div
          class="row mb-3"
          [ngClass]="{ mrgbtm: mobile }"
          *ngIf="viewDetail.paid === 'Paid'"
        >
          <div
            class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
          >
            <img
              class="mr-3 img-height"
              src="assets/images/Certificate_Amount.svg   "
              alt="amount"
            />

            <div>
              <p class="title">Amount</p>
              <p class="subtitle">
                {{ viewDetail.amount ? viewDetail.amount : '-' }}
              </p>
              <p></p>
            </div>
          </div>
          <div
            class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
            *ngIf="viewDetail.paid === 'Paid'"
          >
            <img
              class="mr-3 img-height"
              src="assets/images/Attachment.svg  "
              alt="attachment"
            />

            <div>
              <p class="title">Invoice Attachment</p>
              <p class="subtitle">
                <img src="assets/images/n_image/attachment.svg" />
                &nbsp;
                <a
                  class="attachment"
                  (click)="
                    downloadAttach(sno, viewDetail.invoiceName!, 'invoice')
                  "
                  >{{
                    viewDetail.invoiceName ? viewDetail.invoiceName : '-'
                  }}</a
                >
              </p>
              <p></p>
            </div>
          </div>
        </div>

        <div class="row mb-3" [ngClass]="{ mrgbtm: mobile }">
          <div
            class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
          >
            <img
              class="mr-3 img-height"
              src="assets/images/Attachment.svg  "
              alt="attachment"
            />

            <div>
              <p class="title">Attachment</p>
              <p class="subtitle">
                <img src="assets/images/n_image/attachment.svg" />
                &nbsp;
                <a
                  class="attachment"
                  (click)="
                    downloadAttach(sno, viewDetail.fileName, 'attachment')
                  "
                  >{{ viewDetail.fileName ? viewDetail.fileName : '-' }}</a
                >
              </p>

              <p></p>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="row mb-4 mt-4">
        <div
          class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
        >
          <img
            class="mr-3 img-height"
            src="assets/images/contributionPoint.svg  "
            alt="contribution-Point"
          />

          <div>
            <p class="title">Your Contribution Points</p>
            <p class="subtitle">{{ viewDetail.points }}</p>
            <p></p>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 col-sm-6 col-xs-6 d-flex justify-content-start"
        >
          <div>
            <p class="title">Status</p>
            <div
              div
              class="text-white card-status p-1"
              [ngClass]="{
                rejected: viewDetail.status === 'DECLINED',
                success: viewDetail.status === 'APPROVED',
                pending: viewDetail.status === 'PENDING APPROVAL',
                drafted: viewDetail.status === 'DRAFTED'
              }"
            >
              <ng-container [ngTemplateOutlet]="statusIcon"> </ng-container>
              {{ viewDetail.status }}
            </div>
          </div>
        </div>
      </div>

      <ng-template #statusIcon>
        <img
          class="icon"
          *ngIf="viewDetail.status === 'DRAFTED'"
          src="assets/images/n_image/draft-icon.svg"
        />
        <img
          class="icon"
          *ngIf="viewDetail.status === 'PENDING APPROVAL'"
          src="assets/images/n_image/pending-icon.svg"
        />
        <img
          class="icon"
          *ngIf="viewDetail.status === 'APPROVED'"
          src="assets/images/n_image/approved-icon.svg"
        />
        <img
          class="icon"
          *ngIf="viewDetail.status === 'DECLINED'"
          src="assets/images/n_image/declined-icon.svg"
        />
      </ng-template>
    </div>
  </div>
</div>
