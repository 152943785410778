<table mat-table [dataSource]="dataSource" class="w-100" multiTemplateDataRows>
  <ng-container matColumnDef="no">
    <th mat-header-cell *matHeaderCellDef>S.No</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{
        this.row * this.tablePageIndex +
          (dataSource.filteredData.indexOf(element) + 1)
      }}.
    </td>
  </ng-container>

  <ng-container matColumnDef="title">
    <th [hidden]="!flag" mat-header-cell *matHeaderCellDef>Title</th>
    <td [hidden]="!flag" mat-cell *matCellDef="let element">
      {{ element.title }}
    </td>
  </ng-container>

  <ng-container matColumnDef="issuedDate">
    <th mat-header-cell *matHeaderCellDef>Issued Date</th>
    <td mat-cell *matCellDef="let element">{{ element.date }}</td>
  </ng-container>

  <ng-container matColumnDef="amount">
    <th mat-header-cell *matHeaderCellDef>Total Amount (in Rs)</th>
    <td mat-cell *matCellDef="let element">{{ getTotalAmount(element)| numberFormatter }}</td>
  </ng-container>

  <ng-container matColumnDef="empList">
    <th [hidden]="!flag" mat-header-cell *matHeaderCellDef>Employees List</th>
    <td
      [hidden]="!flag"
      class="empList pointer"
      mat-cell
      *matCellDef="let element"
      (click)="downloadCSV(element.s3fileImage, element.fileName)"
    >
      {{ element.fileName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th [hidden]="flag" mat-header-cell *matHeaderCellDef>Action</th>
    <td [hidden]="flag" mat-cell *matCellDef="let element">
      <mat-icon
        class="circle"
        *ngIf="!budgetHistoryTableMap.get(element.id)"
        (click)="budgetHistoryTableMap.set(element.id, true)"
        >arrow_drop_down</mat-icon
      >
      <mat-icon
        class="circle"
        *ngIf="budgetHistoryTableMap.get(element.id)"
        (click)="budgetHistoryTableMap.set(element.id, false)"
        >arrow_drop_up</mat-icon
      >
    </td>
  </ng-container>
  <ng-container matColumnDef="expandedDetail">
    <td
      class="p-0"
      mat-cell
      *matCellDef="let element"
      [attr.colspan]="displayedColumns.length"
    >
      <table mat-table [dataSource]="innerTable.get(element.id)!" class="w-100">
        <ng-container matColumnDef="Email">
          <th mat-header-cell class="innerTableHeader" *matHeaderCellDef>
            Email
          </th>
          <td mat-cell class="innerTableCell" *matCellDef="let obj">
            {{ obj.empEmail }}
          </td>
        </ng-container>
        <ng-container matColumnDef="BudgetAmount">
          <th mat-header-cell class="innerTableHeader" *matHeaderCellDef>
            Amount (in Rs)
          </th>
          <td mat-cell class="innerTableCell" *matCellDef="let obj">
            {{ obj.amount| numberFormatter }}    
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns"></tr>
      </table>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr
    [hidden]="!budgetHistoryTableMap.get(row.id)"
    mat-row
    *matRowDef="let row; columns: ['expandedDetail']"
  ></tr>
</table>
<div *ngIf="!dataSource.data.length" class="no_record center-align">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <img src="../../../../../assets/images/cross-icon.svg" />
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">No records found!</div>
  </div>
</div>
<mat-paginator
  [pageSizeOptions]="[5, 10]"
  [pageIndex]="tablePageIndex"
  (page)="pageEvent = getData($event)"
  [length]="tableLength"
  showFirstLastButtons
>
</mat-paginator>
