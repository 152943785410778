import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) { }
  createEvent(formData:any){
    return this.http.post<any>('contripoint/event/create',formData);
  }
  createEventDynamic(formData:any){
    return this.http.post<any>('contripoint/add/participant',formData);
  }
  SaveAsDraftEvent(formData:any){
    return this.http.post<any>('contripoint/create/event/draft',formData);
  }
  uploadEntry(formData:any){
    return this.http.post<any>('contripoint/add/participant/entry',formData);
  }
  castVote(eventId:any,rating:any,votedToEmpId:any,entryId:any,voterEmpId:any){
    let obj = {
      "eventId": eventId.toString(),
      "voterEmployeeEmail": voterEmpId.toString(),
      "votedToEmployeeEmail": votedToEmpId.toString(),
      "entryId":entryId.toString(),
      "rating":rating.toString()
    }
    return this.http.post<any>('contripoint/caste/vote',obj)
  }
  casteRating(eventId: number,votedToEmpId: String,voterEmpId:String,entryId:number,rating:String){
    let obj = {
      'eventId': eventId.toString(),
      'votedToEmployeeEmail':votedToEmpId,
      'voterEmployeeEmail': voterEmpId,
      'entryId':entryId.toString(),
      'rating':rating
    }
    return this.http.post<any>('contripoint/caste/vote', obj);
  }
  getEmployeeUnderManager(rmEmail:String){
    let reqParam=new HttpParams().set('rmEmail',rmEmail.toString());
    return this.http.get<any>('contripoint/manager/employee', {'params':reqParam});
  }

  getEmployeeListByManager(){
    return this.http.get<any>('contripoint/managers');
  }
  employeedropdown(): Observable<any>{
    return this.http.get<any>('contripoint/fetch/employee/dropdown');
  }
  getUpcomingEvents(empEmail: String,pageNo:number): Observable<any>{
    let obj = {
      'empEmail': empEmail,
      'pageNo': pageNo.toString()
    }
    return this.http.post<any>('contripoint/view/upcoming/events',obj);
  }
  getClosedEvents(empEmail: String,pageNo:number): Observable<any>{
    let obj = {
      'empEmail': empEmail,
      'pageNo': pageNo.toString(),
    }
    return this.http.post<any>('contripoint/view/closed/events',obj);
  }
  getMyEvents(empEmail: String,pageNo:number): Observable<any> {
    let obj = {
      'empEmail': empEmail,
      'pageNo': (pageNo + 1).toString(),
      }
    return this.http.post('contripoint/my/activity', obj);
  }
  getApprovalEvents(empEmail: String,pageNo:number): Observable<any> {
    let obj = {
      'empEmail': empEmail,
      'pageNo': pageNo.toString(),
      }
    return this.http.post('contripoint/events/review', obj);
  }
  getAllEntries(pageNo:any,eventId:any,empEmail:String){
    let obj = {
      'eventId':eventId.toString(),
      'pageNo': pageNo.toString(),
      'empEmail': empEmail
      }
    return this.http.post('contripoint/view/all/entry', obj);
    }
  viewEntryUploaded(entryId:number,empEmail:String,eventId:number){
    let obj = {
      'entryId':entryId.toString(),
      'empEmail': empEmail,
      'eventId':eventId.toString()
      }
    return this.http.post<any>('contripoint/get/single/entry',obj);
  }
  getEntries(eventId: number) {
    let reqParam=new HttpParams().set('eventId',eventId);
    return this.http.get<any>('contripoint/find/submitted/pending/entry', {params:reqParam});
  }
  getEntriesList(eventId: number,empEmail: String) {
    let obj = {
      'eventId': eventId.toString(),
      'empEmail':empEmail,
    }
    return this.http.post<any>('contripoint/get/participants', obj);
  }
  publishCertificate(eventId:number){
    return this.http.post<any>('contripoint/publish/certificates/admin',eventId);
  }
  getActiveList(eventId: number){
    return this.http.post<any>('contripoint/get/voters', eventId);
  }
  getVotersTable(eventId: number,empEmail: String,pageNo:any){
    let obj = {
      'eventId': eventId.toString(),
      'empEmail': empEmail,
      'pageNo': pageNo.toString()
    }
    return this.http.post<any>('contripoint/view/voter/history', obj);
  }
  getAdminPollingView(entryId:number,empEmail:String){
    let obj = {
      'entryId': entryId.toString(),
      'empEmail':empEmail,
    }
    return this.http.post<any>('contripoint/get/single/entry/admin', obj);
  }
  getList(eventId:Number){
    let reqParam=new HttpParams().set('eventId',eventId.toString());
    return this.http.get<any>('contripoint/get/entry/list',  {params:reqParam});
  }
  demoEmailContent(eventId:number,tempId:number){
    let obj ={
      "eventId":eventId.toString(),
      "tempId":tempId.toString()
    }
    return this.http.post<any>('contripoint/customized/email/existingtext',obj)
  }
  sendEmail(eventId:number,tempId:number,customText:string){
    let obj ={
      "eventId":eventId.toString(),
      "tempId":tempId.toString(),
      "customText":customText
    }
    return this.http.post<any>('contripoint/customized/email',obj)
  }
  previewEmail(eventId:number,tempId:number,customText:string){
    let obj ={
      "eventId":eventId.toString(),
      "tempId":tempId.toString(),
      "customText":customText
    }
    return this.http.post<any>('contripoint/preview/customized/email/existingtext',obj)
  }
  getEventActivity(eventId: any,pageNo:number): Observable<any>{
    let obj = {
      'eventId': eventId.toString(),
      'pageNo': pageNo.toString()
    }
    return this.http.post<any>('contripoint/event/activities',obj);
  }
  getMyEventActivity(eventId: any,pageNo:any,empEmail:String): Observable<any>{
    let obj = {
      'eventId': eventId.toString(),
      'empEmail': empEmail,
      'pageNo': pageNo.toString()
    }
    return this.http.post<any>('contripoint/event/myactivities',obj);
  }

}

