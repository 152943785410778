<div class="mt-4">
  <form [formGroup]="quarterForm" class="mt-4 row w-100 m-0">
    <mat-form-field class="nomination col-6" appearance="outline">
      <mat-label class="label-text">Select Starting Financial Year</mat-label>
      <mat-select
        (selectionChange)="resetOnYearChange()"
        formControlName="startingYear"
        placeholder="Select Starting Financial Year"
        disableOptionCentering
        panelClass="nomination-filter-panel"
      >
        <mat-option
          *ngFor="let year of years"
          class="input-text"
          [value]="year"
        >
          {{ year + "-" + (year + 1) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="nomination col-6" appearance="outline">
      <mat-label class="label-text">Select Ending Financial Year</mat-label>
      <mat-select
        (selectionChange)="resetOnYearChange()"
        formControlName="endingYear"
        placeholder="Select Ending Financial Year"
        disableOptionCentering
        panelClass="nomination-filter-panel"
      >
        <mat-option
          [disabled]="this.quarterForm.value.startingYear > year"
          *ngFor="let year of years"
          class="input-text"
          [value]="year"
        >
          {{ year + "-" + (year + 1) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
  <div class="overflow-quarter-container">
    <div
      class="mt-3"
      *ngIf="
        this.quarterForm.value.startingYear !==
        this.quarterForm.value.endingYear
      "
    >
      <hr class="horizontal-line m-0" />
      <h1 class="px-3">
        Starting Financial Year :
        <span class="h1-data">{{
          this.quarterForm.value.startingYear +
            "-" +
            (this.quarterForm.value.startingYear + 1)
        }}</span>
      </h1>
      <div class="row m-0 w-100 mt-2">
        <div
          role="button"
          class="col-md-3 col-6 mb-4"
          (click)="onStartingQuaterSelect(i)"
          *ngFor="let element of quarterData; let i = index"
        >
          <div
            [ngClass]="{
              'quater-container-selected':
                startingSelectedQuarters === i ||
                (startingSelectedQuarters <= i &&
                  endingSelectedQuarters !== -1 &&
                  startingSelectedQuarters !== -1)
            }"
            class="p-2 quater-container-non-selected"
          >
            <div class="quater-text">QUARTER</div>
            <div class="mt-3 quater-number">{{ element?.quarter }}</div>
            <div class="row w-100 m-0 mt-3">
              <div
                class="col-4 quater-text p-0"
                *ngFor="let month of element?.months"
              >
                {{ month }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="horizontal-line m-0" />

      <h1 class="px-3">
        Ending Financial Year :
        <span class="h1-data">{{
          this.quarterForm.value.endingYear +
            "-" +
            (this.quarterForm.value.endingYear + 1)
        }}</span>
      </h1>
      <div class="row m-0 w-100 mt-2">
        <div
          role="button"
          class="col-md-3 col-6 mb-4"
          (click)="onEndingQuaterSelect(i)"
          *ngFor="let element of quarterData; let i = index"
        >
          <div
            [ngClass]="{
              'quater-container-selected':
                endingSelectedQuarters === i ||
                (endingSelectedQuarters >= i &&
                  endingSelectedQuarters !== -1 &&
                  startingSelectedQuarters !== -1)
            }"
            class="p-2 quater-container-non-selected"
          >
            <div class="quater-text">QUARTER</div>
            <div class="mt-3 quater-number">{{ element?.quarter }}</div>
            <div class="row w-100 m-0 mt-3">
              <div
                class="col-4 quater-text p-0"
                *ngFor="let month of element?.months"
              >
                {{ month }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="horizontal-line m-0" />
      <h1 class="mx-3">
        Date Range :
        <span class="h1-data">{{ startDateText + " - " + endDateText }}</span>
      </h1>
    </div>

    <div
      *ngIf="
        this.quarterForm.value.startingYear ===
        this.quarterForm.value.endingYear
      "
    >
      <div class="row m-0 w-100 mt-4">
        <div
          role="button"
          class="col-md-3 col-6 mb-4"
          (click)="onQuaterSelect(i)"
          *ngFor="let element of quarterData; let i = index"
        >
          <div
            [ngClass]="{
              'quater-container-non-selected': !selectedQuarters.includes(i),
              'quater-container-selected': selectedQuarters.includes(i)
            }"
            class="p-2"
          >
            <div class="quater-text">QUARTER</div>
            <div class="mt-3 quater-number">{{ element?.quarter }}</div>
            <div class="row w-100 m-0 mt-3">
              <div
                class="col-4 quater-text p-0"
                *ngFor="let month of element?.months"
              >
                {{ month }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-error class="mx-3 mb-2" *ngIf="quarterError.length">{{
      quarterError
    }}</mat-error>

    <hr class="horizontal-line m-0" />
  </div>

  <div class="px-3 py-3 d-flex justify-content-end">
    <button
      (click)="sendDataToParentComponent()"
      mat-raised-button
      class="px-4 py-1 border-0 text-white action-btn"
    >
      DONE
    </button>
  </div>
</div>
