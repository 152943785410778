import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContributionCountData } from 'src/app/shared/models/dash-cards.model';

@Injectable({
  providedIn: 'root'
})
export class CountFunctionalityService {

  constructor(private http: HttpClient) { }
  getCount(empEmail: String , years : string): Observable<ContributionCountData> {
let obj ={
    'empEmail': empEmail,
    'years': years,
    }
    let reqParam=new HttpParams();
    reqParam=reqParam.append('empEmail', empEmail.toString());
      reqParam=reqParam.append('years', years);
    return this.http.post<ContributionCountData>('contripoint/contributioncount/byyear', obj);
    }
}