<div class="container">
  <div class="text-style">
    <div class="brand-back-btn-text" (click)="navigateBackToDiscount()">
      <mat-icon class="brand-back-btn">keyboard_arrow_left</mat-icon>
      {{ discountText }}
    </div>
    <div class="float-right">
      <button
        mat-raised-button
        class="save_btn"
        *ngIf="saveBtnShow"
        (click)="updateBrands()"
      >
        Save
      </button>
    </div>
    <div *ngIf="uniqueBrand && uniqueBrand?.length">
      <div class="add-brand-title">Added Brands</div>

      <div class="row mat-card-container">
        <div
          *ngFor="let selectedBrand of uniqueBrand; index as i"
          class="col-xl-3 col-lg-4 col-sm-6 col-md-6 col-xs-6 mb-3"
        >
          <mat-card class="p-0 mat-cards h-100">
            <div class="d-flex justify-content-end pr-3">
              <mat-icon class="clear-icon" (click)="removeBrand(i)"
                >clear</mat-icon
              >
            </div>
            <div
              class="d-flex align-item-center justify-content-center pl-3 pr-3"
            >
              <img
                [src]="selectedBrand?.brandImage"
                class="non-selected-brand-logo"
              />
            </div>
            <div class="brand-title text-center pl-3 pr-3">
              {{ selectedBrand?.brandName }}
            </div>
            <hr class="m-0" />
            <div class="d-flex justify-content-center align-items-end">
              <mat-icon class="checked-icon">done</mat-icon>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="select-brand-title" *ngIf="allBrand.length !== 0">
      Select Brands
    </div>
    <div class="row mat-card-container">
      <div
        *ngFor="let nonSelectedBrand of allBrand; index as i"
        class="col-xl-3 col-lg-4 col-sm-6 col-md-6 col-xs-6 mb-3"
      >
        <mat-card class="p-0 mat-cards h-100">
          <div
            class="d-flex align-item-center justify-content-center pl-3 pr-3"
          >
            <img
              [src]="nonSelectedBrand?.brandImage"
              class="non-selected-brand-logo"
            />
          </div>
          <div class="brand-title text-center pl-3 pr-3">
            {{ nonSelectedBrand?.brandName }}
          </div>
          <hr class="m-0" />
          <div class="d-flex justify-content-center align-items-end mb-4 mt-4">
            <button class="add_btn" (click)="setBrandsAsActive(i)">Add</button>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
