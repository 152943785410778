<div *ngIf="!this.storeData" class="overlay">
  <mat-progress-spinner
    class="spinner"
    [color]="color"
    [mode]="mode"
    [value]="value"
    diameter="50"
  ></mat-progress-spinner>
</div>

<div class="container">
  <div class="w-100 container-div px-3">
    <div class="row req-pos">
      <div id="analyticscss" class="col-xl-2 col-lg-2">
        <mat-form-field appearance="fill">
          <mat-select [(ngModel)]="selectedValue">
            <ng-container *ngFor="let item of analyticList">
              <mat-option [value]="item">{{ item }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-lg-2 col-xl-2"></div>

      <div
        class="col-xl-3 col-lg-3"
        id="analytics-2"
        [ngClass]="{ 'visibilty-none': selectedValue === 'Transactions' }"
      >
        <mat-form-field
          appearance="fill"
          *ngIf="hrAndManager || hr || admin; else managerField"
        >
          <mat-label>EC/DC:</mat-label>
          <mat-select
            [formControl]="multiSelectControl"
            multiple
            (selectionChange)="handleMultiSelectChange()"
            disableOptionCentering
            panelClass="vertical-dropdown"
            noEntriesFoundLabel="No user found"
          >
            <mat-option>
              <ngx-mat-select-search
                [formControl]="searchControl"
                placeholderLabel="Search"
              ></ngx-mat-select-search>
            </mat-option>
            <ng-container *ngFor="let group of filteredGroups">
              <mat-optgroup *ngIf="group.name" [label]="group.name">
                <mat-option *ngFor="let item of group.council" [value]="item">
                  {{ item }}
                </mat-option>
              </mat-optgroup>
              <ng-container *ngIf="!group.name">
                <mat-option *ngFor="let item of group.council" [value]="item">
                  {{ item }}
                </mat-option>
              </ng-container>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <ng-template #managerField>
          <mat-form-field appearance="outline">
            <mat-label>EC/DC:</mat-label>
            <mat-select
              [formControl]="multiSelectControl"
              multiple
              (selectionChange)="sendData()"
              disableOptionCentering
              panelClass="vertical-dropdown"
            >
              <ng-container *ngFor="let group of managerialData">
                <mat-optgroup *ngIf="group.name" [label]="group.name">
                  <mat-option *ngFor="let item of group.council" [value]="item">
                    {{ item }}
                  </mat-option>
                </mat-optgroup>
                <ng-container *ngIf="!group.name">
                  <mat-option *ngFor="let item of group.council" [value]="item">
                    {{ item }}
                  </mat-option>
                </ng-container>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </ng-template>
      </div>

      <div class="col-xl-3 col-lg-3" id="analytics-3">
        <form
          [formGroup]="range"
          *ngIf="selectedValue === 'Contributions'; else amountField"
        >
          <mat-form-field appearance="fill">
            <mat-label>Date Range</mat-label>
            <mat-date-range-input
              [formGroup]="range"
              [rangePicker]="picker"
              [min]="minDate"
              [max]="maxDate"
            >
              <input matStartDate formControlName="start" #dateRangeStart />
              <input
                matEndDate
                formControlName="end"
                #dateRangeEnd
                (dateChange)="onDateChange($event)"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </form>
        <ng-template #amountField>
          <div class="balance-props d-flex justify-content-evenly">
            <div class="d-flex">
              <img src="./assets/images/balance.svg" alt="amount icon" />
            </div>
            
            <div class="mr-1">
              <span class="balance">Balance Amount </span> <br />
              <span class="amt"> ₹{{ balanceAmount | numberFormatter }}</span>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="col-xl-2 col-lg-2">
        <div class="xl-btn px-3 py-2 pointer" (click)="exportToExcel()">
          <img src="./assets/images/download arrow.svg" alt="" />
          <span class="ml-3">Excel</span>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      selectedValue === 'Contributions' &&
      (managerialData?.length || dropDownData?.length)
    "
  >
    <app-contributions-section
      [filterCriteria]="reqObj"
    ></app-contributions-section>
  </div>
  <div *ngIf="selectedValue === 'Transactions'">
    <app-transactions-section
      [filterCriteria]="reqObj"
      (balanceAmount)="getBalance($event)"
    ></app-transactions-section>
  </div>
</div>
