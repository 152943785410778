<button mat-icon-button class="close-button">
  <img
    class="close-icon"
    src="assets/images/Cut.svg"
    (click)="onClose()"
    alt="close-button"
  />
</button>
<mat-card class="banner d-flex justify-content-start mb-4">
  <img
    class="project-heading"
    src="assets/images/project_form.svg  "
    alt="banner"
  />

  <div class="ml-1 pl-3">
    <span class="heading">Add Project</span>
    <span class="subheading d-flex">For Current Financial Year</span>
  </div>
</mat-card>

<div class="pad">
  <div [ngClass]="{ scroll: mobile }">

  <div
    class="note col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-start"
  >
    <img class="mr-3" src="assets/images/info_icon.svg" alt="info icon" />
    <ul class="note-text">
      <li>User Must Not Enter Details Of Any Primary Client Projects .</li>
      <li>
        Do Not Enter Individual Tasks, Only Add Complete Project Details & One
        Entry Per Project.
      </li>
    </ul>
  </div>
  <mat-dialog-content class="modal-body">
    <form [formGroup]="modalForm" (ngSubmit)="onNewSubmit()">
      <div class="form-body">
        <div class="row ml-1">
          <div
            [ngClass]="{ 'mt-1': mobile }"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-3"
          >
            <div>
              <span class="heading-style required goal-type-mrg"
                >Project Type</span
              >
              <mat-radio-group formControlName="projectType">
                <div class="row mrgn-left">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div
                      class="goal-type-border text-truncate"
                      [ngClass]="{ 'selected-goal': inhouse, 'mt-1': mobile }"
                    >
                      <mat-radio-button
                        (click)="clickInhouse();setValue(0)"
                        [checked]="radio_val === 'Inhouse'"
                        value="Inhouse"
                        ><span
                          class="goal-type-font mrg-right"
                          [ngClass]="{ selectTrue: inhouse }"
                          >Inhouse</span
                        >
                      </mat-radio-button>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-12"
                    [ngClass]="{ 'mt-1': mobile }"
                  >
                    <div
                      class="goal-type-border text-truncate"
                      [ngClass]="{ 'selected-goal': external }"
                    >
                      <mat-radio-button
                        (click)="clickExternal();setValue(1)"
                        [checked]="radio_val === 'External'"
                        value="External"
                        ><span
                          class="goal-type-font"
                          [ngClass]="{ selectTrue: external}"
                          >External</span
                        >
                      </mat-radio-button>
                    </div>
                  </div>
                </div>
              </mat-radio-group>
            </div>
          </div>
          <mat-error
            class="error-style fix-top-margin"
            *ngIf="
              modalForm.controls.projectType?.errors && submitted
            "
          >
            Project Type is required
          </mat-error>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <span class="heading-style required">Project Title </span>
            <mat-form-field class="w-100" appearance="fill">
              <input
                class="input-text"
                type="text"
                formControlName="projectName"
                matInput
                [errorStateMatcher]="matcher"
              />
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="
                modalForm.controls.projectName?.errors?.required &&
                submitted
              "
            >
              Project Title is required
            </mat-error>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <span class="heading-style required">Start Date</span>
            <mat-form-field
              class="w-100"
              hintLabel="(Current Financial Year only)"
              appearance="fill"
            >
              <input
                class="input-text"
                (click)="openCalender()"
                readonly
                matInput
                [matDatepicker]="picker"
                [min]="minDate"
                [max]="maxDate"
                formControlName="date"
                [errorStateMatcher]="matcher"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-error
              class="error-style"
              *ngIf="modalForm.controls.date?.errors && submitted"
            >
              Start Date is required
            </mat-error>
          </div>
        </div>

        <div class="row ml-1">
          <div
            [ngClass]="{ 'mt-1': mobile }"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-3"
          >
            <div>
              <span class="heading-style required goal-type-mrg"
                >Project Status</span
              >
              <mat-radio-group formControlName="duration_radio">
                <div class="row mrgn-left">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div
                      class="goal-type-border text-truncate"
                      [ngClass]="{ 'selected-goal': projectCompleted, 'mt-1': mobile }"
                    >
                      <mat-radio-button
                        (click)="clickProjectComplete();setDurationValidators()"
                        [checked]="data.duration_radio === 'completed'"
                        value="completed" 
                        ><span
                          class="goal-type-font mrg-right"
                          [ngClass]="{ selectTrue: projectCompleted }"
                          >Completed</span
                        >
                      </mat-radio-button>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-12"
                    [ngClass]="{ 'mt-1': mobile }"
                  >
                    <div
                      class="goal-type-border text-truncate"
                      [ngClass]="{ 'selected-goal': projectOngoing }"
                    >
                      <mat-radio-button
                        (click)="clickProjectOutgoing();removeDurationValidators()"
                        [checked]="data.duration_radio === 'ongoing'"
                        value="ongoing "
                        ><span
                          class="goal-type-font"
                          [ngClass]="{ selectTrue: projectOngoing }"
                          >Ongoing</span
                        >
                      </mat-radio-button>
                    </div>
                  </div>
                </div>
              </mat-radio-group>
            </div>
          </div>
          <mat-error
            class="error-style fix-top-margin"
            *ngIf="
              modalForm.controls.duration_radio?.errors?.required &&
              submitted
            "
          >
            Project Status is required
          </mat-error>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <span class="heading-style required">Client Name </span>
            <mat-form-field class="w-100" appearance="fill">
              <input
                class="input-text"
                type="text"
                formControlName="clientName"
                [(ngModel)]="data.clientName"
                matInput
                [errorStateMatcher]="matcher"
              />
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="
                modalForm.controls.clientName?.errors?.required &&
                submitted
              "
            >
              Client Name is required
            </mat-error>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" *ngIf="modalForm.value.duration_radio==='completed'">
            <span class="heading-style required"
              >Project Duration (in Weeks)</span
            >
            <mat-form-field class="w-100" appearance="fill">
              <input
                class="input-text"
                type="number"
                formControlName="duration"
                min="4"
                oninput="validity.valid||(value='');"
                matInput
                [errorStateMatcher]="matcher"
              />
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="
                modalForm.controls.duration?.errors && submitted
              "
            >
              Project Duration is requried
            </mat-error>
          </div>
        </div>

        <div class="row ml-1">
          <div
            [ngClass]="{ 'mt-1': mobile }"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-3"
          >
            <div>
              <span class="heading-style required goal-type-mrg"
                >Goal Type</span
              >
              <mat-radio-group formControlName="ecdc">
                <div class="row mrgn-left">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div
                      class="goal-type-border text-truncate"
                      [ngClass]="{ 'selected-goal': first, 'mt-1': mobile }"
                    >
                      <mat-radio-button
                        (click)="clickEc()"
                        [checked]="data.ecdc === 'EC'"
                        value="0"
                        ><span
                          class="goal-type-font mrg-right"
                          [ngClass]="{ selectTrue: first }"
                          >Engineering Council (EC)</span
                        >
                      </mat-radio-button>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-12"
                    [ngClass]="{ 'mt-1': mobile }"
                  >
                    <div
                      class="goal-type-border text-truncate"
                      [ngClass]="{ 'selected-goal': second }"
                    >
                      <mat-radio-button
                        (click)="clickDc()"
                        [checked]="data.ecdc === 'DC'"
                        value="1"
                        ><span
                          class="goal-type-font"
                          [ngClass]="{ selectTrue: second }"
                          >Delivery Council (DC)</span
                        >
                      </mat-radio-button>
                    </div>
                  </div>
                </div>
              </mat-radio-group>
            </div>
          </div>
          <mat-error
            class="error-style fix-top-margin"
            *ngIf="modalForm.controls.ecdc?.errors && submitted"
          >
            Goal Type is required
          </mat-error>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <span class="heading-style required">Description</span>
            <img src="assets/images/info_button.svg" alt="information" title="(Max. 400 Characters Allowed)">
            <mat-form-field class="w-100" appearance="fill">
              <textarea
                rows="3"
                class="input-text"
                type="text-area"
                formControlName="description"
                maxlength="400"
                placeholder="Max 400 Characters allowed" 
                matInput
                [errorStateMatcher]="matcher"
              ></textarea>
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="
                modalForm.controls.description?.errors && submitted
              "
            >
              Please Enter Description about Project
            </mat-error>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  </div>
  <div class="form-buttons row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <button mat-raised-button id="add_btn" (click)="onNewSubmit()">
        SUBMIT
      </button>
    </div>
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <button (click)="onClose()" mat-raised-button id="cancel_btn">
        CANCEL
      </button>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
      <button id="draft_btn" (click)="onDraftSubmit()">
        <img class="mr-2" src="../../../../assets/images/saveasdraft.svg" />Save
        As Draft
      </button>
    </div>
  </div>
</div>
