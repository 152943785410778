import { TrainingandsessionsService } from './../service/trainingandsessions.service';
import { MatDialog } from '@angular/material/dialog';
import {session_data } from './../../../shared/models/training-session.model';
import { GenericTable } from './../../../shared/models/certificate.model';
import { Router } from '@angular/router';
import { AuthUserService } from 'src/app/services/auth-user.service';
import {  Component,  OnInit,  QueryList,  ViewChild,  ViewChildren,} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PageEvent } from '@angular/material/paginator';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { take } from 'rxjs/operators';
import { TrainingSessionViewComponent } from '../training-session-view/training-session-view.component';
import { TrainingSessionFormComponent } from '../training-session-form/training-session-form.component';
import { DeleteModalComponent } from 'src/app/shared/components/delete-modal/delete-modal.component';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { dailogData } from 'src/app/shared/models/confirmation-modal.model'; 
import { ProfileData } from 'src/app/shared/models/profile.model';
import { ToastRef, ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-training-sessions-dashboard',
  templateUrl: './training-sessions-dashboard.component.html',
  styleUrls: ['./training-sessions-dashboard.component.css'],
})
export class TrainingSessionsDashboardComponent implements OnInit {
  pageEvent?: PageEvent;
  tablePageIndex: number;
  screenSize : string;
  tableLength: number;
  fromDate:string;
  toDate:string;
  empEmail:String;
  status: string;
  mobile: boolean;
  dataDialog: dailogData;
  allTrainingSessionData: GenericTable[];
  allTrainingSessionDetails: session_data[];
  trainingForm: session_data;
  ecdcMap = {
    EC: 'Engineering Council',
    DC: 'Delivery Council',
  };
  showImage = false;
  row: session_data[];
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  constructor(
    private toastr:ToastrService,
    private commonService: CommonService,
    private responsiveService: ResponsiveService,
    private authService : AuthUserService,
    private router :Router,
    private trainingService: TrainingandsessionsService,
    public dialog: MatDialog,
  ) {
    this.tablePageIndex = 0;
    this.tableLength = 0;
    this.screenSize=''
   this.fromDate='';
   this.toDate='';
   this.mobile=false;
   this.empEmail = '';
   this.dataDialog = { title: '' };
   this.allTrainingSessionData=[] as GenericTable[];
   this.status='allStatus';
   this.allTrainingSessionDetails= [] as session_data[];
   this.row= [] as session_data[];
   this.trainingForm = {
    id: 0,
    summary: '',
    description: '',
    technology: '',
    activityCount: 0,
    startDate: '',
    endDate: '',
    count: 0,
    action: '',
    modalCheck: '',
    status: '',
    attachment: '',
    createdOn: '',
    technologyapplied: [],
    ecdc: '',
    other: '',
    trainingAndSession :{technology : [ ]}
  };
  }

  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(false);
    this.fromDate = this.commonService.getYearsData().fromDate;
    this.toDate = this.commonService.getYearsData().toDate;
    this.getEmployeeDetails();
    this.getAllTrainingSession();
    this.checkScreenStatus();
    this.onResize();
    this.responsiveService.getMobileStatus().subscribe((isMobile:boolean) => {
      this.mobile = !!isMobile;
    });
  }
 

  /**
   * Get employee details (email)
   */
  getEmployeeDetails(): void {
    this.authService.getEmployeeDetailBehaviorSubject().subscribe((item:ProfileData) => {
        if (item?.data?.email) {
          this.empEmail = item?.data?.email;
        }
       
    });
  }

  /**
   * Provide the data for each page
   * @param {PageEvent}event
   * @returns event
   */
  getAllTrainingSession(event?: PageEvent) : PageEvent{
    this.allTrainingSessionData = [] as GenericTable[];
    let pageNo = 0;
    if (event) {
      pageNo = event.pageIndex + 1;
      this.tablePageIndex = event.pageIndex;
    } else {
      pageNo = 1;
      this.tablePageIndex = 0;
    }
    this.trainingService.getAllTraining(this.empEmail, pageNo, this.fromDate,this.toDate, this.status).pipe(take(1))
      .subscribe({
        next: (data) => {
  
          if (data && data?.data?.length) { 
            this.allTrainingSessionDetails=[];
            for (let TrainingSessionData of data.data) {     

              let createdOn = TrainingSessionData.createdOn;
              TrainingSessionData.myArray = createdOn.split(' ');

              let position = '';
              if (TrainingSessionData.trainingAndSession.otherTechnology)
                TrainingSessionData.other = TrainingSessionData.trainingAndSession.otherTechnology;
              TrainingSessionData.technologyapplied = [];
              for (
                let i = 0;
                i < TrainingSessionData.trainingAndSession.technology.length;
                i++
              ) {
                position +=
                  TrainingSessionData.trainingAndSession.technology[i]
                    .employeeTechnology + ' , ';
                TrainingSessionData.technologyapplied.push(
                  TrainingSessionData.trainingAndSession.technology[i].employeeTechnology
                );
              }
              let a = position.replace(/,\s*$/, '');
              TrainingSessionData.position = a;
              if (TrainingSessionData.trainingAndSession.startDate != null) {
                TrainingSessionData.startDate =
                  TrainingSessionData.trainingAndSession.startDate.substr(0, 10);
              }
              if (TrainingSessionData.trainingAndSession.endDate != null) {
                TrainingSessionData.endDate = TrainingSessionData.trainingAndSession.endDate.substr(
                  0,
                  10
                );
              }
              TrainingSessionData.activityCount = TrainingSessionData.trainingAndSession.headcount;

              let TrainingSessionTableData = {} as GenericTable;
              TrainingSessionTableData.id = TrainingSessionData.id;
              TrainingSessionTableData.title = TrainingSessionData.summary;
              TrainingSessionTableData.createdOn = TrainingSessionData.createdOn.substr(
                0,
                10
              );
              TrainingSessionTableData.ecdc =
                TrainingSessionData.ecdc === 'DC'
                  ? this.ecdcMap.DC
                  : this.ecdcMap.EC;
                  TrainingSessionTableData.status = TrainingSessionData.status;
              this.allTrainingSessionData.push(TrainingSessionTableData);
              this.allTrainingSessionDetails.push(TrainingSessionData);
            }
            this.tableLength = data.total_rows;
          }
        },
        error: (error) => {},
      });
    if (event) {
      return event;
    }
    return new PageEvent();
  }

  /**
   * Add new training-session
   */
  addTrainingSession() :void {
      this.trainingForm = {
        id: 0,
        summary: '',
        startDate: '',
        endDate: '',
        description: '',
        count: 0,
        action: '',
        modalCheck: 'ADD',
        status: '',
        attachment: '',
        createdOn: '',
        technology: '',
        activityCount: 0,
        technologyapplied: [],
        ecdc: '',
        other: '',
        trainingAndSession :{technology : [ ]}
      };
    const dialogRef = this.dialog.open(TrainingSessionFormComponent, {
      width: '650px',
      panelClass: 'my-dialog',
      data: this.trainingForm,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.commonService
          .createTrainingCommon(result, this.empEmail)
          .then(() => {
            this.getAllTrainingSession();
          });
      }
    });
  }

  /**
   * Delete training-session 
   * @param {number}id - training-session id
   */
  deleteTainingSession(id: number): void{
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '481px',
      data: {
        warningTitle: 'Are you sure you want to delete this contribution?',
      },
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe({
      next: (data) => {
        if (data.yesClicked) {
          this.trainingService.deletetrainingsession(id).pipe(take(1)).subscribe({
            next: (data) => {
              this.deleteConfirmationModal();
            },
            error: (error) => {},
          });
        }
      },
    });
  } 
  
  /**
   * Delete confirmation modal
   */
  deleteConfirmationModal(): void {
    let genericDailogData = {
      title: 'Contribution deleted successfully!',
      img: '../../../../assets/images/n_image/Success.gif',
      heading: 'Success!',
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '513px',
      data: genericDailogData,
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe({
      next: () => {
        this.getAllTrainingSession();
      },
    });
  }

  /**
   * View training-session
   * @param {number}id - training-session id
   */
  viewTrainingSession(id: number): void{
    const dialogRef = this.dialog.open(TrainingSessionViewComponent, {
      width : '810px',
      panelClass: 'my-dialog',
      data: id
    });
  }

  /**
   * Edit training-session
   * @param {number}id - training-session id
   */
  editTrainingSessionDetails(id: number): void{
    let TrainingSessionDetails = this.allTrainingSessionDetails.filter(
      (allTrainingSession) => {
        return allTrainingSession.id === id;
      }
    );
    const dialogRef = this.dialog.open(TrainingSessionFormComponent, {
      width: '650px',
      panelClass: 'my-dialog',
      data: TrainingSessionDetails[0],
    });
    dialogRef.afterClosed().pipe(take(1)).subscribe({
      next: (result) => {
        if (result) {
          this.commonService
            .createTrainingEdit(result, id, this.empEmail)
            .then((status) => {
                this.getAllTrainingSession();
            });
        }
      },
    });
    }

  /**
   * Check screen status
   */
  checkScreenStatus(): void {
    this.responsiveService.getScreenStatus().subscribe((screenSize: string) => {
      if (screenSize) {
        this.screenSize = screenSize;
      }
    });
  }

  /**
   * Check screen width
   */
  onResize(): void {
    this.responsiveService.checkWidth();
  }

  /**
   * For small screen
   * @returns screen size in string
   */
  isScreenSizeSmall(): boolean {
    return this.screenSize === 'sm';
  }
   /**
   * For extra small screen
   * @returns screen size in string
   */
   isScreenSizeXS(): boolean {
    return this.screenSize === 'xs' || this.screenSize === 'sm';
  }

  /**
   * Route back to dashboard
   */
  routeBackToMainDashboard() {
    this.router.navigate(['/main-dashboard']);
  }

}
