<div class="container">
  <form [formGroup]="modalFormVoter">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label class="label-text">User Category </mat-label>
          <mat-select
            formControlName="employ_select_voter"
            (selectionChange)="listLoadVoter()"
            placeholder="Select"
            disableOptionCentering
          >
            <mat-option
              class="input-text"
              *ngFor="let select of select_emp"
              [value]="select"
            >
              {{ select }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error
          class="error-style fix-top-margin"
          *ngIf="
            modalFormVoter.controls.employ_select_voter?.errors &&
            this.submitted
          "
        >
          Please specify selection type.
        </mat-error>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-xs-6 pl-3">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Select Users </mat-label>
          <form>
            <input
              name="searchText"
              class="input-text"
              type="text"
              [(ngModel)]="searchTextVoter"
              (ngModelChange)="onSearchChangeVoter()"
              matInput
            />
          </form>

          <mat-selection-list
            class="voter-box"
            (selectionChange)="
              selectionChangeNew(
                $event,
                this.modalFormVoter.value.employ_select_voter
              )
            "
            formControlName="employees_voter"
          >
            <mat-list-option
              *ngFor="let val of employees_list_voter"
              checkboxPosition="before"
              [value]="val"
              [selected]="voterSet.get(val.id)"
            >
              {{ val.id + " " }}{{ val.name }}
            </mat-list-option>
          </mat-selection-list>
        </mat-form-field>
        <mat-error
          class="error-style fix-top-margin"
          *ngIf="selectedValuesVoter.length === 0 && this.submitted"
        >
          Please select atleast 1 User
        </mat-error>
      </div>
    </div>
    <!-- <button mat-raised-button id="add_btn" (click)="onSubmitVoter()"   >
  NEXT
</button>
<button mat-raised-button id="add_btn" (click)="on()"   >
  test
</button> -->
  </form>

  <div class="row mt-3">
    <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6 col-xs-6 mb-2">
      <mat-card class="example-card-1 h-100" (click)="routeView('>20')">
        <mat-card-header>
          <mat-card-title class="title">GIFT CARD</mat-card-title>
          <mat-card-subtitle>
            28% off
            <p class="sel">Select brands with 28% discount</p>
          </mat-card-subtitle>

          <img
            class="gift-styling-1"
            src="../../../../assets/images/gift.png"
            alt="giftbox icon"
          />
          <img
            class="arrow-mark-1"
            src="../../../../assets/images/arrow 1 .svg"
          />
        </mat-card-header>
      </mat-card>
    </div>

    <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6 col-xs-6 mb-2">
      <mat-card class="example-card-2 h-100" (click)="routeView('15-20')">
        <mat-card-header>
          <mat-card-title class="title">GIFT CARD</mat-card-title>
          <mat-card-subtitle
            >12% off
            <p class="sel">select brands with 12% discount</p>
          </mat-card-subtitle>

          <img
            class="gift-styling"
            src="../../../../assets/images/gift.png"
            alt="giftbox icon"
          />
          <img
            class="arrow-mark"
            src="../../../../assets/images/arrow 1 .svg"
          />
        </mat-card-header>
      </mat-card>
    </div>
    <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6 col-xs-6 mb-2">
      <mat-card class="example-card-3 h-100" (click)="routeView('10-14')">
        <mat-card-header>
          <mat-card-title class="title">GIFT CARD</mat-card-title>
          <mat-card-subtitle
            >7% off
            <p class="sel">select brands with 7% discount</p>
          </mat-card-subtitle>

          <img
            class="gift-styling"
            src="../../../../assets/images/gift.png"
            alt="giftbox icon"
          />
          <img
            class="arrow-mark"
            src="../../../../assets/images/arrow 1 .svg"
          />
        </mat-card-header>
      </mat-card>
    </div>
    <div class="col-xl-3 col-lg-4 col-sm-6 col-md-6 col-xs-6 mb-2">
      <mat-card class="example-card-4 h-100" (click)="routeView('5-9')">
        <mat-card-header>
          <mat-card-title class="title">GIFT CARD</mat-card-title>
          <mat-card-subtitle
            >3.5% off
            <p class="sel">select brands with 3.5% discount</p>
          </mat-card-subtitle>

          <img
            class="gift-styling"
            src="../../../../assets/images/gift.png"
            alt="giftbox icon"
          />
          <img
            class="arrow-mark"
            src="../../../../assets/images/arrow 1 .svg"
          />
        </mat-card-header>
      </mat-card>
    </div>
  </div>
</div>
