import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PositionSuffixPipe } from './position-suffix.pipe';
import { PositionPipe } from './position.pipe';
import { NumberFormatterPipe } from './number-formatter.pipe';



@NgModule({
  declarations: [
    PositionSuffixPipe,
    PositionPipe,
    NumberFormatterPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    PositionSuffixPipe,
    PositionPipe,NumberFormatterPipe
  ]
})
export class PipesModule { }
