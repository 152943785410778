import { SharedModule } from 'src/app/shared/shared.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProjectsRoutingModule } from './projects-routing.module';
import { ProjectDashboardComponent } from './project-dashboard/project-dashboard.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { ProjectFormComponent } from './project-form/project-form.component';



@NgModule({
  declarations: [
    ProjectDashboardComponent,
    ProjectViewComponent,
    ProjectFormComponent,

  ],
  imports: [
    CommonModule,
    ProjectsRoutingModule,
    SharedModule
  ]
})
export class ProjectsModule { }
