<mat-nav-list class="sidenav-list">
  <div class="side-nav-heading">
    <img
      src="../../../../assets/images/admin-contripoint-logo.png"
      alt="admin-contripoint-logo"
      width="40px"
      height="33px"
    />
    Contripoint
  </div>

  <div
    [routerLinkActive]="['active-tab']"
    *ngIf="
      isManager || isHr || transactionAccess || isAdmin || isReportingManager
    "
  >
    <a
      [routerLinkActive]="['active-tab']"
      (click)="close()"
      mat-list-item
      routerLink="/admin-portal/analytics-dashboard"
    >
      <img
        src="../../../../assets/images/analytic-icon.svg"
        alt="analytics-dashboard"
        width="18px"
        height="18px"
      />
      <span>Analytical Dashboard</span>
    </a>
  </div>

  <div [routerLinkActive]="['active-tab']">
    <a
      *ngIf="isAdmin"
      [routerLinkActive]="['active-tab']"
      (click)="close()"
      mat-list-item
      routerLink="/admin-portal/reward-distribution"
    >
      <img
        src="../../../../assets/images/reward-distribution-icon.png"
        alt="reward-distribution-icon"
        width="18px"
        height="18px"
      />
      <span>Reward Distribution</span>
    </a>
  </div>

  <div *ngIf="isAdmin" [routerLinkActive]="['active-tab']">
    <a
      (click)="close()"
      mat-list-item
      routerLink="/admin-portal/discount-selection"
    >
      <img
        src="../../../../assets/images/brand-selection.png"
        alt="brand-selection-icon"
        width="18px"
        height="18px"
      />
      <span>Brand Selection</span>
    </a>
    <div class="display-none">
      <a routerLink="/admin-portal/brands-selection/1"></a>
      <a routerLink="/admin-portal/brands-selection/2"></a>
      <a routerLink="/admin-portal/brands-selection/3"></a>
      <a routerLink="/admin-portal/brands-selection/4"></a>
    </div>
  </div>
</mat-nav-list>
