import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, Inject } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { Router } from '@angular/router';
import { SafeResourceUrl } from '@angular/platform-browser';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ChangestatusService } from '../../../services/changestatus.service';
import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { CommentModalComponent } from '../../../shared/components/comment-modal/comment-modal.component';
import { dailogData } from 'src/app/shared/models/confirmation-modal.model';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CommonService } from 'src/app/services/common.service';
import { CertificateDetails } from 'src/app/shared/models/certificate.model';
import {
  EmployeeDetails,
  ProfileData,
} from 'src/app/shared/models/profile.model';
import { CertificationService } from '../service/certification.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-certification-view',
  templateUrl: './certification-view.component.html',
  styleUrls: ['./certification-view.component.css'],
})
export class CertificationViewComponent implements OnInit {
  sno: number;
  mobile: boolean;
  checkApprove: String;
  dataDialog: dailogData;
  base_64_string: String;
  certificateSNO: number;
  imageSource: SafeResourceUrl;
  employeeId: string;
  empEmail: String;
  fileName?: string;
  invoiceName?: string;
  viewType: String;
  empId: String;
  year: String;
  date: Number;
  userData: EmployeeDetails;
  goalType?: string;
  viewDetail: CertificateDetails;
  constructor(
    public dialogRef: MatDialogRef<CertificationViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number,
    private _responsiveService: ResponsiveService,
    private certificationService: CertificationService,
    private _authService: AuthUserService,
    private _changeStatus: ChangestatusService,
    private _router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private commonService: CommonService
  ) {
    this.mobile = false;
    this.dataDialog = { title: '' };
    this.viewType = '';
    this.empId = '';
    this.year = '';
    this.date = 0;
    this.viewDetail = {} as CertificateDetails;
    this.userData = {} as EmployeeDetails;
    this.imageSource = '';
    this.employeeId = '';
    this.checkApprove = '';
    this.sno = 0;
    this.base_64_string = 'this_is_where_you_put_base_64';
    this.empEmail = '';
    this.certificateSNO = 0;
  }

  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(false);
    this._authService
      .getEmployeeDetailBehaviorSubject()
      .subscribe((item: ProfileData) => {
        if (item?.data?.email) {
          this.empEmail = item?.data?.email;
        }
      });
    this.certificateSNO = this.data;
    this._responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
    this.onResize();
    this.getSelectedCertificateView();
  }

  /**
   * close modal
   */
  onClose = () => {
    this.dialogRef.close();
  };

  /**
   * download attach
   */
  downloadAttach = (sno: Number, name: string, downloadFile: string): void => {
    this.certificationService.downloadFile(sno).subscribe((binary) => {
      if (binary.data) {
        if (downloadFile === 'attachment') {
          this.downloadAction(binary.data.url, name);
        } else {
          this.downloadAction(binary.data.invoiceUrl, name);
        }
      }
    });
  };

  /**
   * download file
   * @param {string}url - attachment url
   * @param {string}name -  name of file
   */
  downloadAction = (url: string, name: string) => {
    fetch(url)
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const attach = document.createElement('a');
        attach.style.display = 'none';
        attach.href = url;
        attach.download = name;
        document.body.appendChild(attach);
        attach.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => this.toastr.error('Error in downloading the data'));
  };

  /**
   * get the selected certificate view
   */
  getSelectedCertificateView = (): void => {
    this.certificationService
      .getSelectedCertificateView(Number(this.certificateSNO))
      .pipe(take(1))
      //todo: make interface for data
      .subscribe((data) => {
        (this.sno = data.data.id),
          (this.viewDetail.summary = data.data.summary),
          (this.viewDetail.goalType = data.data.ecdc),
          (this.viewDetail.paid = data.data.certificate.certificationCost),
          (this.viewDetail.amount = data.data.certificate.amount),
          (this.viewDetail.description = data.data.description);
        this.date = Number(data.data.createdOn.substr(0, 4));
        this.year = `${this.date}-${Number(this.date) + 1}`;
        (this.viewDetail.date = new Date(
          data.data.certificate.dateOfCompletion.substr(0, 10)
        )),
          (this.viewDetail.status = data.data.status),
          (this.viewDetail.createdBy = data.data.createdBy),
          (this.viewDetail.createdOn = new Date(
            data.data.createdOn.substr(0, 10)
          )),
          (this.viewDetail.category = data.data.contributionCategory.name);
        (this.viewDetail.points = data.data.totalPoints),
          (this.viewDetail.fileName = data.data.attachmentId.name),
          (this.viewDetail.imageSource = this.commonService.decodeBase64ToImage(
            data.data.attachmentId.attachmentFile.s3FileImage
          )),
          (this.viewDetail.invoiceName = data.data.attachmentIdInvoice?.name),
          (this.viewDetail.comment = data.data.comments);
      });
  };

  /**
   *
   * @param {string}data - status of certificate
   * @returns image address
   */
  getStatusImage = (data: String): String => {
    if (data == 'PENDING APPROVAL') {
      return 'assets/images/n_image/pendingBtn.svg';
    } else if (data == 'DECLINED') {
      return 'assets/images/n_image/deleteBtn.svg';
    } else if (data == 'APPROVED') {
      return 'assets/images/n_image/approveBtn.svg';
    } else {
      return 'assets/images/n_image/draftBtn.svg';
    }
  };

  /**
   * ! will be called once new screens for delegate and review available
   * * make common function with reject()
   * approve Activity for the RM, visible on delegate and Review Avtivity screen
   */
  approveActivity(): void {
    const DialogCommentsRef = this.dialog.open(CommentModalComponent, {
      width: '513px',
    });
    DialogCommentsRef.afterClosed().subscribe((data) => {
      if (data) {
        this._changeStatus
          .approveActivity(
            this.certificateSNO,
            this.empEmail,
            data.commentForm.comment
          )
          //todo: make data interface
          .subscribe((data) => {
            if (data) {
              this.dataDialog = { title: 'Activity Approved Successfully.' };
              const confirmationDialogRef = this.dialog.open(
                ConfirmationModalComponent,
                {
                  width: '513px',
                  data: this.dataDialog,
                }
              );
              this.checkApprove = 'APPROVED';
              confirmationDialogRef.afterClosed().subscribe(() => {
                this._router.navigate(['main-dashboard']);
              });
            }
          });
      }
    });
  }

  /**
   * ! will be called once new screens for delegate and review available
   * * make common function with approve()
   * reject Activity for the RM, visible on delegate and Review Avtivity screen
   */
  rejectActivity(): void {
    const DialogCommentsRef = this.dialog.open(CommentModalComponent, {
      width: '513px',
    });
    //todo : make data interface
    DialogCommentsRef.afterClosed().subscribe((data) => {
      if (data) {
        this._changeStatus
          .rejectActivity(
            this.certificateSNO,
            this.empEmail,
            data.commentForm.comment
          )
          .subscribe((data) => {
            if (data) {
              this.dataDialog = { title: 'Activity Rejected Successfully.' };
              const confirmationDialogRef = this.dialog.open(
                ConfirmationModalComponent,
                {
                  width: '513px',
                  data: this.dataDialog,
                }
              );
              this.checkApprove = 'REJECTED';
              confirmationDialogRef.afterClosed().subscribe(() => {
                this._router.navigate(['main-dashboard']);
              });
            }
          });
      }
    });
  }

  /**
   * check screen resolution
   */
  onResize = (): void => {
    this._responsiveService.checkWidth();
  };
}
