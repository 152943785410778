<div class="container p-5">
    <div class="d-flex justify-content-between ">

        <p class="heading">Transaction Confirmation</p>

        <div class="row close_btn ">
            <button type="button" class="close" (click)="onClose(false)" aria-label="Close">
                <span aria-hidden="true" class="mrg-lft"><i class="material-icons ">close</i></span>
            </button>
        </div>
    </div>
    <div class="alert">
        {{alert}}
    </div>
    <div *ngIf="!data?.data?.faultyEmailList?.length">
        <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="name">
                <th class="head" mat-header-cell *matHeaderCellDef>Name</th>
                <td class="fix-width-short" mat-cell *matCellDef="let element">
                    {{ element?.name }}
                </td>
            </ng-container>
            <ng-container matColumnDef="emailId">
                <th class="head" mat-header-cell *matHeaderCellDef>Email Address</th>
                <td class="fix-width-short" mat-cell *matCellDef="let element">
                    {{ element?.emailId }}
                </td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th class="head" mat-header-cell *matHeaderCellDef>Amount</th>
                <td class="fix-width-short" mat-cell *matCellDef="let element">
                    <ng-container *ngIf="repeated; else elseBlock">
                        {{ element?.amount }}
                    </ng-container>
                    <ng-template #elseBlock>
                        {{ element?.weeklyLimitAmount }}
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="title">
                <th [hidden]="limitExceed" class="head" mat-header-cell *matHeaderCellDef>Title</th>
                <td [hidden]="limitExceed" class="fix-width-short" mat-cell *matCellDef="let element">
                    {{ element?.title }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>


    <div *ngIf="data?.data?.faultyEmailList?.length">
        <table mat-table [dataSource]="invalidMailDataSource" class="w-100">
            <ng-container matColumnDef="emailId">
                <th class="head" mat-header-cell *matHeaderCellDef>Email Address</th>
                <td class="fix-width-short" mat-cell *matCellDef="let element">
                    {{ element }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>


    <mat-paginator [pageSizeOptions]="[5]" showFirstLastButtons>
    </mat-paginator>


    <div class="d-flex">
        <div *ngIf="data?.confirmationType !== 'invalid mail'">
            <button class="continue" (click)="onClose(true)">Continue</button>
        </div>
        <div [ngClass]="{'ml-3':data?.confirmationType !== 'invalid mail'}">
            <button class="discard" (click)="onClose(false)">Discard </button>
        </div>
    </div>

</div>