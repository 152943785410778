import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from '../../../services/responsive.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateTableService } from '../service/certificate-table.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ChangestatusService } from '../../../services/changestatus.service';
import { ConfirmationModalComponent } from '../../../shared/components/confirmation-modal/confirmation-modal.component';
import { CommentModalComponent } from '../../../shared/components/comment-modal/comment-modal.component';
import { MyProfileService } from '../../dashboard/dash-info-cards/service/my-profile.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CommonService } from 'src/app/services/common.service';
import { CertificateDetails } from 'src/app/shared/models/certificate.model';
import { EmployeeDetails } from 'src/app/shared/models/profile.model';
import { genericDailogData } from 'src/app/shared/models/confirmation-modal.model';
import { CertificationService } from '../../certification/service/certification.service';

@Component({
  selector: 'app-certificate-view',
  templateUrl: './certificate-view.component.html',
  styleUrls: ['./certificate-view.component.css']
})
export class CertificateViewComponent implements OnInit {
  sno: number;
  mobile: boolean;
  checkApprove: String;
  dataDialog: genericDailogData;
  base_64_string : String;
  pdfSource: any;
  certificateSNO: number = 0;
  imageSource: SafeResourceUrl;
  employeeId:string;
  empEmail : String;
  fileName?: string;
  invoiceName?: string;
  viewType:String;
  empId:String;
  year:String;
  date:Number;
  userData: EmployeeDetails;
  goalType?:string;
  viewDetail: CertificateDetails
  previous : boolean;

  constructor(private route: ActivatedRoute,
    private _responsiveService: ResponsiveService,
    private certificateService:CertificationService,
    private certificateViewService: CertificateTableService,
    private _authService: AuthUserService,
    private sanitizer: DomSanitizer,
    private _changeStatus: ChangestatusService,
    private _router: Router,
    public dialog: MatDialog,
    private profileService: MyProfileService,
    private commonService : CommonService
  ) {
    this.mobile = false;
    this.dataDialog = { title: "", img: "", heading: "" };
    this.viewType = '';
    this.empId = '';
    this.year = '';
    this.date = 0;
    this.viewDetail = {} as CertificateDetails;
    this.userData = {} as EmployeeDetails;
    this.imageSource = "";
    this.employeeId = '';
    this.checkApprove = "";
    this.sno = 0;
    this.base_64_string = "this_is_where_you_put_base_64"
    this.empEmail = '';
    this.previous = false;

  }
  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(false);
    this._authService.getEmployeeDetailBehaviorSubject().subscribe(item => {
      if (item) {
        this.empEmail = item.data.email;
      }
    });
    this.route.paramMap.subscribe((params) => {
      this.certificateSNO = Number(params?.get('sno'));
      this.viewType = String(params?.get('viewType'))
      this.empId = String(params?.get('empid'))
    });
    this._responsiveService.getMobileStatus().subscribe(isMobile => {
      if (isMobile) {
        this.mobile = true;
      }
      else {
        this.mobile = false;
      }
    });
    this.onResize();


    this.getSelectedCertificateView()
    if(this.empId!='user')
      this.getUserData();
  }
  downloadAction(url:string, name:string){
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
      })
      .catch(() => console.log("error"));
}

downloadAttach = (sno: Number, name: string , downloadFile:string): void => {
  console.log('hello')
    console.log(sno)
  this.certificateService.downloadFile(sno).subscribe((binary) => {
    if (binary?.data) {
    this.downloadActionPrevious(binary.data?.invoiceAttachment, name);
    }
  });
}

downloadActionPrevious(base64String: String, fileName:string) : void {
  const source = `data:application/;base64,${base64String}`;
  const link = document.createElement("a");
  link.href = source;
  link.download = fileName;
  link.click();
}
  getSelectedCertificateView() : void{
    this.certificateViewService.getSelectedCertificateView(Number(this.certificateSNO)).subscribe(data => {
      this.viewDetail.summary = data.data.summary,
      this.viewDetail.goalType= data.data.ecdc,
      this.viewDetail.paid = data.data.certificate.certificationCost,
      this.viewDetail.amount = data.data.certificate.amount,
      this.viewDetail.description = data.data.description;
      this.date = Number(data.data.createdOn.substr(0,4));
      this.year = this.date + "-" + (Number(this.date) + 1);
      this.viewDetail.date = new Date(data.data.certificate.dateOfCompletion.substr(0, 10)),
      this.viewDetail.status = data.data.status,
      this.viewDetail.createdBy = data.data.createdBy,
      this.viewDetail.createdOn = new Date(data.data.createdOn.substr(0,10));
      this.viewDetail.category = data.data.contributionCategory.name
      this.viewDetail.points = data.data.totalPoints,
      this.viewDetail.id=data.data.id;
        this.fileName = data.data.attachmentId.name,
        this.viewDetail.comment = data.data.comments,
        this.viewDetail.fileName = data.data.attachmentId.name
          if (data.data.attachmentId.attachmentFile.s3FileImage) {
            (this.viewDetail.url =
              data.data.attachmentId.attachmentFile.s3FileImage),
              (this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(
                `data:image/png;base64, ${data.data.attachmentId.attachmentFile.s3FileImage}`
              )),
              (this.pdfSource = this._base64ToArrayBuffer(
                data.data.attachmentId.attachmentFile.s3FileImage
              ));
          } else {
            (this.viewDetail.url = data.data.attachmentId.attachmentFile.url),
              (this.imageSource = data.data.attachmentId.attachmentFile.url),
              (this.pdfSource = data.data.attachmentId.attachmentFile.url);
          }
       if(this.viewDetail.goalType=="EC")
       {
        this.goalType="Engineering Council (EC)";
      } 
      if(this.viewDetail.goalType=="DC"){
        this.goalType="Delivery Council (DC)";
      }
      (this.invoiceName = data.data.attachmentIdInvoice.name),
      (this.viewDetail.comment = data.data.comments),
        (this.viewDetail.invoiceBinary =
          data.data.attachmentIdInvoice.attachmentFileInvoice.file)
    })
  }

  _base64ToArrayBuffer(base64: string) : ArrayBuffer {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }


  

  getStatusImage(data : String) :String{
    if(data == 'PENDING APPROVAL'){
  return '../../../../assets/images/n_image/pendingBtn.svg'
    }
    else if(data == 'DECLINED'){
return '../../../../assets/images/n_image/deleteBtn.svg'
    }
    else if(data == 'APPROVED'){
      return '../../../../assets/images/n_image/approveBtn.svg'
    }
    else {
    return '../../../../assets/images/n_image/draftBtn.svg'
    }

  }
  approveActivity() : void {
    const DialogCommentsRef = this.dialog.open(CommentModalComponent, {
      width: '513px'
    });
    DialogCommentsRef.afterClosed().subscribe((data) => {
      if (data) {
        this._changeStatus.approveActivity(this.certificateSNO,this.empEmail, data.commentForm.comment).subscribe((data) => {
          if (data) {
            this.dataDialog = {
              title: "Activity Approved Successfully.",
              img: "../../../../assets/images/n_image/Success.gif",
              heading: "Success!",
            };
            const confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
              width: '513px',
              data: this.dataDialog
            });
            this.checkApprove = 'APPROVED';
            confirmationDialogRef.afterClosed().subscribe(() => {
              if(localStorage.getItem('contribution') === 'delegate'){
                this._router.navigate(['delegate-contributions'])
              }
          else{
            this._router.navigate(['review-activities'])
          }
            });
          }
        })
      }
    }); 
  }

  rejectActivity() : void {
    const DialogCommentsRef = this.dialog.open(CommentModalComponent, {
      width: '513px'
    });
    DialogCommentsRef.afterClosed().subscribe((data) => {
      if (data) {
        this._changeStatus.rejectActivity(this.certificateSNO, this.empEmail,data.commentForm.comment).subscribe((data) => {
          if (data) {
            this.dataDialog = {
              title: "Activity Rejected Successfully.",
              img: "../../../../assets/images/n_image/Success.gif",
              heading: "Success!",
            };
            const confirmationDialogRef = this.dialog.open(ConfirmationModalComponent, {
              width: '513px',
              data: this.dataDialog
            });
            this.checkApprove = 'REJECTED';
            confirmationDialogRef.afterClosed().subscribe(() => {
              if(localStorage.getItem('contribution') === 'delegate'){
                this._router.navigate(['delegate-contributions'])
              }
          else{
            this._router.navigate(['review-activities'])
          }
            });
          }
        })
      }
    });  
  }

  backButtonRoute() : void {
    if (this.viewType == 'review' || this.viewType == 'myactivity') {
    if(localStorage.getItem('contribution') === 'delegate'){
      this._router.navigate(['delegate-contributions'])
    }

else{
  this._router.navigate(['review-activities'])
}
}
else{
  if(this.viewType == 'myteam'){
    this._router.navigate(['certificate/table', 'myteam',this.empId]);
  }else if(this.viewType == 'my-contribution-history'){
    this.year = String(localStorage.getItem('contributionYear'));
    this._router.navigate(['/certificate/table', 'my-contribution-history', 'user'],{ queryParams: { year: this.year } });
  }else if(this.viewType == 'team-contribution-history'){
    this.year = String(localStorage.getItem('teamYear'));
    this._router.navigate(['/certificate/table', 'team-contribution-history', this.empId],{ queryParams: { year: this.year } });
  }else{
    if(this.viewType == 'myView'){
      this._router.navigate(['certificate/table', 'myView','user']);
    }
  }
}
  }

  getUserData(){
      this.profileService.getProfileDetailBehaviorSubject().subscribe((data)=>{
        if(data){
          this.userData = data.data;
          if(this.userData.image){
            this.userData.imgAsset = atob(this.userData.image)
            }
            else{
              this.userData.imgAsset = "../../../../assets/images/def.png";
            }
          }
      })
  }

  backRoute(){
    if(this.viewType === 'myteam'){
      this._router.navigate(['dashboard/my-team']);
    }
    else if(this.viewType === 'my-contribution-history'||this.viewType === 'team-contribution-history'){
      this._router.navigate(['dashboard/contribution-history'])
    }
    else {
      this._router.navigate(['/dashboard']);
    }
  }

  onResize() : void{
    this._responsiveService.checkWidth();
  }

  
}
