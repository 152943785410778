<form class="row mt-3" [formGroup]="rewardDistributionForm">
  <div class="col-md-7 budgeting p-0 pt-2">
    <div class="container">
      <div class="budgeting-heading p-3">Budgeting</div>
      <div class="row p-3">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-3">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label class="label-text">Members</mat-label>
            <mat-select
              formControlName="member_type"
              placeholder="Select"
              (selectionChange)="employeeListLoad()"
              disableOptionCentering
              panelClass="award-dropdown2"
            >
              <mat-option class="input-text" value="employee">
                Select By Individual Member
              </mat-option>
              <mat-option class="input-text" value="manager">
                Select By Manager
              </mat-option>
              <mat-option class="input-text" value="excel">
                Upload Employee Excel
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div>
            <mat-error  class="error-style"
              *ngIf="
                this.submitted2 &&
                this.rewardDistributionForm.value.member_type.length === 0
              "
              >Select member type</mat-error
            >
          </div>
        </div>
        <div *ngIf="this.rewardDistributionForm.value.member_type === 'employee' 
        || this.rewardDistributionForm.value.member_type === 'manager'"
         class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="overflow-x: auto;">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label class="label-text">Search Members*</mat-label>
            <form >
              <input name="searchText" class="input-text" type="text" [(ngModel)]="searchText"  (ngModelChange)="onSearchChange()"  matInput/>
            </form>
            <mat-selection-list
              class="voter-box"
              formControlName="employee_select"
              placeholder="Select Members"
              (selectionChange)="
                selectionChangeNewParticipants(
                  $event,
                  this.rewardDistributionForm.value.member_type
                )
              "
            >
            <div class="overflowed-scroll">
              <mat-list-option
                *ngFor="let emp of employees_list"
                class="emp-list-text"
                [value]="emp"
                [selected]="participantSet.get(emp.email)"
                checkboxPosition="before"
              >
                {{emp.id + " " + emp.name}}
              </mat-list-option>
            </div>
            </mat-selection-list>
          </mat-form-field>
          <div>
            <mat-error class="error-style"
              *ngIf="
                this.submitted2 &&
                this.rewardDistributionForm.value.member_type.length !== 0 &&
                this.selectedValues.length === 0
              "
              >Select employees from the list</mat-error
            >
          </div>
        </div>
        <div *ngIf="this.rewardDistributionForm.value.member_type === 'excel'"
         class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <button
            *ngIf="this.rewardDistributionForm.value.member_type === 'excel'"
            class="mb-2 ml-3 excel_btn"
            (click)="openDialog()"
          >
            Import Data from Excel
          </button>
        </div>
      </div>
      <button
        *ngIf="this.rewardDistributionForm.value.member_type === 'employee' 
        || this.rewardDistributionForm.value.member_type === 'manager'"
        class="mb-2 ml-3"
        id="add_btn"
        (click)="createBudgetTableBtnClicked()"
      >
        Create Budget Table
      </button>
      <mat-error  class="mrg-lft error-style"  *ngIf="this.submitted1 && this.updateTable"
        >Please click on create Table Button to get updated selected employees
        in budgeting table</mat-error
      >
    </div>
    <div class="body table-responsive">
      <table
        mat-table
        [dataSource]="budgetDataSource"
        class="w-100"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="Email">
          <th class="head" mat-header-cell *matHeaderCellDef>Email Id</th>
          <td class="myDesc" mat-cell *matCellDef="let element">
            {{ element.employeeId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Contribution Points">
          <th class="head" mat-header-cell *matHeaderCellDef>
            Contribution Points
          </th>
          <td class="myDesc text-center" mat-cell *matCellDef="let element">
            {{ element.contributionPoints }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Budget (in Rs)">
          <th class="head" mat-header-cell *matHeaderCellDef>Budget (in Rs)</th>
          <td class="myDesc d-flex text-center" mat-cell *matCellDef="let element">
            <input
            *ngIf="participantBudgetEditingAllowed(element.employeeId)"
              class="participant-amount"
              type="number"
              onkeypress="return (event.charCode == 8 || event.charCode == 0) ? '' : event.charCode >= 48 && event.charCode <= 57"
              min="0"
              step="100"
              (change)="onChangeParticipantBudget($event, element.employeeId)"
              [disabled]="!participantBudgetEditingAllowed(element.employeeId)"
              (keyup)="onChangeParticipantBudget($event, element.employeeId)"
              [value]="getParticipantBudgetAmount(element.employeeId)"
              matInput
            />
            <label *ngIf="!participantBudgetEditingAllowed(element.employeeId)" class="final-amount d-flex align-items-center justify-content-center">{{getParticipantBudgetAmount(element.employeeId)!.toFixed(2)}}</label>
          </td>
        </ng-container>
        <ng-container matColumnDef="save/edit">
          <th class="head" mat-header-cell *matHeaderCellDef></th>
          <td class="myDesc" mat-cell *matCellDef="let element">
            <button
              class="edit-delete-btn"
              (click)="editParticipantBudegtAmount(element.employeeId)"
            >
              <mat-icon
                *ngIf="participantBudgetEditingAllowed(element.employeeId)"
                >save</mat-icon
              >
              <mat-icon
                *ngIf="!participantBudgetEditingAllowed(element.employeeId)"
                >edit</mat-icon
              >
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="delete">
          <th class="head" mat-header-cell *matHeaderCellDef></th>
          <td class="myDesc" mat-cell *matCellDef="let element">
            <button
              class="edit-delete-btn"
              (click)="removeParticipant(element.employeeId)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="saveBudgetError">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="budgetDisplayedColumns.length"
            class="text-end"
          >
            <mat-error class="error-style"
              >Click on the save button to store budget of
              {{ element.employeeId }}</mat-error
            >
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="budgetDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: budgetDisplayedColumns"></tr>
        <tr
          [hidden]="
            !this.submitted1 || 
            !participantBudgetEditingAllowed(row.employeeId)"
          mat-row
          *matRowDef="let row; columns: ['saveBudgetError']"
        ></tr>
      </table>
    </div>
    <div *ngIf="!budgetDataSource.data.length" class="no_record center-align">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <img src="../../../../assets/images/cross-icon.svg" />
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          No Member found!
        </div>
      </div>
    </div>
    <mat-paginator
      [pageIndex]="tablePageIndex"
      [pageSize]="5"
      showFirstLastButtons
      (page)="pageEvent = getData($event)"
      [length]="tableLength"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>

  <div class="col-md-5 allot-budget">
    <div class="container">
      <div class="row">
        <div class="budget-at-once-text col-lg-8 col-md-8 col-sm-8 col-xs-8">Allot Budget at Once</div>
        <div class="d-flex justify-content-end col-lg-4 col-md-4 col-sm-4 col-xs-4">
          <button type="button" class="info" [matMenuTriggerFor]="interview" style="height: 40px; width: 40px;">
            <img src="../../../../../assets/images/form-info.svg" width="100%" height="100%">
          </button>
          
        </div>
      </div>
      <div class="point-text">Total Contribution Points</div>
      <div class="point-data">{{ totalContributionPoints }}</div>
      <hr />
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div class="allot-amount-text">
            <div>Allot</div>
            <div>Budget(₹)</div>
          </div>
          <input
            class="total-amount-input pl-2"
            type="number"
            onkeypress="return (event.charCode == 8 || event.charCode == 0) ? '' : event.charCode >= 48 && event.charCode <= 57"
            min="0"
            step="100"
            formControlName="budgetAmount"
            placeholder="Enter"
            [value]="totalAmount"
            (keyup) = "onChangeBudgetAndRatio(true,true)"
            (change) = "onChangeBudgetAndRatio(true,true)"
            matInput
          />
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div class="allot-amount-text">
            <div>Conversion</div>
            <div>Ratio</div>
          </div>
          <input
              class="total-amount-input pl-2 mb-2"
              type="number"
              onkeypress="return (event.charCode == 8 || event.charCode == 0) ? '' : event.charCode >= 48 && event.charCode <= 57"
              min="0"
              step="1"
              formControlName="conversionRatio"
              placeholder="Enter"
              [value]="totalConversionRatio.toFixed(2)"
              (keyup) = "onChangeBudgetAndRatio(false,true)"
              (change) = "onChangeBudgetAndRatio(false,true)"
              matInput
            />
        </div>
      </div>
      <mat-error class="error-style" *ngIf="this.totalAmount === 0 && submitted1">Budget Amount should not be ₹0</mat-error>
      <mat-error class="error-style" *ngIf="updateTableBudget && submitted1 && !splitBtnClicked">Click on split amount button to update the budget of each employee in table</mat-error>
      <div class="pt-3 mb-1" *ngIf="totalContributionPoints !== 0 && !disableSplitInputandBtn">
        <button id="split_btn" (click)="splitAmount()">Split Amount</button>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div class="mt-3 mb-2">Surplus (in Rs)</div>
          <div class="surplus">{{ surplus.toFixed(2) }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="container pl-4 pt-3">
    <mat-error class="error-style" *ngIf="this.submitted1 && this.edit_NoEdit_IconCount"
      >Please save all the budget of employees</mat-error
    >
    <mat-error class="error-style"
      *ngIf="
        this.submitted1 &&
        this.allSelectedEmployees.length === 0 &&
        this.selectedValues.length === 0
      "
      >Table should not be empty</mat-error
    >
  </div>
  <div class="container pl-4 pt-3">
    <button id="new_allot" type="submit" (click)="onSubmit()">Allot</button>
  </div>
</form>

<mat-menu #interview="matMenu" xPosition="before" class="mat-dropdown-styling">
  <div class="container">
      <div class="p-4">
        <span class="font-weight-bold">Note: </span>
        <div>1) Conversion Ratio = ((Allot Budget - Surplus)/Total Contribution Points)</div>
        <br/>
        <div>2) If total contribution points is 0, then the allot budget and conversion ratio input get disabled.</div>
        <br/>
        <div>3) If admin manually distribute budget among employees in table, then also the allot budget and conversion ratio input get disabled</div>
      </div>
  </div>
</mat-menu>
