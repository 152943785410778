<!-- 
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <button type="button" class="close" (click)="onClose(false)" aria-label="Close">
                <span aria-hidden="true"><i class="material-icons">close</i></span>
            </button>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-center ml-1">
            <img src="../../../../assets/images/delete-logo.svg">
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-center ml-1 mt-4">
            <p>{{data.warningTitle}}</p>
        </div>
        <div class="form-buttons row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <button type="submit" mat-raised-button id="yes_btn" (click)="onClose(true)">YES</button>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <button (click)="onClose(false)" mat-raised-button id="no_btn">NO</button>
            </div>
        </div>
    </div> -->

    
<div class="container p-4">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                <img class="gif" src="../../../../assets/images/n_image/Delete.gif">
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
<p>Are you sure?</p>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 message text-center">
            {{data.warningTitle}}
        </div>
        <div class="text-center">
                <button type="submit" class="yes_btn ml-2 mt-2 border-0" (click)="onClose(true)">YES</button>
                <button (click)="onClose(false)" class="no_btn ml-2 mt-2">NO</button>
        </div>
    </div>
</div>