import { Component, OnInit, ViewChild, ViewChildren, QueryList} from '@angular/core';
import { ResponsiveService } from '../services/responsive.service';
import { AuthUserService } from '../services/auth-user.service';
import { SocketService } from '../services/socket.service';
import { MatPaginator } from '@angular/material/paginator';
import { ViewProfileComponent } from '../modules/dashboard/view-profile/view-profile.component';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { NotificationDetails } from './notification.model';
import { Location } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  durationInSeconds : number;
  empId: String;
  navBool:boolean;
  mobile: boolean;
  empEmail : String;
  isLoggedIn: boolean;
  notifications: any;
  notifyData: NotificationDetails[];
  pageNo:number;
  nextpageData:number;
  isAdmin:boolean;
  isAdminManager:boolean;
  admin:boolean;
  currentDate:Date;
  checkManager:boolean;
  @ViewChild('menuBtn1', { read: MatMenuTrigger, static: false}) 'aa': MatMenuTrigger;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();

  constructor(private _responsiveService: ResponsiveService,
    private authUser: AuthUserService,
    private _authService: AuthUserService,
    private _socketService: SocketService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private location: Location,
    private _router: Router,
    public commonService : CommonService) {
    this.empEmail = '';
    this.mobile = false;
    this.empId = '';
    this.checkManager = false;
    this.notifyData = [];
    this.currentDate = new Date(moment().format('YYYY-MM-DD'));
    this.pageNo = 0;
    this.isLoggedIn = false;
    this.isAdmin=false;
    this.isAdminManager=false;
    this.admin=false;
    this.navBool = false;
    this.durationInSeconds = 5;
    this.nextpageData=0;
  }
  
  ngOnInit() : void {
    this._authService.getEmployeeDetailBehaviorSubject().subscribe(item => {
      if (item) {
        this.empId = item.data.id;
        this.empEmail = item.data.email;
        this.checkManager = item.manager;
        this.isAdmin=item.admin;
        this.isAdminManager=item.isAdminManager;
        this.isLoggedIn = true;
      }
      else{
        this.isLoggedIn = false;
      }
    });

    let stompClient = this._socketService.connect();
    stompClient.connect({ username: localStorage.getItem('empId') }, (_frame: any) => {
      stompClient.subscribe('/users/topic/notification', (notifications: any) => {
        this.notifications = JSON.parse(notifications.body);
        for (const element of this.notifications.notification) {
          this.openSnackBar(element);
        }
      })
    });
    this.checkWidth();
    this.onResize();
  }

  checkWidth() : void{
    this._responsiveService.getMobileStatus().subscribe(isMobile => {
      if (isMobile) {
        this.mobile = true;
      }
      else {
        this.mobile = false;
      }
    });
  }
  
  openSnackBar(message: string) : void {
    if(this._authService.CheckuserState()==true){
      let snack=this._snackBar.open(message, 'View', {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        duration: this.durationInSeconds * 1000
      });
  
      snack.onAction().subscribe(()=>{
        this.getNewNotification("NEW");
        this.aa.openMenu(); 
      })
    }
  }

  Logout(): void {
    this.authUser.signOut();
  }
  getNextPageData(page:number){
    this._socketService.getNewNotification(this.empEmail,page+1).subscribe((data)=>{
      if(data.data){
      
       this.nextpageData=data.data.length
      }
      else{this.nextpageData=0}
    })

  }

  getNewNotification(flag:String,event?:Event) : void {
    event?.stopPropagation();
    if(flag=='MORE'){
      this.pageNo=this.pageNo+1;
      this.getNextPageData(this.pageNo);
      this._socketService.getNewNotification(this.empEmail,this.pageNo).subscribe((data)=>{
        if(data.data){
          this.navBool=true;
          for(let element of data.data){
            this.notifyData.push(element);
          }
        }
      })
    }
    else if(flag=='NEW'){
      this.notifyData=[];
      this.pageNo=1;
      this.getNextPageData(this.pageNo);
      this._socketService.getNewNotification(this.empEmail,this.pageNo).subscribe((data)=>{
        if(data.data){
          this.navBool=true;
          this.notifyData=data.data;
          if(this.notifyData.length<1){
            this.navBool=false
          }
          this.getNotificationRead();
          this.commonService.setNotificationCount(0);
        }
      });
    }
  }

  openMenu() : void {
    this.aa.openMenu(); 
  }

  getNotificationRead() {
      this._socketService.getNotificationRead(this.empEmail).subscribe(data => {
        localStorage.setItem('notificationCount','0')
      },(error=>{
        this.toastr.error("Error while loading data"); 
      })); 

  }

  refresh(event : Event) : void{
    this.notifyData = [];
    event.stopPropagation();
    this.getNewNotification("NEW");
  }

  menuClosed() : void {
    this.navBool=false;
    this.commonService.setNewNotificationCount(0);
  }

  openDialog() : void{
    const dialogRef = this.dialog.open(ViewProfileComponent, {
      width: '513px',
      height:'585px',
      panelClass: 'mClass',
    });
  }  

  onResize() : void {
    this._responsiveService.checkWidth();
  }

  notificationRedirection(notificationType:String,eventId:Number,eventType:String) : void{
    if(notificationType=="ClientFeedback"){
      this._router.navigate(['client-feedback/table', 'myView', 'user']);
    }
    else if(notificationType=="TeamBuilding"){
      this._router.navigate(['team-building/table', 'myView', 'user']);
    }
    else if(notificationType=="SelfDevelopment"){
      this._router.navigate(['self-development/table', 'myView', 'user']);
    }
    else if(notificationType=="Projects"){
      this._router.navigate(['project/table', 'myView', 'user']);
    }
    else if(notificationType=="Interview"){
      this._router.navigate(['interview/table', 'myView', 'user']);
    }
    else if(notificationType=="TrainingSession"){
      this._router.navigate(['training-sessions/table', 'myView', 'user']);
    }
    else if(notificationType=="Certificate"){
      this._router.navigate(['certificate/table', 'myView', 'user']);
    }
    else if(notificationType=="Mentorship"){
      this._router.navigate(['mentorship/table', 'myView', 'user']);
    }
    else if(notificationType=="Delegated Contribution Received!"){
      let url = "/dashboard?redirect=delegateContributions"
      this.location.go(url);
      window.location.reload();
    }
    else if(notificationType=="Pending Activity for Approval"){
      if(eventType=="Contest"){
        this._router.navigate(['event/rmView', eventId]);
      }
      else{
        this._router.navigate(['event/nonContest','managerView',eventId]);
      }
    }
    else if(notificationType=="New Event Created" || notificationType=="Event Approved" || notificationType=="Event Rejected" || notificationType=="Enrolled Successfully" || notificationType=="You’ve been added as a voter" || notificationType=="Reminder for Enrollment!" || notificationType=="Winners Are Out !" || notificationType=="Reminder to give Rating!" || notificationType=="Event Ended" || notificationType=="Hurry Up!!" || notificationType=="Hurry Up!"){
      if(eventType=="Contest"){
          this._router.navigate(['event/event', eventId]);
        }
        else{
          this._router.navigate(['event/nonContest','userView',eventId]);
        }
    }
    else if(notificationType=="You Won a Prize"){
      this._router.navigate(['rewards']);
    }
  }
}

