import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {commentsData} from '../../../modules/dashboard/review-activity-table/reviewactivity.model';
import { FormsModule, ReactiveFormsModule,UntypedFormGroup, FormGroupDirective,UntypedFormBuilder, UntypedFormControl, Validators, FormArray,NgForm } from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.css']
})
export class CommentModalComponent implements OnInit {
  modalForm:UntypedFormGroup;
    submitted:boolean;

  constructor(public dialogRef: MatDialogRef<CommentModalComponent>,
    private formBuilder: UntypedFormBuilder) { 
      this.submitted = false;
      this.modalForm = this.formBuilder.group({
      });
    }
    
  ngOnInit(): void {
    this.modalForm=this.formBuilder.group({
      comment:['', Validators.required],
    });

  }
  onSubmit(){
    this.submitted=true;
    if(this.modalForm.invalid)
    return;
    this.dialogRef.close({commentForm:this.modalForm.value});
  }
  onClose() {
    this.dialogRef.close();
  }
  matcher = new MyErrorStateMatcher();
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid);
  }
}