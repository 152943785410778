import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'positionSuffix'
})
export class PositionSuffixPipe implements PipeTransform {
  suffixMap : any = {
    1: 'st',
    2: 'nd',
    3: 'rd'
  };

  edgeCases = [11, 12, 13];

  transform(value: any, args?: any): string {
    value=Number(value);
    const lastTwoDigits = value % 100;
    
    if (this.edgeCases.includes(lastTwoDigits)) {
      return `${value}th`
    }
    if(!value){
      return `-`
    }

    const lastDigit = value % 10;
    const suffix = this.suffixMap[lastDigit];

    return !suffix
      ? `${value}th`
      : `${value}${suffix}`;
  }
}
