import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { NominationService } from 'src/app/modules/hr-recommendation/services/nomination.service';
import { BulkNominationReqBody, GenericNominationAction, NominationActionReqBody } from '../../models/generic.nomination-action.model';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-generic-nomination-action',
  templateUrl: './generic-nomination-action.component.html',
  styleUrls: ['./generic-nomination-action.component.css']
})
export class GenericNominationActionComponent implements OnInit {

  public nominateApproveForm: FormGroup;
  public modalTitle: string;
  public awardDropdownList: Array<string>;
  constructor(private formBuilder: FormBuilder,
    private nominationService: NominationService,
    private dialogRef: MatDialogRef<GenericNominationActionComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: GenericNominationAction,
    private toastr: ToastrService) {
    this.nominateApproveForm = this.formBuilder.group({
      awardType: [this.modalData?.selectedAwardType, [Validators.required]],
      reason: [''],
      ecdc: [this.modalData?.ecDc]
    });
    this.modalTitle = '';
    this.awardDropdownList = [] as string[];
  }

  ngOnInit(): void {
    if (!this.modalData?.sameAwardCategory) {
      this.nominateApproveForm?.get('awardType')?.clearValidators();
      if (this.modalData?.isApproveClicked) {
        this.dialogRef.close({
          awardType: null,
          isSubmitClicked: true
        });
      }
    }

    if (!this.modalData?.isApproveClicked) {
      this.nominateApproveForm.get('reason')?.setValidators([Validators.required,Validators.minLength(20)]);
      this.nominateApproveForm?.get('ecdc')?.clearValidators();
    }
    this.modalTitle = this.modalData?.title;
    this.awardDropdownList = this.modalData?.awardDropdownList
  }

  /**
   * closing the modal
   */
  public onClose(): void {
    this.dialogRef.close({
      isSubmitClicked: false
    });
  }

  /**
   * checking whether all the details are valid in form or not
   * sending the req body in functions to approve or reject nomination or list of nomations
   */
  public onSubmit(): void {
    this.nominateApproveForm.markAllAsTouched();
    if (this.nominateApproveForm.valid) {
      if (this.modalData?.isBulkSelected) {
        this.dialogRef.close({
          awardType: this.nominateApproveForm.value.awardType,
          reason: this.nominateApproveForm.value.reason,
          isSubmitClicked: true
        });
      }
      else {
        let reqBody = {
          praise: this.modalData?.isApproveClicked ? 'APPROVED BY HR' : this.nominateApproveForm.value.reason,
          ecDc: this.nominateApproveForm.value.ecdc,
          recommendedToName: this.modalData?.recommendedToName,
          recommendedToEmail: this.modalData?.recommendedToEmail,
          recommendedBy: localStorage.getItem('email')?.toLowerCase(),
          typeOfAward: this.nominateApproveForm.value.awardType
        } as NominationActionReqBody;

        if (this.modalData?.isApproveClicked) {
          this.approveNomination(reqBody);
        }
        else {
          this.rejectNomination(reqBody);
        }
      }

    }
  }

  /**
   * calling api to approve nomination of employee
   * @param reqBody to send reqbody to api
   */
  public approveNomination(reqBody: NominationActionReqBody): void {
    this.nominationService.approveAward(reqBody).pipe(take(1)).subscribe({
      next: (response) => {
        if (response) {
          this.dialogRef.close({
            isSubmitClicked: true
          });
        }
      },
      error: (error) => {
        if (error.status === 200) {
          this.dialogRef.close({
            isSubmitClicked: true
          });
        }
        else {
          this.toastr.error(error.error);
        }
      }
    })
  }

  /**
   * calling api to approve nomination of employee
   * @param reqBody to send reqbody to api
   */
  public rejectNomination(reqBody: NominationActionReqBody): void {
    this.nominationService.rejectAward(reqBody).pipe(take(1)).subscribe({
      next: (response) => {
        if (response) {
          this.dialogRef.close({
            isSubmitClicked: true
          });
        }
      },
      error: (error) => {
        if (error.status === 200) {
          this.dialogRef.close({
            isSubmitClicked: true
          });
        }
        else {
          this.toastr.error(error.error.text);
        }
      }
    })
  }

}
