import {
  Component,Input,OnInit,QueryList,ViewChildren,} from "@angular/core";
import { PageEvent, MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ToastrService } from "ngx-toastr";
import { AuthUserService } from "src/app/services/auth-user.service";
import { BudgetHistoryTable, rewardHistory } from "src/app/shared/models/admin-portal.model";
import { AdminPortalService } from "../../service/admin-portal.service";


@Component({
  selector: "app-budget-reward-history-table",
  templateUrl: "./budget-reward-history-table.component.html",
  styleUrls: ["./budget-reward-history-table.component.css"],
})
export class BudgetRewardHistoryTableComponent implements OnInit {

  dataSource:MatTableDataSource<rewardHistory>;
  tablePageIndex: number;
  tableLength: number;
  pageEvent?: PageEvent;
  pageNo: number ;
  empId: string;
  empEmail : String;
  innerTable : Map<number,MatTableDataSource<rewardHistory>>;
  budgetHistoryTableMap: Map<number, boolean>;
  row: number;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @Input() flag?: boolean;
  constructor(
    private authService: AuthUserService,
    private adminPortalService: AdminPortalService,
    private toastr: ToastrService
  ) {
    this.pageNo=0;
    this.row=0;
    this.tablePageIndex = 0;
    this.tableLength = 0;
    this.empId = "";
    this.empEmail = '';
    this.budgetHistoryTableMap = new Map<number, boolean>();
    this.dataSource = new MatTableDataSource<rewardHistory>();
    this.innerTable = new Map<number,MatTableDataSource<rewardHistory>>();
    this.dataSource = new MatTableDataSource<rewardHistory>();
    this.pageNo = 0;
    this.row = 0;
  }
  innerDisplayedColumns: string[] = ["Email", "BudgetAmount"];
  displayedColumns: string[] = [
    "no",
    "title",
    "issuedDate",
    "amount",
    "empList",
    "action",
  ];
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<rewardHistory>();
    this.authService.getEmployeeDetailBehaviorSubject().subscribe((item)=>{
      this.empId = item.data.id;
      this.empEmail = item.data.email;
    })
    this.budgetHistoryTableMap.clear();
    this.getData();
  }



  AllotBudget(event?: PageEvent) : void {
    let obj = {};
    if (event) {
      this.row = event!.pageSize;
      this.tablePageIndex = event!.pageIndex;
      obj = {
        empEmail: this.empEmail,
        pageNo: this.tablePageIndex + 1,
        rowsPerPage: event!.pageSize,
      };
    } else {
      this.row = 5;
      obj = {
        empEmail: this.empEmail,
        pageNo: 1,
        rowsPerPage: 5,
      };
    }
    this.AllotBudgetPromise(obj)
      .then((res) => {})
      .catch((err) => {
        this.toastr.error("Error in loading data");
      });
  }

  AllotBudgetPromise(obj: any) {
    let budgetHistory : any = [];
    return new Promise<void>((resolve, reject) => {
      this.adminPortalService.fetchBudgetHistory(obj).subscribe(
        ({ data, total_rows }) => {
          if(data){
            for (let d of data) {
              let budgetHistoryObj = {
                id: d.id,
                date: d.distributedOn.substr(0, 10),
                totalAmount: Number(d.allotBudget),
              };
              budgetHistory.push(budgetHistoryObj);
              this.innerTable.set(d.id,new MatTableDataSource<rewardHistory>(d.budgetRewardDistribution));
            }
          }
          this.dataSource = new MatTableDataSource<rewardHistory>(budgetHistory);
          this.tableLength = total_rows;
          resolve();
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  getData(event?: PageEvent):PageEvent {
    if (this.flag == false) {
      this.AllotBudget(event);
    } else {
      this.OccasionalRewards(event);
    }
    if (event) return event;
    return new PageEvent();
  }

  getTotalAmount(element: BudgetHistoryTable) {
    if (element.totalAmount === undefined) return element.totalAmountOccasionalReward;
    return element.totalAmount;
  }

  OccasionalRewards(event?: PageEvent):PageEvent {
    if (event) {
      this.row = event.pageSize;
      this.pageNo = event.pageIndex;
      this.service1(this.row, this.pageNo + 1)
        .then((res) => {})
        .catch((err) => {
          this.toastr.error("Error in loading data");
        });
    } else {
      this.row = 5;
      this.service2(this.row)
        .then((res) => {})
        .catch((err) => {
          this.toastr.error("Error in loading data");
        });
    }
    if (event) return event;
    return new PageEvent();
  }

  service1(row: number, pageNo: number) {
    return new Promise<void>((resolve, reject) => {
      let obj = {
        empEmail: this.empEmail,
        pageNo: pageNo,
        rowsPerPage: row,
      };
      this.adminPortalService.getOcassionalRewardHistory(obj).subscribe(
        (data) => {
          if (data) {
            if (data && data.data && data.data.length) {
              for (const element of data.data) {
                element.date = element.distributedOn.substr(0, 10);
                let csvFileNames = element.csvFileName;
                element.fileName = element.csvFileName.substr(
                  13,
                  csvFileNames.length - 1
                );
              }
            }
            this.tableLength = data.total_rows;
            this.tablePageIndex = this.pageNo;
            this.dataSource = new MatTableDataSource<rewardHistory>(data.data);
            this.dataSource.paginator = this.paginator.toArray()[1];
            resolve();
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  service2(row: number) {
    return new Promise<void>((resolve, reject) => {
      let obj = {
        empEmail: this.empEmail,
        pageNo: 1,
        rowsPerPage: row,
      };
      this.adminPortalService.getOcassionalRewardHistory(obj).subscribe(
        (data) => {
          if (data) {
            if (data && data.data && data.data.length) {
              for (const element of data.data) {
                element.date = element.distributedOn.substr(0, 10);
                let csvFileNames = element.csvFileName;
                element.fileName = element.csvFileName.substr(
                  13,
                  csvFileNames.length - 1
                );
              }
            }
            this.tableLength = data.total_rows;
            this.tablePageIndex = this.pageNo;
            this.dataSource = new MatTableDataSource<rewardHistory>(data.data);
            this.dataSource.paginator = this.paginator.toArray()[1];
            resolve(data);
          }
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * conver base64 file to image
   * @param base64String -base 64 file
   * @param fileName -filename
   */
  downloadCSV(base64String: String, fileName: string): void {
    const source = `data:application/;base64,${base64String}`;
    const link = document.createElement('a');
    link.href = source;
    link.download = fileName;
    link.click();
  }

}
