import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { take } from "rxjs/operators";
import { AuthUserService } from "src/app/services/auth-user.service";
import { CommonService } from "src/app/services/common.service";
import { SocketService } from "src/app/services/socket.service";
@Injectable({
  providedIn: "root",
})
export class LoaderService {
  isLoading = new Subject<boolean>();
  empEmail: string;
  show() {
    this.isLoading.next(true);
    if (this.commonService.getLoaderHidden()) {
      this.commonService.setLoaderHidden(false);
      this.genericAPI();
      this.getNotificationCount();
    }
  }
  hide() {
    this.isLoading.next(false);
    if (!this.commonService.getLoaderHidden()) {
      this.commonService.setLoaderHidden(true);
    }
  }
  genericAPI() {
      let token= this.authService.getLocalStorageItem();
      if (token !== null) {
      let email = localStorage.getItem("email");
      if (email !== null) this.empEmail = email;
      this.authService
        .userTracking(this.empEmail.toLocaleLowerCase())
        .subscribe(
          (data) => {},
          (error) => {
            let fullServerStatus = this.commonService.getFullServerStatus();
            fullServerStatus.userTrackingApiWorking = false;
            this.commonService.setFullServerStatus(fullServerStatus);
          }
        );
    }
  }
  getNotificationCount() {
    let token= this.authService.getLocalStorageItem();
      if (token !== null) {
      let email = localStorage.getItem("email");
      if (email !== null) this.empEmail = email;
      this._socketService
        .getNotificationCount(this.empEmail.toLocaleLowerCase())
        .subscribe(
          (data: any) => {
            this.commonService.setNotificationCount(data);
            this.commonService.setNewNotificationCount(data);
          },
          (error) => {
            let fullServerStatus = this.commonService.getFullServerStatus();
            fullServerStatus.notificationApiWorking = false;
            this.commonService.setFullServerStatus(fullServerStatus);
          }
        );
    }
  }
  setEmployeeId() {
    let email = localStorage.getItem("email");
    if (email !== null) this.empEmail = email;
    // this.authService.getEmployeeDetailBehaviorSubject().subscribe((item) => {
    //   if (item) {
    //     this.empEmail = item.data.email
    //   }
    // });
  }
  constructor(
    private commonService: CommonService,
    private authService: AuthUserService,
    private _socketService: SocketService
  ) {
    this.empEmail = "";
    this.setEmployeeId();
  }
}
