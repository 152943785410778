import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class CertificateTableService {
  constructor(private http: HttpClient) { }
  // viewCertificate(sno: any) {
   
  //   return this.http.get<any>('contripoint/certificate/view/', {params:queryParams});
  // }
  createCertificate(createCertificateReqBody: any) {
    return this.http.post<any>('contripoint/certificate/add', createCertificateReqBody);
  }
  saveAsDraft(createCertificateReqBody: any) {
    return this.http.post<any>('contripoint/certificate/drafts', createCertificateReqBody);
  }
  getAllCertificate(empEmail: String, pageNo: number, year: String,status:String): Observable<any> {
    let obj = {
      'empEmail': empEmail,
      'pageNo': pageNo,
      'years': year,
      'status':status    
    }
  
    let reqParam=new HttpParams();
    reqParam=reqParam.append('empEmail', empEmail.toString());
    reqParam=reqParam.append('pageNo', pageNo);
    reqParam=reqParam.append('years', year.toString());
    reqParam=reqParam.append('status', status.toString());
    // {'params':reqParam}
      return this.http.post<any>('contripoint/certificate/byYear',obj);
    }
  getSelectedCertificateView(sno: number){
let header=new HttpHeaders().set( 'Access-Control-Allow-Origin','*').set('content-type','application/json')    
let reqParam=new HttpParams().set('id',sno);

    return this.http.get<any>('contripoint/certificate/view',{'params':reqParam,'headers':header});
    }
  downloadFile(certificateSNO: Number) {
    let reqParam=new HttpParams().set('sno',certificateSNO.toString())
    return this.http.get<any>('contripoint/certificate/download/',{'params':reqParam});
  }

  deletecertification(id:Number){
    return this.http.delete<any>('contripoint/certificate/delete/'+id );
  }
}

