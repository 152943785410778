import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChangestatusService {
  private approvalUpdate = new Subject<any>();
  private getLdrUpdate = new Subject<any>();
  constructor(private http: HttpClient) { }
  approveActivity(ContributionId:Number,empEmail:String,comment?:String): Observable<any>{
    let obj = {
      'id': ContributionId,
      'type': "APPROVED",
      'comments':comment,
      'empEmail':empEmail
    }
    return this.http.post<any>('contripoint/change/status',obj);
  }

  sendUpdate(message: string) { 
    this.approvalUpdate.next({ text: message }); 
  }

  getUpdate(): Observable<any> { 
    return this.approvalUpdate.asObservable(); 
  }
  setldr(message: string) {
    this.getLdrUpdate.next({ text: message });
  }
  getldr(): Observable<any> {
    return this.getLdrUpdate.asObservable();
  }
  bulkApprove(idArr:Number[],empEmail:String,comment?:String,type?:String): Observable<any>{
    let obj = {
      'ids': idArr,
      'type': type,
      'comments':comment,
      'empEmail':empEmail
    }
    return this.http.post<any>('contripoint/bulk/approve',obj);
  }
  rejectActivity(ContributionId:Number,empEmail:String,comment?:String): Observable<any>{
    let obj = {
      'id': ContributionId,
      'type': "DECLINED",
      'comments':comment,
      'empEmail':empEmail
    }
    return this.http.post<any>('contripoint/change/status',obj);
  }
}
