<button mat-icon-button class="close-button">
  <img class="close" src="assets/images/Cut.svg" (click)="onClose()" alt="close-icon" />
</button>

<mat-card class="leaderboard d-flex justify-content-start mb-4">
  <img class="certificate-heading" src="assets/images/cerificateIconView.svg  " alt="certificate-banner" />
  <div class="ml-1 mt-2 pl-3">
    <span class="heading">

      View Attachment</span>
  </div>
</mat-card>
<div class="container  " id="cv">
  <div class="d-flex space-between">
    <span>{{data.title}}</span>
    <div class="btn1">
      <button class="list-btn" (click)="download()">
        <img src="assets/images/download-arrow.png" />
        <span class="ml-2">Download</span>
      </button>
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12 col-xs-12">
      <pdf-viewer [ngClass]="{
            'pdf-viewer-web': !mobile,
            'pdf-viewer-mobile': mobile
            }" *ngIf="viewDetail.url?.includes('.pdf'); else elseBlock" [src]="viewDetail.url" [original-size]="true"
        [fit-to-page]="true">
      </pdf-viewer>

    </div>
    <ng-template #elseBlock>
      <div class="image-container d-flex justify-content-center">
        <img class="certificate-img" [src]="viewDetail.url" />
      </div>
    </ng-template>
  </div>

</div>