import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

    public isMobile = new Subject();
    public screenWidth: string;
    public screenStatus = new Subject<string>();


    constructor() {
        this.checkWidth();
        this.screenWidth='';
    }

    onMobileChange(status: boolean) {
        this.isMobile.next(status);
    }

    getMobileStatus(): Observable<any> {
        return this.isMobile.asObservable();
    }

    screenStatusChange(status : string){
        this.screenStatus.next(status);
    }

    getScreenStatus() : Observable<string> {
        return this.screenStatus.asObservable();
    }

    public checkWidth() {
        let width = window.innerWidth;

          if (width <= 460) {
            this.screenWidth = "xsm";
            this.onMobileChange(true);
          }
        else if (width <= 576) {
            this.screenWidth = 'xs';
            this.onMobileChange(true);
        }
        else if (width <= 768) {
            this.screenWidth = 'sm';
            this.onMobileChange(true);
        } else if (width > 768 && width <= 992) {
            this.screenWidth = 'md';
            this.onMobileChange(false);
        } else {
            this.screenWidth = 'lg';
            this.onMobileChange(false);
        }
        this.screenStatusChange(this.screenWidth);
    }

}
