<div class="mrg-lft">
    <button (click)="sidebarFunc()">
      <img class="mrg-lft2" src="assets/images/Open L.svg" alt="open arrow" />
    </button>
  
    <ul type="none" class="mrgn-top" *ngFor="let val of data_limit">
      <div class="row mrg-lft3">
        <div *ngIf="val.position != null">
          <li class="parent-styling" *ngIf="val.position === 1; else second">
            <img class="img_leaders mr-1 image-1" [src]="val.imgAsset" />
            <img class="image-2 ml-4 mb-1" src="assets/images/badge-gold.svg" />
          </li>
          <ng-template #second>
            <li class="parent-styling" *ngIf="val.position === 2; else third">
              <img class="img_leaders mr-1 image-1" [src]="val.imgAsset" />
              <img
                class="mb-1 ml-2 image-3"
                src="assets/images/badge-silver.svg"
              />
            </li>
          </ng-template>
          <ng-template #third>
            <li class="parent-styling" *ngIf="val.position === 3">
              <img class="img_leaders mr-1 image-1" [src]="val.imgAsset" />
              <img
                class="ml-2 mb-2 image-3"
                src="assets/images/badge-bronze.svg"
              />
            </li>
          </ng-template>
          <li
            *ngIf="val.position != 1 && val.position != 2 && val.position != 3"
            class="parent-styling"
          >
          <img class="img_leaders mr-1 image-1" [src]="val.imgAsset" />
          </li>
        </div>
      </div>
    </ul>
  </div>
  