import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import {  midJourney, nominee_data, recom_data } from 'src/app/shared/models/hr-nomination.model';
import { NominationService } from '../../services/nomination.service';

@Component({
  selector: 'app-nomination-journey',
  templateUrl: './nomination-journey.component.html',
  styleUrls: ['./nomination-journey.component.css']
})
export class NominationJourneyComponent implements OnInit {
  viewJourneyData:midJourney
  nominatedData:nominee_data[]
  recommededData:recom_data[]
  constructor(private commonService:CommonService,
    private nominationService:NominationService) {
     this.viewJourneyData={} as midJourney;
     this.nominatedData=[];
     this.recommededData=[];
     }

  ngOnInit(): void {
  this.getData()
  }
  
  /**
   * get Nomination journey Data
   */
  getData():void {
    this.nominationService.viewJourney.subscribe((data)=>{
   if(data)
   { 
    this.nominatedData=data?.allNominations;
    this.recommededData=data?.allRecommendations;
    this.viewJourneyData=data;
    this.viewJourneyData.employee.image=atob(data?.employee?.image);


this.nominatedData.forEach((item:nominee_data) => {
  let date = new Date(item?.recommendedOn);
  item.recommendedOn = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
});


this.recommededData.forEach((item:recom_data) => {
  let date = new Date(item?.recommendedOn);
  item.recommendedOn = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
});
   }
    })

    this.commonService.setNominationSideBarStatus(true);
  }
    /**
   * set leaderboard status open
   */
    setStatus() {
      localStorage.setItem('nominationStatus', 'open');
    }
  
    /**
   * set leaderboard status close
   */
    sidenavFunc() {
      this.commonService.setNominationSideBarStatus(false);
      localStorage.setItem('nominationStatus', 'close');

    }

}

