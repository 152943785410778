import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { project_data } from '../../project/project-table/project.model';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.css']
})
export class ProjectFormComponent implements OnInit {
  name: string = '';
  val :number | undefined;
  projectCompleted:boolean;
  projectOngoing:boolean
  inhouse:boolean;    
  external:boolean;
  modalForm!:UntypedFormGroup;
  submitted:boolean;
  changeTriggered:boolean;
  test:any=null;
  file:File=this.test;
  minDate:Date;
  maxDate:Date;
  mobile:boolean;
  first:boolean;
  second:boolean;
  radio_val: string;
  @ViewChild("picker") datePicker!: MatDatepicker<Date>;
  constructor(
    public dialogRef: MatDialogRef<ProjectFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: project_data,
    private formBuilder: UntypedFormBuilder,
    private _responsiveService: ResponsiveService,
  ) {
    this.submitted=false;
    this.changeTriggered=false;
    this.inhouse=false;
    this.external=false;
    this.first=false;
    this.second=false;
    this.mobile = false;
    this.projectCompleted=false;
    this.projectOngoing=false;
    this.minDate=new Date();
    this.maxDate=new Date();
    this.changeTriggered=false;
    this.radio_val='';
    const currentYear = new Date().getFullYear();
    const currentMonth= new Date().getMonth();
    const currentDate = new Date().getDate();
    this.maxDate = new Date(currentYear, currentMonth, currentDate);
    this.minDate = new Date(`${currentMonth >= 3 ? currentYear : currentYear - 1}-03-01`);
    this.modalForm = this.formBuilder.group({
    });
   }

  ngOnInit(): void {
    this.modalForm=this.formBuilder.group({
      projectName:[this.data.projectName,Validators.required],
      description:[this.data.description,Validators.required],
      projectType:[this.data.projectType,Validators.required],
      clientName:[{value:this.data.clientName,disabled:true},Validators.required],
      duration_radio:[this.data.duration_radio,Validators.required],
      duration:[this.data.duration === 0 ? 4 : this.data.duration],
      date: [this.data.date,Validators.required],
      ecdc:[this.data.ecdc,Validators.required]
    })
    this.radio_val=this.data.projectType;
    if(this.data.duration_radio=='completed'){
      this.setDurationValidators();
    }
     if(this.data.clientName!='')
      {
        this.modalForm.get('clientName')?.enable();
      }
    this._responsiveService.getMobileStatus().subscribe((isMobile:boolean) => {
      this.mobile = isMobile ? true : false;
    });
  }

/**
 * Set client name
 * @param {number}val 
 */
setValue(val: number) {
  this.data.clientName = (val === 0) ? 'Gemini Solutions' : '';
  this.setProjectSelectedBoolean();
}

/**
 * Set client name if client is not 
 * Gemini Solutions
 */
  setProjectSelectedBoolean():void {
  this.modalForm.controls['clientName'].enable()
  }

/**
 * Updating the validators
 */
removeDurationValidators():void{
  this.modalForm.get('duration')?.clearValidators();
  this.modalForm.get('duration')?.updateValueAndValidity();
}
setDurationValidators(){
this.modalForm.get('duration')?.setValidators([Validators.min(1)]);
this.modalForm.get('duration')?.updateValueAndValidity();
}

/**
 * Submit the new form
 */     
onNewSubmit() {
  this.handleSubmit(this.data.modalCheck === 'ADD' ? 'newAdd' : 'editedAdd');
}

/**
 * Submit the draft form
 */ 
onDraftSubmit() {
  this.handleSubmit(this.data.modalCheck === 'ADD' ? 'newAddDraft' : 'editedDraft');
}

/**
 * 
 * Handling API calls ont the basis of submit types
 * 
 */
handleSubmit(flag:string) {
  this.submitted = true;
  if (this.modalForm.invalid) {
    return;
  }
  this.modalForm.value.duration = this.modalForm.value.duration_radio === 'ongoing' ? 0 : this.modalForm.value.duration;
  this.modalForm.value.date = moment(this.modalForm.value.date).format('YYYY-MM-DD');

  this.dialogRef.close({
    resultForm: this.modalForm.value,
    flag: flag,
    objFile: this.file,
    checkFile: this.changeTriggered
  });
}

/**
 * Check condition for EC 
 * requries for design
 */
clickEc() {
  if (this.second == true) {
    this.second = false;
  }
  this.first = true;
}

/**
 * check condition for DC
 * requries for design
 */
clickDc() {
  if (this.first == true) {
    this.first = false;
  }
  this.second = true;
}

/**
 * check condition for project 
 * type which
 * requries for design
 */
clickInhouse() {
  if (this.external == true) {
    this.external = false;
  }
  this.inhouse= true;
}

/**
 * check condition for project
 * type which
 * requries for design
 */
clickExternal() {
  if (this.inhouse == true) {
    this.inhouse= false;
  }
  this.external = true;
}
/**
 * check condition for project status
 * requries for design
 */
clickProjectComplete() {
  if (this.projectOngoing == true) {
    this.projectOngoing = false;
  }
  this.projectCompleted = true;
}
/**
 * check condition for project status
 * requries for design
 */
clickProjectOutgoing() {
  if (this.projectCompleted == true) {
    this.projectCompleted = false;
  }
  this.projectOngoing = true;
}
  
/**
 * Close modal
 */
onClose(): void {
  this.dialogRef.close();
}

/**
 *open calendar
 */
openCalender(): void {
  this.datePicker.open();
}

  matcher = new MyErrorStateMatcher();
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid);
  }
}