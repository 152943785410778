<button mat-icon-button class="close-button">
  <img
    class="close-icon"
    src="assets/images/Cut.svg"
    (click)="onClose()"
    alt="close button"
  />
</button>
<mat-card class="banner d-flex justify-content-start mb-4">
  <img
    class="training-session-heading  mt-1"
    src="assets/images/trainingSession_form.svg  "
    alt="banner"
  />

  <div class="ml-1 pl-3">
    <span class="heading">Add Training & Session</span>
    <span class="subheading d-flex">For Current Financial Year</span>
  </div>
</mat-card>

<div class="pad">
  <div [ngClass]="{ scroll: mobile }">
  <div
    class="note col-xs-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-start"
  >
    <img class="mr-3" src="assets/images/info_icon.svg" alt="info-icon" />
    <span class="note-text"
      >Do not enter any 'Training & Session' attended. This module will only
      include trainings & sessions those were conducted by you.
    </span>
  </div>
  <mat-dialog-content class="modal-body h-80">
    <form [formGroup]="modalForm" (ngSubmit)="onNewSubmit()">
      <div class="form-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <span class="heading-style required text-truncate"
              >Name Of Training & Sessions Conducted</span
            >
            <mat-form-field class="w-100" appearance="fill">
              <input
                class="input-text"
                type="text"
                formControlName="summary"
                matInput
                [errorStateMatcher]="matcher"
              />
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="
                modalForm.controls.summary?.errors && submitted
              "
            >
              Please Enter Activity Title
            </mat-error>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <span class="heading-style required">Session Headcount</span>
            <mat-form-field class="w-100" appearance="fill">
              <input
                class="input-text"
                type="number"
                formControlName="activityCount"
                min="0"
                oninput="validity.valid||(value='');"
                matInput
                [errorStateMatcher]="matcher"
              />
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="
                modalForm.controls.activityCount?.errors &&
                submitted
              "
            >
              Please Enter Activity Title
            </mat-error>
          </div>
        </div>

        <div class="row">
          <div
            class="col-xs-6 col-sm-12 col-md-6 col-lg-6"
            [ngClass]="{ 'mt-3': mobile }"
          >
            <span class="heading-style required">Technology Covered</span>
            <mat-form-field class="w-100" appearance="fill">
              <mat-select
                formControlName="technology"
                multiple
                disableOptionCentering
              >
                <mat-option
                  *ngFor="let posValue of posList"
                  [value]="posValue"
                  >{{ posValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="
                modalForm.controls.technology?.errors && submitted
              "
            >
              Technology is required
            </mat-error>
          </div>

          <div
            class="col-xs-6 col-sm-12 col-md-6 col-lg-6"
            *ngIf="modalForm.value.technology.includes('Other')"
          >
            <span class="heading-style required">Other</span>
            <mat-form-field class="w-100" appearance="fill">
              <input
                class="input-text"
                type="text"
                formControlName="other"
                matInput
                [errorStateMatcher]="matcher"
                placeholder="Enter Other technology"
              />
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="other_validator === true && submitted"
            >
              Other technology is required
            </mat-error>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <span class="heading-style required"
              >No. Of Training & Session Provided
            </span>
            <mat-form-field class="w-100" appearance="fill">
              <input
                class="input-text"
                type="number"
                formControlName="count"
                min="0"
                oninput="validity.valid||(value='');"
                matInput
                [errorStateMatcher]="matcher"
              />
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="
                modalForm.controls.count?.errors &&
                submitted
              "
            >
              No of Training and Session Provided
            </mat-error>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-6 col-sm-12 col-md-6 col-lg-6">
            <span class="heading-style required">Start Date</span>
            <mat-form-field
              class="w-100"
              hintLabel="(Current Financial Year only)"
              appearance="fill"
            >
              <input
                class="input-text"
                (click)="openCalender()"
                readonly
                matInput
                [matDatepicker]="picker"
                [min]="minDate"
                [max]="maxDate"
                formControlName="startDate"
                [errorStateMatcher]="matcher"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-error
              class="error-style"
              *ngIf="
                modalForm.controls.startDate?.errors && submitted
              "
            >
              Start Date is required
            </mat-error>
          </div>
          <div class="col-xs-6 col-sm-12 col-md-6 col-lg-6">
            <span class="heading-style required">End Date</span>
            <mat-form-field
              class="w-100"
              hintLabel="(Current Financial Year only)"
              appearance="fill"
            >
              <input
                class="input-text"
                (click)="openCalender2()"
                readonly
                matInput
                [matDatepicker]="picker2"
                [min]="modalForm.value.startDate"
                [max]="maxDate"
                formControlName="endDate"
                [errorStateMatcher]="matcher"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <mat-error
              class="error-style"
              *ngIf="
                modalForm.controls.endDate?.errors && submitted
              "
            >
              End Date is required
            </mat-error>
          </div>
        </div>

        <div class="row p-2">
          <div
            [ngClass]="{ 'mt-1': mobile }"
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-3"
          >
            <div>
              <span class="heading-style required goal-type-mrg"
                >Goal Type</span
              >
              <mat-radio-group formControlName="ecdc">
                <div class="row mrgn-left">
                  <div class="col-lg-6 col-md-6 col-sm-12">
                    <div
                      class="goal-type-border text-truncate"
                      [ngClass]="{ 'selected-goal': first, 'mt-1': mobile }"
                    >
                      <mat-radio-button
                        (click)="clickEc()"
                        [checked]="data.ecdc === 'EC'"
                        value="0"
                        ><span
                          class="goal-type-font mrg-right"
                          [ngClass]="{ selectTrue: first }"
                          >Engineering Council (EC)</span
                        >
                      </mat-radio-button>
                    </div>
                  </div>
                  <div
                    class="col-lg-6 col-md-6 col-sm-12"
                    [ngClass]="{ 'mt-1': mobile }"
                  >
                    <div
                      class="goal-type-border text-truncate"
                      [ngClass]="{ 'selected-goal': second }"
                    >
                      <mat-radio-button
                        (click)="clickDc()"
                        [checked]="data.ecdc === 'DC'"
                        value="1"
                        ><span
                          class="goal-type-font"
                          [ngClass]="{ selectTrue: second }"
                          >Delivery Council (DC)</span
                        >
                      </mat-radio-button>
                    </div>
                  </div>
                </div>
              </mat-radio-group>
            </div>
          </div>
          <mat-error
            class="error-style fix-top-margin"
            *ngIf="modalForm.controls.ecdc?.errors && submitted"
          >
            Goal Type is required
          </mat-error>
        </div>

        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <span class="heading-style required">Description</span>
            <img src="assets/images/info_button.svg" alt="information" title="(Max. 400 Characters Allowed)">
            <mat-form-field class="w-100" appearance="fill">
              <textarea
                rows="2"
                class="input-text"
                type="text-area"
                formControlName="description"
                maxlength="400"
                placeholder="Max 400 Characters allowed" 
                matInput
                [errorStateMatcher]="matcher"
              ></textarea>
            </mat-form-field>
            <mat-error
              class="error-style fix-top-margin"
              *ngIf="
                modalForm.controls.description?.errors && submitted
              "
            >
              Please Enter Description about Training & Session
            </mat-error>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <div class="form-buttons row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <button mat-raised-button id="add_btn" (click)="onNewSubmit()">
        SUBMIT
      </button>
    </div>
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <button (click)="onClose()" mat-raised-button id="cancel_btn">
        CANCEL
      </button>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
      <button id="draft_btn" (click)="onDraftSubmit()">
        <img class="mr-2" src="assets/images/saveasdraft.svg" />Save
        As Draft
      </button>
    </div>
  </div>
</div>
</div>
