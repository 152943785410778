<div *ngIf="this.StoreData == false" class="overlay">
  <mat-progress-spinner
    class="spinner"
    [color]="color"
    [mode]="mode"
    [value]="value"
    diameter="50"
    *ngIf="this.StoreData == false"
  ></mat-progress-spinner>
</div>
<div class="container">
  <div class="row clearfix">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div class="row pt-2 pb-2">
        <div class="d-flex justify-content-start align-items-center col-lg-2 col-md-2 col-sm-2 col-2">
          <button class="d-flex" (click)="backRoute()">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <div class="d-flex justify-content-end align-items-center col-lg-10 col-md-10 col-sm-10 col-10" >
          <nav aria-label="breadcrumb">
            <ol class="m-0 breadcrumb">
              <li *ngIf="this.viewType === 'myView'" class="breadcrumb-item non-active">
                <a (click)="backRoute()">Dashboard</a>
              </li>
              <li *ngIf="this.viewType === 'my-contribution-history' || this.viewType === 'team-contribution-history'" class="breadcrumb-item non-active">
                <a (click)="backRoute()">Contribution History</a>
              </li>
              <li *ngIf="this.viewType === 'myteam'" class="breadcrumb-item non-active">
                <a (click)="backRoute()">My Team</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Certificates
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="card">
        <div class="body" [ngClass]="{ 'table-responsive': mobile == true }">
          <div class="materialTableHeader" [ngClass]="{ 'mobile-view-header': mobile }">
            <div class="row">
              <div class="col-8">
                <ul class="header-buttons-left mt-4" [ngClass]="{ 'pl-3': mobile }" type="none">
                  <li class="dropdown">
                    <h2>Certificates</h2>
                  </li>
                </ul>
              </div>
              <div class="col-4 mt-3">
                <ul class="header-buttons mr-5">
                  <div *ngIf="this.viewType === 'myteam' ||this.viewType === 'team-contribution-history'">
                    <div class="card-head">
                      
                      <img class="profile-img" [src]='userData.imgAsset' alt="profile-image">
                      <div class="user-details">
                      {{userData.name}}
                      <p class="user-sub-details">{{userData.designation}} </p>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="this.viewType === 'myView'">
                    <button *ngIf="!mobile" mat-raised-button id="add_btn" (click)="openDialog('ADD')" class="btn">
                      ADD NEW
                    </button>
                    <div *ngIf="mobile" mat-raised-button id="add_btn_mobile" (click)="openDialog('ADD')">
                      <img src="../../../../assets/images/add_.png" />
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData(sort)" class="w-100">
            <ng-container matColumnDef="summary">
              <th class="head" mat-header-cell *matHeaderCellDef mat-sort-header="summary">
                Name Of Certification
              </th>
              <td class="fix-width-short" mat-cell *matCellDef="let element">
                {{ element.summary }}
              </td>
            </ng-container>

     
            <ng-container matColumnDef="date">
              <th class="head" mat-header-cell *matHeaderCellDef mat-sort-header="date">
                Completion Date
              </th>
              <td mat-cell *matCellDef="let element">{{ element.date }}</td>
            </ng-container>

            <ng-container matColumnDef="attachment">
              <th class="head" mat-header-cell *matHeaderCellDef mat-sort-header="attachment">
                Attachment
              </th>
              <td mat-cell class="c" *matCellDef="let element">
                {{ element.attachment }}
              </td>
            </ng-container>


            <ng-container matColumnDef="status">
              <th [hidden]="flag" class="head" mat-header-cell *matHeaderCellDef mat-sort-header="status">
                Status
              </th>
              <td [hidden]="flag" mat-cell *matCellDef="let element">
                <mat-chip *ngIf="element.status == 'DRAFTED'; else pending" id="new" selected>
                  {{ element.status }}
                </mat-chip>
                <ng-template #pending>
                  <mat-chip *ngIf="element.status == 'PENDING APPROVAL'; else approved" id="pending" selected>
                    {{ element.status }}</mat-chip>
                </ng-template>
                <ng-template #approved>
                  <mat-chip *ngIf="element.status == 'APPROVED'; else declined" id="approved" selected>
                    {{ element.status }}</mat-chip>
                </ng-template>
                <ng-template #declined>
                  <mat-chip *ngIf="element.status == 'DECLINED'" id="declined" selected>
                    {{ element.status }}</mat-chip>
                </ng-template>
              </td>
            </ng-container>
            <mat-chip-list></mat-chip-list>
     
            <ng-container matColumnDef="action">
              <th class="head text-center" id="action-cell" mat-header-cell *matHeaderCellDef>Action</th>
              <td id="action-cell" mat-cell *matCellDef="let element">
                <img *ngIf="
                    element.status == 'DRAFTED' || element.status == 'DECLINED' && (this.viewType === 'myView')
                  " (click)="openDialog('EDIT', element)" class="px-2"
                  src="../../../../assets/images/Edit.svg" />
                <img [ngClass]="{'view-adjust':element.status=='APPROVED','view-ad':element.status=='PENDING APPROVAL'} 
                "[routerLink]="['view',element.id]" src="../../../../assets/images/View.svg" class="px-2" />
                <img *ngIf="
                    element.status == 'DRAFTED' || element.status == 'PENDING APPROVAL' && (this.viewType === 'myView')
                  " (click)="routeDelete(element.id)" class="px-2"
                  src="../../../../assets/images/delete.svg" />
              </td> 
            </ng-container>

            <ng-container matColumnDef="createdOn">
              <th class="head" mat-header-cell *matHeaderCellDef mat-sort-header>
                Created On
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.myArray[0] }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <div *ngIf="!dataSource.data.length" class="no_record center-align">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img src="../../../../assets/images/cross-icon.svg" />
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                No records found!
              </div>
            </div>
          </div>
          <mat-paginator *ngIf="dataSource.data.length || (this.hideData && this.viewType === 'myteam')" [ngClass]="{ 'position-fix': mobile }" #paginator
            [pageIndex]="tablePageIndex" [pageSize]="5" showFirstLastButtons
            (page)="pageEvent = getAllCertificate($event)" [length]="tableLength">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>