import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AdminPortalService } from '../../service/admin-portal.service';
import { NgbCarousel, NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import Chart from 'chart.js/auto';
import { take } from 'rxjs/internal/operators/take';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/services/common.service';
import {
  TopPerformer,
  ContributionCardData,
  ContributionCategory,
  CategoryCount,
  UserProfile,
  FilterCriteria,
  AnalyticData,
} from 'src/app/shared/models/transaction.model';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-contributions-section',
  templateUrl: './contributions-section.component.html',
  styleUrls: ['./contributions-section.component.css'],
})
export class ContributionsSectionComponent implements OnInit, AfterViewInit {
  @Input() filterCriteria!: FilterCriteria;
  @ViewChildren('carousel') carousels!: QueryList<NgbCarousel>;
  @ViewChild('myChart', { static: false }) myChart!: ElementRef;
  @Input() dateRange: string = '';
  @Input() verticals: Array<string> = [];
  chart: any;
  topCategoriesData: Array<TopPerformer> = [];
  contrinutionData: ContributionCardData = {} as ContributionCardData;
  categoriesData: AnalyticData;
  contrinutionPercentage: CategoryCount = {} as CategoryCount;
  carouselsMap: { [key: number]: NgbCarousel } = {};
  topPerformerTotal: number = 0;
  totalCategoryCount: number = 0;
  topPerformerCategoryWise: Array<UserProfile> = [];
  screenSize: string = '';
  previousPayload: FilterCriteria = {} as FilterCriteria;
  totalSum: number = 0;
  @ViewChild('disappating_carousel', { static: false }) carousel2!: NgbCarousel;
  @ViewChild('trending_carousel', { static: false }) carousel3!: NgbCarousel;

  constructor(
    public adminPortalService: AdminPortalService,
    config: NgbCarouselConfig,
    public toastr: ToastrService,
    public commonService: CommonService,
    public responsiveSrvice: ResponsiveService
  ) {
    config.interval = 5000;
    config.keyboard = true;
    config.pauseOnHover = true;
    config.showNavigationArrows = true;
    this.categoriesData = {} as AnalyticData;
  }

  ngOnInit(): void {
    this.checkScreenStatus();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterCriteria) {
      this.updateData(changes.filterCriteria.currentValue);
    }
  }

  updateData(data: FilterCriteria): void {
    if (Object.keys(data).length !== 0) {
      this.getContributionData(data);
      this.getCategoriesData(data);
      this.getTopCategoriesData(data);
    }
  }
  checkScreenStatus(): void {
    this.responsiveSrvice.getScreenStatus().subscribe((screenSize: string) => {
      if (screenSize) {
        this.screenSize = screenSize;
      }
    });
  }
  hasTrendingCategories(): boolean {
    return this.categoriesData?.mostTrending?.length > 1;
  }
  hasDissipatingCategories(): boolean {
    return this.categoriesData?.mostDissipating?.length > 1;
  }
  hasMultipleTopPerformerContributions(contribution: any): boolean {
    return contribution?.analyticTopPerfomerContributionsDto?.length > 1;
  }

  /**
   *Provies the data for the total contributions, total contributors and of top performerns
   * @param reqObj payload object
   */
  getContributionData(reqObj: any) {
    this.adminPortalService
      .getContributionData(reqObj)
      .pipe(take(1))
      .subscribe(
        (resposne) => {
          if (resposne) {
            this.contrinutionData = resposne;

            this.totalCategoryCount = this.getTotalCategoryCount();
            setTimeout(() => {
              this.createChart();
            }, 1000);
            this.topPerformerTotal =
              this.contrinutionData.analyticTopPerfomerContributionsDto.length;
            this.contrinutionData.analyticTopPerfomerContributionsDto.forEach(
              (employee) => {
                employee.image = atob(employee.image);
              }
            );
          }
          this.widthCalculator();
        },
        (error) => {
          this.toastr.error(error.error);
        }
      );
  }

  /**
   *Provies the data of most trending and most dissipating category
   * @param reqObj payload object
   */
  getCategoriesData(reqObj: any) {
    this.adminPortalService
      .getCategoiesData(reqObj)
      .pipe(take(1))
      .subscribe(
        (data) => {
          if (data) {
            this.categoriesData = data;
            this.categoriesData.mostTrending.forEach(
              (category) =>
                (category.categoryIcon =
                  this.commonService.decodeBase64SvgToImage(
                    category.categoryIcon
                  ))
            );

            this.categoriesData.mostDissipating.forEach((category) => {
              category.categoryIcon = this.commonService.decodeBase64SvgToImage(
                category.categoryIcon
              );
            });
          }
        },
        (error) => {
          this.toastr.error(error.error);
        }
      );
  }

  /**
   *Provies the data of top performers for all the contribution categories
   * @param reqObj payload object
   */
  getTopCategoriesData(reqObj: any) {
    this.adminPortalService
      .getTopcategory(reqObj)
      .pipe(take(1))
      .subscribe(
        (data) => {
          if (data?.length) {
            this.topCategoriesData = data;
            this.topCategoriesData.forEach((category) => {
              category.categoryIcon = this.commonService.decodeBase64SvgToImage(
                category.categoryIcon
              );

              category.analyticTopPerfomerContributionsDto.forEach((emp) => {
                emp.image = atob(emp.image);
                emp.contributionImg = this.commonService.decodeBase64ToImage(
                  emp.contributionImg
                );
              });
            });
          } else {
            this.topCategoriesData = [];
          }
        },
        (error) => {
          this.toastr.error(error.error);
        }
      );
  }

  /**
   * Calcuate width of contribution progress bar
   */
  widthCalculator() {
    const totalSum = this.contrinutionData?.totalCategoryCount ?? 0;

    type CategoryKeys = keyof typeof this.contrinutionData.categoryCount;

    const categories: CategoryKeys[] = [
      'certification',
      'interview',
      'clientFeedback',
      'mentorship',
      'projects',
      'selfDevelopmentActivity',
      'teamBuildingActivity',
      'trainingSessions',
    ];

    categories.forEach((category) => {
      const count = this.contrinutionData?.categoryCount?.[category] ?? 0;
      this.contrinutionPercentage[category] =
        totalSum > 0 ? (count / totalSum) * 100 : 0;
    });
  }

  createChart() {
    const canvas: any = this.myChart.nativeElement;
    if (!canvas) {
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    if (this.chart) {
      this.chart.destroy();
    }

    canvas.width = 334;
    canvas.height = 325;

    const chartData = {
      labels: [
        'Certifications',
        'Interview Taken',
        'Self Development',
        'Mentorship',
        'Client Feedback',
        'Training & Sessions',
        'Team Building Activity',
        'Projects',
      ],
      datasets: [
        {
          label: 'Contribution category',
          data: [
            this.contrinutionData?.categoryCount?.certification,
            this.contrinutionData?.categoryCount?.interview,
            this.contrinutionData?.categoryCount?.selfDevelopmentActivity,
            this.contrinutionData?.categoryCount?.mentorship,
            this.contrinutionData?.categoryCount?.clientFeedback,
            this.contrinutionData?.categoryCount?.trainingSessions,
            this.contrinutionData?.categoryCount?.teamBuildingActivity,
            this.contrinutionData?.categoryCount?.projects,
          ],
          backgroundColor: [
            'rgba(251, 95, 95, 1)',
            'rgba(96, 168, 255, 1)',
            'rgba(76, 190, 160, 1)',
            'rgba(253, 157, 233, 1)',
            'rgba(253, 169, 62, 1)',
            'rgba(180, 180, 180, 1)',
            'rgba(255, 200, 104, 1)',
            'rgba(194, 135, 253, 1)',
          ],
          hoverOffset: 4,
        },
      ],
    };

    this.chart = new Chart(ctx, {
      type: 'doughnut',
      data: chartData,
      options: {
        maintainAspectRatio: false,
        aspectRatio: 2.5,
        cutout: 125,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });
  }

  ngAfterViewInit() {
    this.carousels.changes.subscribe(() => {
      this.mapCarousels();
    });
    this.mapCarousels();
  }

  /**
   * Moves the Dissipating carousel to the previous slide.
   */
  previousDissipatingCarousel() {
    this.carousel2.prev();
  }

  /**
   * Moves the Dissipating carousel to the next slide.
   */
  nextDissipatingCarousel() {
    this.carousel2.next();
  }

  /**
   * Moves the trending carousel to the previous slide.
   */
  previousTrendingCarousel() {
    this.carousel3.prev();
  }

  /**
   * Moves the trending carousel to the next slide.
   */
  nextTrendingCarousel() {
    this.carousel3.next();
  }

  /**
   * Maps the carousels to their respective indices.
   */
  mapCarousels() {
    this.carousels.forEach((carousel, index) => {
      this.carouselsMap[index] = carousel;
    });
  }

  /**
   * Moves the carousel to the next slide.
   */
  nextSlide(index: number) {
    const carousel = this.carouselsMap[index];
    if (carousel) {
      carousel.next();
    }
  }

  /**
   * Moves the carousel to the previous slide.
   */
  prevSlide(index: number) {
    const carousel = this.carouselsMap[index];
    if (carousel) {
      carousel.prev();
    }
  }

  /**
   * Provies the summation of all 8 contribution categories
   * @returns number
   */
  getTotalCategoryCount(): number {
    return (
      (this.contrinutionData?.categoryCount?.certification || 0) +
      (this.contrinutionData?.categoryCount?.interview || 0) +
      (this.contrinutionData?.categoryCount?.selfDevelopmentActivity || 0) +
      (this.contrinutionData?.categoryCount?.mentorship || 0) +
      (this.contrinutionData?.categoryCount?.clientFeedback || 0) +
      (this.contrinutionData?.categoryCount?.trainingSessions || 0) +
      (this.contrinutionData?.categoryCount?.teamBuildingActivity || 0) +
      (this.contrinutionData?.categoryCount?.projects || 0)
    );
  }

  isScreenSizeMedium(): boolean {
    return this.screenSize === 'md';
  }
}
