import { ProjectsModule } from './modules/projects/projects.module';
import { SelfDevelopmentModuleModule } from './modules/self-development-module/self-development-module.module';
import { TrainingandsessionsModule } from './modules/trainingandsessions/trainingandsessions.module';
import { SharedModule } from "./shared/shared.module";
import { NgModule } from "@angular/core";
import { LeaderboardSidebarComponent } from './Layout/leaderboard/leaderboard-sidebar/leaderboard-sidebar.component';
import { LeaderboardDashComponent } from './Layout/leaderboard/leaderboard-dash/leaderboard-dash.component';
import { ApiInterceptorService } from "./services/api-interceptor.service";
import { NominationJourneyComponent } from './modules/hr-recommendation/components/nomination-journey/nomination-journey.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from "@angular/material/list";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { MatTableModule } from "@angular/material/table";
import { AppRoutingModule } from "./app-routing.module";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { ResponsiveService } from "./services/responsive.service";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatDialogModule } from "@angular/material/dialog";
import { ToastrModule } from "ngx-toastr";
import { CertificateModule } from "./modules/certificate/certificate.module";
import { CertificationModule } from "./modules/certification/certification.module";
import { NavbarComponent } from "./navbar/navbar.component";
import { environment } from "src/environments/environment";
import { FeedbackTableService } from "src/app/modules/client-feedback/service/feedback-table.service";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { CertificateTableService } from "src/app/modules/certificate/service/certificate-table.service";
import { MatCardModule } from "@angular/material/card";
import { SocialAuthServiceConfig } from "angularx-social-login";
import { SocialLoginModule, GoogleLoginProvider } from "angularx-social-login";
import { PdfViewerModule } from "ng2-pdf-viewer";

import { MAT_RADIO_DEFAULT_OPTIONS } from "@angular/material/radio";
import { SocketService } from "./services/socket.service";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatBadgeModule } from "@angular/material/badge";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatDividerModule } from "@angular/material/divider";

import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoaderComponentComponent } from "./modules/loader/loader-component/loader-component.component";
import { LoaderService } from "./modules/loader/service/loader.service";
import { LoaderInterceptor } from "./modules/loader/interceptor/loader.interceptor";

import { BnNgIdleService } from "bn-ng-idle";
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./app.config";
import {
  MsalGuard,
  MsalBroadcastService,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import { FooterComponent } from './Layout/footer/footer.component';
import { JoyrideModule } from "ngx-joyride";
import { AdminPortalModule } from "./modules/admin-portal/admin-portal.module";
import { RoutingConfirmationModalComponent } from "./shared/components/routing-confirmation-modal/routing-confirmation-modal.component";
import { HeaderSectionComponent } from './Layout/header-section/header-section.component';
import { SideNavbarComponent } from './Layout/side-navbar/side-navbar.component';
import { NgOptimizedImage } from '@angular/common';
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoaderComponentComponent,
    FooterComponent,
    RoutingConfirmationModalComponent,
    HeaderSectionComponent,
    SideNavbarComponent,
    LeaderboardSidebarComponent,
    LeaderboardDashComponent,
    NominationJourneyComponent
  ],
  imports: [
    SharedModule,
    BrowserModule,
    AppRoutingModule,
    AdminPortalModule,
    MsalModule,
    SocialLoginModule,
    FormsModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    NgbModule,
    CertificateModule,
    CertificationModule,
    TrainingandsessionsModule,
    SelfDevelopmentModuleModule,
    ProjectsModule,
    HttpClientModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatCardModule,
    PdfViewerModule,
    NgOptimizedImage,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
    MatSelectModule,
    MatMenuModule,
    MatBadgeModule,
    MatExpansionModule,
    MatPaginatorModule,
    MatDividerModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    JoyrideModule.forRoot(),
  ],
  providers: [
    BnNgIdleService,
    LoaderService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: "BASE_API_URL", useValue: environment.apiUrl },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ResponsiveService,
    SocketService,
    FeedbackTableService,
    CertificateTableService,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "379534445283-aennlqpsi8b2vcl6a5r97dcg0ch8dj05.apps.googleusercontent.com"
            ),
          },
        ],
      },
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }