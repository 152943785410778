import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  AnalyticData,
  BrandsData,
  ContributionExcelData,
  FilterCriteria,
  MostUsedBrandTable,
  TimeData,
  Transaction,
  TransactionData,
} from 'src/app/shared/models/transaction.model';

import {
  ContributionCardData,
  ContributionCategory,
  TopPerformer,
} from 'src/app/shared/models/transaction.model';

@Injectable({
  providedIn: 'root',
})
export class AdminPortalService {
  private allotmentType: string;
  private dcTechList$: Observable<Array<string>> | undefined;
  private ecTechList$: Observable<Array<string>> | undefined;

  filterCriteria: BehaviorSubject<FilterCriteria> =
    new BehaviorSubject<FilterCriteria>({} as FilterCriteria);

  constructor(private http: HttpClient) {
    this.allotmentType = '';
  }
  getAllotmentType() {
    return this.allotmentType;
  }
  setAllotmentType(data: string) {
    this.allotmentType = data;
  }
  getAllSelectedEmployees(empIdObjectArray: any): Observable<any> {
    return this.http.post('contripoint/fetch/employeePoints', empIdObjectArray);
  }
  allotBudgetingData(dataObj: any): Observable<any> {
    return this.http.post('contripoint/create/budgetReward', dataObj);
  }
  getCsvData(req: any): Observable<any> {
    return this.http.post<any>('contripoint/import/data/from/XLSX', req);
  }
  sendData(obj: any): Observable<any> {
    return this.http.post<any>('contripoint/create/occasionalReward', obj);
  }
  fetchBudgetHistory(obj: any): Observable<any> {
    return this.http.post<any>('contripoint/fetch/budgetReward', obj);
  }
  getOcassionalRewardHistory(obj: any): Observable<any> {
    return this.http.post<any>('contripoint/fetch/occasionalReward', obj);
  }
  getAllBrands(): Observable<any> {
    let obj = '';
    return this.http.post<any>('contripoint/get/all/brands', obj);
  }
  getActiveBrands(id: number): Observable<any> {
    let obj = id;
    return this.http.post<any>('contripoint/select/active/brands', obj);
  }
  removeActiveBrands(id: number): Observable<any> {
    let obj = id;
    return this.http.post<any>('contripoint/deactivate/brands', obj);
  }
  getBudgetDataFromXlsx(req: FormData) {
    return this.http.post<Map<String, Number>>(
      'contripoint/import/data/from/XLSX/budget',
      req
    );
  }
  getUniqueBrand(data: any) {
    return this.http.post<any>('contripoint/get/unique/brand', data);
  }
  setUniqueBrand(req: any) {
    return this.http.post<any>('contripoint/set/unique/brand', req);
  }
  getRewardAmount() {
    return this.http.get<any>('contripoint/rewards/distributed/by/admin');
  }
  getGeminiWalletAmount() {
    return this.http.get<any>('contripoint/getCredit');
  }
  getTitleList() {
    return this.http.get<Array<string>>('contripoint/title/dropdown');
  }

  getContributionData(data: FilterCriteria): Observable<ContributionCardData> {
    return this.http.post<ContributionCardData>(
      'contripoint/get/count/declined/approved',
      data
    );
  }
  getCategoiesData(data: FilterCriteria): Observable<AnalyticData> {
    return this.http.post<AnalyticData>(
      'contripoint/get/trending/dissipating',
      data
    );
  }

  getTopcategory(data: FilterCriteria): Observable<Array<TopPerformer>> {
    return this.http.post<Array<TopPerformer>>(
      'contripoint/get/top/category',
      data
    );
  }

  getDcTechList(): Observable<Array<string>> {
    if (!this.dcTechList$) {
      this.dcTechList$ = this.http
        .get<Array<string>>('contripoint/fetch/dc-dropdown')
        .pipe(shareReplay(1));
    }
    return this.dcTechList$;
  }

  getEcTechList(): Observable<Array<string>> {
    if (!this.ecTechList$) {
      this.ecTechList$ = this.http
        .get<Array<string>>('contripoint/fetch/ec-dropdown')
        .pipe(shareReplay(1));
    }
    return this.ecTechList$;
  }

  getTransactionReport(): Observable<TransactionData> {
    return this.http.get<TransactionData>('contripoint/get/transaction/report');
  }
  activityGraph(data: TimeData): Observable<Array<BrandsData>> {
    return this.http.post<Array<BrandsData>>(
      'contripoint/get/activity/graph',
      data
    );
  }

  yearsData(): Observable<Array<string>> {
    return this.http.get<Array<string>>('contripoint/year/dropdown');
  }

  mostUsedBrands(data: TimeData): Observable<Array<MostUsedBrandTable>> {
    return this.http.post<Array<MostUsedBrandTable>>(
      'contripoint/most/used/brands',
      data
    );
  }

  transactionDetails(data: TimeData): Observable<Array<Transaction>> {
    return this.http.post<Array<Transaction>>(
      'contripoint/download/transactions',
      data
    );
  }

  contributionDetails(
    data: FilterCriteria
  ): Observable<Array<ContributionExcelData>> {
    return this.http.post<Array<ContributionExcelData>>(
      'contripoint/download/contributions',
      data
    );
  }
}
