import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AllBrandsComponent } from './admin-brand-selection/all-brands/all-brands.component';
import { MatDialog } from '@angular/material/dialog';
import { RoutingConfirmationModalComponent } from 'src/app/shared/components/routing-confirmation-modal/routing-confirmation-modal.component';
@Injectable({
  providedIn: 'root'
})
export class UnsavedGuard implements CanDeactivate<AllBrandsComponent> {
  constructor(private dialog:MatDialog){}
  
  canDeactivate(
    component: AllBrandsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if(component.isDirty){
        // return window.confirm("you have made changes")
const dialogRef=this.dialog.open(RoutingConfirmationModalComponent,{width:'512px',disableClose:true});
return dialogRef.afterClosed()
      }
  
    return true;
  }
  
}
