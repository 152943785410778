import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contribution-modal',
  templateUrl: './contribution-modal.component.html',
  styleUrls: ['./contribution-modal.component.css']
})
export class ContributionModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
