<div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <button type="button" class="close" (click)="onClose(false)" aria-label="Close">
            <span aria-hidden="true"><i class="material-icons">close</i></span>
        </button>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-center ml-1">
        <img src="../../../../assets/images/warning-image.png">
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-center ml-1 mt-3">
        <p>{{data.title}}</p>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-center ml-1 mt-1" *ngIf="data.countTitle">
        <p class="countTitle">{{data.countTitle}}</p>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-center ml-1" *ngIf="data.countTitle1">
        <p class="countTitle">{{data.countTitle1}}</p>
    </div>
    <div *ngIf="data.button1!=='warning'"  class="form-buttons row mt-3">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 ">
            <button type="submit" mat-raised-button id="yes_btn" (click)="onClose(true)">{{data.button1}}</button>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 ">
            <button (click)="onClose(false)" mat-raised-button id="no_btn">{{data.button2}}</button>
        </div>
    </div>
    <div *ngIf="data.button1==='warning'" class="btn-adjust row mt-3">
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-6 ">
            <button (click)="onClose(false)" mat-raised-button id="no_btn">{{data.button2}}</button>
        </div>
    </div>
</div>