import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommentModalComponent } from './components/comment-modal/comment-modal.component';
import { CommonModalComponent } from './components/common-modal/common-modal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { WelcomeModalComponent } from './components/welcome-modal/welcome-modal.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatDatepickerModule,
  MatDateRangeInput,
} from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatMenuModule } from '@angular/material/menu';
import { MatTab, MatTabsModule } from '@angular/material/tabs';
import { MatDividerModule } from '@angular/material/divider';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatSortModule } from '@angular/material/sort';
import { HttpClientModule } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatStepperModule } from '@angular/material/stepper';
import { SuccessfullModalComponent } from './components/successfull-modal/successfull-modal.component';
import { GenericContributionTableComponent } from './components/generic-contribution-table/generic-contribution-table.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { GenericNominationDetailsComponent } from './components/generic-nomination-details/generic-nomination-details.component';
import { GenericNominationActionComponent } from './components/generic-nomination-action/generic-nomination-action.component';
import { GenericViewModalComponent } from './components/generic-view-modal/generic-view-modal.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CountdownModule } from '@ciri/ngx-countdown';
import { QuarterSelectFilterModalComponent } from './components/quarter-select-filter-modal/quarter-select-filter-modal.component';
import { CommonContributionTableComponent } from './components/common-contribution-table/common-contribution-table.component';

@NgModule({
  declarations: [
    UnderMaintenanceComponent,
    WelcomeModalComponent,
    DeleteModalComponent,
    CommonModalComponent,
    SuccessModalComponent,
    ConfirmationModalComponent,
    CommentModalComponent,
    SuccessfullModalComponent,
    GenericContributionTableComponent,
    GenericModalComponent,
    GenericNominationDetailsComponent,
    GenericNominationActionComponent,
    GenericViewModalComponent,
    QuarterSelectFilterModalComponent,
    CommonContributionTableComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatGridListModule,
    MatCardModule,
    MatIconModule,
    NgbModule,
    MatTableModule,
    MatSidenavModule,
    MatListModule,
    PdfViewerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatDividerModule,
    MatChipsModule,
    MatRadioModule,
    MatSortModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatDividerModule,
    MatSlideToggleModule,
    Ng2SearchPipeModule,
    NgxMaterialTimepickerModule,
    CountdownModule,
  ],
  exports: [
    UnderMaintenanceComponent,
    WelcomeModalComponent,
    DeleteModalComponent,
    CommonModalComponent,
    SuccessModalComponent,
    GenericContributionTableComponent,
    ConfirmationModalComponent,
    CommentModalComponent,
    CommonModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatGridListModule,
    MatCardModule,
    MatIconModule,
    NgbModule,
    MatTableModule,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDialogModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    MatMenuModule,
    MatDividerModule,
    MatChipsModule,
    MatRadioModule,
    MatSortModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatStepperModule,
    MatDividerModule,
    MatSlideToggleModule,
    Ng2SearchPipeModule,
    NgxMaterialTimepickerModule,
    CountdownModule,
    CommonContributionTableComponent,
  ],
})
export class SharedModule {}
