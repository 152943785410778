import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private http: HttpClient) { }
  getAllProject(empEmail: String, pageNo: Number,years:string,status:string): Observable<any> {
    let obj = {
      'empEmail': empEmail,
      'pageNo': pageNo.toString(),
      'years': years,
      'status':status
    }
    return this.http.post('contripoint/project/byYear', obj);

  }
  createProject(createInterviewRequestBody: any) {
    return this.http.post<any>('contripoint/project/add', createInterviewRequestBody);
  }
  saveAsDraft(createInterviewRequestBody: any) {
    return this.http.post<any>('contripoint/project/drafts', createInterviewRequestBody);
  }
  getSelectedProjectView(projectId: number) {
    let reqParam=new HttpParams().set('projectId',projectId);
     return this.http.get<any>('contripoint/project/view', {'params':reqParam});
    }
    deleteproject(projectId:Number){
      return this.http.delete<any>('contripoint/project/delete/'+projectId);
    }
  
}
