import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MentorshipService {

  constructor(private http: HttpClient) { }
  getAllMentorship(empEmail: String, pageNo: number,years:string,status:string): Observable<any> {
    let obj = {
      'empEmail': empEmail,
      'pageNo': pageNo.toString(),
      'years':years,
      'status':status
    }
    return this.http.post('contripoint/mentorship/byYear', obj);
  }

  createMentorship(createMentorshipRequestBody: any) {
    return this.http.post<any>('contripoint/mentorship/add', createMentorshipRequestBody);
  }
  viewMentorship(mentorshipId: any) {
    let reqParam=new HttpParams().set('mentorshipId',mentorshipId);
    return this.http.get<any>('contripoint//mentorship/view', {'params':reqParam});
  }
  saveAsDraft(createMentorshipRequestBody: any) {
    return this.http.post<any>('contripoint/mentorship/drafts', createMentorshipRequestBody);
  }
  getSelectedMentorshipView(mentorshipId: number) {
    let reqParam=new HttpParams().set('mentorshipId',mentorshipId);
    return this.http.get<any>('contripoint/mentorship/view', {'params':reqParam});
  }

  getTechnologies(): Observable<any> {
  return this.http.get<any>('contripoint/technologies/view');
  }
  deletementorship(mentorshipId:Number){
    return this.http.delete<any>('contripoint/mentorship/delete/'+mentorshipId);
  }
  getEmployees(empEmail:String){
    let obj = {
      empEmail:empEmail
    }
    return this.http.post<any>('contripoint/fetch/filtered/dropdown',obj);
  }

  employeedropdown(): Observable<any> {
    let obj = {
    }
    return this.http.get<any>('contripoint/fetch/employee/dropdown', obj);
  }

}