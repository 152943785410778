<mat-sidenav-container>
  <mat-sidenav #sidenav [(opened)]="opened" position="end" class="side">
    <div class="container">
      <div class="row mt-2">
        <div class="filter-heading col-lg-10 col-sm-10 col-md-10 col-xs-10">
          Filters
        </div>
        <div class="close-styling">
          <button
            type="button"
            class="close"
            (click)="sidenav.close()"
            aria-label="Close"
          >
            <span aria-hidden="true"><i class="material-icons">close</i></span>
          </button>
        </div>
      </div>

      <div class="mt-5">
        <form [formGroup]="filterForm" (ngSubmit)="onNewSubmit()">
          <mat-form-field appearance="fill" class="example-form-field">
            <mat-label>Select date range</mat-label>
            <mat-date-range-input [rangePicker]="rangePicker">
              <input
                matStartDate
                placeholder="Start date"
                formControlName="Startdate"
              />
              <input
                matEndDate
                placeholder="End date"
                formControlName="Enddate"
              />
            </mat-date-range-input>

            <mat-datepicker-toggle
              matSuffix
              [for]="rangePicker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #rangePicker>
              <mat-date-range-picker-actions>
                <button
                  mat-raised-button
                  color="primary"
                  matDateRangePickerApply
                  class="add_btn"
                  (click)="onNewSubmit()"
                >
                  APPLY
                </button>
                <button mat-button matDateRangePickerCancel class="cancel_btn">
                  CLOSE
                </button>
              </mat-date-range-picker-actions>
            </mat-date-range-picker>
          </mat-form-field>
        </form>
      </div>

      <mat-divider class="mt-4"></mat-divider>

      <form [formGroup]="employeForm">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label class="label-text">Participant Category *</mat-label>
              <mat-select
                formControlName="employ_select"
                (selectionChange)="listLoad()"
                placeholder="Select"
              >
                <mat-option
                  class="input-text"
                  *ngFor="let select of select_emp"
                  [value]="select"
                >
                  {{ select }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 voter-box">
            <mat-form-field class="w-100" appearance="outline">
              <mat-label>Eligible Participants *</mat-label>
              <input
                class="input-text"
                type="text"
                #multiUserSearch
                (keydown)="onInputChange()"
                matInput
              />
              <mat-selection-list
                (selectionChange)="
                  selectionChangeNewParticipant(
                    $event,
                    this.employeForm.value.employ_select
                  )
                "
                formControlName="employees"
              >
                <mat-list-option
                  *ngFor="let val of employees_list"
                  checkboxPosition="before"
                  [value]="val"
                  [selected]="participantSet.get(val.id)"
                  class="option-styling"
                >
                  {{ val.id + " " }}{{ val.name }}
                </mat-list-option>
              </mat-selection-list>
            </mat-form-field>
          </div>
        </div>
      </form>
      <div class="form-buttons row mt-2">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <button mat-raised-button id="add_btn" (click)="onApply()">
            APPLY
          </button>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <button mat-raised-button id="cancel" (click)="onClose()">
            CANCEL
          </button>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <button></button>
    <div class="container">
      <div class="row">
        <div class="btn2">
          <button class="filter-btn" (click)="sidenav.open()">
            <img src="../../assets/images/filter.png" />
          </button>
        </div>
        <div class="btn1">
          <button (click)="download()" class="list-btn">
            <img src="../../assets/images/download-arrow.png" />
            <span class="ml-2">Excel</span>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 col-sm-4 col-md-4 col-xs-4">
          <mat-card>
            <mat-card-title>Total Contributions</mat-card-title>
            <mat-card-subtitle>15490</mat-card-subtitle>
          </mat-card>
        </div>
        <div class="col-lg-4 col-sm-4 col-md-4 col-xs-4">
          <mat-card>
            <mat-card-title>Total Contribution Categories</mat-card-title>
            <mat-card-subtitle>08</mat-card-subtitle>
          </mat-card>
        </div>
        <div class="col-lg-4 col-sm-4 col-md-4 col-xs-4">
          <mat-card>
            <mat-card-title>Total Number Of Contributors</mat-card-title>
            <mat-card-subtitle>6580</mat-card-subtitle>
          </mat-card>
        </div>
      </div>
      <div class="row mr-bot">
        <div class="col-lg-6 col-md-12 Col-sm-12 mb-sm-31">
          <mat-card class="contributor">
            <div class="mt-3 mrg-b">
              <mat-card-title class="title ml-4"
                >Top Contributor
              </mat-card-title>
            </div>
            <div [ngClass]="{ scroll: this.len > 3 }">
              <ul type="none" class="mt-3" *ngFor="let val of data_limit">
                <div class="row">
                  <div
                    *ngIf="val.position != null"
                    class="col-lg-3 col-md-3 col-sm-3 col-xs-3 sty"
                  >
                    <li
                      class="parent-styling"
                      *ngIf="val.position == 1; else second"
                    >
                      {{ val.position | positionSuffix }}
                      <img
                        class="img_leaders ml-3 image-1"
                        [src]="val.imgAsset"
                      />
                      <img
                        class="image-2 ml-4 mb-1"
                        src="assets/images/badge-gold.svg"
                      />
                    </li>
                    <ng-template #second>
                      <li
                        class="parent-styling"
                        *ngIf="val.position == 2; else third"
                      >
                        {{ val.position | positionSuffix }}
                        <img
                          class="img_leaders ml-3 image-1"
                          [src]="val.imgAsset"
                        />
                        <img
                          class="mb-2 ml-2 image-3"
                          src="assets/images/badge-silver.svg"
                        />
                      </li>
                    </ng-template>
                    <ng-template #third>
                      <li class="parent-styling" *ngIf="val.position == 3">
                        {{ val.position | positionSuffix }}
                        <img
                          class="img_leaders ml-3 image-1"
                          [src]="val.imgAsset"
                        />
                        <img
                          class="ml-2 mb-2 image-3"
                          src="assets/images/badge-bronze.svg"
                        />
                      </li>
                    </ng-template>
                  </div>

                  <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <li class="pos_name">{{ val.name }}</li>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <li *ngIf="val.totalPoints != null" class="pos_role">
                          {{ val.designation }}{{ "&ensp;" }}| {{ "&nbsp;"
                          }}{{ val.totalPoints + " points" }}
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </mat-card>
        </div>
        <div class="col-lg-6 col-sm-12 col-md-12 col-xs-12 mr-top">
          <mat-card> Bar graph </mat-card>
        </div>
      </div>

      <div class="card">
        <div class="body" [ngClass]="{ 'table-responsive': mobile == true }">
          <div
            class="materialTableHeader"
            [ngClass]="{ 'mobile-view-header': mobile }"
          >
            <div class="row">
              <div class="col-12">
                <ul
                  class="header-buttons-left mt-4"
                  [ngClass]="{ 'pl-3': mobile }"
                  type="none"
                >
                  <li class="dropdown">
                    <h2>Contribution Category</h2>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <table mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="S.no">
              <th class="head" mat-header-cell *matHeaderCellDef>S.No.</th>
              <td mat-cell *matCellDef="let element">{{ element.position }}</td>
            </ng-container>

            <ng-container matColumnDef="Contribution category">
              <th class="head" mat-header-cell *matHeaderCellDef>
                Contribution category
              </th>
              <td class="myDesc" mat-cell *matCellDef="let element">
                {{ element.name }} >
              </td>
            </ng-container>

            <ng-container matColumnDef="Description">
              <th class="head" mat-header-cell *matHeaderCellDef>
                Description
              </th>
              <td class="myDesc" mat-cell *matCellDef="let element">
                {{ element.symbol }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Contribution By">
              <th class="head" mat-header-cell *matHeaderCellDef>
                Contribution By
              </th>
              <td class="myDesc" mat-cell *matCellDef="let element">
                {{ element.code }}
              </td>
            </ng-container>

            <ng-container matColumnDef="Points">
              <th class="head" mat-header-cell *matHeaderCellDef>Points</th>
              <td class="myDesc" mat-cell *matCellDef="let element">
                {{ element.points }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
