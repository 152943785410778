<div *ngIf="!this.StoreData" class="overlay">
  <mat-progress-spinner
    class="spinner"
    [color]="color"
    [mode]="mode"
    [value]="value"
    diameter="50"
  ></mat-progress-spinner>
</div>
<mat-divider></mat-divider>

<div class="container">
  <form [formGroup]="rewardForm">
    <div class="mt-3 item">
      <p class="reward">Occasional Reward</p>
      <div class="row">
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6">
          <mat-form-field class="text-left" appearance="fill">
            <mat-label>Title</mat-label>
            <mat-select
              formControlName="title"
              placeholder="Select"
              panelClass="title-dropdown"
              [errorStateMatcher]="matcher"
            >
              <mat-option
                class="input-text"
                *ngFor="let titles of titleList"
                [value]="titles"
              >
                {{ titles }}
              </mat-option>
            </mat-select>
            <mat-error
              class="error-style"
              *ngIf="rewardForm.get('title')?.hasError('required') && submitted"
            >
              Title is required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6">
          <button
            type="button"
            *ngIf="!mobile"
            (click)="openDialog()"
            class="excel_btn float-right"
          >
            Import Data from Excel
          </button>

          <div *ngIf="mobile" (click)="openDialog()" class="text-right">
            <img src="/assets/images/add_.png" />
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <mat-form-field class="w-100" appearance="fill">
            <mat-label class="label-text">Description </mat-label>
            <textarea
              class="desc-text"
              rows="4"
              type="text"
              formControlName="description"
              [maxLength]="250"
              matInput
              placeholder="Enter Description"
            ></textarea>
          </mat-form-field>
          <mat-error
            class="error-style fix-top-margin"
            *ngIf="
              rewardForm.get('description')?.hasError('required') && submitted
            "
          >
            Description is required.
          </mat-error>
          <p class="helper-text">(maximum 250 characters)</p>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="body" [ngClass]="{ 'table-responsive': mobile }">
  <table mat-table [dataSource]="dataSource" class="w-100">
    <ng-container matColumnDef="emailId">
      <th class="head" mat-header-cell *matHeaderCellDef>Email Id</th>
      <td class="fix-width-short" mat-cell *matCellDef="let element">
        {{ element?.emailId }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th class="head" mat-header-cell *matHeaderCellDef>Amount(in Rs)</th>
      <td class="fix-width-short" mat-cell *matCellDef="let element">
        {{ element?.amount }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <div *ngIf="!dataSource.data.length" class="no_record center-align">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <img src="/assets/images/cross-icon.svg" />
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        No records found!
      </div>
    </div>
  </div>

  <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
  </mat-paginator>
  <p class="amt">Total Amount={{ this.totalAmount }}</p>
  <div class="form-buttons row pd">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <button mat-raised-button id="allot" (click)="onNewSubmit()">
        ALLOT
      </button>
    </div>
  </div>
</div>
