import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PageEvent } from '@angular/material/paginator';

import { CertificateTableData } from './../../models/team-contribution-history.model';
import { Router } from '@angular/router';
import { ResponsiveService } from 'src/app/services/responsive.service';

import {
  ClientFeedbackTableData,
  InterviewTableData,
  MentorshipTableData,
  ProjectTableData,
  SelfDevTable,
  TeamBuildingTableData,
  TrainingAndSession,
} from './../../models/team-contribution-history.model';
@Component({
  selector: 'app-common-contribution-table',
  templateUrl: './common-contribution-table.component.html',
  styleUrls: ['./common-contribution-table.component.css'],
})
export class CommonContributionTableComponent implements OnInit {
  tablePageIndex: number;
  pageEvent?: PageEvent;
  dataSource: any;
  screenSize: string;
  @Input() ContributionType: string = '';
  @Output() viewEventEmitter: EventEmitter<number>;
  @Output() pageChangeEmitter: EventEmitter<number>;
  @Input() tableLength: number = 0;
  @Input() tableData!: any;
  @Input() displayedColumns: string[] = [];
  @Input() columnHeaders: { [key: string]: string } = {};
  constructor(
    public router: Router,
    public responsiveService: ResponsiveService
  ) {
    this.tablePageIndex = 0;
    this.screenSize = '';
    this.viewEventEmitter = new EventEmitter<number>();
    this.pageChangeEmitter = new EventEmitter<number>();
  }
  ngOnInit(): void {
    this.checkScreenStatus();
    this.onResize();
  }
  /**
   * Emits the event to view a certificate by its ID.
   * @param {number} id - The ID of the certificate to view.
   */
  viewCertificate(id: number): void {
    this.viewEventEmitter.emit(id);
  }

  /**
   * Subscribes to screen size changes and updates the screen size property.
   */
  checkScreenStatus(): void {
    this.responsiveService.getScreenStatus().subscribe((screenSize: string) => {
      if (screenSize) {
        this.screenSize = screenSize;
      }
    });
  }

  /**
   * Checks if the current screen size is medium or smaller.
   * @returns {boolean} - Returns true if the screen size is 'md', 'sm', 'xsm', or 'xs'.
   */
  isScreenSizeMd(): boolean {
    const isMdOrSmaller = ['md', 'sm', 'xsm', 'xs'].includes(this.screenSize);
    return isMdOrSmaller;
  }

  /**
   * Triggers a check for the current screen width.
   */
  onResize(): void {
    this.responsiveService.checkWidth();
  }

  /**
   * Updates the table data status and sets the appropriate data source based on the contribution type.
   */
  getDataType(): void {
    const statusMap: { [key: string]: string } = {
      'PENDING APPROVAL': 'Pending',
      APPROVED: 'Approved',
      DECLINED: 'Declined',
      DRAFTED: 'Drafted',
    };

    this.tableData.forEach((item: { status: string }) => {
      if (item.status in statusMap) {
        item.status = statusMap[item.status];
      }
    });
    switch (this.ContributionType) {
      case 'Certificates':
        this.dataSource = new MatTableDataSource<CertificateTableData>(
          this.tableData
        );
        break;
      case 'Projects':
        this.dataSource = new MatTableDataSource<ProjectTableData>(
          this.tableData
        );
        break;
      case 'Training & Sessions':
        this.dataSource = new MatTableDataSource<TrainingAndSession>(
          this.tableData
        );
        break;
      case 'Interview Taken':
        this.dataSource = new MatTableDataSource<InterviewTableData>(
          this.tableData
        );
        break;
      case 'Mentorship':
        this.dataSource = new MatTableDataSource<MentorshipTableData>(
          this.tableData
        );
        break;
      case 'Team Building Activity':
        this.dataSource = new MatTableDataSource<TeamBuildingTableData>(
          this.tableData
        );
        break;
      case 'Client Feedback':
        this.dataSource = new MatTableDataSource<ClientFeedbackTableData>(
          this.tableData
        );
        break;
      default:
        this.dataSource = new MatTableDataSource<SelfDevTable>(this.tableData);
        break;
    }
  }

  /**
   * Handles changes to input properties and updates the data type if tableData changes.
   * @param {SimpleChanges} changes - The changes to the component's inputs.
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableData) {
      this.getDataType();
    }
  }

  /**
   * Handles pagination and emits an event when the page changes.
   * @param {PageEvent} [event] - The pagination event.
   * @returns {PageEvent} - Returns the page event.
   */
  getAllCertificates(event?: PageEvent): PageEvent {
    if (event) {
      this.tablePageIndex = event.pageIndex;
      this.pageChangeEmitter.emit(event.pageIndex);
    }
    return new PageEvent();
  }

  /**
   * Navigates back to the contribution history dashboard.
   */
  backRoute(): void {
    this.router.navigate(['contribution-history/dashboard']);
  }
}
