
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "src/app/shared/shared.module";
import { TrainingandsessionsRoutingModule } from './trainingandsessions-routing.module';
import { TrainingSessionsDashboardComponent } from './training-sessions-dashboard/training-sessions-dashboard.component';
import { TrainingSessionFormComponent } from './training-session-form/training-session-form.component';
import { TrainingSessionViewComponent } from './training-session-view/training-session-view.component';


@NgModule({
  declarations: [
    TrainingSessionsDashboardComponent,
    TrainingSessionFormComponent,
    TrainingSessionViewComponent
  ],
  imports: [
    CommonModule,
    TrainingandsessionsRoutingModule,
    SharedModule
  ]
})
export class TrainingandsessionsModule { }
