import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import * as moment from 'moment';
import { take } from 'rxjs/operators';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CommonService } from 'src/app/services/common.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { DeleteModalComponent } from 'src/app/shared/components/delete-modal/delete-modal.component';
import {
  certificate_data,
  GenericTable,
} from 'src/app/shared/models/certificate.model';
import { CertificationFormComponent } from '../certification-form/certification-form.component';
import { CertificationService } from '../service/certification.service';
import { Router } from '@angular/router';
import { CertificationViewComponent } from '../certification-view/certification-view.component';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProfileData } from 'src/app/shared/models/profile.model';

@Component({
  selector: 'app-certification-dashboard',
  templateUrl: './certification-dashboard.component.html',
  styleUrls: ['./certification-dashboard.component.css'],
})
export class CertificationDashboardComponent implements OnInit {
  pageEvent?: PageEvent;
  tableLength: number;
  tablePageIndex: number;
  mobile: boolean;
  email: String;
  fromDate: String;
  toDate: String;
  storeData: boolean;
  mode: ProgressSpinnerMode;
  value: number;
  color: ThemePalette;
  allCertificatesBasicData: GenericTable[];
  allCertificateDetails: certificate_data[];
  status: string;
  screenSize: string;
  ecdcMap = {
    EC: 'Engineering Council',
    DC: 'Delivery Council',
  };
  constructor(
    private commonService: CommonService,
    private certificationService: CertificationService,
    private authService: AuthUserService,
    private responsiveService: ResponsiveService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.tableLength = 0;
    this.tablePageIndex = 0;
    this.email = '';
    this.allCertificatesBasicData = [] as GenericTable[];
    this.fromDate='';
    this.toDate='';
    this.status = 'allStatus';
    this.screenSize = '';
    this.mobile = false;
    this.color = 'primary';
    this.mode = 'indeterminate';
    this.value = 50;
    this.storeData = true;
    this.allCertificateDetails = [] as certificate_data[];
  }

  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(false);
    this.fromDate = this.commonService.getYearsData().fromDate;
    this.toDate = this.commonService.getYearsData().toDate;
    this.getEmployeeDetails();
    this.getAllCertificates();
    this.checkScreenStatus();
    this.onResize();
    this.responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
  }

  /**
   * get employeed details
   */
  getEmployeeDetails = (): void => {
    this.authService
      .getEmployeeDetailBehaviorSubject()
      .subscribe((item: ProfileData) => {
        if (item?.data?.email) {
          this.email = item?.data?.email;
        }
      });
  };

  /**
   * get all the certificate with respect to the email
   */
  getAllCertificates = (event?: PageEvent): PageEvent => {
    this.allCertificatesBasicData = [] as GenericTable[];
    let pageNo = 0;
    if (event) {
      pageNo = event.pageIndex + 1;
      this.tablePageIndex = event.pageIndex;
    } else {
      pageNo = 1;
      this.tablePageIndex = 0;
    }
    this.storeData=false;
    this.certificationService
      .getAllCertificate(this.email, pageNo, this.status, this.fromDate, this.toDate)
      .pipe(take(1))
      .subscribe({
        //todo: create interface
        next: (data) => {
          if (data?.data) {
            this.allCertificateDetails = [];
            for (let certificateData of data.data) {
              certificateData.selectedTechnology = [];
              certificateData.other =
                certificateData.certificate.otherTechnology;
              certificateData.attachment = certificateData.attachmentId.name;
              certificateData.amount = certificateData.certificate.amount;
              certificateData.paid =
                certificateData.certificate.certificationCost;
              certificateData.myArray = certificateData.createdOn.split(' ');
              certificateData.date =
                certificateData.certificate.dateOfCompletion.substr(0, 10);
              for (const element of certificateData.certificate.technology) {
                certificateData.selectedTechnology.push(
                  element.employeeTechnology
                );
              }
              if (certificateData?.attachmentIdInvoice?.name) {
                certificateData.invoice =
                  certificateData.attachmentIdInvoice.name;
              } else {
                certificateData.invoice = '';
              }
              let certificateTableData = {} as GenericTable;
              certificateTableData.img = this.commonService.decodeBase64ToImage(
                certificateData.attachmentId.attachmentFile.s3FileImage
              );
              certificateTableData.pdf = this.commonService.decodeBase64ToImage(
                certificateData.attachmentId.attachmentFile.s3FileImage
              );
              certificateTableData.id = certificateData.id;
              certificateTableData.title = certificateData.summary;
              certificateTableData.createdOn = certificateData.createdOn.substr(
                0,
                10
              );
              certificateTableData.ecdc =
                certificateData.ecdc === 'DC'
                  ? this.ecdcMap.DC
                  : this.ecdcMap.EC;
              certificateTableData.status = certificateData.status;
              this.allCertificatesBasicData.push(certificateTableData);
              this.allCertificateDetails.push(certificateData);
            }
            this.tableLength = data.total_rows;
           
          }
          this.storeData=true;
        },
        error: () => {
          this.toastr.error('Error while loading Data');
          this.storeData=true;
        },
      });
    if (event) {
      return event;
    }
    return new PageEvent();
  };

  /**
   * delete certificate
   * @param {number}id - certificate ID
   */
  deleteCertificate = (id: number): void => {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: '481px',
      data: {
        warningTitle: 'Are you sure you want to delete this contribution?',
      },
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          if (data.yesClicked) {
            this.certificationService
              .deleteCertification(id)
              .pipe(take(1))
              .subscribe({
                next: (data) => {
                  this.deleteConfirmationModal();
                },
                error: () => {
                  this.toastr.error('Error while Deleting Certificate');
                },
              });
          }
        },
      });
  };

  /**
   * view the certificate
   * @param {number}id - certificate ID
   */
  viewCertificate = (id: number): void => {
    this.dialog.open(CertificationViewComponent, {
      width: '810px',
      data: id,
      panelClass: 'my-dialog',
    });
  };

  /**
   * edit the certificate
   * @param {number}id - certificate ID
   */
  editCertificateDetails = (id: number): void => {
    let certificateDetails = this.allCertificateDetails.filter(
      (certificateData) => {
        return certificateData.id === id;
      }
    );
    const dialogRef = this.dialog.open(CertificationFormComponent, {
      width: '650px',
      panelClass: 'my-dialog',
      data: certificateDetails[0],
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          if (result) {
            this.commonService
              .createcertificateEdit(result, id, this.email)
              .then((status) => {
                if (status) {
                  this.getAllCertificates();
                }
              })
              .catch((err) => {
                this.storeData = true;
              });
          }
        },
      });
  };

  /**
   * check screen status
   */
  checkScreenStatus = (): void => {
    this.responsiveService.getScreenStatus().subscribe((screenSize: string) => {
      if (screenSize) {
        this.screenSize = screenSize;
      }
    });
  };

  /**
   * for small screen
   * @returns screen size in string
   */
  isScreenSizeSmall = (): boolean => {
    return this.screenSize === 'sm';
  };
  /**
   * for extra small screen
   * @returns screen size in string
   */
  isScreenSizeXS = (): boolean => {
    return this.screenSize === 'xs' || this.screenSize === 'sm';
  };

  /**
   * check screen width
   */
  onResize = (): void => {
    this.responsiveService.checkWidth();
  };

  addNewCertificate() {
    let certificateForm = {
      id: 0,
      summary: '',
      description: '',
      url: '',
      attachment: '',
      invoice: '',
      paid: '',
      action: '',
      modalCheck: 'ADD',
      status: '',
      certificatAttachment: '',
      date: '',
      createdOn: '',
      selectedTechnology: [],
      ecdc: '',
      other: '',
    };
    const dialogRef = this.dialog.open(CertificationFormComponent, {
      width: '650px',
      panelClass: 'my-dialog',
      data: certificateForm,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          if (result) {
            this.storeData = false;
            this.commonService
              .createCeritificateCommon(result, this.email)
              .then((status) => {
                if (status) {
                  this.storeData = true;
                  this.getAllCertificates();
                }
              })
              .catch((err) => {
                this.storeData = true;
              });
          }
        },
      });
  }

  deleteConfirmationModal() {
    let genericDailogData = {
      title: 'Certificate deleted successfully!',
      img: 'assets/images/n_image/Success.gif',
      heading: 'Success!',
    };
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      width: '513px',
      data: genericDailogData,
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.getAllCertificates();
        },
      });
  }

  /**
   * routing back to dashboard
   */
  routeBackToMainDashboard = (): void => {
    this.router.navigate(['main-dashboard']);
  };
}
