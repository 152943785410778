<div
  *ngIf="isLoggedIn === true"
  [ngClass]="{ 'web-styling': !mobile, 'mobile-styling': mobile }"
>
  <div
    class="header"
    joyrideStep="footer-section"
    title="Footer Section"
    text="Refer below links to raise a Bug/Feedback, View Demo videos or reach out to the help channel."
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6">
          <div class="logo mt-5">
            <img src="../../../../assets/images/Gemini-logo-footer.png" />
          </div>
          <p>
            Contripoint is a colleague recognition portal which primarily helps
            the organisation in gathering the contributions of all colleagues on
            one platform. The mode of calculation of points based on
            contribution is uniform and transparent which leads to a healthy
            work environment and equality in performance assessment.
          </p>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-6 mt-5 mb-3 videos-link">
          <h2 class="mt-2 videos-styling">Important Links</h2>
          <div class="row">
            <div class="col-lg-12 col-sm-12 col-xs-12 col-md-12 ml-1 mb-3">
              <button routerLink="/bug-report">Report a Bug/Feedback</button>
            </div>
            <div class="col-lg-12 col-sm-12 col-xs-12 col-md-12 ml-2 mb-3">
              <button routerLink="/demo-videos">Demo and Feature Video</button>
            </div>
            <div class="col-lg-12 col-sm-12 col-xs-12 col-md-12 ml-2">
              <a
                href="https://teams.microsoft.com/l/team/19%3aNJSJmv6F_m756VkjMcOTSh08XAiYBZ7DJKShyoyMDbI1%40thread.tacv2/conversations?groupId=0cb4e31c-acb7-471c-87c1-bacc90a16f28&tenantId=b9806c7d-9280-4e44-afea-6dc0ff495c2f"
                target="_blank"
              >
                <button>Contripoint Help Channel</button></a
              >
            </div>
          </div>
        </div>
      </div>
      <hr />
      <p class="bottom-styling">
        <span>All Trademarks, logos and brand names are the property of their respective owner. All company, product and service names used in this website are for identification purposes only. Use of these names, trademarks and brands does not imply endorsement.</span>
       <br>
        <img src="../../../../assets/images/copyright-logo.png" /> 2022 Gemini
        Solutions Pvt Ltd.
      </p>
    </div>
  </div>
</div>

