import { ResponsiveService } from "./../../../services/responsive.service";
import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  UntypedFormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
  NgForm,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatDatepicker } from "@angular/material/datepicker";
import { certificate_data } from "../../../shared/models/certificate.model";
import { CertificateTableService } from "../service/certificate-table.service";
import { ErrorStateMatcher } from "@angular/material/core";
import * as moment from "moment";
import { MentorshipService } from "../../mentorship/service/mentorship.service";
import { EmployeeTechnology } from "src/app/shared/models/general.model";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-certificate-add-modal",
  templateUrl: "./certificate-add-modal.component.html",
  styleUrls: ["./certificate-add-modal.component.css"],
})
export class CertificateAddModalComponent implements OnInit {
  modalForm: UntypedFormGroup;
  mobile: boolean;
  tech_obj: EmployeeTechnology;
  other_validator: boolean;
  tech_Send: EmployeeTechnology[];
  submitted: boolean;
  posList: String[];
  changeTriggered: boolean;
  test: String;
  file: any;
  fileSizeInKb: number;
  minDate: Date;
  @ViewChild("picker") datePicker!: MatDatepicker<Date>;
  maxDate: Date;

  constructor(
    public dialogRef: MatDialogRef<CertificateAddModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: certificate_data,
    private formBuilder: UntypedFormBuilder,
    private certificateService: CertificateTableService,
    private _responsiveService: ResponsiveService,
    private toastr:ToastrService,
    private mentorshipService: MentorshipService
  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.test = "";
    this.posList = [] as String[];
    this.tech_Send = [] as EmployeeTechnology[];
    this.tech_obj = {} as EmployeeTechnology;
    this.other_validator = false;
    this.submitted = false;
    this.changeTriggered = false;
    this.fileSizeInKb = 0;
    this.mobile = false;
    this.modalForm = this.formBuilder.group({});
    this.getTechnologies();
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    this.maxDate = new Date();
    if (currentMonth >= 3) {
      this.minDate = new Date(currentYear, 3, 1);
    } else if (currentMonth < 3) {
      this.minDate = new Date(currentYear - 1, 3, 1);
    }
    this.modalForm = this.formBuilder.group({
      summary: [this.data.summary, Validators.required],
      technology: [this.data.selectedTechnology, Validators.required],
      attachment: [this.data.attachment],
      attachmentFile: [""],
      other: [this.data.other],
      date: [this.data.date, Validators.required],
      ecdc: [this.data.ecdc, Validators.required],
    });
    this.setValidator();
  }

  
  openCalender(): void {
    this.datePicker.open();
  }

  downloadAttach(sno: Number): void {
    this.certificateService.downloadFile(sno).subscribe((binary) => {
      if (binary.data) {
        this.downloadAction(binary.data.url, this.data.attachment);
      }
    });
  }
  downloadAction(url:string, name:string){
    fetch(url)
      .then(resp => resp.blob())
      .then(blob => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
      })
      .catch(() =>  this.toastr.error("Error in downloading the data"));
}
  onChange(event: any): void {
    this.changeTriggered = true;
    this.file = event.target.files[0];
    this.fileSizeInKb = Math.round(this.file.size / 1024);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  getTechnologies(): void {
    this.mentorshipService.getTechnologies().subscribe((data) => {
      this.posList = data;
    });
  }

  setValidator(): void {
    if (this.data.attachment == "") {
      this.modalForm.controls["attachmentFile"].setValidators([
        Validators.required,
      ]);
    }
  }

  onNewSubmit(): void {
    this.submitted = true;
    if (this.fileSizeInKb > 151) return;
    if (this.modalForm.value.technology.includes("Other")) {
      this.other_validator = true;
      this.modalForm.get("other")?.setValidators([Validators.required]);
      this.modalForm.get("other")?.updateValueAndValidity();
    }
    if (!this.modalForm.value.technology.includes("Other")) {
      this.other_validator = false;
      this.modalForm.get("other")?.clearValidators();
      this.modalForm.get("other")?.updateValueAndValidity();
      this.modalForm.value.other = 0;
    }
    if (this.modalForm.invalid) return;
    for (const element of this.modalForm.value.technology) {
      this.tech_obj = { employeeTechnology: element };
      this.tech_Send.push(this.tech_obj);
    }
    this.modalForm.value.technology = this.tech_Send;

    if (this.data.modalCheck === "ADD") {
      this.modalForm.value.date = moment(this.modalForm.value.date).format(
        "YYYY-MM-DD"
      );
      this.dialogRef.close({
        resultForm: this.modalForm.value,
        flag: "newAdd",
        objFile: this.file,
        checkFile: this.changeTriggered,
      });
    } else {
      this.modalForm.value.date = moment(this.modalForm.value.date).format(
        "YYYY-MM-DD"
      );
      this.dialogRef.close({
        resultForm: this.modalForm.value,
        flag: "editedAdd",
        objFile: this.file,
        checkFile: this.changeTriggered,
      });
    }
  }

  onDraftSubmit(): void {
    this.submitted = true;
    if (this.fileSizeInKb > 151) return;
    if (this.modalForm.value.technology.includes("Other")) {
      this.other_validator = true;
      this.modalForm.get("other")?.setValidators([Validators.required]);
      this.modalForm.get("other")?.updateValueAndValidity();
    }
    if (!this.modalForm.value.technology.includes("Other")) {
      this.other_validator = false;
      this.modalForm.get("other")?.clearValidators();
      this.modalForm.get("other")?.updateValueAndValidity();
      this.modalForm.value.other = 0;
    }
    if (this.modalForm.invalid) return;
    for (const element of this.modalForm.value.technology) {
      this.tech_obj = { employeeTechnology: element };
      this.tech_Send.push(this.tech_obj);
    }
    this.modalForm.value.technology = this.tech_Send;

    if (this.data.modalCheck === "ADD") {
      this.modalForm.value.date = moment(this.modalForm.value.date).format(
        "YYYY-MM-DD"
      );
      this.dialogRef.close({
        resultForm: this.modalForm.value,
        flag: "newAddDraft",
        objFile: this.file,
        checkFile: this.changeTriggered,
      });
    } else {
      this.modalForm.value.date = moment(this.modalForm.value.date).format(
        "YYYY-MM-DD"
      );
      this.dialogRef.close({
        resultForm: this.modalForm.value,
        flag: "editedDraft",
        objFile: this.file,
        checkFile: this.changeTriggered,
      });
    }
  }

  matcher = new MyErrorStateMatcher();
}
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid);
  }
}
