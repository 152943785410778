import { Component, OnInit } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { CommonService } from 'src/app/services/common.service';
import { LeaderBoardData } from 'src/app/shared/models/leaderboard.model';
import { LeaderboardService } from '../service/leaderboard.service';
import { ChangestatusService } from 'src/app/services/changestatus.service';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-leaderboard-dash',
  templateUrl: './leaderboard-dash.component.html',
  styleUrls: ['./leaderboard-dash.component.css'],
})
export class LeaderboardDashComponent implements OnInit {
  rank: number;
  len: number;
  mobile: boolean;
  data_limit: LeaderBoardData[];
  data_leader: LeaderBoardData[];
  constructor(
    private _leaderboard: LeaderboardService,
    private commonService: CommonService,
    private responsiveService: ResponsiveService
  ) {
    this.mobile = false;
    this.len = 0;
    this.rank = 30;
    this.data_limit = [] as LeaderBoardData[];
    this.data_leader = [] as LeaderBoardData[];
  }

  ngOnInit(): void {
    this.getLeaderboardData();
    this.onResize();
    this.responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
  }

  /**
   * set leaderboard status
   */
  sidebarFunc = (): void => {
    localStorage.setItem('leaderboardStatus', 'open');
    this.commonService.setLeaderboardStatus(true);
    this.getLeaderboardData()
  }

  /**
   * get data for leaderboard
   * set the activity update for leaderboard section.
   */
  getLeaderboardData = (): void => {
    this._leaderboard.getDetails(this.rank).pipe(take(1)).subscribe((data: LeaderBoardData[]) => {
      if (data) {
        this.data_limit = [];
        this.data_leader = data;
        this.len = data.length;
        for (let i = 0; i < this.data_leader.length; i++) {
          if (i > 9) break;
          this.data_limit.push(this.data_leader[i]);
        }
        for (const element of this.data_leader) {
          if (element.image) {
            element.imgAsset = atob(element.image);
          } else {
            element.imgAsset = `assets/images/def.png`;
          }
        }
      }
    });
    this.commonService.sendUpdate(`side leadeboard`);
  }

  /**
   * check display width
   */
  onResize = (): void => {
    this.responsiveService.checkWidth();
  }
}
