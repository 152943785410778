<div  (click)="setStatus()">
<button (click)="sidenavFunc()">
    <img class="close" src="assets/images/Close L.svg" alt="close-arrow" />
  </button>
  <div>
    <img
      class="leaderboardBanner"
      src="assets/images/Leaderboard Banner.svg"
      alt="leaderboardBanner"
    />
  </div>
  
  <div class="mrg-top">
    <div class="row mt-3 divBorder" *ngFor="let val of data_limit">
      <div
        class="col-lg-4 col-md-4 col-sm-4 col-4"
        *ngIf="val.position === 1; else second"
      >
        <img class="img_leaders" [src]="val.imgAsset" alt="profile-image" />
        <img class="badge1" src="assets/images/badge-gold.svg" alt="badge-icon" />
        <span class="positionOne">{{"0" + val.position  }}</span>
      </div>
  
      <ng-template #second>
        <div
          class="col-lg-4 col-md-4 col-sm-4 col-4"
          *ngIf="val.position === 2; else elseBlock"
        >
          <img class="img_leaders" [src]="val.imgAsset" alt="profile-image" />
          <img class="badge2" src="assets/images/badge-silver.svg" alt="badge-icon" />
          <span class="positionTwo">{{ "0" + val.position  }}</span>
        </div>
      </ng-template>
      <ng-template #elseBlock>
        <div
          class="col-lg-4 col-md-4 col-sm-4 col-4"
          *ngIf="val.position === 3; else last"
        >
          <img class="img_leaders" [src]="val.imgAsset" alt="profile-image" />
          <img class="badge2" src="assets/images/badge-bronze.svg" alt="badge-icon" />
          <span class="positionThree">{{ " 0" + val.position  }}</span>
        </div>
      </ng-template>
      <ng-template #last>
        <div class="col-lg-4 col-md-4 col-sm-4 col-4">
          <img class="img_leaders" [src]="val.imgAsset" alt="profile-image" />
          <span class="positionThree">
            {{ val.position <= 9 ? "0" + val.position : val.position }}</span
          >
        </div>
      </ng-template>
  
      <div class="col-lg-6 col-md-6 col-sm-6 col-6">
        <span class="name">{{ val.name }}</span>
        <p class="designation">{{ val.designation }}</p>
      </div>
  
      <div class="col-lg-2 col-md-2 col-sm-2 col-2">
        <img
          class="rounded mx-auto d-block"
          src="assets/images/gold star.svg"
          alt="badge-icon"
        />
        <p class="pointStyling">{{ val.totalPoints }}</p>
      </div>
    </div>
  </div>
</div>
  