import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteModalDetails } from '../../models/delete-modal.model';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.css']
})
export class DeleteModalComponent implements OnInit {
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteModalDetails) {
  }
  

  ngOnInit(): void {
  }
  
  onClose(check:boolean) {
    this.dialogRef.close({
      yesClicked:check
    });
  }

}
