import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProfileData } from 'src/app/shared/models/profile.model';
import { shareReplay } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class MyProfileService {
  profileDetails: BehaviorSubject<ProfileData>;
  private employeeDetail$: Observable<any> | null = null;
  constructor(private http: HttpClient) {
    this.profileDetails = new BehaviorSubject<ProfileData>({} as ProfileData);
  }
  getDetails(): Observable<any> {
    if (!this.employeeDetail$) {
      this.employeeDetail$ = this.http
        .get<any>('contripoint/my/profile')
        .pipe(shareReplay(1));
    }

    return this.employeeDetail$;
  }

  getProfileDetailBehaviorSubject(): Observable<ProfileData> {
    return this.profileDetails.asObservable();
  }

  setProfileDetailsBehaviorSubject(data: ProfileData) {
    this.profileDetails.next(data);
  }

  getProfileData():Observable<any>{
    return this.http.get<any>('contripoint/my/profile')
  }
}
