import { genericDailogData } from './../../../shared/models/confirmation-modal.model';
import { MyProfileService } from "./../../dashboard/dash-info-cards/service/my-profile.service";
import { CertificateTableService } from "src/app/modules/certificate/service/certificate-table.service";
import { CertificateAddModalComponent } from "./../certificate-add-modal/certificate-add-modal.component";
import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { ResponsiveService } from "../../../services/responsive.service";
import { MatDialog } from "@angular/material/dialog";
import { certificate_data } from "../../../shared/models/certificate.model";
import { MatTableDataSource } from "@angular/material/table";
import { dailogData } from "src/app/shared/models/confirmation-modal.model";
import { ConfirmationModalComponent } from "../../../shared/components/confirmation-modal/confirmation-modal.component";
import { ToastrService } from "ngx-toastr";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { PageEvent } from "@angular/material/paginator";
import { CommonService } from "src/app/services/common.service";
import { AuthUserService } from "src/app/services/auth-user.service";
import { Router, ActivatedRoute } from "@angular/router";
import { DeleteModalComponent } from "src/app/shared/components/delete-modal/delete-modal.component";
import * as moment from "moment";
import { EmployeeDetails } from "src/app/shared/models/profile.model";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";
@Component({
  selector: "app-certificate-table",
  templateUrl: "./certificate-table.component.html",
  styleUrls: ["./certificate-table.component.css"],
})
export class CertificateTableComponent implements OnInit {
  certificateForm: certificate_data;
  dataDialog: dailogData;
  displayedColumns: string[] = [
    "summary",
    "date",
    "status",
    "action",
    "createdOn",
  ];
  mobile: boolean;
  tablePageIndex: number = 0;
  value :number;
  color: ThemePalette ;
  mode: ProgressSpinnerMode ;
  StoreData: boolean;
  tableLength: number = 0;
  empId: String;
  empEmail: String;
  userEmail: any;
  pageEvent?: PageEvent;
  dataSource: MatTableDataSource<certificate_data>;
  viewType: String;
  flag: boolean = false;
  userData: EmployeeDetails;
  hideData: boolean = true;
  clickedRows = new Set<certificate_data>();
  year: String;
  statusBoolean: String;
  genericDailogData:genericDailogData
  constructor(
    private _responsiveService: ResponsiveService,
    public dialog: MatDialog,
    public router: Router,
    public _route: ActivatedRoute,
    private commonService: CommonService,
    private certificateService: CertificateTableService,
    private toastr: ToastrService,
    private _authService: AuthUserService,
    private profileService: MyProfileService
  ) {
    this.dataSource = new MatTableDataSource<certificate_data>([]);
    this.mobile = false;
    this.dataDialog = { title: "" };
    this.genericDailogData={title:'',img:'',heading:''}
    this.certificateForm = {
      id: 0,
      summary: "",
      description: "",
      url: "",
      attachment: "",
      paid:"",
      amount:0,
      invoice:"",
      action: "",
      modalCheck: "",
      status: "",
      certificatAttachment: "",
      date: "",
      createdOn: "",
      selectedTechnology: [],
      ecdc: "",
      other: "",
    };
    this.viewType = "";
    this.year = "";
    this.statusBoolean = "";
    this.empId = "";
    this.userData = {} as EmployeeDetails;
    this.empEmail = "";
    this.value=50;
    this.StoreData=true;
    this.color="primary";
    this.mode="indeterminate";
  }

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  route(id: String) {
    this.router.navigateByUrl(`/certificate/${id}`, {
      skipLocationChange: true,
    });
  }

  ngOnInit(): void {
    this.onResize();
    this.commonService.setAdminPortalOpened(false);
    this._authService.getEmployeeDetailBehaviorSubject().subscribe((item) => {
      if (item) {
        this.empId = item.data.id;
        this.empEmail = item.data.email;
      }
    });
    this._route.queryParams.subscribe((queryparams) => {
      this.year = queryparams.year;
    });

    this._route.paramMap.subscribe((params) => {
      this.viewType = String(params?.get("viewType"));
      if (
        this.viewType === "myteam" ||
        this.viewType === "team-contribution-history" ||
        this.viewType ===  "my-contribution-history"
      ) {
        this.empId = String(params?.get("id"));
        this.empEmail=String(localStorage.getItem('selectedEmail'))
        this.flag = true;
      }
    });
    if (this.viewType == "myView" || this.viewType == "myteam") {
      let currentMonth = new Date().getMonth() + 1;
      if (currentMonth <= 3) {
        this.year = moment().year() - 1 + "-" + moment().year();
      } else {
        this.year = moment().year() + "-" + (moment().year() + 1);
      }
    }
    this._responsiveService.checkWidth();
    this.getAllCertificate();
   
  }

  ngAfterViewChecked(): void {
    if (this.dataSource.data.length != 0) {
      const list = document.getElementsByClassName("mat-paginator-range-label");
      this.tableLength > 0
        ? (list[0].innerHTML =
            "Page: " +
            (this.tablePageIndex + 1).toString() +
            " of " +
            Math.ceil(this.tableLength / 5))
        : (list[0].innerHTML = "Page: 0 of 0");
    }
  }

  routeDelete(id: number): void {
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      width: "513px",
      data: {
        id,
        warningTitle: "Are you sure you want to delete this contribution?",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.res == true) {
        this.genericDailogData = { title: "Contribution deleted succesfully!",  img:'../../../../assets/images/n_image/Success.gif',
        heading:'Success!'    };
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
          width: "513px",
          data: this.genericDailogData,
        });
        this.certificateService
          .deletecertification(result.id)
          .subscribe((data) => {
            if (data)
              this.commonService.sendUpdate("Contribution deleted succesfully");
            this.getAllCertificate();
          });
      }
    });
  }
  openDialog(modalCheck: string, row?: certificate_data): void {
    if (modalCheck == "ADD") {
      this.certificateForm = {
        id: 0,
        summary: "",
        description: "",
        url: "",
        paid:"",
        amount:0,
        invoice:"",
        attachment: "",
        action: "",
        modalCheck: "ADD",
        status: "",
        date: "",
        certificatAttachment: "",
        createdOn: "",
        selectedTechnology: [],
        ecdc: "",
        other: "",
      };

      const dialogRef = this.dialog.open(CertificateAddModalComponent, {
        width: "650px",
        data: this.certificateForm,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.StoreData=false;
          this.commonService
            .createCeritificateCommon(result, this.empEmail)
            .then(() => {
              this.StoreData=true;
              this.getAllCertificate();
            }).catch((err)=>{
              this.StoreData=true;
            });
        }
      });
    } else if (modalCheck == "EDIT") {
      if (row) {
        this.certificateForm = row;
      }
      const dialogRef = this.dialog.open(CertificateAddModalComponent, {
        width: "650px",
        data: this.certificateForm,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.StoreData=false;
          this.commonService
            .createcertificateEdit(result, row?.id, this.empEmail)
            .then(() => {
              this.StoreData=true;
              this.getAllCertificate();
            }).catch((err)=>{
              this.StoreData=true;
            });;
        }
      });
    }
  }

  backRoute(): void {
    if (this.viewType === "myteam") {
      this.router.navigate(["dashboard/my-team"]);
    } else if (
      this.viewType === "my-contribution-history" ||
      this.viewType === "team-contribution-history"
    ) {
      this.router.navigate(["dashboard/contribution-history"]);
    } else {
      this.router.navigate(["/dashboard"]);
    }
  }

  getCertificate1(pageNo: number): Promise<certificate_data> {
    const apiCall = new Promise<certificate_data>((resolve, reject) => {
      this.certificateService
        .getAllCertificate(
          this.empEmail,
          pageNo + 1,
          this.year,
          this.statusBoolean
        )
        .subscribe(
          (data) => {
            this.userData=data.employee;
            if(this.userData.image){
              this.userData.imgAsset = atob(this.userData.image)
              }
              else{
                this.userData.imgAsset = "../../../../assets/images/def.png";
              }
            if (data && data.data && data.data.length) {
              for (const element of data.data) {
                let createdOn = element.createdOn;
                if (element.status === "APPROVED") {
                  this.hideData = false;
                }
                element.myArray = createdOn.split(" ");
                element.attachment = element.attachmentId.name;
                element.date = element.certificate.dateOfCompletion.substr(
                  0,
                  10
                );
              }
              for (const element of data.data) {
                let testArr = [];
                element.other = element.certificate.otherTechnology;
                for (const element1 of element.certificate.technology) {
                  testArr.push(element1.employeeTechnology);
                }
                element.selectedTechnology = testArr;
              }
           
              this.tableLength = data.total_rows;
              this.tablePageIndex = pageNo;
              this.dataSource = new MatTableDataSource<certificate_data>(
                data.data
              );
              if (this.flag) {
                this.dataSource.filter = "APPROVED";
              }
              this.dataSource.paginator = this.paginator.toArray()[1];
              this.dataSource.sort = this.sort;
            }
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
    return apiCall;
  }

  getCertificate2(): Promise<certificate_data> {
    const apiCall = new Promise<certificate_data>((resolve, reject) => {
      this.certificateService
        .getAllCertificate(this.empEmail, 1, this.year, this.statusBoolean)
        .subscribe(
          (data) => {
            this.userData=data.employee;
              if(this.userData.image){
                this.userData.imgAsset = atob(this.userData.image)
                }
                else{
                  this.userData.imgAsset = "../../../../assets/images/def.png";
                }
            if (data && data.data && data.data.length) {
              for (const element of data.data) {
                let createdOn = element.createdOn;
                if (element.status === "APPROVED") {
                  this.hideData = false;
                }
                element.myArray = createdOn.split(" ");
                element.attachment = element.attachmentId.name;
                element.date = element.certificate.dateOfCompletion.substr(
                  0,
                  10
                );
              }
              for (const element of data.data) {
                let testArr = [];
                element.other = element.certificate.otherTechnology;
                for (const element1 of element.certificate.technology) {
                  testArr.push(element1.employeeTechnology);
                }
                element.selectedTechnology = testArr;
              }
              
              this.tableLength = data.total_rows;
              this.tablePageIndex = 0;
              this.dataSource = new MatTableDataSource<certificate_data>(
                data.data
              );
              if (this.flag) {
                this.dataSource.filter = "APPROVED";
              }
              this.dataSource.paginator = this.paginator.toArray()[1];
              this.dataSource.sort = this.sort;
            } else {
              this.tableLength = data.total_rows;
              this.tablePageIndex = 0;
              this.dataSource = new MatTableDataSource<certificate_data>(
                data.data
              );
              if (this.flag) {
                this.dataSource.filter = "APPROVED";
              }
              this.dataSource.paginator = this.paginator.toArray()[1];
              this.dataSource.sort = this.sort;
            }
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
    return apiCall;
  }
  getAllCertificate(event?: PageEvent): PageEvent {
    if (event?.pageIndex) {
      let pageNo: number = event.pageIndex;
      if (this.flag) {
        this.statusBoolean = "approved";
      } else {
        this.statusBoolean = "allStatus";
      }

      this.getCertificate1(pageNo)
        .then((res) => {})
        .catch((err) => {
          this.toastr.error("Error in loading data");
        });
    } else {
      if (this.flag) {
        this.statusBoolean = "approved";
      } else {
        this.statusBoolean = "allStatus";
      }
      this.getCertificate2()
        .then((res) => {})
        .catch((err) => {
          console.log(localStorage.getItem('selectedEmail'))
          this.toastr.error("Error in loading data");
        });
    }
    if (event) return event;
    return new PageEvent();
  }

  sortData(sort: MatSort): void {
    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string) => {
      if (!data[sortHeaderId]) {
        return this.sort.direction === "asc" ? "3" : "1";
      }
      return "2" + data[sortHeaderId].toString().toLowerCase();
    };
  }

  onResize(): void {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.mobile = isMobile;
    });
  }
}
