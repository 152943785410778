import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TrainingandsessionsService {
  constructor(private http: HttpClient) {}

  /**
   * Get all training data of user
   */
  getAllTraining(
    empEmail: String,
    pageNo: number,
    fromDate: string,
    toDate: string,
    status: String
  ): Observable<any> {
    let obj = {
      email: empEmail,
      pageNo: pageNo.toString(),
      fromDate: fromDate,
      toDate: toDate,
      status: status,
    };
    return this.http.post('contripoint/trainingsessions/byYear', obj);
  }

  /**
   * Get training data detail for view
   * @param trainingSessionId - training-session Id
   */
  getSelectedTrainingSessionView(trainingSessionId: number) {
    let header = new HttpHeaders()
      .set('Access-Control-Allow-Origin', '*')
      .set('content-type', 'application/json');
    let reqParam = new HttpParams().set('trainingSessionId', trainingSessionId);
    return this.http
      .get<any>('contripoint/trainingsession/view', {
        params: reqParam,
        headers: header,
      })
      .pipe(
        map((response: any) => {
          const sessionData = { ...response };
          sessionData.data.createdOn = sessionData.data.createdOn.substr(0, 10);
          sessionData.data.trainingAndSession.endDate =
            sessionData.data.trainingAndSession.endDate.substr(0, 10);
          sessionData.data.trainingAndSession.startDate =
            sessionData.data.trainingAndSession.startDate.substr(0, 10);
          return sessionData;
        })
      );
  }

  /**
   * API provides technology list
   */
  getTechnologies(): Observable<Array<String>> {
    return this.http.get<Array<String>>('contripoint/technologies/view');
  }

  /**
   * API for adding new training data
   */
  createTraining(createTrainingRequestBody: any) {
    return this.http.post<any>(
      'contripoint/trainingsession/add',
      createTrainingRequestBody
    );
  }

  /**
   * API for saving the training data as draft
   */
  saveAsDraft(createTrainingRequestBody: any) {
    return this.http.post<any>(
      'contripoint/trainingsession/drafts',
      createTrainingRequestBody
    );
  }

  /**
   * Delete api call for deletion of particular training-session
   * @param trainingSessionId - training-session Id
   */
  deletetrainingsession(trainingSessionId: Number) {
    return this.http.delete<any>(
      'contripoint/trainingsession/delete/' + trainingSessionId
    );
  }
}
