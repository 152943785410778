import { Injectable } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { SocialUser } from 'angularx-social-login';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from './common.service';
import { MyProfileService } from '../modules/dashboard/dash-info-cards/service/my-profile.service';
import { ProfileData } from '../shared/models/profile.model';
import { EncryptionService } from './encryption.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root',
})
export class AuthUserService {
  user?: SocialUser;
  loggedIn: boolean;
  empEmail: string;
  userEmail: any = '';
  empDetail = new BehaviorSubject<any>(null);
  notificationBoolean: boolean;
  welcomeBoolean: boolean;
  jwtToken: any;
  timeError = new BehaviorSubject<any>(null);
  path: string;
  APPID: string;

  private refreshTokenTimeout: any;
  constructor(
    private encryptionService: EncryptionService,
    private authService: SocialAuthService,
    private commonService: CommonService,
    private router: Router,
    private profileService: MyProfileService,
    private http: HttpClient,
    private toastr: ToastrService
  ) {
    this.empEmail = '';
    this.welcomeBoolean = false;
    this.loggedIn = false;
    this.notificationBoolean = false;
    this.welcomeBoolean = false;
    this.path = ``;
    this.APPID = 'b9806c7d-9280-4e44-afea-6dc0ff495c2f';
  }

  checkServiceState() {
    this.authService.authState.toPromise().then((user) => {
      this.user = user;
      this.loggedIn = user != null;
    });
    return this.loggedIn;
  }

  getEmployeeDetailBehaviorSubject(): Observable<any> {
    return this.empDetail.asObservable();
  }
  CheckuserState() {
    let resultToken = localStorage.getItem('token');
    let profileDetails;
    let encryptedProfileDetails = localStorage.getItem('profileData');
    if (encryptedProfileDetails) {
      profileDetails = this.encryptionService.decryptData(
        encryptedProfileDetails
      );
    }
    this.userEmail = localStorage.getItem('email');

    let decryptedData;
    let encryptedData = localStorage.getItem('encryptedData');
    if (encryptedData) {
      decryptedData = this.encryptionService.decryptData(encryptedData);
    }
    if (resultToken && this.userEmail && decryptedData) {
      this.empDetail.next(decryptedData);
      if (profileDetails !== null)
        this.profileService.setProfileDetailsBehaviorSubject(profileDetails);
      this.timeError.next(localStorage.getItem('timeError'));
      return true;
    } else {
      return false;
    }
  }

  signOut(): void {
    if (this.checkServiceState() == true) this.authService.signOut();

    this.stopRefreshTokenTimer();
    this.empDetail.next(null);
    this.profileService.setProfileDetailsBehaviorSubject({} as ProfileData);
    let count = localStorage.getItem('notificationCount');
    let neoToken = localStorage.getItem('neo-token');
    localStorage.clear();
    if (count) localStorage.setItem('notificationCount', count);
    else {
      localStorage.setItem('notificationCount', '0');
    }
    if (neoToken != null) {
      localStorage.setItem('neo-token', neoToken);
    }
    this.router.navigate(['login']);
  }

  public startRefreshTokenTimer() {
    const jwtToken = JSON.parse(atob(this.empDetail.value.token.split('.')[1]));
    const expireDate = new Date(jwtToken.exp * 1000);
    const errorInTime = Number(this.timeError.value);
    const timeout = errorInTime + (expireDate.getTime() - Date.now());
    if (timeout <= 0) {
      this.signOut();
    }
    this.refreshTokenTimeout = setTimeout(() => this.signOut(), timeout);
  }
  public stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  // setEmpDetailsFromAPI(token: string): Observable<any> {
  //   return this.http.post<any>("contripoint/api/authenticate", token);
  // }

  setEmpDetailsFromAPI(empMail: string, token: string): Observable<any> {
    const obj = {
      token: token,
      email: empMail,
    };
    return this.http.post<any>('contripoint/api/authenticate', obj);
  }

  sendLoginDetails(username: string, password: string): Observable<any> {
    const obj = {
      email: username,
      password: password,
    };
    return this.http.post<any>('contripoint/demo/login', obj);
  }

  authenticateLoginDetails(username: string, password: string): Promise<void> {
    localStorage.setItem('email', username);

    return new Promise<void>((resolve, reject) => {
      this.sendLoginDetails(username, password).subscribe(
        (item: any) => {
          if (item) {
            this.handleLoginSuccess(item.body);
            resolve();
          } else {
            reject(this.toastr.error('Login failed'));
          }
        },
        (err) => {
          this.handleLoginError(err);
          reject(err);
        }
      );
    });
  }

  private handleLoginSuccess(body: any): void {
    this.empDetail.next(body);

    this.jwtToken = this.parseJwtToken(this.empDetail.value.token);
    this.timeError.next(
      Date.now() - new Date(this.jwtToken.iat * 1000).getTime()
    );
    localStorage.setItem('timeError', this.timeError.value.toString());

    const now = new Date();
    const val = {
      value: body.token,
      expiry: now.getTime() + 24 * 60 * 60 * 1000, // 24 hours from now
    };
    localStorage.setItem('key', JSON.stringify(val));
    localStorage.setItem('token', body.token);

    const encryptedData = this.encryptionService.encryptData(body);
    localStorage.setItem('encryptedData', encryptedData);
    localStorage.setItem('empId', body.data.id);
  }

  private handleLoginError(err: any): void {
    console.log(err);
    if (err.status === 404) {
      this.toastr.error(err.error);
    } else {
      const loginServerStatus = this.commonService.getLoginServerStatus();
      loginServerStatus.isWorking = true;
      this.commonService.setLoginServerStatus(loginServerStatus);
    }
  }

  private parseJwtToken(token: string): any {
    return JSON.parse(atob(token.split('.')[1]));
  }

  setToken(email?: any, backend_token?: any) {
    return new Promise<void>((resolve, reject) => {
      localStorage.setItem('email', email);
      this.setEmpDetailsFromAPI(email, backend_token).subscribe(
        (item: any) => {
          if (item) {
            this.empDetail.next(item.body);

            this.jwtToken = JSON.parse(
              atob(this.empDetail.value.token.split('.')[1])
            );
            this.timeError.next(
              Date.now() - new Date(this.jwtToken.iat * 1000).getTime()
            );

            localStorage.setItem('timeError', this.timeError.value);

            const now = new Date();
            const val = {
              value: item.body.token,
              expiry: now.getTime() + 24 * 60 * 60 * 1000,
            };
            localStorage.setItem('key', JSON.stringify(val));
            localStorage.setItem('token', item.body.token);
            const encryptedData = this.encryptionService.encryptData(item.body);
            localStorage.setItem('encryptedData', encryptedData);
            localStorage.setItem('empId', item.body.data.id);
            // this.getCurrentVersion().subscribe((data: any) => {
            //   if (data.data < environment.version) {
            //     if (!localStorage.getItem("firstLoad")) {
            //       localStorage["firstLoad"] = true;
            //       window.location.reload();
            //     } else localStorage.removeItem("firstLoad");
            //   }
            // });
            resolve();
          } else {
            reject();
          }
        },
        (err) => {
          let loginServerStatus = this.commonService.getLoginServerStatus();
          loginServerStatus.isWorking = true;
          this.commonService.setLoginServerStatus(loginServerStatus);
        }
      );
    });
  }

  getLocalStorageItem(): any {
    const itemStr = localStorage.getItem('key');

    if (itemStr === null) {
      return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date().getTime();
    if (now > item.expiry) {
      localStorage.removeItem('key');
      return null;
    }

    return item.value;
  }

  tokenExchangeApi(idToken: string): Observable<any> {
    let headers = new HttpHeaders({ Authorization: 'Bearer ' + idToken });
    this.path = `https://tokenmintingsvc.geminisolutions.com/token/exchange/${this.APPID}`;
    return this.http.get(this.path, { headers, responseType: 'text' });
  }

  setNotificationModalBoolean(value: boolean) {
    this.notificationBoolean = value;
  }
  getNotificationModalBoolean() {
    return this.notificationBoolean;
  }
  getWelcomeModalBoolean() {
    return this.welcomeBoolean;
  }
  setWelcomeModalBoolean(value: boolean) {
    this.welcomeBoolean = value;
  }

  userTracking(empEmail: String): Observable<any> {
    let obj = { employeeEmail: empEmail };
    return this.http.post<any>('contripoint/history/record', obj);
  }

  getCurrentVersion() {
    let obj = ' ';
    return this.http.post('contripoint/current/version', obj);
  }

  // setToken(backend_token?: any) {
  //   return new Promise<void>((resolve, reject) => {
  //     this.setEmpDetailsFromAPI(backend_token).subscribe(
  //       (item: any) => {
  //         if (item) {
  //           this.empDetail.next(item.body);

  //           this.jwtToken = JSON.parse(
  //             atob(this.empDetail.value.token.split(".")[1])
  //           );
  //           this.timeError.next(
  //             Date.now() - new Date(this.jwtToken.iat * 1000).getTime()
  //           );

  //           localStorage.setItem("timeError", this.timeError.value);
  //           localStorage.setItem("token", item.body.token);
  //           localStorage.setItem("empDetails", JSON.stringify(item.body));
  //           localStorage.setItem("empId", item.body.data.id);
  //           this.getCurrentVersion().subscribe((data: any) => {
  //             if (data.data < environment.version) {
  //               if (!localStorage.getItem("firstLoad")) {
  //                 localStorage["firstLoad"] = true;
  //                 window.location.reload();
  //               } else localStorage.removeItem("firstLoad");
  //             }
  //           });
  //           resolve();
  //         } else {
  //           reject();
  //         }
  //       },
  //       (err) => {
  //         let loginServerStatus = this.commonService.getLoginServerStatus();
  //         loginServerStatus.isWorking = true;
  //         this.commonService.setLoginServerStatus(loginServerStatus);
  //       }
  //     );
  //   });
  // }
  neoAuthentication(accessToken: string) {
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + accessToken,
    });
    let path = 'https://graph.microsoft.com/v1.0/me';
    return this.http.get(path, { headers });
  }
}
