
<div class="modal-feedback">
  <div class="modal-header position-sticky-top">
    <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10">
      <p class="modal-header-text modal-title">Add New Certificate</p>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
      <button type="button" class="info" [matMenuTriggerFor]="certificate">
        <img src="../../../../assets/images/form-info.svg" />
      </button>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
      <button type="button" class="close" (click)="onClose()" aria-label="Close">
        <span aria-hidden="true"><i class="material-icons">close</i></span>
      </button>
    </div>
  </div>
  <mat-menu #certificate="matMenu" xPosition="before" class="mat-dropdown-styling">
    <div class="certificate-container">
      <ul class="ul-container">
        <div class="d-flex flex-nowrap bd-highlight certificate-row">
          <div class="p-2 bd-highlight widthOne" >
            <p class="heading">Name of Certification</p>
          </div>
          <div class="p-2 bd-highlight widthTwo" >
            <p class="arrow">-</p>
          </div>
          <div class="p-2 bd-highlight widthThree">
            <p class="heading-data">Title of certificate</p>
          </div>
        </div>
        <div class="d-flex flex-nowrap bd-highlight teambuilding-row">
          <div class="p-2 bd-highlight widthOne" >
            <p class="heading">Date of Completion</p>
          </div>
          <div class="p-2 bd-highlight widthTwo">
            <p class="arrow">-</p>
          </div>
          <div class="p-2 bd-highlight widthThree">
            <p class="heading-data">
              Date on which certificate was completed (only current FY)
            </p>
          </div>
        </div>
        <div class="d-flex flex-nowrap bd-highlight teambuilding-row">
          <div class="p-2 bd-highlight widthOne">
            <p class="heading">Attachment</p>
          </div>
          <div class="p-2 bd-highlight widthTwo">
            <p class="arrow">-</p>
          </div>
          <div class="p-2 bd-highlight widthThree">
            <p class="heading-data">
              Upload the certificate of course. (size- 150 KB or less & format-
              pdf, jpg, jpeg or eml only)
            </p>
          </div>
        </div>
        <div class="d-flex flex-nowrap bd-highlight teambuilding-row">
          <div class="p-2 bd-highlight widthOne" >
            <p class="heading">Technology Covered</p>
          </div>
          <div class="p-2 bd-highlight widthTwo" >
            <p class="arrow">-</p>
          </div>
          <div class="p-2 bd-highlight widthThree" >
            <p class="heading-data">
              Displays a multi select dropdown with list of technologies covered
              in certificate. User can choose ‘Other’ if name of technology is
              not found. Another text box will pop up where user can write name
              of technology.
            </p>
          </div>
        </div>
        <div class="d-flex flex-nowrap bd-highlight teambuilding-row">
          <div class="p-2 bd-highlight widthOne" >
            <p class="heading">Select Goal Type</p>
          </div>
          <div class="p-2 bd-highlight widthTwo" >
            <p class="arrow">-</p>
          </div>
          <div class="p-2 bd-highlight widthThree" >
            <p class="heading-data">
              Goals are of 2 types -(i)Engineering (ii)Delivery .
              If the contributions falls under engineering goal, then 
              select EC and similarly if it falls under delivery goal, select DC type.
            </p>
          </div>
        </div>
        <div class="d-flex flex-nowrap bd-highlight teambuilding-row">
          <div class="p-2 bd-highlight widthFour">
            <li class="arrow ml-3"></li>
          </div>
          <div class="p-2 bd-highlight widthFive">
            <p class="main-heading">
              Please enter details only for current Financial Year.
            </p>
          </div>
        </div>
        <h2 class="dont-styling ml-2">Dont’s -</h2>
        <div class="d-flex flex-nowrap bd-highlight teambuilding-row">
          <div class="p-2 bd-highlight widthFour">
            <li class="arrow ml-3"></li>
          </div>
          <div class="p-2 bd-highlight widthFive">
            <p class="main-heading">
              User cannot add free certifications like from Udemy, youtube,
              linkedin, etc.
            </p>
          </div>
        </div>
        <div class="d-flex flex-nowrap bd-highlight teambuilding-row">
          <div class="p-2 bd-highlight widthFour">
            <li class="arrow ml-3"></li>
          </div>
          <div class="p-2 bd-highlight widthFive">
            <p class="main-heading">Attachment size must not exceed 150 KB.</p>
          </div>
        </div>
      </ul>
    </div>
  </mat-menu>
  <mat-dialog-content class="modal-body">
    <form [formGroup]="modalForm" (ngSubmit)="onNewSubmit()">
      <div class="form-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label class="label-text">Name Of Certification </mat-label>
              <input class="input-text" type="text" formControlName="summary" matInput [errorStateMatcher]="matcher" />
            </mat-form-field>
            <mat-error class="error-style fix-top-margin" *ngIf="
                  modalForm.controls.summary?.errors && this.submitted == true
                ">
                Name Of Certification is required
              </mat-error>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <mat-form-field class="w-100" hintLabel="(Current Financial Year only)" appearance="fill">
              <mat-label class="label-text">Date of Completion </mat-label>
              <input class="input-text" (click)="openCalender()" readonly matInput [matDatepicker]="picker"
                [min]="minDate" [max]="maxDate" formControlName="date" [errorStateMatcher]="matcher"
                placeholder="Date of Completion" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-error class="error-style" *ngIf="
                  modalForm.controls.date?.errors && this.submitted == true
                ">
                Completion Date is required
              </mat-error>
          </div>

          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label class="label-text">Technology covered</mat-label>
              <mat-select formControlName="technology" multiple placeholder="Select Technology" disableOptionCentering>
                <mat-option *ngFor="let posValue of posList" [value]="posValue">{{ posValue }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error class="error-style fix-top-margin" *ngIf="
                  modalForm.controls.technology?.errors &&
                  this.submitted == true
                ">
                Technology is required
              </mat-error>
          </div>
        </div>

        <div class="row mt-2" *ngIf="this.modalForm.value.technology.includes('Other')">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <mat-form-field class="w-100" appearance="fill">
              <mat-label class="label-text">Other</mat-label>
              <input class="input-text" type="text" formControlName="other" matInput [errorStateMatcher]="matcher"
                placeholder="Enter Other technology" />
            </mat-form-field>
            <mat-error class="error-style fix-top-margin" *ngIf="this.other_validator == true && this.submitted == true">
              Other technology is required
            </mat-error>
          </div>
        </div>
        
        <div >
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4 mt-3 radio_bg">
          <div  class="radio_label">
          <mat-label>Select Goal Type <span class="red">*</span></mat-label><br>
          </div>
           <div class="radio_button mt-2">
          <mat-radio-group formControlName="ecdc">
              <mat-radio-button [checked]="this.data.ecdc=='EC'" class="mrg-right"  value="0" >Engineering Council (EC)</mat-radio-button>
              <mat-radio-button    [checked]="this.data.ecdc=='DC'" value="1" >Delivery Council (DC)</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <mat-error class="error-style fix-top-margin" *ngIf="modalForm.controls.ecdc?.errors && this.submitted==true">
          Goal Type is required
      </mat-error>
        </div>
        
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 pb-2">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
              [ngClass]="{ 'attachment-div': this.data.attachment != '' }">
              <img class="mr-2" *ngIf="this.data.attachment != ''"
                src="../../../../assets/images/attachment-black.svg" />
              <a class="attachment-display-new" *ngIf="changeTriggered == false; else elseBlock"
                (click)="downloadAttach(this.data.id)">{{ this.data.attachment }}</a>
            </div>
            <ng-template #elseBlock>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" [ngClass]="{
                    'attachment-div':
                      changeTriggered == true && this.data.attachment == ''
                  }">
                  <img class="mr-2" *ngIf="
                      changeTriggered == true && this.data.attachment == ''
                    " src="../../../../assets/images/attachment-black.svg" />
                  <a class="attachment-display mt-2">{{ file.name }}</a>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <label for="firsting" class="attachment_text" id="attachment_btn"><img
                src="../../../../assets/images/attachment-icon.png" />
              Upload certificate</label>
            <input type="file" id="firsting" class="form-control" formControlName="attachmentFile"
              (change)="onChange($event)" />
            <mat-error class="mt-1 error-style fix-top-margin" *ngIf="
                modalForm.controls.attachmentFile?.errors &&
                this.submitted == true
              ">
              Attachment is required
            </mat-error>
            <mat-error class="mt-1 error-style fix-top-margin" *ngIf="
            this.fileSizeInKb>151 &&
           this.submitted == true
         ">
         Max. file size must be 150 KB
       </mat-error>
       <p class="helper-text">
        (Max. file size must be 150 KB and format can be pdf , jpg , jpeg , eml
        only)
      </p>
      <p class="required-styling" *ngIf="!file"></p>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <div class="form-buttons row">
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <button mat-raised-button id="add_btn" (click)="onNewSubmit()">
        SUBMIT
      </button>
    </div>
    <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
      <button (click)="onClose()" mat-raised-button id="cancel_btn">
        CANCEL
      </button>
    </div>
    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
      <button id="draft_btn" (click)="onDraftSubmit()">
        <img src="../../../../assets/images/save-draft.png" />Save as draft
      </button>
    </div>
  </div>
</div>