import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { self_dev_data } from 'src/app/shared/models/self-development.model';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ResponsiveService } from 'src/app/services/responsive.service';

@Component({
  selector: 'app-self-development-form',
  templateUrl: './self-development-form.component.html',
  styleUrls: ['./self-development-form.component.css']
})
export class SelfDevelopmentFormComponent implements OnInit {
  @ViewChild('picker') datePicker!: MatDatepicker<Date>;
  modalForm: UntypedFormGroup;
  first = false;
  second = false;
  mobile: boolean;
  submitted: boolean;
  minDate: Date;
  maxDate: Date;
  matcher: MyErrorStateMatcher;
  constructor(
    public dialogRef: MatDialogRef<SelfDevelopmentFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: self_dev_data,
    private _responsiveService: ResponsiveService,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService
  ) {
    this.matcher = new MyErrorStateMatcher();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.modalForm = this.formBuilder.group({});
    this.submitted = false;
    this.mobile = false;
  }

  ngOnInit(): void {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    if (currentMonth >= 3) {
      this.minDate = new Date(currentYear, 3, 1);
      this.maxDate = new Date(currentYear + 1, 2, 31);
    }
    else if (currentMonth < 3) {
      this.minDate = new Date(currentYear - 1, 3, 1);
      this.maxDate = new Date(currentYear, 2, 31);
    }
    this.modalForm = this.formBuilder.group({
      summary: [this.data.summary, Validators.required],
      description: [this.data.description, Validators.required],
      duration: [this.data.duration, [Validators.min(1), Validators.required]],
      date: [this.data.date, Validators.required],
      ecdc: [this.data.ecdc, Validators.required]
    })
    this._responsiveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
  }

  /**
   * close the form modal
   */
  onClose(): void {
    this.dialogRef.close();
  }

  /**
   * submit the form
   */
  onSubmit = (value?: string): void => {
    this.submitted = true;
    if (this.modalForm.invalid)
      return;
    if (value === 'add') {
      this.data.modalCheck === 'ADD' ? this.setApiCall('newAdd') : this.setApiCall('editedAdd');
    }
    else if (value === 'draft') {
      this.data.modalCheck === 'ADD' ? this.setApiCall('newAddDraft') : this.setApiCall('editedDraft');
    }
    else {
      this.toastr.error('Error while submitting Data')
    }
  }

  /**
   * set the api  calls
   */
  setApiCall = (flagValue: string): void => {
    this.modalForm.value.date = moment(this.modalForm.value.date).format('YYYY-MM-DD');
    this.dialogRef.close({ resultForm: this.modalForm.value, flag: flagValue });
  }

  /**
  * check condition for EC
  * requries for design
  */
  clickEc() {
    if (this.second == true) {
      this.second = false;
    }
    this.first = true;
  }
  /**
  * check condition for DC
  * requries for design
  */
  clickDc() {
    if (this.first == true) {
      this.first = false;
    }
    this.second = true;
  }

  /**
   * open calender
   */
  openCalender(): void {
    this.datePicker.open();
  }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    _form: FormGroupDirective | NgForm | null
  ): boolean {
    return !!(control && control.invalid);
  }
}