import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SelfDevelopmentModuleRoutingModule } from './self-development-module-routing.module';
import { SelfDevelopmentDashboardComponent } from './self-development-dashboard/self-development-dashboard.component';
import { SelfDevelopmentViewComponent } from './self-development-view/self-development-view.component';
import { SelfDevelopmentFormComponent } from './self-development-form/self-development-form.component';


@NgModule({
  declarations: [
    SelfDevelopmentDashboardComponent,
    SelfDevelopmentViewComponent,
    SelfDevelopmentFormComponent
  ],
  imports: [
    CommonModule,
    SelfDevelopmentModuleRoutingModule,
    SharedModule
  ]
})
export class SelfDevelopmentModuleModule { }
