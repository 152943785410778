import { Component, OnInit } from "@angular/core";
import { ResponsiveService } from "src/app/services/responsive.service";
import { Router } from "@angular/router";
import { CommonService } from "src/app/services/common.service";
import { MentorshipService } from "src/app/modules/mentorship/service/mentorship.service";
import { EventService } from "src/app/modules/events/services/event.service";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { EmployeeDropdownData } from "src/app/shared/models/admin-portal.model";
@Component({
  selector: "app-discount-category",
  templateUrl: "./discount-category.component.html",
  styleUrls: ["./discount-category.component.css"],
})
export class DiscountCategoryComponent implements OnInit {
  mobile: boolean;
  modalFormVoter: UntypedFormGroup;
  select_emp = ["Select individual participants", "Select by manager"];
  selectedValuesVoter: Array<string>;
  _employees_list_voter: EmployeeDropdownData[];
  employees_list_voter: EmployeeDropdownData[];
  submitted: boolean;
  searchTextVoter: string;
  voterSet = new Map();
  constructor(
    private mentorshipService: MentorshipService,
    private _responsiveService: ResponsiveService,
    private route: Router,
    private _eventService: EventService,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder
  ) {
    this._employees_list_voter = [] as EmployeeDropdownData[];
    this.employees_list_voter = [] as EmployeeDropdownData[];
    this.selectedValuesVoter = new Array<string>();
    this.searchTextVoter = "";
    this.mobile = false;
    this.submitted = false;
    this.modalFormVoter = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.onResize();
    this._responsiveService.checkWidth();
    this.commonService.setAdminPortalOpened(true);
    this.modalFormVoter = this.formBuilder.group({
      employ_select_voter: [],
      employees_voter: [],
      search: [""],
    });
  }

  listLoadVoter() {
    this.selectedValuesVoter = [];
    this.voterSet.clear();

    if (
      this.modalFormVoter.value.employ_select_voter ==
      "Select individual participants"
    ) {
      this.mentorshipService.employeedropdown().subscribe((data) => {
        this.employees_list_voter = data.data;
        this._employees_list_voter = data.data;
      });
      this.employees_list_voter.forEach((item: EmployeeDropdownData) => {
        this.voterSet.set(item.email, false);
      });
    } else if (
      this.modalFormVoter.value.employ_select_voter == "Select by manager"
    ) {
      this._eventService.getEmployeeListByManager().subscribe((data) => {
        this.employees_list_voter = data;
        this._employees_list_voter = data;
      });
    }
  }
  selectionChangeNew($event: any, selectionType: string) {
    this.voterSet.set(
      $event.options[0]._value.email,
      !this.voterSet.get($event.options[0]._value.email)
    );
    this.func(selectionType);
  }

  func(selectionType: string) {
    if (selectionType == "Select individual participants") {
      for (let [key, value] of this.voterSet) {
        let index = this.selectedValuesVoter.indexOf(key);
        if (value == true && index == -1) {
          this.selectedValuesVoter.push(key);
        }
        if (value == false && index >= 0) {
          this.selectedValuesVoter.splice(index, 1);
        }
      }
    } else if (selectionType == "Select by manager") {
      this.selectedValuesVoter = [];
      for (let [key, value] of this.voterSet) {
        let index = this.selectedValuesVoter.indexOf(key);
        if (value == true && index == -1) {
          this._eventService.getEmployeeUnderManager(key).subscribe((data) => {
            if (data) {
              for (let emp of data) {
                this.selectedValuesVoter.push(emp.email);
              }
            }
          });
        }
        if (value == false && index >= 0) {
          this.selectedValuesVoter.splice(index, 1);
        }
      }
    }
  }
  onSearchChangeVoter() {
    const searchInput = this.searchTextVoter
      ? this.searchTextVoter.toLowerCase()
      : "";
    this.employees_list_voter = this._employees_list_voter.filter(
      (u: EmployeeDropdownData) => {
        const name: string = u.name.toLowerCase();
        return name.indexOf(searchInput) > -1;
      }
    );
  }
  onSubmitVoter() {
    this.submitted = true;
    this.commonService.setEmails(this.selectedValuesVoter);
    if (!this.modalFormVoter.invalid && this.selectedValuesVoter.length > 0) {
      this.modalFormVoter.value.employees_voter = this.selectedValuesVoter;
    }
  }

  routeView(category: string) {
    this.submitted = true;
    if (this.selectedValuesVoter.length === 0) {
      return;
    } else {
      if (category == ">20") {
        this.commonService.setEmails(this.selectedValuesVoter);
        this.route.navigate(["admin-portal/brands-selection", 1]);
      } else if (category == "15-20") {
        this.commonService.setEmails(this.selectedValuesVoter);
        this.route.navigate(["admin-portal/brands-selection", 2]);
      } else if (category == "10-14") {
        this.commonService.setEmails(this.selectedValuesVoter);
        this.route.navigate(["admin-portal/brands-selection", 3]);
      } else if (category == "5-9") {
        this.commonService.setEmails(this.selectedValuesVoter);
        this.route.navigate(["admin-portal/brands-selection", 4]);
      }
    }
  }
  onResize() {
    this._responsiveService.getMobileStatus().subscribe((isMobile) => {
      this.mobile = isMobile;
    });
  }
}
