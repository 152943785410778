import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AdminPortalService } from '../../service/admin-portal.service';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { take } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { AwardServiceService } from 'src/app/modules/award-nomination/service/award-service.service';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import {
  ContributionExcelData,
  FilterCriteria,
  Group,
  TimeData,
  Transaction,
} from 'src/app/shared/models/transaction.model';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-analytic-dashboard',
  templateUrl: './analytic-dashboard.component.html',
  styleUrls: ['./analytic-dashboard.component.css'],
})
export class AnalyticDashboardComponent implements OnInit {
  range: FormGroup;
  multiSelectControl = new FormControl();
  searchControl = new FormControl();
  dropDownData: Group[] = [];
  filteredGroups: Group[] = [];
  managerialData: Group[] = [];
  allEmployeesSelected = false;
  mobile: boolean = false;
  transactionsExcelData: Array<Transaction> = [];
  contributionExcelData: Array<ContributionExcelData> = [];
  manager: boolean = false;
  hr: boolean = false;
  rm: boolean = false;
  hrAndManager: boolean = false;
  ecList: Array<string> = [];
  dcList: Array<string> = [];
  reqObj: FilterCriteria = {} as FilterCriteria;
  color: ThemePalette;
  mode: ProgressSpinnerMode;
  storeData: boolean = false;
  value: number = 50;
  reportingManager: boolean = false;
  admin: boolean = false;
  minDate: Date = new Date(2021, 3, 1);
  maxDate: Date = new Date();
  balanceAmount: number = 0;
  startDate: Date;
  endDate: Date;

  constructor(
    private fb: FormBuilder,
    public adminPortalService: AdminPortalService,
    private responisveService: ResponsiveService,
    private commonService: CommonService,
    private awardService: AwardServiceService,
    private toastr: ToastrService,
    private authService: AuthUserService
  ) {
    this.mode = 'indeterminate';
    this.storeData = true;
    this.color = 'primary';
    const currentYear = new Date().getFullYear();
    const startYear =
      new Date().getMonth() >= 3 ? currentYear : currentYear - 1;

    this.startDate = new Date(startYear, 3, 1);

    this.endDate = new Date();
    this.range = this.fb.group({
      start: [this.startDate],
      end: [this.endDate],
    });
  }
  analyticList: Array<string> = [];
  selectedValue: string = '';

  ngOnInit(): void {
    this.authService.getEmployeeDetailBehaviorSubject().subscribe((item) => {
      if (item?.isRm && item?.isHR) {
        this.hrAndManager = true;
      } else if (item?.isHR) {
        this.hr = true;
      }
      if (item?.isManager) {
        this.manager = true;
      }
      if (item?.isRm) {
        this.reportingManager = true;
      }
      if (item?.isAdminManager || item?.admin) {
        this.admin = true;
      }

      if (
        item?.isHR ||
        item?.isManager ||
        item?.isAdminManager ||
        item?.admin ||
        item?.isRm
      ) {
        this.analyticList.push('Contributions');
        this.selectedValue = 'Contributions';
      }
      if (
        item?.getAccessToTransactionAnalytics ||
        item?.isAdminManager ||
        item?.admin
      ) {
        this.analyticList.push('Transactions');
      }
      if (
        item?.getAccessToTransactionAnalytics &&
        !(item?.isAdminManager || item?.admin)
      ) {
        this.selectedValue = 'Transactions';
      }
    });

    this.onResize();
    this.checkMobile();
    this.reqData();
    this.searchFilter();

    this.commonService.setAdminPortalOpened(true);
  }
  /**
   * Dropdown will show based on the role
   */
  reqData() {
    if (this.hr || this.hrAndManager || this.admin) {
      setTimeout(() => {
        this.dropDown();
      }, 500);
    } else {
      setTimeout(() => {
        this.getManagerData();
      }, 500);
    }
  }

  /**
   * Search EC/DC name
   */
  searchFilter() {
    this.searchControl.valueChanges.subscribe((searchText) => {
      this.filterGroups(searchText);
    });
  }

  /**
   *
   * Triggers API on changing the date
   */
  onDateChange(event: any) {
    if (this.range.value.start && this.range.value.end) {
      this.sendData();
    }
  }

  /**
   * dropdown data for manager
   */
  getManagerData() {
    if (this.reportingManager) {
      this.managerialData.push({
        name: '',
        council: ['My Team (Direct Report)'],
      });
    }
    this.awardService
      .getDcVertical()
      .pipe(take(1))
      .subscribe(
        (dcData: Array<string>) => {
          if (dcData?.length) {
            this.dcList = dcData;
            this.managerialData.push({
              name: 'Delivery Council',
              council: dcData,
            });
          }
          this.awardService.getEcVertical().subscribe(
            (ecData: Array<string>) => {
              if (ecData?.length) {
                this.ecList = ecData;
                this.managerialData.push({
                  name: 'Engineering Council',
                  council: ecData,
                });
              }
              if (this.reportingManager) {
                this.multiSelectControl.setValue(['My Team (Direct Report)']);
              } else if (this.dcList?.length) {
                this.multiSelectControl.setValue([this.dcList[0]]);
              } else {
                this.multiSelectControl.setValue([this.ecList[0]]);
              }
              setTimeout(() => {
                this.sendData();
              }, 100);
            },
            (error) => {
              this.toastr.error(error.error);
            }
          );
        },
        (error) => {
          this.toastr.error(error.error);
        }
      );
  }

  /**
   * Sets data to EC/DC vertical dropdown for HR
   *
   */
  dropDown() {
    this.adminPortalService
      .getDcTechList()
      .pipe(take(1))
      .subscribe((dcList: Array<string>) => {
        this.adminPortalService
          .getEcTechList()
          .pipe(take(1))
          .subscribe((ecList: Array<string>) => {
            this.ecList = ecList;
            this.dcList = dcList;
            this.dropDownData.push({ name: '', council: ['All Employees'] });
            if (this.hrAndManager || this.admin) {
              this.dropDownData.push({
                name: '',
                council: ['My Team (Direct Report)'],
              });
            }
            if (ecList?.length) {
              this.dropDownData.push({
                name: 'Engineering Council',
                council: ecList,
              });
            }
            if (dcList?.length) {
              this.dropDownData.push({
                name: 'Delivery Council',
                council: dcList,
              });
            }

            this.multiSelectControl.setValue(['All Employees']);
            this.filteredGroups = [...this.dropDownData];
            this.handleMultiSelectChange();
          });
      });
  }
  /**
   * This method filters the council name based on the entered data
   * @param searchText -string
   * @returns council
   */
  filterGroups(searchText: string): void {
    if (!searchText) {
      this.filteredGroups = [...this.dropDownData];
      return;
    }

    this.filteredGroups = this.dropDownData
      .map((group) => ({
        name: group.name,
        council: group.council.filter((item) =>
          item.toLowerCase().includes(searchText.toLowerCase())
        ),
      }))
      .filter((group) => group.council.length > 0);
  }

  /**
   * This method send the data to transaction and contribution component ,
   *  which are later on used as payload for the APIs
   */
  sendData() {
    const finalVerticals = this.multiSelectControl.value.filter(
      (val: string) => val !== 'All Employees'
    );

    for (let index = 0; index < finalVerticals.length; index++) {
      if (finalVerticals[index] === 'My Team (Direct Report)') {
        finalVerticals[index] = 'My Team';
      }
    }

    const startDate = this.formatDate(this.range.value.start);
    const endDate = this.formatDate(this.range.value.end);
    this.reqObj = {
      vertical: finalVerticals,
      fromDate: startDate,
      toDate: endDate,
    };
  }

  /**
   * Formats a date string into the specified format 'YYYY-MM-DD HH:mm:ss.SSS'.
   *
   * @param {string} dateString - The date string to format.
   * @returns {string} The formatted date string.
   */
  formatDate(dateString: string): string {
    const date = moment(dateString);

    return date.format('YYYY-MM-DD HH:mm:ss.SSS');
  }

  /**
   * Updates the selected values of the multi-select control based on user interactions.
   * This function checks if "All Employees" is selected and adjusts the selected values
   * accordingly. It ensures that selecting "All Employees" selects all available options,
   * and deselecting it removes all selections. It also maintains a flag to track the
   * selection state of "All Employees".
   */
  handleMultiSelectChange() {
    const values = this.multiSelectControl.value;
    const allValues = this.dropDownData.reduce(
      (acc: string[], group) => acc.concat(group.council),
      []
    );

    if (this.multiSelectControl.value.includes('All Employees')) {
      if (!this.allEmployeesSelected) {
        this.allEmployeesSelected = true;
        setTimeout(() => {
          this.multiSelectControl.setValue(allValues, {
            emitEvent: true,
          });
        }, 50);
      } else {
        this.allEmployeesSelected = false;
        for (
          let index = 0;
          index < this.multiSelectControl.value.length;
          index++
        ) {
          if (this.multiSelectControl.value[index] === 'All Employees') {
            this.multiSelectControl.value.splice(index, 1);
            break;
          }
        }
      }
    } else {
      if (this.allEmployeesSelected) {
        this.allEmployeesSelected = false;
        this.multiSelectControl.setValue([], { emitEvent: true });
      } else {
        if (allValues.length - 1 === this.multiSelectControl.value.length) {
          this.allEmployeesSelected = true;
          this.multiSelectControl.setValue([...allValues], {
            emitEvent: true,
          });
        }
      }
    }

    setTimeout(() => {
      this.sendData();
    }, 50);
  }

  /**
   * check width of the screen
   */
  onResize() {
    this.responisveService.checkWidth();
  }

  /**
   * check mobile screen is true or false
   */
  checkMobile() {
    this.responisveService.getMobileStatus().subscribe((isMobile: boolean) => {
      this.mobile = !!isMobile;
    });
  }

  getTransactionsExcelData(reqPayload: TimeData) {}

  /**
   * Fetches transaction details based on the specified date range, processes the data,
   * and generates an Excel file for download. Displays an error message if the fetch fails.
   */
  getFinancialYear = (dateStr: string): string => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = date.getMonth();
    if (month < 3) {
      return `${year - 1}-${year}`;
    } else {
      return `${year}-${year + 1}`;
    }
  };

  // Group data by financial year
  groupByFinancialYear = (data: any[]): any => {
    return data.reduce((acc, curr) => {
      const year = this.getFinancialYear(curr.StartTime);
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(curr);
      return acc;
    }, {});
  };
  downloadTransactionExcel = (): void => {
    let fromDate = this.formatDate(String(this.startDate));
    let toDate = this.formatDate(String(this.endDate));
    this.storeData = false;
    this.adminPortalService
      .transactionDetails({
        fromDate: '2022-04-01 00:00:00.000',
        toDate: toDate,
      })
      .pipe(take(1))
      .subscribe(
        (data) => {
          this.storeData = true;
          if (data?.length) {
            this.transactionsExcelData = data;
            this.transactionsExcelData.forEach((transaction) => {
              transaction.startTime = this.formatDate(transaction.startTime);
              transaction.endTime = this.formatDate(transaction.endTime);
            });
            const worksheetData = this.transactionsExcelData.map((item) => ({
              TransactionType: item.transactionType,
              Amount: item.amount,
              EmployeeEmail: item.employeeEmail,
              BalanceBeforeTransaction: item.balanceBeforeTransaction
                ? item.balanceBeforeTransaction
                : 'NA',
              GeminiWalletDeduction: item.geminiWalletDeduction
                ? item.geminiWalletDeduction
                : 'NA',
              Discount: item.discount ? item.discount : 'NA',
              StartTime: item.startTime.substring(0, 10),
              EndTime: item.endTime.substring(0, 10),
              TransactionStatus: item.transactionStatus,
              Title: item.title ? item.title : 'NA',
            }));
            const financialYearData = this.groupByFinancialYear(worksheetData);
            console.log(financialYearData);

            const workbook = XLSX.utils.book_new();

            for (const year in financialYearData) {
              if (financialYearData.hasOwnProperty(year)) {
                const worksheet = XLSX.utils.json_to_sheet(
                  financialYearData[year]
                );

                if (worksheet['!ref']) {
                  const range = XLSX.utils.decode_range(worksheet['!ref']); // Get the full range of the sheet

                  worksheet['!autofilter'] = {
                    ref: XLSX.utils.encode_range({
                      s: { r: 0, c: 0 },
                      e: { r: range.e.r, c: 0 },
                    }),
                  };
                }

                XLSX.utils.book_append_sheet(workbook, worksheet, year);
              }
            }

            const excelBuffer = XLSX.write(workbook, {
              bookType: 'xlsx',
              type: 'array',
            });
            this.saveAsExcelFile(excelBuffer, 'Transaction_data');
          }
        },
        (error) => {
          this.toastr.error(error.error);
        }
      );
  };

  saveAsExcelFile = (buffer: any, fileName: string): void => {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const blob = new Blob([buffer], { type: EXCEL_TYPE });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.xlsx`;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  };
  // downloadTransactionExcel() {}

  /**
   * Exports either contributions or transactions data to an Excel file based on the selected value.
   */
  exportToExcel() {
    if (this.selectedValue === 'Contributions') {
      this.downloadContributionExcel();
    } else {
      this.downloadTransactionExcel();
    }
  }

  /**
   * Fetches contribution details based on the specified request object,
   * processes the data, and generates an Excel file for download.
   * Displays an error message if the fetch fails.
   */
  downloadContributionExcel() {
    this.adminPortalService.contributionDetails(this.reqObj).subscribe(
      (response) => {
        if (response?.length) {
          this.contributionExcelData = response;
          const worksheetData = this.contributionExcelData.map(
            (item: ContributionExcelData) => ({
              Points: item.points,
              Name: item.name,
              ContributionSummary: item.contributionSummary || 'NA',
              ContributionDate: item.contributionDate,
              ContributionDescription: item.contributionDescription,
              EmployeeEmail: item.employeeEmail,
              Designation: item.designation,
              ReviewedBy: item.reviewedBy,
              Status: item.status,
              Ecdc: item.ecdc,
            })
          );

          const worksheet = XLSX.utils.json_to_sheet(worksheetData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

          XLSX.writeFile(workbook, 'Contribution Data.xlsx');
        }
      },
      (error) => {
        this.toastr.error(error.error);
      }
    );
  }
  /**
   *
   * @param balance -available balance
   */
  getBalance(balance: number) {
    this.balanceAmount = balance;
  }
}
