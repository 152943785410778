import { take } from 'rxjs/operators';
import { ProjectsService } from './../service/projects.service';
import { Router } from '@angular/router';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ResponsiveService } from 'src/app/services/responsive.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AuthUserService } from 'src/app/services/auth-user.service';
import {  ProjectDetails, project_data } from 'src/app/shared/models/project.model';
import { GenericTable} from 'src/app/shared/models/certificate.model';
import { MatSort } from '@angular/material/sort';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ProjectFormComponent } from '../project-form/project-form.component';
import { ConfirmationModalComponent } from 'src/app/shared/components/confirmation-modal/confirmation-modal.component';
import { DeleteModalComponent } from 'src/app/shared/components/delete-modal/delete-modal.component';
import { ProjectViewComponent } from '../project-view/project-view.component';
import { ProfileData } from 'src/app/shared/models/profile.model';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.css']
})
export class ProjectDashboardComponent implements OnInit {
  pageEvent?: PageEvent;
  screenSize : string;
  projectForm:project_data;
  empEmail:String;
  // year: string;
  fromDate:string;
  toDate:string;
  status: string;
  allProjectsData:GenericTable[];
  allProjectDetails: ProjectDetails[]
  tableLength:number;
  mobile: boolean;
  tablePageIndex:number;
  ecdcMap = {
    EC: 'Engineering Council',
    DC: 'Delivery Council',
  };
  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  constructor(
    private responsiveService: ResponsiveService,
    private router :Router,
    private authService : AuthUserService,
    private commonService: CommonService,
    private projectService: ProjectsService,
    public dialog: MatDialog,
    private toastr : ToastrService
  ) { 
    this.screenSize='';
    this.empEmail='';
    this.allProjectsData= [] as GenericTable[];
    this.allProjectDetails=[] as ProjectDetails[];
    this.tableLength=0;
    this.tablePageIndex=0;
    this.mobile=false;
    // this.year='';
    this.fromDate='';
    this.toDate='';
    this.status='allStatus';
    this.projectForm={id:0,projectName:'',clientName:'',duration:0,description:'',projectType:'',action:'',modalCheck:'',status:'',createdOn:'',date:'',duration_radio:'',ecdc:''};
  }

  ngOnInit(): void {
    this.commonService.setAdminPortalOpened(false);
    this.fromDate = this.commonService.getYearsData().fromDate;
    this.toDate = this.commonService.getYearsData().toDate;
    this.getEmployeeDetails();
    this.getAllProjects();
    this.checkScreenStatus();
    this.onResize();
    this.responsiveService.getMobileStatus().subscribe((isMobile:boolean) => {
      this.mobile = isMobile;
    });
  }

  // getYearsData(){
  //   let currentMonth = new Date().getMonth() + 1;
  //   let year : any  =  `${moment().year()}`;
  //   if (currentMonth <= 3) {
  //     // new Date(year - 1, 0o4, 0o1, 0o0, 0o0, 0o0, 0o1);
  //     this.fromDate = `${moment().year() - 1}-04-01 00:00:00.001`;
  //     this.toDate = `${moment().year()}-03-31 23:59:59.999`;
  //   } else {
  //     this.fromDate = `${moment().year()}-04-01 00:00:00.001`;
  //     this.toDate = `${moment().year() + 1}-03-31 23:59:59.999`;
  //   }
  // }

  ngAfterViewChecked(): void {
    if (this.allProjectsData.length != 0) {
      const list = document.getElementsByClassName('mat-paginator-range-label');
      this.tableLength > 0
        ? (list[0].innerHTML =
            'Page: ' +
            (this.tablePageIndex + 1).toString() +
            ' of ' +
            Math.ceil(this.tableLength / 5))
        : (list[0].innerHTML = 'Page: 0 of 0');
    }
  }

  /**
   * get employee details (email)
   */
  getEmployeeDetails(): void {
    this.authService.getEmployeeDetailBehaviorSubject().subscribe((item:ProfileData) => {
      if (item?.data?.email) {
        this.empEmail = item?.data?.email;
      }
    });
  }

/**
 * Provide the data for each page
 * @param {PageEvent}event
 * @returns event
 */
  getAllProjects(event?: PageEvent) : PageEvent{
    this.allProjectsData = [] as GenericTable[];
    let pageNo = 0;
    pageNo = event ? (event.pageIndex + 1) : 1;
    this.tablePageIndex = event ? event.pageIndex : 0;

    this.projectService.getAllProject(this.empEmail, pageNo, this.fromDate,this.toDate, this.status).pipe(take(1))
    .subscribe({
      next: (data) => {

        if (data && data.data) { 
          this.allProjectDetails=[];
          for (let ProjectData of data.data) {  
            
            let createdOn=ProjectData.createdOn;
            ProjectData.myArray = createdOn.split(' ');
            ProjectData.clientName = ProjectData.project.clientName
            ProjectData.duration = ProjectData.project.duration
            ProjectData.projectType = ProjectData.project.projectType
            ProjectData.date = ProjectData.contributionDate.substr(0,10);
            ProjectData.projectName = ProjectData.summary; 
            ProjectData.duration_radio = (ProjectData.duration == 0) ? 'ongoing' : 'completed';
            let ProjectTableData = {} as GenericTable;
            ProjectTableData.id = ProjectData.id;
            ProjectTableData.title = ProjectData.summary;
            ProjectTableData.createdOn = ProjectData.createdOn.substr(
              0,
              10
            );
            ProjectTableData.ecdc =
            ProjectData.ecdc === 'DC'
                ? this.ecdcMap.DC
                : this.ecdcMap.EC;
                ProjectTableData.status = ProjectData.status;
            this.allProjectsData.push(ProjectTableData);
            this.allProjectDetails.push(ProjectData);
          }
          this.tableLength = data.total_rows;
        }
      },
      error: (error) => {},
    });

  
    if (event) {
      return event;
    }
    return new PageEvent();
  }

  /**
 * Add new project
 */
  addProjects(){
    this.projectForm = {
      id: 0,
      projectName: '',
      clientName:'',
     duration:0,
     description:'',
      projectType:'',
      action: '',
      modalCheck: 'ADD',
      status: '',
      createdOn:'',
      date:'',
      duration_radio:'',
      ecdc:''
    }
    const dialogRef = this.dialog.open(ProjectFormComponent, {
      width: '650px',
      panelClass: 'my-dialog',
      data: this.projectForm
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
         this.commonService.createProjectCommon(result,this.empEmail).then(() => {
          this.getAllProjects();
        })
      }
    });
}

/**
 * Delete project 
 * @param {number}id - Project id
 */
deleteProjects(id : number){
  const dialogRef = this.dialog.open(DeleteModalComponent, {
    width: '481px',
    data: {
      warningTitle: 'Are you sure you want to delete this contribution?',
    },
  });
  dialogRef.afterClosed().pipe(take(1)).subscribe({
    next: (data) => {
      if (data.yesClicked) {
        this.projectService.deleteproject(id).pipe(take(1)).subscribe({
          next: (data) => {
            this.deleteConfirmationModal();
          },
          error: (error) => {},
        });
      }
    },
  });
} 

/**
 * delete confirmation  
 */
deleteConfirmationModal(): void {
  let genericDailogData = {
    title: 'Contribution deleted successfully!',
    img: 'assets/images/n_image/Success.gif',
    heading: 'Success!',
  };
  const dialogRef = this.dialog.open(ConfirmationModalComponent, {
    width: '513px',
    data: genericDailogData,
  });
  dialogRef.afterClosed().pipe(take(1)).subscribe({
    next: () => {
      this.getAllProjects();
    },
  });
}

/**
 * View project
 * @param {number}id - Project id
 */
viewProjects(id : number){
    this.dialog.open(ProjectViewComponent, {
      width : '810px',
      panelClass: 'my-dialog',
    data: id
  });
}

/**
 * Edit project
 * @param {number}id - Project id
 */
editProjectsDetails(id : number){
  let ProjectDetails = this.allProjectDetails.filter(
    (project : any) => {
      return project.id === id;
    }
  );
  const dialogRef = this.dialog.open(ProjectFormComponent, {
    width: '650px',
    panelClass: 'my-dialog',
    data: ProjectDetails[0],
  });
  dialogRef.afterClosed().pipe(take(1)).subscribe({
    next: (result) => {
      if (result) {
        this.commonService
          .createProjectEdit(result, id, this.empEmail)
          .then((status) => {
              this. getAllProjects();
          });
      }
    },
  });
 }

  /**
   * check screen status
   */
  checkScreenStatus(): void {
    this.responsiveService.getScreenStatus().subscribe((screenSize: string) => {
      if (screenSize) {
        this.screenSize = screenSize;
      }
    });
  }

  /**
   * Check screen width
   */
  onResize(): void {
    this.responsiveService.checkWidth();
  }

  /**
   * For small screen
   * @returns screen size in string
   */
  isScreenSizeSmall(): boolean {
    return this.screenSize === 'sm';
  }

  /**
   * For extra small screen
   * @returns screen size in string
   */
  isScreenSizeXS(): boolean {
    return this.screenSize === 'xs' || this.screenSize === 'sm';
  }

  /**
   * Routing back to dashboard
   */
  routeBackToMainDashboard() {
    this.router.navigate(['/main-dashboard']);
  }

}
