import { genericDailogData } from './../../models/confirmation-modal.model';
import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { dailogData } from '../../models/confirmation-modal.model';
@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {  
  constructor(public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dailogData,
    @Inject(MAT_DIALOG_DATA) public genericData : genericDailogData) { 

  }
 
  ngOnInit(): void {
    setTimeout(() => {
      this.dialogRef.close();
    }, 2500);
  }
  onClose() {
    this.dialogRef.close();
  }
}





