<div id="navbar" *ngIf="isLoggedIn == true" class="navbar">
  <div></div>
  <div class="row w-100">
    <div [ngClass]="{ 'col-sm-10': !mobile, 'col-8': mobile }">
      <div class="container">
        <div class="brand-logo" title="ContriPoint">
          <a class="navbarbrand" href="#">
            <img
              src="../../assets/images/contripoint-logo.png"
              alt="contripoint-logo"
              width="100%"
              height="100%"
            />
          </a>
        </div>
        <div class="container width-css brand-tabs">
          <div class="row w-261">
            <div  
              title="Home"
              class="col-5"
              [routerLinkActive]="['active-tab']"
              [routerLinkActiveOptions]="{ exact: true }"
              title="Home"
            >
              <a
                class="d-flex flex-row brand-tab-label1 justify-content-center align-items-center mt-2"
                routerLink="/dashboard"
              >
                <img
                  class="vertical-align top-mar faq"
                  src="../../assets/images/home.svg"
                />
                <span class="text-dark ml-1">Home</span>
              </a>
            </div>
            <div class="col-5" [routerLinkActive]="['active-tab']" title="Event">
              <a
                class="d-flex flex-row brand-tab-label2 justify-content-center align-items-center mt-2"
                routerLink="/event"
              >
                <img
                  class="vertical-align top-mar faq"
                  src="../../assets/images/event.svg"
                />
                <span class="text-dark ml-1">Events</span>
              </a>
            </div>
          </div>
          <div class="row w-261">
            <div class="col-5" [routerLinkActive]="['active-tab']" title="Rewards">
              <a
                class="d-flex flex-row brand-tab-label3 justify-content-center align-items-center mt-2"
                routerLink="/rewards"
              >

                <img
                  class="vertical-align top-mar faq"
                  src="../../assets/images/reward.svg"
                />
                <span class="text-dark ml-1">Rewards</span>
              </a>
            </div>
            <div class="col-5 ml-1" [routerLinkActive]="['active-tab']" title="FAQs">
              <a
                class="d-flex flex-row brand-tab-label4 justify-content-center align-items-center mt-2"
                routerLink="/faq-section"
              >
                <img
                  class="vertical-align top-mar faq faq-icon"
                  src="../../assets/images/FAQ .svg"
                />
                <span class="text-dark faq-text ml-2">FAQs</span>
              </a>
            </div>
          </div>
          <div class="row w-261">
            <div
              title="My Team"
              class="col-6 d-flex"
              [routerLinkActive]="['active-tab']"
              *ngIf="checkManager"
              title="My Team"
            >
              <a
                class="d-flex flex-row brand-tab-label4 justify-content-center align-items-center mt-2"
                routerLink="/dashboard/my-team"
              >
                <img
                  class="vertical-align top-mar faq team-icon"
                  src="../../assets/images/my team.svg"
                />
                <span class="text-dark faq-text ml-2">My Team</span>
              </a>
            </div>
            <div class="col-5" [routerLinkActive]="['active-tab']"*ngIf="isAdmin==true" title="Admin Portal">
              <a
                routerLink="/admin-portal"
                class="d-flex flex-row brand-tab-label4 justify-content-center align-items-center mt-2"
                >
                <img
                  class="vertical-align top-mar faq"
                  src="../../assets/images/Admin Portal icon.svg"
                />
                <span class="text-dark faq-text ml-2">Admin Portal</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      [ngClass]="{ 'col-sm-2': !mobile, 'col-4': mobile }"
      class="yuph vertical-align"
    >
      <div class="row">
        <div [ngClass]="{ 'col-sm-4': !mobile, 'col-3': mobile }" title="Notifications">
          <mat-icon
            [matBadgeHidden]="this.commonService.getNewNotificationCount() < 1"
            matBadge="{{this.commonService.getNewNotificationCount()}}"
            matBadgeColor="warn"
          >
            <img
              class="rounded-circle vertical-align"
              src="../../assets/images/notification.svg"
              (click)="getNewNotification('NEW')"
              [matMenuTriggerFor]="aa"
              (menuClosed)="menuClosed()"
              #menuBtn1
            />
          </mat-icon>
        </div>
        <mat-menu
          #aa="matMenu"
          xPosition="before"
          backdropClass="custClass"
          class="my-class"
        >
          <div
            *ngIf="notifyData && notifyData.length > 0"
            class="notification-container"
          >
            <h1 class="heading">NOTIFICATIONS</h1>
            <mat-divider class="w-100"></mat-divider>
            <div class="d-flex flex-nowrap bd-highlight">
              <div class="p-2 bd-highlight notification-count">
                {{ this.commonService.getNewNotificationCount() }} New Notifications
              </div>
              <div class="p-2 bd-highlight">
                <p (click)="refresh($event)" class="view-text ptr">Refresh</p>
              </div>
            </div>
            <mat-divider class="w-100"></mat-divider>
            <ul class="ul-notification" *ngFor="let notification of notifyData">
              <div
                class="d-flex flex-nowrap bd-highlight notification-row cursor-styling"
                (click)="
                  notificationRedirection(
                    notification.type,
                    notification.eventId,
                    notification.eventType
                  )
                "
              >
                <div class="p-2 bd-highlight" style="width: 19%">
                  <img
                    *ngIf="notification.type == 'TrainingSession'"
                    src="../../assets/images/trainings-sessions.svg"
                  />
                  <img
                    *ngIf="notification.type == 'Mentorship'"
                    src="../../assets/images/mentorship.svg"
                  />
                  <img
                    *ngIf="notification.type == 'TeamBuilding'"
                    src="../../assets/images/team-building.svg"
                  />
                  <img
                    *ngIf="notification.type == 'Projects'"
                    src="../../assets/images/projects.svg"
                  />
                  <img
                    *ngIf="notification.type == 'ClientFeedback'"
                    src="../../assets/images/client-feedback.svg"
                  />
                  <img
                    *ngIf="notification.type == 'Certificate'"
                    src="../../assets/images/certifications.svg"
                  />
                  <img
                    *ngIf="notification.type == 'SelfDevelopment'"
                    src="../../assets/images/self-development.svg"
                  />
                  <img
                    *ngIf="notification.type == 'Interview'"
                    src="../../assets/images/interviews-taken.svg"
                  />
                  <img
                    *ngIf="notification.type == 'Enrolled Successfully'"
                    src="../../assets/images/enrolledSuccessfully.png"
                  />
                  <img
                    *ngIf="notification.type == 'Event Approved'"
                    src="../../assets/images/eventApproved.png"
                  />
                  <img
                    *ngIf="notification.type == 'New Event Created'"
                    src="../../assets/images/eventCreated.png"
                  />
                  <img
                    *ngIf="notification.type == 'Event Ended'"
                    src="../../assets/images/eventEnded.png"
                  />
                  <img
                    *ngIf="notification.type == 'Event Rejected'"
                    src="../../assets/images/eventRejected.png"
                  />
                  <img
                    *ngIf="
                      notification.type == 'Hurry Up!!' ||
                      notification.type == 'Hurry Up!'
                    "
                    src="../../assets/images/hurryUp.png"
                  />
                  <img
                    *ngIf="notification.type == 'Pending Activity for Approval'"
                    src="../../assets/images/pendingApproval.png"
                  />
                  <img
                    *ngIf="
                      notification.type == 'Reminder to give Rating!' ||
                      notification.type == 'You’ve been added as a voter'
                    "
                    src="../../assets/images/reminderAndVoter.png"
                  />
                  <img
                    *ngIf="notification.type == 'Reminder for Enrollment!'"
                    src="../../assets/images/reminderEnrollment.png"
                  />
                  <img
                    *ngIf="notification.type == 'Winners Are Out !'"
                    src="../../assets/images/winnersOut.png"
                  />
                  <img
                    *ngIf="notification.type == 'You Won a Prize!'"
                    src="../../assets/images/wonPrize.png"
                  />
                  <img *ngIf="notification.type=='Delegated Contribution Received!'" src='../../assets/images/delegate.svg'>
                </div>
                <div class="bd-highlight" style="width: 68%">
                  <div class="p-1 bd-highlight header-text">
                    {{ notification.type }}
                  </div>
                  <div class="p-1 bd-highlight notification-text">
                    {{ notification.notificationMessage }}
                  </div>
                </div>
                <div class="p-2 bd-highlight" style="width: 17%">
                  <div class="bd-highlight time-date">
                    <p *ngIf="notification.date == this.currentDate">
                      {{ notification.time }}
                    </p>
                    <p *ngIf="notification.date != this.currentDate">
                      {{ notification.date | date: "dd" }}/{{
                        notification.date | date: "MM"
                      }}/{{ notification.date | date: "YY" }}
                    </p>
                  </div>
                  <div class="bd-highlight image-container">
                    <img
                      *ngIf="notification.read == '1'"
                      src="../../assets/images/Ellipse 55.svg"
                    />
                  </div>
                </div>
              </div>
              <mat-divider class="w-100"></mat-divider>
            </ul>
          </div>
          <ul class="ul-notification">
            <div
              *ngIf="navBool == false && notifyData && notifyData.length < 1"
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ul-notification"
            >
              <img
                class="example-notification"
                src="../../assets/images/amgub-xm6fd.svg "
              />
            </div>
          </ul>
          <div *ngIf="notifyData && notifyData.length > 4 && nextpageData>=1" class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 centre_align mb-2"
            >
              <button
                class="view_more"
                (click)="getNewNotification('MORE', $event)"
              >
                VIEW MORE
              </button>
            </div>
          </div>
        </mat-menu>

        <div [ngClass]="{ 'col-sm-4': !mobile, 'col-4': mobile }" title="Menu">
          <mat-icon>
            <a>
              <img
                class="vertical-align"
                src="../../assets/images/navbar-person.png"
                [matMenuTriggerFor]="navbarPerson"
              />
              <div
                id="contribution-history"
                joyrideStep="contribution-history"
                title="Contribution History/Logout"
                stepPosition="bottom"
                text="This section allows you to view your respective contributions for the Previous Financial Years. In addition to this, the manager can also view his team's contribution for the previous years."
              ></div>
            </a>
          </mat-icon>
        </div>
        <mat-menu #navbarPerson="matMenu" yPosition="below">
          <button mat-menu-item (click)="openDialog()">
            <mat-icon>person</mat-icon>
            <span >View Profile</span>
          </button>
          <button mat-menu-item routerLink="/checkout">
           <mat-icon>shopping_cart_checkout</mat-icon>
            <span >Checkout</span>
          </button>
          <button mat-menu-item routerLink="dashboard/contribution-history">
            <mat-icon>restore</mat-icon>
            <span>Contribution History</span>
          </button>
          <button mat-menu-item (click)="Logout()">
            <mat-icon class="logoutButton">power_settings_new</mat-icon>
            <span>Logout</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</div>
