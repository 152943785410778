import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthUserService } from '../services/auth-user.service';
import { MyProfileService } from '../modules/dashboard/dash-info-cards/service/my-profile.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {

  constructor(
    private authUserService: AuthUserService,
    private router: Router,
    private profileService:MyProfileService
  ) {
    
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.profileService.getDetails().pipe(
      map((data) => {
        if (data?.admin || data?.isAdminManager) {
         
          return true;
        } else {
          this.router.navigate(['/main-dashboard']);
          return false;
        }
      })
    );
  }
  
}
