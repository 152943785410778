import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormatter',
})
export class NumberFormatterPipe implements PipeTransform {
  transform(value: number | string): string {
    if (value === null || value === undefined) {
      return '';
    }

    let [integerPart, decimalPart] = value.toString().split('.');

    const lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    const formattedInteger =
      otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
      (otherDigits ? ',' : '') +
      lastThreeDigits;

    decimalPart = (decimalPart || '0').padEnd(2, '0');

    return `${formattedInteger}.${decimalPart}`;
  }
}
