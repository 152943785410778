
<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">
<app-loader-component></app-loader-component>
<div *ngIf="maintainencePageStatus.isWorking">
  <app-under-maintenance
    [serverImage]="maintainencePageStatus.serverImg"
  ></app-under-maintenance>
</div>
<div
  *ngIf="
    !maintainencePageStatus.isWorking && loginServerStatus.isWorking
  "
>
  <app-under-maintenance
    [serverImage]="loginServerStatus.serverImg"
  ></app-under-maintenance>
</div>
<div
  *ngIf="
    !maintainencePageStatus.isWorking &&
    !loginServerStatus.isWorking &&
    !fullServerStatus.userTrackingApiWorking &&
    !fullServerStatus.notificationApiWorking
  "
>
  <app-under-maintenance
    [serverImage]="fullServerStatus.serverImg"
  ></app-under-maintenance>
</div>

<div
  (window:resize)="onResize()"
  *ngIf="
    !maintainencePageStatus.isWorking &&
    !loginServerStatus.isWorking &&
    (fullServerStatus.userTrackingApiWorking ||
    fullServerStatus.notificationApiWorking)
  "
  [ngClass]="{
    'body-container-desktop': !mobile && isLoggedIn == true,
    'body-container-mobile': mobile && isLoggedIn == true,
    'body-login': isLoggedIn == false
  }"
> 

<app-header-section *ngIf="isLoggedIn == true && !isAdminOpened()" class="h-100"></app-header-section>
 <div class="pt-3">
    <app-side-navbar [ngClass]="{ 'top-margin': !isAdminOpened() && isLoggedIn }" *ngIf="(isLoggedIn && !isAdminOpened())"
   [hidden]="show==true && isScreenSizeXSM() " ></app-side-navbar>
   

  <mat-sidenav-container [hasBackdrop]="false" *ngIf=" !isAdminOpened() ;else elseBlock"  >
    <mat-sidenav  *ngIf="this.commonService.getNominationSideBarStatus()===false"
      #sidenav
      mode="over"
      [opened]="sidenavFunc()"
      position="end"
      class="side "
      [ngClass]="{'mob-sidebar-width' : isScreenSizeXS() ||isScreenSizeXSM(), 'sidebar-width':!isScreenSizeXS(),'sidebar-padding':isLoggedIn}"
 
    >
    <app-leaderboard-sidebar></app-leaderboard-sidebar>    
  </mat-sidenav>
  <mat-sidenav *ngIf="this.commonService.getNominationSideBarStatus()===true"    
  #sidenav
  mode="over"
  [opened]="viewJourneyFunc()"
  position="end"
  class="side "
  [ngClass]="{'mob-sidebar-width' : isScreenSizeXS() ||isScreenSizeXSM(), 'nomination-sidebar-width':!isScreenSizeXS(),'sidebar-padding':isLoggedIn}"
>
    <app-nomination-journey></app-nomination-journey>
  </mat-sidenav>
    <mat-sidenav-content  style="overflow: hidden"  [ngClass]="{'sidebar-padding':!mobile && isLoggedIn, 'sidebar-mob-padding' : mobile &&isLoggedIn}">
      <div  [ngClass]="{' top-margin' :!isAdminOpened() && isLoggedIn,'sidebar-padding':isLoggedIn}">
      <app-leaderboard-dash *ngIf="isLoggedIn == true && !isAdminOpened() && !mobile">  </app-leaderboard-dash>
      <div class="mobile" *ngIf="mobile && isLoggedIn">
        <img  (click)="sidebarFunc()" src="assets/images/Leaderboardicon.svg" alt="Leaderboardicon">
      </div>
      <router-outlet class="ml-2"></router-outlet>
    </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <ng-template #elseBlock>
    <mat-sidenav-container class="w-100 " [ngClass]="{ 'side-nav-height': isAdminOpened() }">
      <mat-sidenav class="admin-portal"
        #sidenavigation
        [(opened)]="opened"
        (opened)="open()"
        (closed)="close()"
        *ngIf="isAdminOpened()"
        [mode]="sideNavMode"
      >
        <app-admin-sidebar ></app-admin-sidebar>
      </mat-sidenav>
      <mat-sidenav-content style="overflow: hidden">
        <div [ngClass]="{'top-margin' :!isAdminOpened() && isLoggedIn}">
          <app-admin-navbar></app-admin-navbar>
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

  </ng-template>

 </div>




</div>
